const BASE_URL = "https://apiv3.multihub.com.au/";

// const BASE_URL = "http://192.168.1.107:8001/";


export async function VerifyAccount_Api(offer_data) {
  const res = await fetch(BASE_URL + "verify/account/", {
    method: "POST",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
    body: JSON.stringify(offer_data),
  });
  console.log(res);
  var jsonData = await res.json();
  console.log(jsonData);
  return jsonData;
}
 