import React, { useState } from "react";
import LoginForm from "../LoginForm";
import logo from "../../assets/logo.png";
import SignUpForm from "../SignUpForm";
import {
  SignUp_Api,
  SignIn_Api,
  ForgotPassword_Api,
} from "../../Busniess_Logic/API/AccountApi";
import { setUser } from "../../utils/userinfo";
import ForgotPasswordForm from "../ForgotPasswordForm";

function Account(props) {

  const [isLogin, setlogin] = useState(true);

  const [isLoading, setloading] = useState(false);

  const [forgotpassword, setforgotpassword] = useState(false);

  const [skip, setskip] = useState(false);

  const SignUp = async (signup_data) => {
    setloading(true);

    try {
      let signup = {
        fullname: signup_data.fullname,
        email: signup_data.email,
        phonenumber: signup_data.phonenumber,
        password: signup_data.password,
      };

      console.log("before =>", signup);
      const res = await SignUp_Api(signup);
      console.log("response =>", res);

      if (res) {
        if (res.success) {
          setloading(false);
          alert(
            `Your Registration Done !!`,
            `Please check your email for account verification`
          );
          setlogin(true);
        } else {
          setloading(false);
          alert(res.response);
        }
      }
    } catch (error) {
      setloading(false);
    }
  };

  const SignIn = async (signin_data) => {
    setloading(true);

    try {
      let signindata = {
        email: signin_data.login_email.toString().toLocaleLowerCase(),
        password: signin_data.login_password,
      };

      console.log("signindata =>", signindata);

      const res = await SignIn_Api(signindata);
      console.log(res);

      if (res) {
        if (res.success) {
          console.log(res);
          setloading(false);
          setUser({ id: res.user_id, token: res.token });
          props.closeModal()
        } else {
          setloading(false);
          alert(res.response);
        }
      }
    } catch (error) {
      setloading(false);
    }
  };

  const ForgotPasswordApi = async (email) => {
    setloading(true);
    console.log(email)
    try {
      const res = await ForgotPassword_Api(email);
      console.log(res);

      setloading(false);
      if (res)
        if (res.success) {
          alert("Password reset link sent to your email");
          setforgotpassword(false);

        } else alert(res.response);

    } catch (error) {
      alert(error);
      setloading(false);
    } 
  };

  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="z-50 modal-bg rounded-lg shadow-xl w-11/12 md:w-96 px-4 py-8 md:p-8">
        
        <div className="w-full flex flex-col justify-center items-center mb-5">
          <img
            src={logo}
            className="w-40 md:w-48 justify-center h-full mb-1"
          />
          <p className="text-gray-400 text-sm mb-3 font-light text-center">
              {`Create FREE Account Enjoy All Features`}
          </p>
        </div>

        <div className="mb-8">
            <p className="text-white text-xl font-semibold text-left">
                { forgotpassword ? `Reset password` : isLogin ? `Log In` : `Sign Up`}
            </p>
            <p className="text-gray-400 text-xs font-light text-left">
                {`Track your bets anywhere anytime`}
            </p>
        </div>

        {forgotpassword ? (
          <ForgotPasswordForm
            onSubmit={(email) => ForgotPasswordApi(email)}
            onClose={() => setforgotpassword(false)}
            isLoading={isLoading}
          />
        ) : (
          <div>
            {isLogin ? (
              <LoginForm
                isLogin={isLogin}
                onChangeForm={() => setlogin(!isLogin)}
                onLoginSubmit={(data) => SignIn(data)}
                onForgotPassword={() => setforgotpassword(true)}
                isLoading={isLoading}
              />
            ) : (
              <SignUpForm
                isLogin={isLogin}
                onChangeForm={() => setlogin(!isLogin)}
                onLoginSubmit={(data) => SignIn(data)}
                onSignupSubmit={(data) => SignUp(data)}
                isLoading={isLoading}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Account;
