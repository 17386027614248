import React, { useEffect, useImperativeHandle, useState } from "react";
import { extractKeyList, GetTeamImageByTeamId, BBLLengendList,} from "./BBL_Constant";
import '../../utils/styles/table/table.scss'
import { FormatDate } from "../Utils/Utils";

const greeen = '#26e169';
const mediumGreeen = '#73ec9e';
const lightGreeen = '#c1f6d4';


function BBLPlayerTable(props) {

    const [cat, setCat] = useState(null)
    const [sortedth, setSortedth] = useState(null)
    const [playerList, setPlayerlist] = useState([])

    const [onHover, setHover] = useState(false)
    const [hoverkey, setHoverkey] = useState(null)

    useEffect(() => {

        setCat(props.cat)
        sortByList('total', props.player_list)

    }, [props.cat,props.hthPlayerList])

    useImperativeHandle(null, () => {
        //console.log('useImperativeHandle')
    })

    const ViewPlayer = (item) => {
        props.his.push('/bblplayer?id=' + item + '&tc=' + props.bgcolor+'&s='+props.season)
    }

    // ascending and descending
    const compareByAsc = (category, key) => {
        return function (a, b) {
            if (a[category] !== null && a[category][key] !== null && b[category] !== null && b[category][key] !== null) {
                if (a[category][key] < b[category][key]) return -1;
            } else return 0

            if (a[category] !== null && a[category][key] !== null && b[category] !== null && b[category][key] !== null) {
                if (a[category][key] > b[category][key]) return 1
            } else return 0;
            return 0;
        };
    }

    const compareByDesc = (category, key) => {
        return function (a, b) {
            if (a[category] !== null && a[category][key] !== null && b[category] !== null && b[category][key] !== null) {
                if (a[category][key] < b[category][key]) return 1;
            } else return 0

            if (a[category] !== null && a[category][key] !== null && b[category] !== null && b[category][key] !== null) {
                if (a[category][key] > b[category][key]) return -1;
            } else return 0
            return 0;
        };
    }

    const checkKey = (key) => {

        if (key === 'player') return 'name'
        else if (key === '#') return 'player_number'
        else if (key === 'gp') return 'gp'

        let result = key
        extractKeyList.map((x)=>{
            if(x.category === cat)
                if (x.name.toLowerCase() === key.toString().toLowerCase()) 
                    result = x.key
        })
        return result
    }

    const sortBy = (key) => {
        
        setSortedth(key)
        key = checkKey(key)

        var categoryName = getCategoryName(props.cat)

        let notNullList = []
        var nullList = []

        playerList.map((x) =>  x[categoryName] && x[categoryName][key] !== null ? notNullList.push(x) : nullList.push(x)  )

        let arrayCopy = [...notNullList];
        const arrInStr = JSON.stringify(arrayCopy);

        // compareByAscNameIndex compareByDescNameIndex
        if (key === 'name' || key === 'player_number') arrayCopy.sort(compareByDescNameIndex(key))
        else arrayCopy.sort(compareByDesc(categoryName, key));

        const arrInStr1 = JSON.stringify(arrayCopy);
        if (arrInStr === arrInStr1) {
            if (key === 'name' || key === 'player_number') arrayCopy.sort(compareByAscNameIndex(key))
            else arrayCopy.sort(compareByAsc(categoryName, key));
        }

        var alllist = arrayCopy.concat(nullList)
        setPlayerlist(alllist)
    }

    const sortByList = (key, list) => {
        if(list !=null && list!=undefined){
            let notNullList = []
            let nullList = []

            var categoryName = getCategoryName(props.cat)
            list.map((x) => x[categoryName] && x[categoryName][key] !==null ? notNullList.push(x) : nullList.push(x) )

            let arrayCopy = [...notNullList];
            arrayCopy.sort(compareByDesc(categoryName, key));

            let alllist = arrayCopy.concat(nullList)
            setPlayerlist(alllist)
        }
    }

    const getCategoryName =(cat)=>{
        if(cat === 1) return 'run'
        else if(cat === 2) return 'four'
        else if(cat === 3) return 'six'
        else if(cat === 4) return 'wicket'
    }

    const compareByAscNameIndex = (key) => {
        if (key === 'player_id')
            return function (a, b) {
                if (parseInt(a[key]) > parseInt(b[key])) return 1;
                if (parseInt(a[key]) < parseInt(b[key])) return -1;
                return 0;
            };
        else return function (a, b) {
            if (a[key] > b[key]) return 1;
            if (a[key] < b[key]) return -1;
            return 0;
        };
    }

    const compareByDescNameIndex = (key) => {
        if (key === 'player_id')
            return function (a, b) {
                if (parseInt(a[key]) < parseInt(b[key])) return 1;
                if (parseInt(a[key]) > parseInt(b[key])) return -1;
                return 0;
            };
        else
            return function (a, b) {
                if (a[key] < b[key]) return 1;
                if (a[key] > b[key]) return -1;
                return 0;
            };
    }

    const colorBy = (key, value) => {

        if (value === 0.0 || value === 0 || value === null) return ' '
        else if (value !== null && key !== 'margin') {

            let nullRemoveList = []
            let nullList = []

            let categoryName = getCategoryName(props.cat)
            playerList.map((x) => x[categoryName] && x[categoryName][key] !==null ? nullRemoveList.push(x) : nullList.push(x) )
           
            let arrayCopy = [...nullRemoveList];
            if(arrayCopy.length >= 1){

                const arrInStr = JSON.stringify(arrayCopy);
                var max = arrayCopy.reduce((prev, current) => prev[categoryName][key] > current[categoryName][key] ? prev : current)

                if (max[categoryName][key] === value) return greeen

                let maxIndex = arrayCopy.indexOf(max)

                if (maxIndex > -1) {
                    arrayCopy.splice(maxIndex, 1);
                }

                if(arrayCopy.length >= 1){
                    let max2 = arrayCopy.reduce((prev, current) => (prev[categoryName][key] > current[categoryName][key] ? prev : current))
                    if (max2[categoryName][key] === value) return mediumGreeen

                    let maxIndex2 = arrayCopy.indexOf(max2)

                    if (maxIndex2 > -1) {
                        arrayCopy.splice(maxIndex2, 1);
                    }
                }
                
                if(arrayCopy.length >= 1){
                    let max3 = arrayCopy.reduce((prev, current) => (prev[categoryName][key] > current[categoryName][key] ? prev : current))
                    if (max3[categoryName][key] === value) return lightGreeen
                }
            }
        }
    }

    const colorByValues = (key, value) => {
        if (value === 0 || value === null) return ''
        else if (value !== null && key != 'team score') {

            if (key === 'counts') {
                let modifedValue = parseFloat(value.toString().replace('%',''))
                if ( modifedValue >= 80) return greeen;
                else if (modifedValue >= 60 && modifedValue <= 79.99) return mediumGreeen;
                else if (modifedValue >= 40 && modifedValue <= 59.99) return lightGreeen;
            }

        }
        else if (key === 'team score') {

            var templist = []
            playerList.forEach(item => {
                templist.push(item.margin)
            })

            var sortedMarginList = templist.sort((a, b) => b - a).slice(0, 5)
            if (sortedMarginList.includes(value)) return greeen
            else return 'transparent'

        }
    }



    const onMouseEnter = (key) => {
        setHover(true)
        setHoverkey(key)
    }

    const GetKeyHighestValue = (key) => {

        var result = ''
        if(key=='team') {}
        else {
            
            BBLLengendList.forEach((item, index) => {

                if (cat === 1 && item.category === 1) {
                    if (key.toString().toUpperCase().trim(' ') === item.name.toUpperCase().trim(' '))
                        result = item.fullform.toString().replaceAll("_", " ")
                }
                else if (cat === 2 && item.category === 2) {
                    if (key.toString().toUpperCase().trim(' ') === item.name.toUpperCase().trim(' '))
                    result = item.fullform.toString().replaceAll("_", " ")
                }
                else if (cat === 3 && item.category === 3) {
                    if (key.toString().toUpperCase().trim(' ') === item.name.toUpperCase().trim(' '))
                    result = item.fullform.toString().replaceAll("_", " ")
                }
                else if (cat === 4 && item.category === 4) {
                    if (key.toString().toUpperCase().trim(' ') === item.name.toUpperCase().trim(' '))
                    result = item.fullform.toString().replaceAll("_", " ")
                }
                else if (cat === 5 && item.category === 5) {
                    if (key.toString().toUpperCase().trim(' ') === item.name.toUpperCase().trim(' '))
                    result = item.fullform.toString().replaceAll("_", " ")
                }
    
            })
        }
        return result
    }

    const onMouseLeave = (key) => {
        setHoverkey(null)
        setHover(false)
    }

    const maniPulateName = (name) => {
        var splitter = name.split(' ')
        if (splitter.length > 1) {

            var initial = splitter[0].substr(0, 1)

            var fullname = ''
            if (splitter.length > 2) fullname = splitter[1] + ' ' + splitter[2]
            else fullname = splitter[1]

            var result = initial + ". " + fullname
            return result
        } else return name

    }

    return (
        <div className='nba-player-table-com rounded-md w-full'>
            {
                props.player_list && props.player_list.length === 0 ?
                    <div>
                        <p className="py-10">There's no players in NFL team</p>
                    </div>
                    :
                    <div >
                        <table className='w-full mt-8 rounded-md tb-shadow text-sm xl:text-xs 2xl:text-lg text-black overflow-hidden' >
                            <tr className=''>
                            {
                                props.headerData &&
                                props.headerData.map((item, index) => {
                                    if (item === 'player' || item == '#' || item == 'team') {
                                        return <th onClick={() =>  sortBy(item) } style={{ backgroundColor: props.bgcolor,textAlign:item == '#' ? 'center' : 'left' }}
                                            className={
                                            item === sortedth ? 'text-left cursor-pointer uppercase font-bold text-md px-2 py-2 text-white tb-th-color'
                                            : 'cursor-pointer uppercase font-bold text-md px-2 py-2 text-left text-white'
                                            }>
                                            {item.toString().replaceAll("_", " ")}</th>
                                    }
                                    else if (props.cat <= 5) {
                                        // if(item === 'avd' || item === 'tg' || item === 'afp') sortBy(item)
                                        return <th onMouseEnter={() => {
                                            if (item !== '') onMouseEnter(item)
                                        }}
                                            onMouseLeave={() => {
                                                onMouseLeave()
                                            }}
                                            onClick={() => sortBy(item)} style={{ backgroundColor: props.bgcolor }}
                                            className={item === sortedth ? 'cursor-pointer uppercase font-bold text-md px-2 py-2 text-white tb-th-color'
                                                : 'cursor-pointer uppercase font-bold text-md px-2 py-2 text-white'}>
                                            <div className={hoverkey === item ? 'block nfl-tb-header-hover-content z-50 w-max' : 'hidden nfl-tb-header-hover-content z-50 w-max'}>
                                                <p className='text-white'>{hoverkey === item ? GetKeyHighestValue(item) : ''}</p>
                                            </div>
                                            {item.toString().replaceAll("_", " ")}</th>
                                    }
                                    else {
                                        return <th
                                            style={{ backgroundColor: props.bgcolor }}
                                            className={'cursor-pointer uppercase font-bold px-2 py-2 text-white'}>
                                            {item.toString().replaceAll("_", " ")}</th>
                                    }
                                })
                            }
                            </tr>
                            

                            {
                                playerList &&
                                playerList.map((item, index) => {

                                    let runs = item.run

                                    let fours = item.four

                                    let sixes = item.six

                                    let wickets = item.wicket
                                    

                                    if (cat === 1 && runs)
                                    {

                                        if(runs.total && runs.total !== 0)
                                        return <tr onClick={() => ViewPlayer(item.player_id)} 
                                        className={ props.hthteamid ? 
                                        item.teamid == props.curretteamid  ?  
                                        "cursor-pointer bg-white" :"cursor-pointer tb-hth-data"
                                        :'cursor-pointer tb-data' }>

                                            {
                                                props.hthteamid &&
                                                <td className='py-2 my-2 lg:py-3 xl:py-3 2xl:py-3 m-auto' width='0%'>
                                                    <img src={GetTeamImageByTeamId(item.teamid)} className='m-auto w-7 h-7'/>
                                                </td>
                                            }

                                            {/* <td className='py-2 my-2 lg:py-3 xl:py-3 2xl:py-3' width='6%'>{'#' + item.player_number}</td> */}
                                            <td className='py-2 my-2 lg:py-3 xl:py-3 2xl:py-3 text-left pl-2 w-auto'><nobr>{maniPulateName(item.name)}</nobr> </td>

                                            <td style={{ backgroundColor:colorBy('total',runs.total)}} width='10%'>{runs.total ? runs.total : 0}</td>
                                            <td style={{ backgroundColor:colorBy('avg',runs.avg)}} width='10%'>{runs.avg ? parseFloat(runs.avg).toFixed(1) : 0}</td>

                                            <td style={{ backgroundColor:colorByValues('counts',runs.ten_plus)}} width='10%'>{runs.ten_plus ? runs.ten_plus+"%" :"0%"}</td>
                                            <td style={{ backgroundColor:colorByValues('counts',runs.fiftheen_plus)}} width='10%'>{runs.fiftheen_plus ? runs.fiftheen_plus+"%" : "0%"}</td>
                                            <td style={{ backgroundColor:colorByValues('counts',runs.twenty_plus)}} width='13%'>{runs.twenty_plus ? runs.twenty_plus+"%" : "0%"}</td>
                                            <td style={{ backgroundColor:colorByValues('counts',runs.twentyfive_plus)}} width='10%'>{runs.twentyfive_plus ? runs.twentyfive_plus+"%" :"0%"}</td>
                                            <td style={{ backgroundColor:colorByValues('counts',runs.thirty_plus)}} width='10%'>{runs.thirty_plus ? runs.thirty_plus+"%" : "0%"}</td>
                                            <td style={{ backgroundColor:colorByValues('counts',runs.forty_plus)}} width='13%'>{runs.forty_plus ? runs.forty_plus+"%" : "0%"}</td>
                                            <td style={{ backgroundColor:colorByValues('counts',runs.fifty_plus)}} width='13%'>{runs.fifty_plus ? runs.fifty_plus+"%" : "0%"}</td>

                                            <td style={{ backgroundColor:colorBy('low',runs.low)}} width='13%'>{runs.low ? runs.low : 0}</td>
                                            <td style={{ backgroundColor:colorBy('high',runs.high)}} width='13%'>{runs.high ? runs.high : 0}</td>
                                            <td style={{ backgroundColor:'transparent' }} width='13%'>{runs.gp ? runs.gp : 0}</td>
                                        </tr>

                                        else return <></>
                                    }

                                    if (cat === 2 && fours){

                                        return <tr onClick={() => ViewPlayer(item.player_id)} 
                                        className={ props.hthteamid ? 
                                        item.teamid == props.curretteamid  ?  
                                        "cursor-pointer bg-white" :"cursor-pointer tb-hth-data"
                                        :'cursor-pointer tb-data' }>

                                            {
                                                props.hthteamid &&
                                                <td className='py-2 my-2 lg:py-3 xl:py-3 2xl:py-3 m-auto' width='0%'>
                                                    <img src={GetTeamImageByTeamId(item.teamid)} className='m-auto w-7 h-7'/>
                                                </td>
                                            }

                                            {/* <td className='py-2 my-2 lg:py-3 xl:py-3 2xl:py-3' width='6%'>{'#' + item.player_number}</td> */}
                                            <td className='py-2 my-2 lg:py-3 xl:py-3 2xl:py-3 text-left pl-2 w-auto'><nobr>{maniPulateName(item.name)}</nobr></td>

                                            <td style={{ backgroundColor:colorBy('total',fours.total)}} width='11%'>{fours.total ? fours.total : 0}</td>
                                            <td style={{ backgroundColor:colorBy('avg',fours.avg)}} width='11%'>{fours.avg ? parseFloat(fours.avg).toFixed(1) : 0}</td>

                                            <td style={{ backgroundColor:colorByValues('counts',fours.one_plus)}} width='11%'>{fours.one_plus ? fours.one_plus+"%" : "0%"}</td>
                                            <td style={{ backgroundColor:colorByValues('counts',fours.two_plus) }} width='11%'>{fours.two_plus ? fours.two_plus+"%" : "0%"}</td>
                                            <td style={{ backgroundColor:colorByValues('counts',fours.three_plus)}} width='11%'>{fours.three_plus ? fours.three_plus+"%" : "0%"}</td>
                                            <td style={{ backgroundColor:colorByValues('counts',fours.four_plus)}} width='11%'>{fours.four_plus ? fours.four_plus+"%": "0%"}</td>

                                            <td style={{ backgroundColor:'transparent' }} width='6%'>{fours.gp ? fours.gp : 0}</td>
                                        </tr>

                                    }
                                        

                                    else if (cat === 3 && sixes){

                                        return <tr onClick={() => ViewPlayer(item.player_id)} 
                                            className={ props.hthteamid ? 
                                            item.teamid == props.curretteamid  ? 
                                            "cursor-pointer bg-white " :"cursor-pointer tb-hth-data"
                                            :'cursor-pointer tb-data' }>

                                            {
                                                props.hthteamid &&
                                                <td className='py-2 my-2 lg:py-3 xl:py-3 2xl:py-3 m-auto' width='0%'>
                                                    <img src={GetTeamImageByTeamId(item.teamid)} className='m-auto w-7 h-7'/>
                                                </td>
                                            }

                                            {/* <td className='py-2 my-2 lg:py-3 xl:py-3 2xl:py-3' width='6%'>{'#' + item.player_number}</td> */}
                                            <td className='py-2 my-2 lg:py-3 xl:py-3 2xl:py-3 text-left pl-2 w-auto'><nobr>{maniPulateName(item.name)}</nobr></td>

                                            <td style={{ backgroundColor:colorBy('total',sixes.total)}} width='12%'>{sixes.total ? sixes.total  :0 }</td>
                                            <td style={{ backgroundColor:colorBy('avg',sixes.avg ) }} width='12%'>{sixes.avg ? parseFloat(sixes.avg).toFixed(1) :0}</td>

                                            <td style={{ backgroundColor:colorByValues('counts',sixes.one_plus)}} width='12%'>{sixes.one_plus ? sixes.one_plus+"%" : "0%" }</td>
                                            <td style={{ backgroundColor:colorByValues('counts',sixes.two_plus)}} width='12%'>{sixes.two_plus ? sixes.two_plus+"%" :"0%"}</td>
                                            <td style={{ backgroundColor:colorByValues('counts',sixes.three_plus) }} width='12%'>{sixes.three_plus ? sixes.three_plus+"%" :"0%"}</td>

                                            <td style={{ backgroundColor:'transparent' }} width='12%'>{sixes.gp ? sixes.gp : 0}</td>
                                        </tr>

                                    }
                                        

                                    else if (cat === 4 && wickets){

                                        return <tr onClick={() => ViewPlayer(item.player_id)} 
                                            className={ props.hthteamid ? 
                                            item.teamid == props.curretteamid  ?  
                                            "cursor-pointer bg-white" :"cursor-pointer tb-hth-data"
                                            :'cursor-pointer tb-data' }>

                                            {
                                                props.hthteamid &&
                                                <td className='py-2 my-2 lg:py-3 xl:py-3 2xl:py-3 m-auto' width='0%'>
                                                    <img src={GetTeamImageByTeamId(item.teamid)} className='m-auto w-7 h-7'/>
                                                </td>
                                            }

                                            {/* <td className='py-2 my-2 lg:py-3 xl:py-3 2xl:py-3' width='6%'>{'#' + item.player_number}</td> */}
                                            <td className='py-2 my-2 lg:py-3 xl:py-3 2xl:py-3 text-left pl-2 w-auto'> <nobr>{maniPulateName(item.name)}</nobr> </td>

                                            <td style={{ backgroundColor:colorBy('total',wickets.total)}} width='12%'>{wickets.total ? wickets.total : 0}</td>
                                            <td style={{ backgroundColor:colorBy('avg',wickets.avg)}} width='12%'>{wickets.avg ? parseFloat(wickets.avg).toFixed(1) : 0}</td>

                                            <td style={{ backgroundColor:colorByValues('counts',wickets.one_plus)}} width='12%'>{wickets.one_plus ? wickets.one_plus+"%" : "0%"}</td>
                                            <td style={{ backgroundColor: colorByValues('counts',wickets.two_plus) }} width='12%'>{wickets.two_plus ? wickets.two_plus+"%" : "0%"}</td>
                                            <td style={{ backgroundColor:colorByValues('counts',wickets.three_plus) }} width='12%'>{wickets.three_plus ? wickets.three_plus+"%" : "0%"}</td>

                                            <td style={{ backgroundColor:colorBy('low',wickets.low)}} width='12%'>{wickets.low ? wickets.low : 0}</td>
                                            <td style={{ backgroundColor:colorBy('high',wickets.high)}} width='12%'>{wickets.high ? wickets.high : 0}</td>
                                            <td style={{ backgroundColor:"transparent"}} width='12%'>{wickets.gp ? wickets.gp : 0}</td>
                                        </tr>
                                    }
                                    
                                    else if (cat === 5)
                                    {
                                        let margin = item.for_runs - item.away_runs

                                        let result = margin === 0 ? 'D' : margin > 0 ? 'W' : 'L'

                                        return <tr className='cursor-pointer tb-data w-full'>

                                            <td className='w-1/5 text-base py-2 lg:py-3 xl:py-3 2xl:py-3'>{FormatDate(item.date)}</td>
                                            <td className='text-center text-black w-1/5 text-base lg:text-base xl:text-base 2xl:text-base '>{item.opponent}</td>

                                            <td className='w-1/5 text-base'>{item.team_score}</td>
                                            <td className='w-1/5 text-base'>{item.opp_score}</td>

                                            <td className='w-1/5 text-base' style={{ color: result === 'W' ? 'green' : result === 'D' ? 'grey' : 'red', fontWeight: 'bolder' }}>{result}</td>
                                            {/* <td className='w-1/5 text-base'>{Math.abs(margin) }</td> */}

                                        </tr>
                                    }
                                })
                            }
                        </table>
                    </div>
            }
        </div>
    )
}

export default BBLPlayerTable;