import React, { useEffect, useImperativeHandle, useState } from "react";
import { NBALengendList } from "./NBA_Constant";
import "../../utils/styles/table/table.scss";

const greeen = "#26e169";
const mediumGreeen = "#73ec9e";
const lightGreeen = "#c1f6d4";

function NRLPlayerTable(props) {
  const [cat, setCat] = useState(null);
  const [sortedth, setSortedth] = useState(null);
  const [playerList, setPlayerlist] = useState([]);

  const [onHover, setHover] = useState(false);
  const [hoverkey, setHoverkey] = useState(null);

  useEffect(() => {
    setCat(props.cat);

    if (props.cat === 1) {
      sortByList("total_points", props.player_list);
    } else if (props.cat === 2) {
      sortByList("total_rebounds", props.player_list);
    } else if (props.cat === 3) {
      sortByList("total_assists", props.player_list);
    } else if (props.cat === 4) {
      sortByList("total_three_pointers", props.player_list);
    } else if (props.cat === 5) {
      sortByList("total_three_pointers", props.player_list);
    }
  }, [props.cat, props.hthPlayerList]);

  useImperativeHandle(null, () => {
    //console.log('useImperativeHandle')
  });

  const ViewPlayer = (item) => {
    props.his.push(
      "/nbaplayer?id=" + item + "&tc=" + props.bgcolor + "&s=" + props.season
    );
  };

  // ascending and descending
  const compareByAsc = (category, key) => {
    return function (a, b) {
      if (
        a[category] !== null &&
        a[category][key] !== null &&
        b[category] !== null &&
        b[category][key] !== null
      ) {
        if (a[category][key] < b[category][key]) return -1;
      } else return 0;

      if (
        a[category] !== null &&
        a[category][key] !== null &&
        b[category] !== null &&
        b[category][key] !== null
      ) {
        if (a[category][key] > b[category][key]) return 1;
      } else return 0;
      return 0;
    };
  };

  const compareByDesc = (category, key) => {
    return function (a, b) {
      if (
        a[category] !== null &&
        a[category][key] !== null &&
        b[category] !== null &&
        b[category][key] !== null
      ) {
        if (a[category][key] < b[category][key]) return 1;
      } else return 0;

      if (
        a[category] !== null &&
        a[category][key] !== null &&
        b[category] !== null &&
        b[category][key] !== null
      ) {
        if (a[category][key] > b[category][key]) return -1;
      } else return 0;
      return 0;
    };
  };

  const checkKey = (key) => {
    if (key === "player") return "name";
    else if (key === "#") return "player_number";
    else if (key === "gp") return "games_played";

    if (cat === 1) {
      if (key == "15+") return "fiftheen_plus_points";
      else
        key = NBALengendList.slice(0, 13).find((x) => x.name === key).fullform;
    } else if (cat === 2) {
      key = NBALengendList.slice(14, 27).find((x) => x.name === key).fullform;
    } else if (cat === 3) {
      key = NBALengendList.slice(28, 40).find((x) => x.name === key).fullform;
    } else if (cat === 4) {
      key = NBALengendList.slice(41, NBALengendList.length - 1).find(
        (x) => x.name === key
      ).fullform;
    }

    return key;
  };

  const sortBy = (key) => {
    setSortedth(key);

    key = checkKey(key);
    var categoryName =
      props.cat === 1
        ? "points"
        : props.cat === 2
        ? "rebounds"
        : props.cat === 3
        ? "assists"
        : props.cat === 4
        ? "three_points"
        : null;

    var nullRemoveList = [];
    var nullList = [];
    playerList.forEach((item) => {
      if (item.points && item.rebounds && item.assists && item.three_points)
        nullRemoveList.push(item);
      else nullList.push(item);
    });

    let arrayCopy = [...playerList];
    const arrInStr = JSON.stringify(arrayCopy);
    if (key === "name" || key === "player_number")
      arrayCopy.sort(compareByAscNameIndex(key));
    else arrayCopy.sort(compareByDesc(categoryName, key));
    const arrInStr1 = JSON.stringify(arrayCopy);

    if (arrInStr === arrInStr1) {
      if (key === "name" || key === "player_number")
        arrayCopy.sort(compareByDescNameIndex(key));
      else arrayCopy.sort(compareByAsc(categoryName, key));
    }

    var alllist = arrayCopy.concat(nullList);
    //console.log(arrayCopy, nullList.length)
    setPlayerlist(alllist);
  };

  const sortByList = (key, list) => {
    var nullRemoveList = [];
    var nullList = [];
    // key = checkKey(key)

    if (list != null && list != undefined) {
      list.forEach((item) => {
        if (item.points && item.rebounds && item.assists && item.three_points)
          nullRemoveList.push(item);
        else nullList.push(item);
      });

      var categoryName =
        props.cat === 1
          ? "points"
          : props.cat === 2
          ? "rebounds"
          : props.cat === 3
          ? "assists"
          : props.cat === 4
          ? "three_points"
          : null;
      let arrayCopy = [...nullRemoveList];

      arrayCopy.sort(compareByDesc(categoryName, key));

      var alllist = arrayCopy.concat(nullList);
      //console.log(arrayCopy)
      setPlayerlist(alllist);
    }
  };

  const compareByAscNameIndex = (key) => {
    if (key === "player_id")
      return function (a, b) {
        if (parseInt(a[key]) > parseInt(b[key])) return 1;
        if (parseInt(a[key]) < parseInt(b[key])) return -1;
        return 0;
      };
    else
      return function (a, b) {
        if (a[key] > b[key]) return 1;
        if (a[key] < b[key]) return -1;
        return 0;
      };
  };

  const compareByDescNameIndex = (key) => {
    if (key === "player_id")
      return function (a, b) {
        if (parseInt(a[key]) < parseInt(b[key])) return 1;
        if (parseInt(a[key]) > parseInt(b[key])) return -1;
        return 0;
      };
    else
      return function (a, b) {
        if (a[key] < b[key]) return 1;
        if (a[key] > b[key]) return -1;
        return 0;
      };
  };

  const colorBy = (key, value) => {
    if (value === 0.0 || value === 0 || value === null) return " ";
    else if (value !== null && key !== "margin") {
      var nullRemoveList = [];
      playerList.forEach((item) => {
        if (item.points && item.rebounds && item.assists && item.three_points)
          nullRemoveList.push(item);
      });

      let arrayCopy = [...nullRemoveList];
      const arrInStr = JSON.stringify(arrayCopy);

      var categoryName =
        props.cat === 1
          ? "points"
          : props.cat === 2
          ? "rebounds"
          : props.cat === 3
          ? "assists"
          : props.cat === 4
          ? "three_points"
          : null;
      var max = arrayCopy.reduce((prev, current) =>
        prev[categoryName][key] > current[categoryName][key] ? prev : current
      );

      if (max[categoryName][key] === value) return greeen;

      var maxIndex = arrayCopy.indexOf(max);

      if (maxIndex > -1) {
        arrayCopy.splice(maxIndex, 1);
      }

      var max2 = arrayCopy.reduce((prev, current) =>
        prev[categoryName][key] > current[categoryName][key] ? prev : current
      );
      if (max2[categoryName][key] === value) return mediumGreeen;

      var maxIndex2 = arrayCopy.indexOf(max2);

      if (maxIndex2 > -1) {
        arrayCopy.splice(maxIndex2, 1);
      }

      var max3 = arrayCopy.reduce((prev, current) =>
        prev[categoryName][key] > current[categoryName][key] ? prev : current
      );
      if (max3[categoryName][key] === value) return lightGreeen;
    }
  };

  const colorByValues = (key, value) => {
    if (value === 0 || value === null) return "";
    else if (value !== null && key != "margin") {
      // key=checkKey(key)
      if (key === "avp") {
        value = parseFloat(value).toFixed(1);
        if (value >= 30) return greeen;
        else if (value >= 20 && value <= 29.99) return mediumGreeen;
        else if (value >= 10 && value <= 19.99) return lightGreeen;
      }

      if (key === "avr") {
        value = parseFloat(value).toFixed(1);
        if (value >= 8) return greeen;
        else if (value >= 6 && value <= 7.99) return mediumGreeen;
        else if (value >= 4 && value <= 5.99) return lightGreeen;
      }

      if (key === "ata") {
        value = parseFloat(value).toFixed(1);
        if (value >= 6) return greeen;
        else if (value >= 5 && value <= 5.99) return mediumGreeen;
        else if (value >= 4 && value <= 4.99) return lightGreeen;
      }

      if (key === "atp") {
        value = parseFloat(value).toFixed(1);
        if (value >= 1.8) return greeen;
        else if (value >= 1.4 && value <= 1.79) return mediumGreeen;
        else if (value >= 1 && value <= 1.39) return lightGreeen;
      }

      if (key === "counts") {
        if (value >= 85) return greeen;
        else if (value >= 70 && value <= 84.99) return mediumGreeen;
        else if (value >= 55 && value <= 69.99) return lightGreeen;
      }
    } else if (key === "margin") {
      var templist = [];
      playerList.forEach((item) => {
        templist.push(item.margin);
      });

      var sortedMarginList = templist.sort((a, b) => b - a).slice(0, 5);
      if (sortedMarginList.includes(value)) return greeen;
      else return "transparent";
    }
  };

  const onMouseEnter = (key) => {
    setHover(true);
    setHoverkey(key);
  };

  const GetKeyHighestValue = (key) => {
    var result = "";
    if (key == "team") {
    } else {
      NBALengendList.forEach((item, index) => {
        if (cat === 1) {
          if (index <= 14) {
            if (
              key.toString().toUpperCase().trim(" ") ===
              item.name.toUpperCase().trim(" ")
            )
              result = item.fullform.toString().replaceAll("_", " ");
          }
        } else if (cat === 2) {
          if (index >= 14 && index <= 27) {
            if (
              key.toString().toUpperCase().trim(" ") ===
              item.name.toUpperCase().trim(" ")
            )
              result = item.fullform.toString().replaceAll("_", " ");
          }
        } else if (cat === 3) {
          if (index >= 28 && index <= 40) {
            if (
              key.toString().toUpperCase().trim(" ") ===
              item.name.toUpperCase().trim(" ")
            )
              result = item.fullform.toString().replaceAll("_", " ");
          }
        } else {
          if (
            key.toString().toUpperCase().trim(" ") ===
            item.name.toUpperCase().trim(" ")
          )
            result = item.fullform.toString().replaceAll("_", " ");
        }
      });
    }
    return result;
  };

  const onMouseLeave = (key) => {
    setHoverkey(null);
    setHover(false);
  };

  const maniPulateName = (name) => {
    var splitter = name.split(" ");
    if (splitter.length > 1) {
      var initial = splitter[0].substr(0, 1);

      var fullname = "";
      if (splitter.length > 2) fullname = splitter[1] + " " + splitter[2];
      else fullname = splitter[1];

      var result = initial + ". " + fullname;

      return result;
      // .length > 15 ? result.slice(0, 15) + "." : result
    } else return name;
  };

  const totalColors = (index) => {
    if (index === 0) return greeen;
    else if (index >= 1 && index <= 2) return mediumGreeen;
    else if (index >= 3 && index <= 4) return lightGreeen;
    else return "transparent";
  };

  return (
    <div className="nba-player-table-com rounded-md w-full">
      {props.player_list && props.player_list.length === 0 ? (
        <div>
          <p className="py-10">There's no players in NBA team</p>
        </div>
      ) : (
        <div>
          <table className="w-full mt-8 rounded-md tb-shadow text-sm xl:text-xs 2xl:text-lg text-black overflow-hidden">
            <tr className="">
              {props.headerData &&
                props.headerData.map((item, index) => {
                  if (item === "player" || item == "#" || item == "team") {
                    return (
                      <th
                        onClick={() => sortBy(item)}
                        style={{ backgroundColor: props.bgcolor }}
                        className={
                          item === sortedth
                            ? "text-left cursor-pointer uppercase font-bold text-md px-2 py-2 text-white tb-th-color"
                            : "cursor-pointer uppercase font-bold text-md px-2 py-2 text-left text-white"
                        }
                      >
                        {item}
                      </th>
                    );
                  } else if (props.cat <= 4) {
                    // if(item === 'avd' || item === 'tg' || item === 'afp') sortBy(item)
                    return (
                      <th
                        onMouseEnter={() => {
                          if (item !== "") onMouseEnter(item);
                        }}
                        onMouseLeave={() => {
                          onMouseLeave();
                        }}
                        onClick={() => sortBy(item)}
                        style={{ backgroundColor: props.bgcolor }}
                        className={
                          item === sortedth
                            ? "cursor-pointer uppercase font-bold text-md px-2 py-2 text-white tb-th-color"
                            : "cursor-pointer uppercase font-bold text-md px-2 py-2 text-white"
                        }
                      >
                        <div
                          className={
                            hoverkey === item
                              ? "block tb-header-hover-content z-50 w-max"
                              : "hidden tb-header-hover-content z-50 w-max"
                          }
                        >
                          <p className="text-white">
                            {hoverkey === item ? GetKeyHighestValue(item) : ""}
                          </p>
                        </div>
                        {item}
                      </th>
                    );
                  } else {
                    return (
                      <th
                        style={{ backgroundColor: props.bgcolor }}
                        className={
                          "cursor-pointer uppercase font-bold px-2 py-2 text-white"
                        }
                      >
                        {item}
                      </th>
                    );
                  }
                })}
            </tr>

            {playerList &&
              playerList.map((item, index) => {
                // points
                var pointsData = item.points;
                let totle_points =
                  pointsData && pointsData.total_points !== null
                    ? pointsData.total_points
                    : 0;
                let average_points =
                  pointsData && pointsData.average_points !== null
                    ? pointsData.average_points.toFixed(1)
                    : 0;
                let ten_plus_points =
                  pointsData && pointsData.ten_plus_points !== null
                    ? pointsData.ten_plus_points.toFixed(0)
                    : 0;
                let fiftheen_plus_points =
                  pointsData && pointsData.fiftheen_plus_points !== null
                    ? pointsData.fiftheen_plus_points.toFixed(0)
                    : 0;

                let twenty_plus_points =
                  pointsData && pointsData.twenty_plus_points !== null
                    ? pointsData.twenty_plus_points.toFixed(0)
                    : 0;
                let twentyfive_plus_points =
                  pointsData && pointsData.twentyfive_plus_points !== null
                    ? pointsData.twentyfive_plus_points.toFixed(0)
                    : 0;
                let thirty_plus_points =
                  pointsData && pointsData.thirty_plus_points !== null
                    ? pointsData.thirty_plus_points.toFixed(0)
                    : 0;
                let thirtyfive_plus_points =
                  pointsData && pointsData.thirtyfive_plus_points !== null
                    ? pointsData.thirtyfive_plus_points.toFixed(0)
                    : 0;

                let forty_plus_points =
                  pointsData && pointsData.forty_plus_points !== null
                    ? pointsData.forty_plus_points.toFixed(0)
                    : 0;
                let lowest_points =
                  pointsData && pointsData.lowest_points !== null
                    ? pointsData.lowest_points
                    : 0;
                let second_lowest_points =
                  pointsData && pointsData.second_lowest_points !== null
                    ? pointsData.second_lowest_points
                    : 0;
                let highest_points =
                  pointsData && pointsData.highest_points !== null
                    ? pointsData.highest_points
                    : 0;

                let second_highest_points =
                  pointsData && pointsData.second_highest_points !== null
                    ? pointsData.second_highest_points
                    : 0;
                let games_played =
                  pointsData && pointsData.games_played !== null
                    ? pointsData.games_played
                    : 0;

                // rebound
                var reboundsData = item.rebounds;
                let total_rebounds =
                  reboundsData && reboundsData.total_rebounds !== null
                    ? reboundsData.total_rebounds
                    : 0;
                let average_rebounds =
                  reboundsData && reboundsData.average_rebounds !== null
                    ? reboundsData.average_rebounds.toFixed(1)
                    : 0;
                let four_plus_rebounds =
                  reboundsData && reboundsData.four_plus_rebounds !== null
                    ? reboundsData.four_plus_rebounds.toFixed(0)
                    : 0;
                let six_plus_rebounds =
                  reboundsData && reboundsData.six_plus_rebounds !== null
                    ? reboundsData.six_plus_rebounds.toFixed(0)
                    : 0;

                let eight_plus_rebounds =
                  reboundsData && reboundsData.eight_plus_rebounds !== null
                    ? reboundsData.eight_plus_rebounds.toFixed(0)
                    : 0;
                let ten_plus_rebounds =
                  reboundsData && reboundsData.ten_plus_rebounds !== null
                    ? reboundsData.ten_plus_rebounds.toFixed(0)
                    : 0;
                let twelve_plus_rebounds =
                  reboundsData && reboundsData.twelve_plus_rebounds !== null
                    ? reboundsData.twelve_plus_rebounds.toFixed(0)
                    : 0;
                let fourtheen_plus_rebounds =
                  reboundsData && reboundsData.fourtheen_plus_rebounds !== null
                    ? reboundsData.fourtheen_plus_rebounds.toFixed(0)
                    : 0;

                let sixtheen_plus_rebounds =
                  reboundsData && reboundsData.sixtheen_plus_rebounds !== null
                    ? reboundsData.sixtheen_plus_rebounds.toFixed(0)
                    : 0;
                let lowest_rebounds =
                  reboundsData && reboundsData.lowest_rebounds !== null
                    ? reboundsData.lowest_rebounds
                    : 0;
                let second_lowest_rebounds =
                  reboundsData && reboundsData.second_lowest_rebounds !== null
                    ? reboundsData.second_lowest_rebounds
                    : 0;
                let highest_rebounds =
                  reboundsData && reboundsData.highest_rebounds !== null
                    ? reboundsData.highest_rebounds
                    : 0;

                let second_highest_rebounds =
                  reboundsData && reboundsData.second_highest_rebounds !== null
                    ? reboundsData.second_highest_rebounds
                    : 0;
                let rebounds_games_played =
                  reboundsData && reboundsData.games_played !== null
                    ? reboundsData.games_played
                    : 0;

                // assists
                var assistsData = item.assists;
                let total_assists =
                  assistsData && assistsData.total_assists !== null
                    ? assistsData.total_assists
                    : 0;
                let average_total_assists =
                  assistsData && assistsData.average_total_assists !== null
                    ? assistsData.average_total_assists.toFixed(1)
                    : 0;
                let two_plus_assists =
                  assistsData && assistsData.two_plus_assists !== null
                    ? assistsData.two_plus_assists.toFixed(0)
                    : 0;
                let four_plus_assists =
                  assistsData && assistsData.four_plus_assists !== null
                    ? assistsData.four_plus_assists.toFixed(0)
                    : 0;

                let six_plus_assists =
                  assistsData && assistsData.six_plus_assists !== null
                    ? assistsData.six_plus_assists.toFixed(0)
                    : 0;
                let eight_plus_assists =
                  assistsData && assistsData.eight_plus_assists !== null
                    ? assistsData.eight_plus_assists.toFixed(0)
                    : 0;
                let ten_plus_assists =
                  assistsData && assistsData.ten_plus_assists !== null
                    ? assistsData.ten_plus_assists.toFixed(0)
                    : 0;
                let twelve_plus_assists =
                  assistsData && assistsData.twelve_plus_assists !== null
                    ? assistsData.twelve_plus_assists.toFixed(0)
                    : 0;

                let lowest_assists =
                  assistsData && assistsData.lowest_assists !== null
                    ? assistsData.lowest_assists
                    : 0;
                let second_lowest_assists =
                  assistsData && assistsData.second_lowest_assists !== null
                    ? assistsData.second_lowest_assists
                    : 0;
                let highest_assists =
                  assistsData && assistsData.highest_assists !== null
                    ? assistsData.highest_assists
                    : 0;

                let second_highest_assists =
                  assistsData && assistsData.second_highest_assists !== null
                    ? assistsData.second_highest_assists
                    : 0;
                let assists_games_played =
                  assistsData && assistsData.games_played !== null
                    ? assistsData.games_played
                    : 0;

                // three points
                var threePointsData = item.three_points;
                let total_three_pointers =
                  threePointsData &&
                  threePointsData.total_three_pointers !== null
                    ? threePointsData.total_three_pointers
                    : 0;
                let average_three_pointers =
                  threePointsData &&
                  threePointsData.average_three_pointers !== null
                    ? threePointsData.average_three_pointers.toFixed(1)
                    : 0;
                let one_plus_three_points =
                  threePointsData &&
                  threePointsData.one_plus_three_points !== null
                    ? threePointsData.one_plus_three_points.toFixed(0)
                    : 0;
                let two_plus_three_points =
                  threePointsData &&
                  threePointsData.two_plus_three_points !== null
                    ? threePointsData.two_plus_three_points.toFixed(0)
                    : 0;

                let three_plus_three_points =
                  threePointsData &&
                  threePointsData.three_plus_three_points !== null
                    ? threePointsData.three_plus_three_points.toFixed(0)
                    : 0;
                let four_plus_three_points =
                  threePointsData &&
                  threePointsData.four_plus_three_points !== null
                    ? threePointsData.four_plus_three_points.toFixed(0)
                    : 0;
                let five_plus_three_points =
                  threePointsData &&
                  threePointsData.five_plus_three_points !== null
                    ? threePointsData.five_plus_three_points.toFixed(0)
                    : 0;

                let lowest_three_pointers =
                  threePointsData &&
                  threePointsData.lowest_three_pointers !== null
                    ? threePointsData.lowest_three_pointers
                    : 0;
                let second_lowest_three_pointers =
                  threePointsData &&
                  threePointsData.second_lowest_three_pointers !== null
                    ? threePointsData.second_lowest_three_pointers
                    : 0;
                let highest_three_pointers =
                  threePointsData &&
                  threePointsData.highest_three_pointers !== null
                    ? threePointsData.highest_three_pointers
                    : 0;

                let second_highest_three_pointers =
                  threePointsData &&
                  threePointsData.second_highest_three_pointers !== null
                    ? threePointsData.second_highest_three_pointers
                    : 0;
                let three_points_played =
                  threePointsData && threePointsData.games_played !== null
                    ? threePointsData.games_played
                    : 0;

                if (cat === 1)
                  return (
                    <tr
                      onClick={() => ViewPlayer(item.player_id)}
                      className={
                        props.hthteamid
                          ? item.teams && item.teams.id == props.curretteamid
                            ? "cursor-pointer bg-white"
                            : "cursor-pointer tb-hth-data"
                          : "cursor-pointer tb-data"
                      }
                    >
                      {props.hthteamid && (
                        <td
                          className="py-2 my-2 lg:py-3 xl:py-3 2xl:py-3 m-auto"
                          width="0%"
                        >
                          <img
                            src={item.teams && item.teams.image}
                            className="m-auto w-7 h-7"
                          />
                        </td>
                      )}

                      <td
                        className="py-2 my-2 lg:py-3 xl:py-3 2xl:py-3"
                        width="6%"
                      >
                        {"#" + item.player_number}
                      </td>
                      <td className="text-left pl-2 w-full">
                        {maniPulateName(item.name)}
                      </td>

                      <td
                        style={{ backgroundColor: totalColors(index) }}
                        width="6%"
                      >
                        {totle_points}
                      </td>
                      <td
                        style={{
                          backgroundColor: colorByValues("avp", average_points),
                        }}
                        width="6%"
                      >
                        {average_points}
                      </td>

                      <td
                        style={{
                          backgroundColor: colorByValues(
                            "counts",
                            ten_plus_points
                          ),
                        }}
                        width="6%"
                      >
                        {ten_plus_points + "%"}
                      </td>
                      <td
                        style={{
                          backgroundColor: colorByValues(
                            "counts",
                            fiftheen_plus_points
                          ),
                        }}
                        width="6%"
                      >
                        {fiftheen_plus_points + "%"}
                      </td>
                      <td
                        style={{
                          backgroundColor: colorByValues(
                            "counts",
                            twenty_plus_points
                          ),
                        }}
                        width="6%"
                      >
                        {twenty_plus_points + "%"}
                      </td>
                      <td
                        style={{
                          backgroundColor: colorByValues(
                            "counts",
                            twentyfive_plus_points
                          ),
                        }}
                        width="6%"
                      >
                        {twentyfive_plus_points + "%"}
                      </td>
                      <td
                        style={{
                          backgroundColor: colorByValues(
                            "counts",
                            thirty_plus_points
                          ),
                        }}
                        width="6%"
                      >
                        {thirty_plus_points + "%"}
                      </td>
                      <td
                        style={{
                          backgroundColor: colorByValues(
                            "counts",
                            thirtyfive_plus_points
                          ),
                        }}
                        width="6%"
                      >
                        {thirtyfive_plus_points + "%"}
                      </td>
                      <td
                        style={{
                          backgroundColor: colorByValues(
                            "counts",
                            forty_plus_points
                          ),
                        }}
                        width="6%"
                      >
                        {forty_plus_points + "%"}
                      </td>

                      <td width="6%">{lowest_points}</td>
                      <td width="6%">{second_lowest_points}</td>
                      <td
                        style={{ backgroundColor: totalColors(index) }}
                        width="6%"
                      >
                        {highest_points}
                      </td>
                      <td
                        style={{ backgroundColor: totalColors(index) }}
                        width="6%"
                      >
                        {second_highest_points}
                      </td>
                      <td width="6%">{games_played}</td>
                    </tr>
                  );
                else if (cat === 2)
                  return (
                    <tr
                      onClick={() => ViewPlayer(item.player_id)}
                      className={
                        props.hthteamid
                          ? item.teams && item.teams.id == props.curretteamid
                            ? "cursor-pointer bg-white"
                            : "cursor-pointer tb-hth-data"
                          : "cursor-pointer tb-data"
                      }
                    >
                      {props.hthteamid && (
                        <td
                          className="py-2 my-2 lg:py-3 xl:py-3 2xl:py-3 m-auto"
                          width="0%"
                        >
                          <img
                            src={item.teams && item.teams.image}
                            className="m-auto w-7 h-7"
                          />
                        </td>
                      )}

                      <td
                        className="py-2 my-2 lg:py-3 xl:py-3 2xl:py-3"
                        width="6%"
                      >
                        {"#" + item.player_number}
                      </td>
                      <td className="text-left pl-2 w-full" width="100px">
                        {maniPulateName(item.name)}
                      </td>

                      <td
                        style={{ backgroundColor: totalColors(index) }}
                        width="6%"
                      >
                        {total_rebounds}
                      </td>
                      <td
                        style={{
                          backgroundColor: colorByValues(
                            "avr",
                            average_rebounds
                          ),
                        }}
                        width="6%"
                      >
                        {average_rebounds}
                      </td>

                      <td
                        style={{
                          backgroundColor: colorByValues(
                            "counts",
                            four_plus_rebounds
                          ),
                        }}
                        width="6%"
                      >
                        {four_plus_rebounds + "%"}
                      </td>
                      <td
                        style={{
                          backgroundColor: colorByValues(
                            "counts",
                            six_plus_rebounds
                          ),
                        }}
                        width="6%"
                      >
                        {six_plus_rebounds + "%"}
                      </td>
                      <td
                        style={{
                          backgroundColor: colorByValues(
                            "counts",
                            eight_plus_rebounds
                          ),
                        }}
                        width="6%"
                      >
                        {eight_plus_rebounds + "%"}
                      </td>
                      <td
                        style={{
                          backgroundColor: colorByValues(
                            "counts",
                            ten_plus_rebounds
                          ),
                        }}
                        width="6%"
                      >
                        {ten_plus_rebounds + "%"}
                      </td>
                      <td
                        style={{
                          backgroundColor: colorByValues(
                            "counts",
                            twelve_plus_rebounds
                          ),
                        }}
                        width="6%"
                      >
                        {twelve_plus_rebounds + "%"}
                      </td>
                      <td
                        style={{
                          backgroundColor: colorByValues(
                            "counts",
                            fourtheen_plus_rebounds
                          ),
                        }}
                        width="6%"
                      >
                        {fourtheen_plus_rebounds + "%"}
                      </td>
                      <td
                        style={{
                          backgroundColor: colorByValues(
                            "counts",
                            sixtheen_plus_rebounds
                          ),
                        }}
                        width="6%"
                      >
                        {sixtheen_plus_rebounds + "%"}
                      </td>

                      <td width="6%">{lowest_rebounds}</td>
                      <td width="6%">{second_lowest_rebounds}</td>
                      <td
                        style={{ backgroundColor: totalColors(index) }}
                        width="6%"
                      >
                        {highest_rebounds}
                      </td>
                      <td
                        style={{ backgroundColor: totalColors(index) }}
                        width="6%"
                      >
                        {second_highest_rebounds}
                      </td>
                      <td width="6%">{rebounds_games_played}</td>
                    </tr>
                  );
                else if (cat === 3)
                  return (
                    <tr
                      onClick={() => ViewPlayer(item.player_id)}
                      className={
                        props.hthteamid
                          ? item.teams && item.teams.id == props.curretteamid
                            ? "cursor-pointer bg-white"
                            : "cursor-pointer tb-hth-data"
                          : "cursor-pointer tb-data"
                      }
                    >
                      {props.hthteamid && (
                        <td
                          className="py-2 my-2 lg:py-3 xl:py-3 2xl:py-3 m-auto"
                          width="0%"
                        >
                          <img
                            src={item.teams && item.teams.image}
                            className="m-auto w-7 h-7"
                          />
                        </td>
                      )}

                      <td
                        className="py-2 my-2 lg:py-3 xl:py-3 2xl:py-3"
                        width="6%"
                      >
                        {"#" + item.player_number}
                      </td>
                      <td className="text-left pl-2 w-full" width="100px">
                        {maniPulateName(item.name)}
                      </td>

                      <td
                        style={{ backgroundColor: totalColors(index) }}
                        width="6%"
                      >
                        {total_assists}
                      </td>
                      <td
                        style={{
                          backgroundColor: colorByValues(
                            "ata",
                            average_total_assists
                          ),
                        }}
                        width="6%"
                      >
                        {average_total_assists}
                      </td>

                      <td
                        style={{
                          backgroundColor: colorByValues(
                            "counts",
                            two_plus_assists
                          ),
                        }}
                        width="6%"
                      >
                        {two_plus_assists + "%"}
                      </td>
                      <td
                        style={{
                          backgroundColor: colorByValues(
                            "counts",
                            four_plus_assists
                          ),
                        }}
                        width="6%"
                      >
                        {four_plus_assists + "%"}
                      </td>
                      <td
                        style={{
                          backgroundColor: colorByValues(
                            "counts",
                            six_plus_assists
                          ),
                        }}
                        width="6%"
                      >
                        {six_plus_assists + "%"}
                      </td>
                      <td
                        style={{
                          backgroundColor: colorByValues(
                            "counts",
                            eight_plus_assists
                          ),
                        }}
                        width="6%"
                      >
                        {eight_plus_assists + "%"}
                      </td>
                      <td
                        style={{
                          backgroundColor: colorByValues(
                            "counts",
                            ten_plus_assists
                          ),
                        }}
                        width="6%"
                      >
                        {ten_plus_assists + "%"}
                      </td>
                      <td
                        style={{
                          backgroundColor: colorByValues(
                            "counts",
                            twelve_plus_assists
                          ),
                        }}
                        width="6%"
                      >
                        {twelve_plus_assists + "%"}
                      </td>

                      <td width="6%">{lowest_assists}</td>
                      <td width="6%">{second_lowest_assists}</td>

                      <td
                        style={{ backgroundColor: totalColors(index) }}
                        width="6%"
                      >
                        {highest_assists}
                      </td>
                      <td
                        style={{ backgroundColor: totalColors(index) }}
                        width="6%"
                      >
                        {second_highest_assists}
                      </td>
                      <td width="6%">{assists_games_played}</td>
                    </tr>
                  );
                else if (cat === 4)
                  return (
                    <tr
                      onClick={() => ViewPlayer(item.player_id)}
                      className={
                        props.hthteamid
                          ? item.teams && item.teams.id == props.curretteamid
                            ? "cursor-pointer bg-white"
                            : "cursor-pointer tb-hth-data"
                          : "cursor-pointer tb-data"
                      }
                    >
                      {props.hthteamid && (
                        <td
                          className="py-2 my-2 lg:py-3 xl:py-3 2xl:py-3 m-auto"
                          width="0%"
                        >
                          <img
                            src={item.teams && item.teams.image}
                            className="m-auto w-7 h-7"
                          />
                        </td>
                      )}

                      <td
                        className="py-2 my-2 lg:py-3 xl:py-3 2xl:py-3"
                        width="6%"
                      >
                        {"#" + item.player_number}
                      </td>
                      <td className="text-left pl-2 w-full" width="100px">
                        {maniPulateName(item.name)}
                      </td>

                      <td
                        style={{ backgroundColor: totalColors(index) }}
                        width="6%"
                      >
                        {total_three_pointers}
                      </td>
                      <td
                        style={{
                          backgroundColor: colorByValues(
                            "atp",
                            average_three_pointers
                          ),
                        }}
                        width="6%"
                      >
                        {average_three_pointers}
                      </td>

                      <td
                        style={{
                          backgroundColor: colorByValues(
                            "counts",
                            one_plus_three_points
                          ),
                        }}
                        width="6%"
                      >
                        {one_plus_three_points + "%"}
                      </td>
                      <td
                        style={{
                          backgroundColor: colorByValues(
                            "counts",
                            two_plus_three_points
                          ),
                        }}
                        width="6%"
                      >
                        {two_plus_three_points + "%"}
                      </td>
                      <td
                        style={{
                          backgroundColor: colorByValues(
                            "counts",
                            three_plus_three_points
                          ),
                        }}
                        width="6%"
                      >
                        {three_plus_three_points + "%"}
                      </td>
                      <td
                        style={{
                          backgroundColor: colorByValues(
                            "counts",
                            four_plus_three_points
                          ),
                        }}
                        width="6%"
                      >
                        {four_plus_three_points + "%"}
                      </td>
                      <td
                        style={{
                          backgroundColor: colorByValues(
                            "counts",
                            five_plus_three_points
                          ),
                        }}
                        width="6%"
                      >
                        {five_plus_three_points + "%"}
                      </td>

                      <td width="6%">{lowest_three_pointers}</td>
                      <td width="6%">{second_lowest_three_pointers}</td>

                      <td
                        style={{ backgroundColor: totalColors(index) }}
                        width="6%"
                      >
                        {highest_three_pointers}
                      </td>
                      <td
                        style={{ backgroundColor: totalColors(index) }}
                        width="6%"
                      >
                        {second_highest_three_pointers}
                      </td>
                      <td width="6%">{three_points_played}</td>
                    </tr>
                  );
                else if (cat === 5)
                  return (
                    <tr className="cursor-pointer tb-data w-full">
                      <td className="w-1/5 text-base py-2 lg:py-3 xl:py-3 2xl:py-3">
                        {new Date(item.date).getDate() +
                          " " +
                          new Date(item.date)
                            .toLocaleDateString("default", { month: "long" })
                            .substr(0, 3)}
                      </td>
                      <td className="text-center text-black w-1/5 text-base lg:text-base xl:text-base 2xl:text-base ">
                        {item.opponent}
                      </td>

                      <td className="w-1/5 text-base">{item.score_for}</td>
                      <td className="w-1/5 text-base">{item.score_against}</td>

                      <td
                        className="w-1/5 text-base"
                        style={{
                          color:
                            item.result === "W"
                              ? "green"
                              : item.result === "D"
                              ? "grey"
                              : "red",
                          fontWeight: "bolder",
                        }}
                      >
                        {item.result}
                      </td>
                      <td className="w-1/5 text-base">{item.margin}</td>
                    </tr>
                  );
              })}
          </table>
        </div>
      )}
    </div>
  );
}

export default NRLPlayerTable;
