import {
  ADMIN_CREDENTIALS,
  decode_admin_credentials,
  email_validation,
  encode_admin_credentials,
  password_validation,
  username_validation,
  validate_admin_credential,
} from "../../Utils/Utils";
import React, { useState } from "react";

function Login(props) {

  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");

  const [email_err, setemail_err] = useState(false);
  const [password_err, setpassword_err] = useState(false);

  const [show_password, setshowpassword] = useState(false);

  const [loggedin, setlogin] = useState(false);

  const validate_password = (txt) => setpassword_err(password_validation(txt));
  const validate_email = (txt) => {
    setemail_err(username_validation(txt))
  };

  const AdminLogin = () => {
    if (!email_err && !password_err && email !== "" && password !== "") {

      if(validate_admin_credential(email,password)){

        let encode_email = encode_admin_credentials(email)
        let encode_password = encode_admin_credentials(password)

          window.localStorage.setItem(
              ADMIN_CREDENTIALS,
              JSON.stringify({ username: encode_email, password: encode_password })
          );
          props.loggedin()

      }else alert('Incorrect admin credential')
      
    }
  };

  return (
    <div
      className="flex flex-auto justify-center items-center "
      style={{ height: "85vh" }}
    >
      <div className="border border-gray-500 p-3 w-10/12 md:w-1/3 xl:w-1/4 2xl:w-1/5 rounded-md py-5">
        <h3 className="text-xl font-medium">Admin Dashboard</h3>

        <div className="my-5 p-2">
          <div className="xl:my-3 2xl:my-5">
            <p className="text-left text-xs">Username</p>
            <div className="flex flex-row items-center justify-between">
              <input
                type={"text"}
                onChange={(event) => setemail(event.target.value)}
                value={email}
                placeholder="Eg: John@1234"
                className="w-full my-2 rounded-md p-1.5 focus:outline-none bg-transparent border border-gray-500"
                onBlur={(event) => validate_email(event.target.value)}
              />
            </div>

            {email_err && (
              <p className="text-red-500 text-sm text-left mb-4">{`Invalid username,should contain min of 8 letters`}</p>
            )}
          </div>

          <div className="xl:my-3 2xl:my-5">
            <p className="text-left text-xs">Password</p>
            <div className="flex flex-row items-center justify-between">
              <input
                type={show_password ? "text" : "password"}
                onChange={(event) => setpassword(event.target.value)}
                value={password}
                placeholder="Eg: John@1234"
                className="w-full my-2 rounded-md p-1.5 focus:outline-none bg-transparent border border-gray-500"
                onBlur={(event) => validate_password(event.target.value)}
              />
              {/* <a
                  className="cursor-pointer text-xs ml-1"
                  onClick={() =>
                    setshowpassword(!show_password)
                  }
                >
                  {show_password ? `hide` : `show`}
                </a> */}
            </div>

            {password_err && (
              <p className="text-red-500 text-sm text-left mb-4">{`Invalid password / Password should be contain uppercase, lowercase, alphanumeric and special characters`}</p>
            )}
          </div>

          <div className="mt-2">
            <button
              type="submit"
              //   disabled={!isSubmitBtnEnabled() || loading}
              onClick={AdminLogin}
              style={{ backgroundColor: "#fd0356" }}
              className=" w-full p-2 rounded-lg font-semibold bg-[#fd0356]"
            >
              {`Admin Login`}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
