import React, { useEffect, useState } from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

function AppLoader(props){

    const [msg,setMsg]=useState()
    const [loader,setLoader]=useState()

    useEffect(()=>{

    })

    return (
        <div className='loader-com flex flex-1 flex-col justify-center content-center items-center align-center'>
            <Loader
                type="Oval"
                color="#5089C6"
                height={40}
                width={40}
                // timeout={3000} //3 secs
            />
            <p className='xl text-white'>{props.msg}</p>
        </div>
    )
}

export default AppLoader;