export const SEASON_LISTS = "SEASON_LISTS";

export const getSeasonList = () => {
  return JSON.parse(window.localStorage.getItem(SEASON_LISTS));
};

export const setSeasonList = (season_list) => {
  window.localStorage.setItem(SEASON_LISTS, JSON.stringify(season_list));
};

export const clearSeasonList = () => {
    window.localStorage.removeItem(SEASON_LISTS);
  };




export const SEASON_LIST =[
    {
      id:1,
      name:'afl',
      season:[
        {
          id:1,
          year:'2023'
        },
        {
          id:2,
          year:'2022'
        },
        {
          id:3,
          year:'2021'
        },
      ]
    },
    {
      id:2,
      name:'nrl',
      season:[
        {
          id:1,
          year:'2022'
        },
        {
          id:2,
          year:'2021'
        },
      ]
    },
    {
      id:3,
      name:'nba',
      season:[
        {
          id:1,
          year:'2022/23'
        },
        {
          id:2,
          year:'2021/22'
        },
      ]
    },
    {
      id:4,
      name:'epl',
      season:[
        {
          id:1,
          year:'2022/23'
        },
        {
          id:2,
          year:'2021/22'
        },
      ]
    },
    {
      id:5,
      name:'nfl',
      season:[
        {
          id:1,
          year:'2022/23'
        }
      ]
    },
    {
      id:6,
      name:'bbl',
      season:[
        {
          id:1,
          year:'2022/23'
        }
      ]
    }
  ]
   