import React, { useEffect } from "react";
import AppBar from "../AppBar/AppBar";
import { withRouter } from "react-router-dom";
import { VerifyAccount_Api } from "../../Busniess_Logic/API/VerifyAccountApi";
import { useState } from "react";
function VerifyAccount(props) {


  const [verified, setverified] = useState(null);
  const [in_progress, setprogress] = useState(false);
  const [alreadyverified, setalreadyverified] = useState(false);

  useEffect(() => {
    async function verifyuser() {
      try {
        setprogress(true);

        let user_id = new URL(window.location.href).searchParams.get("id");
        let token = new URL(window.location.href).searchParams.get("token");

        if ( user_id || token) {

          console.log(user_id,token)

          let result = await VerifyAccount_Api({ token: user_id ? user_id : token });

          if (result.success) {
            setverified(true);
            setprogress(false);
          } 

          else {
            
            setprogress(false);

            if(result.response === "user already verified.") {
              setverified(true);
              setalreadyverified(true)
            } else{
              setverified(false);
              alert(result.response)
            }

          }
          
        } else if( !user_id || !token ){
          setprogress(false);
          alert("Sorry, cannot find user..!!");
        }
      } catch (error) {
        alert(error);
        setprogress(false);
      }
    }

    verifyuser();
  }, []);

  const openApp = () => {
    try {
      window.open(`multihub://`);
    } catch (error) {
      console.log("Cannot open app now");
      window.open(
        "https://play.google.com/store/apps/details?id=com.multihub.app"
      );
    }
  };

  return (
    <div>
      <AppBar his={props.history} />
      <div
        className="flex flex-col justify-center items-center"
        style={{ height: "91vh" }}
      >
        {in_progress ? (
          <p className="text-white">Verifying your email..</p>
        ) : (
          <>
            {verified == true ? (
              <>
                <div className="bg-green-500 rounded-full w-20 h-20">
                  <p
                    style={{ fontSize: "60px" }}
                    className="h-20 font-extrabold"
                  >
                    &#10003;
                  </p>
                </div>
                
                <p className="w-full my-2">{ alreadyverified ? `Already Verified!` : `Verified!` } </p>

                {/* <button className="my-2 primary-bg-color py-2 px-5 rounded-lg" onClick={openApp}> */}
                <p className="text-md my-3 capitalize text-gray-300 ">
                  visit multihub app
                </p>
                {/* </button> */}
              </>
            ) : verified === false ? (
              <>
                <div className="bg-red-300 rounded-full w-20 h-20 flex flex-row justify-center items-center">
                  <p
                    style={{ fontSize: "40px" }}
                    className="h-16 w-20 font-extrabold"
                  >
                    &#10060;
                  </p>
                </div>
                <p className="w-full my-2 capitalize font-medium">
                  Cannot verify your account !!
                </p>
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default withRouter(VerifyAccount);
