import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import AppBar from "../AppBar/AppBar";
import PageBar from "../AppBar/PageBar";
import AppLoader from "../Loader/Loader";
import '../../utils/styles/teamdetail/teamdetail.scss'
import { SponserListCom } from "../SponerList/SponserList";
import { GetNRLPlayerDetail } from "../../Busniess_Logic/API/NRLApi";
import CopyRights from "../CopyRights";
import AdSenseCom from "../AdSense/AdSense";

function NRLPlayerDetail(props) {

    const [called, setCalled] = useState(false)
    const [detail, setDetail] = useState(null)
    const [userDetail, setUserdetail] = useState(null)
    const [loader, setLoader] = useState(false)

    const [tc, setTc] = useState(null)
    const [hoverHeader, setHoverheader] = useState(null)
    const [userTries, setUsertries] = useState([])

    useEffect(() => {
        getplayerdetails()
    },[])

    const getplayerdetails = async () => {

        setLoader(true)
        var surl = new URLSearchParams(window.location.href)
        var url = new URL(window.location.href);
        var c = url.searchParams.get("id");
        var season = surl.get("s");
        console.log('season',season)

        const res = await GetNRLPlayerDetail( c ? c : "1-14245",season ? season :"2021" )
        if (res) {
            setDetail(res)
            CalculateTryData(res)
        }

        // const player = await GetNRLPlayer(c ? c : "1-14245")
        // if (player) {
        //     setUserdetail(player)
        //     // processTBData(player[0])
        // }

        setCalled(true)
        setLoader(false)
        setTc(surl.get('tc') ? surl.get('tc') :"#8f1651")

    }


    const CalculateTryData = (data) => {
        if (data) {
            var triesList = []
            data.games && data.games.forEach(item => {
                var eachTries = 0
                item.stats.map(state => {
                    if (state.id === '1-6') {
                        eachTries = eachTries + state.value
                    }
                })
                triesList.push(eachTries)
            })

            var contentList = []
            if (triesList.length !== 0) {
                var sortedList = []
                sortedList = triesList.sort()

                contentList.push({ "title": "Tries", "content": '' })

                contentList.push({ 'title': 'total tries', 'content': sortedList.reduce((a, b) => a + b) })
                contentList.push({ 'title': 'highest try', 'content': sortedList[(sortedList.length) - 1] })
                contentList.push({ 'title': 'average try score', 'content': (sortedList.reduce((a, b) => a + b, 0) / sortedList.length).toFixed(1) })

                contentList.push({ 'title': 'Anytime Try Scorer', 'content': sortedList.filter(function (ele) { return ele == 1 }).length + "/" + triesList.length })
                contentList.push({ 'title': 'two tries', 'content': sortedList.filter(function (ele) { return ele == 2 }).length + "/" + triesList.length })
                contentList.push({ 'title': 'three tries', 'content': sortedList.filter(function (ele) { return ele == 3 }).length + "/" + triesList.length })

                setUsertries(contentList)
            }
        }
    }
 

    const playerDataTable = (tableData) => {
        return (
            <table className='rounded-lg tb-shadow h-auto w-full text-black'>
                {
                    tableData.map((item, index) => {
                        if (index === 0) return <tr style={{ backgroundColor: tc }}>
                            <th className='rounded-tl-md uppercase w-full text-white text-left' style={{ backgroundColor: tc }}>{item.title}</th>
                            <th className='rounded-tr-md w-1/2'>{item.content}</th>
                        </tr>
                        else return <tr className=''>
                            <td className='capitalize text-center pl-4 w-1/2 py-1'>{item.title}</td>
                            <td className='w-1/2'>{item.content}</td>
                        </tr>
                    })
                }
            </table>
        )
    }

    const CalculateTries = () => {
        var triesList = []
        detail.games.forEach(item => {
            var tries = 0

            item.stats.forEach(stat => {
                if (stat.id === '1-6') tries = tries + stat.value
            })

            triesList.push(tries)
        })
        return triesList
    }

    const getTotalGames = () => {
        if(detail && detail.games) return (detail.games.length).toString()
        else return "0"
    }

    return (
        <div className=''>
            <AppBar his={props.history} />

            {
                loader && <div style={{ position: 'relative' }}><AppLoader msg={'Getting player details'} /></div>
            }

            { !loader &&

                <div className='player-detail-com w-full sm:w-full md:w-full lg:w-3/5 
                m-auto xl:w-3/4 2xl:w-3/4 p-3 xl:p-0 2xl:p-0 2xl:py-5 pb-8'>
                    <div className='xl:flex 2x:flex xl:flex-1 2xl:flex-1'>
                        {
                            detail!=null && detail!=undefined ? 
                            <div className='mx-1 w-full xl:w-3/4 2xl:w-4/5'
                        >
                            <AdSenseCom/>

                            <PageBar his={props.history} />

                            <div className='team-container p-4 rounded-lg border border-grey-100 shadow-md flex flex-1 flex-row items-center' style={{ backgroundColor: tc }}>
                                <div className='team-name w-full'><h1 className='text-3xl font-bold text-white'>{detail.player_name ? detail.player_name :''}</h1></div>
                            </div>

                            <div className='my-2 p-3 rounded-lg shadow-md border border-grey-100 flex flex-1 justify-around'>
                                <p className='text-left px-2'>Total games played</p>
                                <p className='text-left px-2'><strong>{getTotalGames()}</strong></p>
                            </div>

                            {
                                detail.games && detail.games.length != 0 ?
                                <div className='w-full'>
                                    <div className='rounded-lg shadow-xl items-center my-4 top-tb-container' style={{ position: 'relative' }}>
                                        <table className='p-2 top-table text-base xl:text-sm 2xl:text-lg bg-white text-black'>
                                            <tr>
                                                <th className='rounded-tl-md text-white p-2 capitalize' style={{ backgroundColor: tc }}>Opposition name</th>
                                                {
                                                    detail.games && detail.games.map((item, index) => {
                                                        if (item.away_team.abbreviation === detail.player_team.abbreviation)
                                                            return <td className='text-md top-tb-data' onMouseEnter={() => setHoverheader(item.home_team.abbreviation)} onMouseLeave={() => setHoverheader(null)}>
                                                                <div className='tool-tip-con'>
                                                                    <strong>{item.home_team.abbreviation}</strong>
                                                                    <p className='tool-tip-data'>{item.home_team.name}</p>
                                                                </div>
                                                            </td>
                                                        else return <td className='text-md top-tb-data' onMouseEnter={() => setHoverheader(item.away_team.abbreviation)} onMouseLeave={() => setHoverheader(null)}>
                                                            <div className='tool-tip-con'>
                                                                <strong>{item.away_team.abbreviation}</strong>
                                                                <p className='tool-tip-data'>{item.away_team.name}</p>
                                                            </div>
                                                        </td>
                                                    })
                                                }
                                            </tr>

                                            <tr >
                                                <th className='text-white p-2 capitalize' style={{ backgroundColor: tc }}>date</th>
                                                {
                                                    detail.games.map((item, index) => {
                                                        return <td className='text-sm top-tb-data'>
                                                            <strong>{new Date(item.start_time).getDate() + ' ' + new Date(item.start_time).toLocaleDateString('default', { month: 'long' }).substr(0, 3)}</strong>
                                                        </td>
                                                    })
                                                }
                                            </tr>

                                            <tr >
                                                <th className='text-white p-2 capitalize' style={{ backgroundColor: tc }}>tries</th>
                                                {CalculateTries().map((item) => {
                                                    return <td className='top-tb-data'><strong>{item === 0 || item === -Infinity || item === NaN ? '-' : item}</strong></td>
                                                })}
                                            </tr>

                                        </table>
                                    </div>

                                    <div className='grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 
                                        gap-2 items-start justify-between w-full data-table-con'>

                                        {
                                            userTries && playerDataTable(userTries)
                                        }

                                    </div>

                                </div>
                                :
                                <div>
                                    <p className="text-white py-3 text-center">No games played</p>
                                </div>
                            }

                        </div>:
                        <div className='mx-1 w-full xl:w-3/4 2xl:w-4/5 m-auto'>
                            <p className="text-white py-5 text-center">Data not found</p>
                        </div>
                        }
                        
                        <div className='mt-14 xl:w-1/4 2xl:w-1/5'>
                            <SponserListCom />
                        </div>
                    </div>
                </div>
            }

            { !loader && <CopyRights his={props.history}/> }

        </div>
    )
}

export default withRouter(NRLPlayerDetail);