import { useEffect } from "react";
import { useState } from "react";
import {
  GetOfferList_Api,
} from "../../../../Busniess_Logic/API/AdminDashboard";
import {
  ADMIN_CREDENTIALS,
  CenterContent,
  formatAMPM,
} from "../../../Utils/Utils";
import AddOffer from "./AddOffer";

function OfferList(props) {
  const [addpost, setaddpost] = useState(false);
  // const [show_notification, set_show_notification] = useState(false);
  const [offer_data, setofferdata] = useState(null);

  const [offer_list, setofferlist] = useState([]);

  const [loading, setloader] = useState(false);


  useEffect(() => {
    async function getOfferList() {

      if(offer_list.length === 0)
      await GetOfferList();

    }
    getOfferList();
  }, []);

  const GetOfferList = async () => {
    setloader(true);
    try {
      const res = await GetOfferList_Api();
      if (res) setofferlist(res);
    } catch (error) {
      console.log(error);
      setloader(false);
    } finally {
      setloader(false);
    }
  };

  const updateList = async () => {
    setaddpost(!addpost);
    await GetOfferList();
  };

  const Logout = () => {
    window.localStorage.removeItem(ADMIN_CREDENTIALS);
    window.location.reload();
  };

  if (loading) return CenterContent("Getting offer list");
  else
    return (
      <div className="">
        {addpost && (
          <div className="absolute top-0 left-0 right-0 bottom-0 bg-transparent">
            <div
              className="absolute top-0 left-0 right-0 bottom-0 bg-black opacity-75 cursor-pointer"
              onClick={() => setaddpost(!addpost)}
            />
            <AddOffer
              closeModal={() => updateList()}
              updateList={()=> updateList()}
            />
          </div>
        )}

        <div className="flex justify-between w-full mb-5">
          <button
            type="submit"
            style={{ color: "#fff", border: "2px solid #fd0356" }}
            className="px-4 py-1.5 rounded-lg font-medium"
            onClick={Logout}
          >
            {`Logout`}
          </button>

          <button
            type="submit"
            style={{ color: "#fff", border: "2px solid #fd0356" }}
            className="px-4 py-1.5 rounded-lg font-medium"
            onClick={() => setaddpost(!addpost)}
          >
            {`Add New Notification`}
          </button>
        </div>

        {offer_list.length === 0 ? (
          CenterContent("Add new notification...")
        ) : (
          <table className="w-full">
            <thead className="border-b border-gray-600">
              <tr>
                <th className="text-left capitalize" style={{ width: "25%" }}>
                  <p className="mb-4">name</p>
                </th>
                <th className="text-left capitalize" style={{ width: "25%" }}>
                  <p className="mb-4">conent</p>
                </th>
                <th className="text-left capitalize" style={{ width: "10%" }}>
                  <p className="mb-4">created</p>
                </th>
                <th className="text-center capitalize" style={{ width: "20%" }}>
                  <p className="mb-4">status</p>
                </th>
              </tr>
            </thead>

            <tbody>
              {[...offer_list]
                .sort((a, b) => b.id - a.id)
                .map((item, i) => {
                  return (
                    <tr key={item.id} className="items-center">
                      <td>
                        <p className="text-left capitalize text-sm py-2 my-4">
                          {item.title}
                        </p>
                      </td>
                      <td>
                        {" "}
                        <p className="text-left capitalize text-sm">
                          {item.content}
                        </p>{" "}
                      </td>
                      <td>
                        {" "}
                        <p className="text-left capitalize text-sm">
                          {formatAMPM(new Date(item.created_in))}
                        </p>{" "}
                      </td>
                      <td>
                        {item.status ? (
                          <p className="text-center font-medium capitalize text-sm text-green-500">{`Notified`}</p>
                        ) : (
                          <div>
                            {item.scheduled !== null ? (
                              <>
                                <p className="text-sm text-yellow-400 font-medium">
                                  &#8986; {formatAMPM(new Date(item.scheduled))}
                                </p>
                              </>
                            ) : (
                              <button
                                type="button"
                                style={{ backgroundColor: "#fd0356" }}
                                className="px-4 py-1.5 text-xs rounded-lg font-semibold bg-[#fd0356] my-5 mx-1"
                                // onClick={() => setofferdata(item)}
                              >
                                Notify
                              </button>
                            )}
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
      </div>
    );
}

export default OfferList;
