const BASE_URL = "https://apiv3.multihub.com.au/";

// const BASE_URL = "http://192.168.1.107:8000/";

export async function SignUp_Api(signup_data) {
  const res = await fetch(BASE_URL + "signup/", {
    method: "POST",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
    body: JSON.stringify(signup_data),
  });
  // console.log(res);
  var jsonData = await res.json();
  // console.log(jsonData);
  return jsonData;
}

export async function SignIn_Api(login_data) {
  const res = await fetch(BASE_URL + "signin/", {
    method: "POST",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
    body: JSON.stringify(login_data),
  });
  // console.log(res);
  var jsonData = await res.json();
  // console.log(jsonData);
  return jsonData;
}

export async function ForgotPassword_Api(email) {
  const res = await fetch(BASE_URL + "forgot/password/", {
    method: "POST",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
    body: JSON.stringify(email),
  });
  // console.log(res);
  var jsonData = await res.json();
  // console.log(jsonData);
  return jsonData;
}

export async function LogOut_Api(userid) {
  const res = await fetch(BASE_URL + "logout/", {
    method: "POST",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
    body: JSON.stringify({ user_id: userid }),
  });
  // console.log(res);
  var jsonData = await res.json();
  // console.log(jsonData);
  return jsonData;
}

export async function GetUserProfile_Api(user_id) {
  const res = await fetch(BASE_URL + "user/" + user_id + "/", {
    method: "GET",
  });
  var jsonData = await res.json();
  // console.log(jsonData);
  return jsonData;
}

export async function GetSeasonList_Api() {
  const res = await fetch(BASE_URL + "season/list/", {
    method: "GET",
  });
  var jsonData = await res.json();
  // console.log(jsonData);
  return jsonData;
}
