import { browserName , isAndroid, osName, osVersion,mobileVendor , isDesktop , mobileModel } from 'react-device-detect';

export const reportLog = async(sportsName) => {
  var data = {
    "api_key": "1a087838bf694ce118002c25d430c996",

    "events": [
      {
        "event_type":sportsName,
        "user_id": "datamonster@gmail.com",
        "device_id": "C8F9E604-F01A-4BD9-95C6-8E5357DF265D",
        "platform": isDesktop ? "PC" : isAndroid ? "ANDROID" : "IOS",
        "os_name": osName ? osName : "",
        "os_version": osVersion ? osVersion : "",
        "device_brand": mobileVendor ? mobileVendor : "",
        "device_manufacturer":  mobileVendor ? mobileVendor : "",
        "device_model" : mobileModel ? mobileModel :"",
        "event_properties": {
          "name": sportsName,
          "browser":browserName?browserName :"",
        }
      }
    ]
  }
  var headers = {
    'Content-Type':'application/json',
    'Accept':'*/*'
  
  };

  const res = await fetch('https://api2.amplitude.com/2/httpapi', {
    method: 'post',
    body:JSON.stringify(data),
    headers: headers,
    success: function(data) {
      // console.log(JSON.stringify(data));
    }
  })
  const json = await res.json();
  
}

