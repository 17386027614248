import React , { useEffect} from 'react';

// import { Container } from './styles';

const Drafstars = () => {

  useEffect(() => {
    window.location.href = 
    // "https://record.affiliates.playup.com/_0ACOkAcIIE36PBA04iUMN2Nd7ZgqdRLk/1/";
    "https://record.affiliates.playup.com/_0ACOkAcIIE12qOYNvGXWVGNd7ZgqdRLk/1/";
  }, [])
  return <p>Please wait while we redirect!</p>;
}

const Neds = () => {

  useEffect(() => {
    window.location.href = 
    // "https://record.nedsaffiliates.com.au/_rNbqy-8ivW8Lak7-PYDSdWNd7ZgqdRLk/1/?b=WIZARDS";
    "https://record.nedsaffiliates.com.au/_rNbqy-8ivW8Lak7-PYDSdWNd7ZgqdRLk/1/?b=WIZARDS";
  }, [])
  return <p>Please wait while we redirect!</p>;
}


const Boombet = () => {

  useEffect(() => {
    window.location.href = "https://www.boombet.com.au/join/?Referrer=PuntingWizards";
  }, [])
  return <p>Please wait while we redirect!</p>;
}





const Ladbrokes = () => {

  useEffect(() => {
    window.location.href = 
    // "https://record.wageringaffiliates.com.au/_I7NX2_GSSjkB0WjZNehKqWNd7ZgqdRLk/1/?b=WIZARDS";
    "https://record.wageringaffiliates.com.au/_I7NX2_GSSjkB0WjZNehKqWNd7ZgqdRLk/1/?b=WIZARDS"
  }, [])
  return <p>Please wait while we redirect!</p>;
}


const Playup = () => {

  useEffect(() => {
    window.location.href = 
    // "http://record.affiliates.playup.com/_0ACOkAcIIE0a0J_CtyaYcGNd7ZgqdRLk/1/";
    "https://record.affiliates.playup.com/_0ACOkAcIIE3CP9GC20JVYmNd7ZgqdRLk/1/ "
  }, [])
  return <p>Please wait while we redirect!</p>;
}

const Betr = () => { 
  useEffect(() => {
    window.location.href = 
    // "http://record.affiliates.playup.com/_0ACOkAcIIE0a0J_CtyaYcGNd7ZgqdRLk/1/";
    "https://aus01.safelinks.protection.outlook.com/?url=https%3A%2F%2Frecord.betraffiliates.com.au%2F_OYeaPWW-7QEOMRDMpvVHzmNd7ZgqdRLk%2F1%2F&data=05%7C01%7Cmichael.liston%40betr.com.au%7C08c0bda19f8f4072a2c608dac2bb048c%7Cdda3f2f6de5d4b688a9754b9fc911ef4%7C0%7C0%7C638036407159279140%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C1000%7C%7C%7C&sdata=U2Nu0ukCdX%2BhgGuQPIO%2BWwzexTvCI7sykyXFIQnyOc0%3D&reserved=0"
  }, [])
  return <p>Please wait while we redirect!</p>;
}



const Unibet = () => {

  useEffect(() => {
    window.location.href = "https://b1.trickyrock.com/redirect.aspx?pid=32519285&bid=21418";
  }, [])
  return <p>Please wait while we redirect!</p>;
}


export  {Drafstars , Neds , Boombet  , Ladbrokes, Playup, Unibet, Betr };