//nrl tries header
export const NRLTriresHeader = [
  "#",
  "player",
  "tt",
  "avt",
  "1t",
  "2t",
  "3t",
  "ht",
  "gp",
];

export const NRLTeamList = [
  //1st
  {
    name: "Brisbane Broncos",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NRL_team_icons/brisbane.png",
    bgcolor: "#8f1651",
    team_id: "1-27",
  },

  //team id 1-21 and 1-29 hardcored id
  {
    name: "Canberra Raiders",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NRL_team_icons/canberra.png",
    bgcolor: "#009f35",
    team_id: "1-29",
  },
  {
    name: "Sydney Roosters",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NRL_team_icons/sydney.png",
    bgcolor: "#082b4d",
    team_id: "1-21",
  },

  {
    name: "Canterbury-Bankstown Bulldogs",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NRL_team_icons/canterbury.png",
    bgcolor: "#1469ca",
    team_id: "1-26",
  },
  {
    name: "Manly Sea Eagles",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NRL_team_icons/manlyseaeagles.png",
    bgcolor: "#ad1d68",
    team_id: "1-22",
  },
  {
    name: "Gold Coast Titans",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NRL_team_icons/goldcoasttitans.png",
    bgcolor: "#00acde",
    team_id: "1-24",
  },

  //second
  {
    name: "Melbourne Storm",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NRL_team_icons/melbourne.png",
    bgcolor: "#001441",
    team_id: "1-31",
  },
  {
    name: "North Queensland Cowboys",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NRL_team_icons/northqueenslandcowboys.png",
    bgcolor: "#005696",
    team_id: "1-28",
  },
  {
    name: "New Zealand Warriors",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NRL_team_icons/newzealand.png",
    bgcolor: "#b6b7b9",
    team_id: "1-36",
  },
  {
    name: "Newcastle Knights",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NRL_team_icons/newcastle.png",
    bgcolor: "#006ab2",
    team_id: "1-23",
  },
  {
    name: "St. George Illawarra Dragons",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NRL_team_icons/stgeorgeillawaradragons.png",
    bgcolor: "#ff2700",
    team_id: "1-32",
  },
  {
    name: "Parramatta Eels",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NRL_team_icons/paramatta.png",
    bgcolor: "#007dbc",
    team_id: "1-35",
  },

  //3rd
  {
    name: "Penrith Panthers",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NRL_team_icons/penrith.png",
    bgcolor: "#050000",
    team_id: "1-30",
  },
  {
    name: "Cronulla-Sutherland Sharks",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NRL_team_icons/cronullasharks.png",
    bgcolor: "#70cbda",
    team_id: "1-34",
  },
  {
    name: "Wests Tigers",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NRL_team_icons/westsTigers.png",
    bgcolor: "#ff7d00",
    team_id: "1-33",
  },
  {
    name: "South Sydney Rabbitohs",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NRL_team_icons/southSydneyRabbitohs.png",
    bgcolor: "#009861",
    team_id: "1-25",
  },
];

export const NRLTbHoverList = [
  {
    id: 1,
    name: "avt",
    fullform: "average total tries",
  },
  {
    id: 2,
    name: "tt",
    fullform: "total tries",
  },
  {
    id: 3,
    name: "1t",
    fullform: "Anytime Try Scorer",
  },
  {
    id: 4,
    name: "2t",
    fullform: "two tries",
  },
  {
    id: 5,
    name: "3t",
    fullform: "three tries",
  },
  {
    id: 6,
    name: "ht",
    fullform: "highest tries",
  },
  {
    id: 7,
    name: "gp",
    fullform: "games played",
  },
];
