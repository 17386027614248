export const NBA_Team_List = [
  {
    name: "Atlanta Hawks",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Atlanta+Hawks.png",
    short_form: "Hawks",
    bgcolor: "#cf0a2c",
    team_id: "7-11",
  },
  {
    name: "Boston Celtics",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Boston+Celtics.png",
    short_form: "Celtics",
    bgcolor: "#008337",
    team_id: "7-1",
  },
  {
    name: "Brooklyn Nets",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Brooklyn+Nets.png",
    short_form: "Nets",
    bgcolor: "#333333",
    team_id: "7-2",
  },
  {
    name: "Charlotte Hornets",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Charlotte+Hornets.png",
    short_form: "Hornets",
    bgcolor: "#008d8b",
    team_id: "7-12",
  },
  {
    name: "Chicago Bulls",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Chicago+Bulls.png",
    short_form: "Bulls",
    bgcolor: "#ce1141",
    //#852e44
    team_id: "7-6",
  },
  {
    name: "Cleveland Cavaliers",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Cleveland+Cavaliers.png",
    short_form: "Cavaliers",
    bgcolor: "#6a0032",
    team_id: "7-7",
  },
  {
    name: "Dallas Mavericks",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Dallas+Mavericks.png",
    short_form: "Mavericks",
    bgcolor: "#0064b1",
    team_id: "7-21",
  },
  {
    name: "Denver Nuggets",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Denver+Nuggets.png",
    short_form: "Nuggets",
    bgcolor: "#09203c",
    team_id: "7-26",
  },
  {
    name: "Destroit Pistons",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Destroit+Pistons.png",
    short_form: "Pistons",
    bgcolor: "#00438c",
    team_id: "7-8",
  },
  {
    name: "Golden State Warriors",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Golden+State+Warriors.png",
    short_form: "Warriors",
    bgcolor: "#00438c",
    team_id: "7-16",
  },
  {
    name: "Houston Rockets",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Houston+Rockets2.png",
    short_form: "Rockets",
    // bgcolor: "#595959",
    //#bd395a
    bgcolor: "#ce1141",
    team_id: "7-22",
  },
  {
    name: "Indiana Pacers",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Indiana+Pacers.png",
    short_form: "Pacers",
    bgcolor: "#fdbb34",
    team_id: "7-9",
  },
  {
    name: "LA Clippers",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/LA+Clippers.png",
    short_form: "Clippers",
    bgcolor: "#da1a32",
    team_id: "7-17",
  },
  {
    name: "Los Angeles Lakers",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Los+Angeles+Lakers.png",
    short_form: "Lakers",
    bgcolor: "#552582",
    team_id: "7-18",
  },
  {
    name: "Memphis Grizzlies",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Memphis+Grizzlies.png",
    short_form: "Grizzlies",
    bgcolor: "#072441",
    team_id: "7-23",
  },
  {
    name: "Miami Heat",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Miami+Heat.png",
    short_form: "Heat",
    bgcolor: "#98002e",
    team_id: "7-13",
  },
  {
    name: "Milwaukee Bucks",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Milwaukee+Bucks.png",
    short_form: "Bucks",
    bgcolor: "#2b5134",
    team_id: "7-10",
  },
  {
    name: "Minnesota Timberwolves",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Minnesota+Timberwolves.png",
    short_form: "Timberwolves",
    bgcolor: "#00558b",
    team_id: "7-27",
  },
  {
    name: "New Orleans Pelicans",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/New+Orleans+Pelicans.png",
    short_form: "Pelicans",
    bgcolor: "#002b5c",
    team_id: "7-24",
  },
  {
    name: "New York Knicks",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/New+York+Knicks.png",
    short_form: "Knicks",
    bgcolor: "#006bb6",
    team_id: "7-3",
  },
  {
    name: "Oklahoma City Thunder",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Oklahoma+City+Thunder.png",
    short_form: "Thunder",
    bgcolor: "#002d62",
    team_id: "7-28",
  },
  {
    name: "Orlando Magic",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Orlando+Magic.png",
    short_form: "Magic",
    bgcolor: "#0076c0",
    team_id: "7-14",
  },
  {
    name: "Philadelphia 76ers",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Philadelphia+76ers.png",
    short_form: "76ers",
    bgcolor: "#0076c0",
    //#4783a8
    team_id: "7-4",
  },
  {
    name: "Phoenix Suns",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Phoenix+Suns.png",
    short_form: "Suns",
    bgcolor: "#3f2680",
    team_id: "7-19",
  },
  {
    name: "Portland Trial Blazers",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Portland+Trial+Blazers.png",
    short_form: "Trial Blazers",
    bgcolor: "#e03a3e",
    team_id: "7-29",
  },
  {
    name: "Sacramento Kings",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Sacramento+Kings.png",
    short_form: "Kings",
    bgcolor: "#5b2b82",
    team_id: "7-20",
  },
  {
    name: "San Antonio Spurs",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/San+Antonio+Spurs.png",
    short_form: "Spurs",
    bgcolor: "#333333",
    team_id: "7-25",
  },
  {
    name: "Toronto Raptors",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Toronto+Raptors2.png",
    short_form: "Raptors",
    // bgcolor: "#595959",
    //#d65173
    bgcolor: "#ce1141",
    team_id: "7-5",
  },
  {
    name: "Utah Jazz",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Utah+Jazz.png",
    short_form: "Jazz",
    bgcolor: "#002b5c",
    team_id: "7-30",
  },
  {
    name: "Washington Wizards",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Washington+Wizards.png",
    short_form: "Wizards",
    bgcolor: "#002b5c",
    team_id: "7-15",
  },
];

//nba points header
export const NBAPointsHeader = [
  "#",
  "player",
  "tp",
  "avp",
  "10+",
  "15+",
  "20+",
  "25+",
  "30+",
  "35+",
  "40+",
  "lp",
  "slp",
  "hp",
  "shp",
  "gp",
];

//nba rebound header
export const NBAReboundsHeader = [
  "#",
  "player",
  "tr",
  "avr",
  "4+",
  "6+",
  "8+",
  "10+",
  "12+",
  "14+",
  "16+",
  "lr",
  "slr",
  "hr",
  "shr",
  "gp",
];

//nba assists header
export const NBAAssistsHeader = [
  "#",
  "player",
  "ta",
  "ata",
  "2+",
  "4+",
  "6+",
  "8+",
  "10+",
  "12+",
  "la",
  "sla",
  "ha",
  "sha",
  "gp",
];

//nba three points header
export const NBAThreePointsHeader = [
  "#",
  "player",
  "ttp",
  "atp",
  "1+",
  "2+",
  "3+",
  "4+",
  "5+",
  "ltp",
  "sltp",
  "htp",
  "shtp",
  "gp",
];

export const NBALengendList = [
  {
    id: 1,
    name: "tp",
    fullform: "total_points",
  },
  {
    id: 2,
    name: "avp",
    fullform: "average_points",
  },
  {
    id: 3,
    name: "10+",
    fullform: "ten_plus_points",
  },
  {
    id: 4,
    name: "15+",
    fullform: "fifteen_plus_points",
  },
  {
    id: 5,
    name: "20+",
    fullform: "twenty_plus_points",
  },
  {
    id: 6,
    name: "25+",
    fullform: "twentyfive_plus_points",
  },
  {
    id: 7,
    name: "30+",
    fullform: "thirty_plus_points",
  },
  {
    id: 8,
    name: "35+",
    fullform: "thirtyfive_plus_points",
  },
  {
    id: 9,
    name: "40+",
    fullform: "forty_plus_points",
  },
  {
    id: 10,
    name: "lp",
    fullform: "lowest_points",
  },
  {
    id: 11,
    name: "slp",
    fullform: "second_lowest_points",
  },
  {
    id: 12,
    name: "hp",
    fullform: "highest_points",
  },
  {
    id: 13,
    name: "shp",
    fullform: "second_highest_points",
  },
  {
    id: 14,
    name: "gp",
    fullform: "games played",
  },

  //rebounds
  {
    id: 15,
    name: "tr",
    fullform: "total_rebounds",
  },
  {
    id: 16,
    name: "avr",
    fullform: "average_rebounds",
  },
  {
    id: 17,
    name: "4+",
    fullform: "four_plus_rebounds",
  },
  {
    id: 18,
    name: "6+",
    fullform: "six_plus_rebounds",
  },
  {
    id: 19,
    name: "8+",
    fullform: "eight_plus_rebounds",
  },
  {
    id: 20,
    name: "10+",
    fullform: "ten_plus_rebounds",
  },
  {
    id: 21,
    name: "12+",
    fullform: "twelve_plus_rebounds",
  },
  {
    id: 22,
    name: "14+",
    fullform: "fourtheen_plus_rebounds",
  },
  {
    id: 23,
    name: "16+",
    fullform: "sixtheen_plus_rebounds",
  },
  {
    id: 24,
    name: "lr",
    fullform: "lowest_rebounds",
  },
  {
    id: 25,
    name: "slr",
    fullform: "second_lowest_rebounds",
  },
  {
    id: 26,
    name: "hr",
    fullform: "highest_rebounds",
  },
  {
    id: 27,
    name: "shr",
    fullform: "second_highest_rebounds",
  },
  {
    id: 28,
    name: "gp",
    fullform: "games played",
  },

  //assists
  {
    id: 29,
    name: "ta",
    fullform: "total_assists",
  },
  {
    id: 30,
    name: "ata",
    fullform: "average_total_assists",
  },
  {
    id: 31,
    name: "2+",
    fullform: "two_plus_assists",
  },
  {
    id: 32,
    name: "4+",
    fullform: "four_plus_assists",
  },
  {
    id: 33,
    name: "6+",
    fullform: "six_plus_assists",
  },
  {
    id: 34,
    name: "8+",
    fullform: "eight_plus_assists",
  },
  {
    id: 35,
    name: "10+",
    fullform: "ten_plus_assists",
  },
  {
    id: 36,
    name: "12+",
    fullform: "twelve_plus_assists",
  },
  {
    id: 37,
    name: "la",
    fullform: "lowest_assists",
  },
  {
    id: 38,
    name: "sla",
    fullform: "second_lowest_assists",
  },
  {
    id: 39,
    name: "ha",
    fullform: "highest_assists",
  },
  {
    id: 40,
    name: "sha",
    fullform: "second_highest_assists",
  },
  {
    id: 41,
    name: "gp",
    fullform: "games played",
  },

  //three points
  {
    id: 42,
    name: "ttp",
    fullform: "total_three_pointers",
  },
  {
    id: 43,
    name: "atp",
    fullform: "average_three_pointers",
  },
  {
    id: 44,
    name: "1+",
    fullform: "one_plus_three_points",
  },
  {
    id: 45,
    name: "2+",
    fullform: "two_plus_three_points",
  },
  {
    id: 46,
    name: "3+",
    fullform: "three_plus_three_points",
  },
  {
    id: 47,
    name: "4+",
    fullform: "four_plus_three_points",
  },
  {
    id: 48,
    name: "5+",
    fullform: "five_plus_three_points",
  },
  {
    id: 49,
    name: "ltp",
    fullform: "lowest_three_pointers",
  },
  {
    id: 50,
    name: "sltp",
    fullform: "second_lowest_three_pointers",
  },
  {
    id: 51,
    name: "htp",
    fullform: "highest_three_pointers",
  },
  {
    id: 52,
    name: "shtp",
    fullform: "second_highest_three_pointers",
  },
  {
    id: 53,
    name: "gp",
    fullform: "games played",
  },
];
