import React from "react";
import "../../utils/styles/appbar/appbar.scss";
import logo from "../../assets/Logo/logo.png";
import home from "../../assets/home.png";
import fb from "../../assets/Facebook.png";
import { useState } from "react";
import Account from "../Account";
import { getUser,removeUser } from "../../utils/userinfo";

import { AiOutlineUser } from "react-icons/ai";
import { useEffect } from "react";

function AppBar(props) {

  const [show_account, setaccount] = useState(false);

  useEffect(()=>{
    setaccount(props.show_signup)
  },[props.show_signup])

  const redirectToHome = () => {
    if (props.his) {
      if (props.his.location.pathname === "/") window.location.reload();
      else props.his.push("/");
    }
  };

  const responsiveRedirect = () => {
    if (window.innerWidth <= 749)
      window.open(
        "https://m.facebook.com/groups/604338443451503/?ref=bookmarks"
      );
    else window.open("https://www.facebook.com/groups/604338443451503");
  };

  const puntingGroupRedirect = () => {
    if (window.innerWidth <= 749)
      window.open(
        "https://m.facebook.com/groups/604338443451503/?ref=bookmarks"
      );
    else window.open("https://www.facebook.com/groups/604338443451503");
  };

  const manageAccount = (value) => {
    setaccount(value);
    props.closeSignupModal()
    value
      ? (document.body.style.overflowY = "hidden")
      : (document.body.style.overflowY = "auto");
  };

  const redirectToProfile=()=> props.his.push('profile')

  return (
    <div>
      {show_account ? (
        <div className="absolute top-0 left-0 right-0 bottom-0 bg-transparent">
          <div
            className="absolute top-0 left-0 right-0 bottom-0 bg-black opacity-80 cursor-pointer z-50"
            onClick={() => manageAccount(!show_account)}
          />
          <Account closeModal={() => manageAccount(!show_account)} />
        </div>
      ) : (
        <></>
      )}

      <div className="app-bar-com">
        <nav
          className="app-header-bg 
            px-4 md:px-4 lg:px-4 xl:px-0 2xl:px-0
            py-3 md:py-3 lg:py-3 xl:py-2 2xl:py-2 
            "
        >
          <div className="flex flex-1 justify-between items-center w-full sm:w-full md:w-full lg:w-3/4 xl:w-3/4 2xl:w-3/4 m-auto">
            <img
              src={logo}
              className="cursor-pointer text-4xl text-white font-bold text-center
                uppercase h-10"
              onClick={redirectToHome}
            />

            {props.his &&
            props.his.location &&
            props.his.location.pathname !== "/" ? (
              <div>
              <img
                src={home}
                onClick={redirectToHome}
                className="cursor-pointer text-4xl text-white font-bold text-center mx-0
                md:ml-20 lg:ml-20 xl:ml-20 2xl:ml-20 uppercase h-6"
              />
              </div>
            ) : (
              <div className="flex flex-auto items-center justify-end">
                <a
                  className="cursor-pointer flex-auto justify-end items-center hidden md:flex"
                  onClick={responsiveRedirect}
                >
                  <p className="hidden lg:block text-white text-sm font-bold capitalize">
                    Multi hub punting Group
                  </p>
                  <img
                    src={fb}
                    onClick={() => puntingGroupRedirect}
                    className="cursor-pointer text-4xl text-white font-bold text-center mx-0
                        md:ml-4 lg:ml-4 xl:ml-2 2xl:ml-2 uppercase h-6"
                  />
                </a>

                {getUser() ? (
                  <a className="ml-3 cursor-pointer" onClick={redirectToProfile}>
                    <AiOutlineUser color={"white"} size={"25"}/>{" "}
                  </a>
                ) : (
                  <button
                    className="primary-bg-color px-3 py-1.5 rounded-lg capitalize text-sm font-medium ml-3"
                    onClick={() => manageAccount(!show_account)}
                  >
                    {`Sign Up / Log In `}
                  </button>
                )}
              </div>
            )}
          </div>
        </nav>
      </div>
    </div>
  );
}
export default AppBar;
