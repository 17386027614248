export const NBA_Team_List = [
  {
    name: "Atlanta Hawks",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Atlanta+Hawks.png",
    short_form: "Hawks",
    bgcolor: "#cf0a2c",
    team_id: "7-11",
  },
  {
    name: "Boston Celtics",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Boston+Celtics.png",
    short_form: "Celtics",
    bgcolor: "#008337",
    team_id: "7-1",
  },
  {
    name: "Brooklyn Nets",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Brooklyn+Nets.png",
    short_form: "Nets",
    bgcolor: "#333333",
    team_id: "7-2",
  },
  {
    name: "Charlotte Hornets",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Charlotte+Hornets.png",
    short_form: "Hornets",
    bgcolor: "#008d8b",
    team_id: "7-12",
  },
  {
    name: "Chicago Bulls",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Chicago+Bulls.png",
    short_form: "Bulls",
    bgcolor: "#ce1141",
    //#852e44
    team_id: "7-6",
  },
  {
    name: "Cleveland Cavaliers",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Cleveland+Cavaliers.png",
    short_form: "Cavaliers",
    bgcolor: "#6a0032",
    team_id: "7-7",
  },
  {
    name: "Dallas Mavericks",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Dallas+Mavericks.png",
    short_form: "Mavericks",
    bgcolor: "#0064b1",
    team_id: "7-21",
  },
  {
    name: "Denver Nuggets",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Denver+Nuggets.png",
    short_form: "Nuggets",
    bgcolor: "#09203c",
    team_id: "7-26",
  },
  {
    name: "Destroit Pistons",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Destroit+Pistons.png",
    short_form: "Pistons",
    bgcolor: "#00438c",
    team_id: "7-8",
  },
  {
    name: "Golden State Warriors",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Golden+State+Warriors.png",
    short_form: "Warriors",
    bgcolor: "#00438c",
    team_id: "7-16",
  },
  {
    name: "Houston Rockets",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Houston+Rockets2.png",
    short_form: "Rockets",
    // bgcolor: "#595959",
    //#bd395a
    bgcolor: "#ce1141",
    team_id: "7-22",
  },
  {
    name: "Indiana Pacers",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Indiana+Pacers.png",
    short_form: "Pacers",
    bgcolor: "#fdbb34",
    team_id: "7-9",
  },
  {
    name: "LA Clippers",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/LA+Clippers.png",
    short_form: "Clippers",
    bgcolor: "#da1a32",
    team_id: "7-17",
  },
  {
    name: "Los Angeles Lakers",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Los+Angeles+Lakers.png",
    short_form: "Lakers",
    bgcolor: "#552582",
    team_id: "7-18",
  },
  {
    name: "Memphis Grizzlies",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Memphis+Grizzlies.png",
    short_form: "Grizzlies",
    bgcolor: "#072441",
    team_id: "7-23",
  },
  {
    name: "Miami Heat",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Miami+Heat.png",
    short_form: "Heat",
    bgcolor: "#98002e",
    team_id: "7-13",
  },
  {
    name: "Milwaukee Bucks",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Milwaukee+Bucks.png",
    short_form: "Bucks",
    bgcolor: "#2b5134",
    team_id: "7-10",
  },
  {
    name: "Minnesota Timberwolves",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Minnesota+Timberwolves.png",
    short_form: "Timberwolves",
    bgcolor: "#00558b",
    team_id: "7-27",
  },
  {
    name: "New Orleans Pelicans",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/New+Orleans+Pelicans.png",
    short_form: "Pelicans",
    bgcolor: "#002b5c",
    team_id: "7-24",
  },
  {
    name: "New York Knicks",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/New+York+Knicks.png",
    short_form: "Knicks",
    bgcolor: "#006bb6",
    team_id: "7-3",
  },
  {
    name: "Oklahoma City Thunder",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Oklahoma+City+Thunder.png",
    short_form: "Thunder",
    bgcolor: "#002d62",
    team_id: "7-28",
  },
  {
    name: "Orlando Magic",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Orlando+Magic.png",
    short_form: "Magic",
    bgcolor: "#0076c0",
    team_id: "7-14",
  },
  {
    name: "Philadelphia 76ers",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Philadelphia+76ers.png",
    short_form: "76ers",
    bgcolor: "#0076c0",
    //#4783a8
    team_id: "7-4",
  },
  {
    name: "Phoenix Suns",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Phoenix+Suns.png",
    short_form: "Suns",
    bgcolor: "#3f2680",
    team_id: "7-19",
  },
  {
    name: "Portland Trial Blazers",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Portland+Trial+Blazers.png",
    short_form: "Trial Blazers",
    bgcolor: "#e03a3e",
    team_id: "7-29",
  },
  {
    name: "Sacramento Kings",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Sacramento+Kings.png",
    short_form: "Kings",
    bgcolor: "#5b2b82",
    team_id: "7-20",
  },
  {
    name: "San Antonio Spurs",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/San+Antonio+Spurs.png",
    short_form: "Spurs",
    bgcolor: "#333333",
    team_id: "7-25",
  },
  {
    name: "Toronto Raptors",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Toronto+Raptors2.png",
    short_form: "Raptors",
    // bgcolor: "#595959",
    //#d65173
    bgcolor: "#ce1141",
    team_id: "7-5",
  },
  {
    name: "Utah Jazz",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Utah+Jazz.png",
    short_form: "Jazz",
    bgcolor: "#002b5c",
    team_id: "7-30",
  },
  {
    name: "Washington Wizards",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NBL_team_icons/Washington+Wizards.png",
    short_form: "Wizards",
    bgcolor: "#002b5c",
    team_id: "7-15",
  },
];


//nba points header
export const NFLTouchDownHeader = [
  "#",
  "player",
  "TT",
  "AVT",
  "T1+",
  "T2+",
  "T3+",
  "HT",
  "GP"
];
//nba points header
export const NFLTouchDownPassesHeader = [
  "#",
  "player",
  "TTP",
  "AVP",
  "TP1+",
  "TP2+",
  "TP3+",
  "TP4+",
  "TP5+",
  "LTP",
  "HTP",
  "GP"
];

//nba rebound header
export const NFLRushingYardHeader = [
  "#",
  "player",
  "TRY",
  "AVR",
  "30+",
  "40+",
  "50+",
  "60+",
  "70+",
  "80+",
  "90+",
  "100+",
  "110+",
  "LRY",
  "HRY",
  "GP"
];

//nba assists header
export const NFLReceivingYardHeader = [
  "#",
  "player",
  "TR",
  "AVR",
  "15+",
  "30+",
  "45+",
  "60+",
  "75+",
  "LRY",
  "HRY",
  "GP"
];

//nba three points header
export const NFLPassingYardHeader = [
  "#",
  "player",
  "TPY",
  "APY",
  "150+",
  "175+",
  "200+",
  "225+",
  "250+",
  "275+",
  "300+",
  "325+",
  "350+",
  "LPY",
  "HPY",
  "GP"
];

export const NFLLengendList = [
  // touchdown pass
  {
    id: 1,
    name: "TT",
    fullform: "total_touchdowns",
    category: 1,
  },
  {
    id: 2,
    name: "AVT",
    fullform: "average_touchdowns",
    category: 1,
  },
  {
    id: 3,
    name: "t1+",
    fullform: "anytime_touchdown_scorer",
    category: 1,
  },
  {
    id: 4,
    name: "t2+",
    fullform: "2_or_more_touchdows",
    category: 1,
  },
  {
    id: 5,
    name: "t3+",
    fullform: "3_or_more_touchdows",
    category: 1,
  },
  {
    id: 6,
    name: "ht",
    fullform: "highest_touchdowns",
    category: 1,
  },
  {
    id: 7,
    name: "gp",
    fullform: "games_played",
    category: 1,
  },

  // touchdown pass
  {
    id: 8,
    name: "TTP",
    fullform: "total_touchdowns_passes",
    category: 2,
  },
  {
    id: 9,
    name: "avp",
    fullform: "average_touchdowns_passes",
    category: 2,
  },
  {
    id: 10,
    name: "tp1+",
    fullform: "1_or_more_touchdows_passes",
    category: 2,
  },
  {
    id: 11,
    name: "tp2+",
    fullform: "2_or_more_touchdows_passes",
    category: 2,
  },
  {
    id: 12,
    name: "tp3+",
    fullform: "3_or_more_touchdows_passes",
    category: 2,
  },
  {
    id: 13,
    name: "tp4+",
    fullform: "4_or_more_touchdows_passes",
    category: 2,
  },
  {
    id: 14,
    name: "tp5+",
    fullform: "5_or_more_touchdows_passes",
    category: 2,
  },
  {
    id: 15,
    name: "ltp",
    fullform: "lowest_touchdown_passes",
    category: 2,
  },
  {
    id: 16,
    name: "htp",
    fullform: "highest_touchdown_passes",
    category: 2,
  },
  {
    id: 17,
    name: "gp",
    fullform: "games_played",
    category: 2,
  },

  //passing yards
  {
    id: 18,
    name: "TPY",
    fullform: "total passing yards",
    category: 3,
  },
  {
    id: 19,
    name: "APY",
    fullform: "average passing yards",
    category: 3,
  },
  {
    id: 20,
    name: "150+",
    fullform: "150 or more passing yards",
    category: 3,
  },
  {
    id: 21,
    name: "175+",
    fullform: "175 or more passing yards",
    category: 3,
  },
  {
    id: 22,
    name: "200+",
    fullform: "200 or more passing yards",
    category: 3,
  },
  {
    id: 23,
    name: "225+",
    fullform: "225 or more passing yards",
    category: 3,
  },
  {
    id: 24,
    name: "250+",
    fullform: "250 or more passing yards",
    category: 3,
  },
  {
    id: 25,
    name: "275+",
    fullform: "275 or more passing yards",
    category: 3,
  },
  {
    id: 26,
    name: "300+",
    fullform: "300 or more passing yards",
    category: 3,
  },
  {
    id: 27,
    name: "325+",
    fullform: "325 or more passing yards",
    category: 3,
  },
  {
    id: 28,
    name: "350+",
    fullform: "350 or more passing yards",
    category: 3,
  },
  {
    id: 29,
    name: "LPY",
    fullform: "lowest passing yards",
    category: 3,
  },
  {
    id: 30,
    name: "HPY",
    fullform: "highest passing yards",
    category: 3,
  },
  {
    id: 31,
    name: "GP",
    fullform: "games played",
    category: 3,
  },

  // rushing yards
  {
    id: 32,
    name: "TRY",
    fullform: "total rushing yards",
    category: 4,
  },
  {
    id: 33,
    name: "AVR",
    fullform: "average rushing yards",
    category: 4,
  },
  {
    id: 34,
    name: "30+",
    fullform: "30 or more rushing yards",
    category: 4,
  },
  {
    id: 35,
    name: "40+",
    fullform: "40 or more rushing yards",
    category: 4,
  },
  {
    id: 36,
    name: "50+",
    fullform: "50 or more rushing yards",
    category: 4,
  },
  {
    id: 37,
    name: "60+",
    fullform: "60 or more rushing yards",
    category: 4,
  },
  {
    id: 38,
    name: "70+",
    fullform: "70 or more rushing yards",
    category: 4,
  },
  {
    id: 39,
    name: "80+",
    fullform: "80 or more rushing yards",
    category: 4,
  },
  {
    id: 40,
    name: "90+",
    fullform: "90 or more rushing yards",
    category: 4,
  },
  {
    id: 41,
    name: "100+",
    fullform: "100 or more rushing yards",
    category: 4,
  },
  {
    id: 42,
    name: "110+",
    fullform: "110 or more rushing yards",
    category: 4,
  },
  {
    id: 43,
    name: "LRY",
    fullform: "lowest rushing yards",
    category: 4,
  },
  {
    id: 44,
    name: "HRY",
    fullform: "highest rushing yards",
    category: 4,
  },
  {
    id: 45,
    name: "GP",
    fullform: "games played",
    category: 4,
  },

  //receving yards
  {
    id: 46,
    name: "TR",
    fullform: "total receiving yards",
    category: 5,
  },
  {
    id: 47,
    name: "AVR",
    fullform: "average receiving yards",
    category: 5,
  },
  {
    id: 48,
    name: "15+",
    fullform: "15 or more receiving yards",
    category: 5,
  },
  {
    id: 49,
    name: "30+",
    fullform: "30 or more receiving yards",
    category: 5,
  },
  {
    id: 50,
    name: "45+",
    fullform: "45 or more receiving yards",
    category: 5,
  },
  {
    id: 51,
    name: "60+",
    fullform: "60 or more receiving yards",
    category: 5,
  },
  {
    id: 52,
    name: "75+",
    fullform: "75 or more receiving yards",
    category: 5,
  },
  {
    id: 53,
    name: "LRY",
    fullform: "lowest receiving yards",
    category: 5,
  },
  {
    id: 54,
    name: "HRY",
    fullform: "highest receiving yards",
    category: 5,
  },
  {
    id: 55,
    name: "GP",
    fullform: "games played",
    category: 5,
  },
];


export const extractKeyList = [

  //touchdown
  {
    name: "tt",
    key: "total",
    category:1
  },
  {
    name: "avt",
    key: "avg",
    category:1
  },
  {
    name: "t1+",
    key:"td_one",
    category:1
  },
  {
    name: "t2+",
    key: "td_two",
    category:1
  },
  {
    name: "t3+",
    key: "td_three",
    category:1
  },
  {
    name: "ht",
    key: "high",
    category:1
  },



  //touchdown passes
  {
    name: "ttp",
    key: "total",
    category:2
  },
  {
    name: "avp",
    key: "avg",
    category:2
  },
  {
    name: "tp1+",
    key: "tp_one",
    category:2
  },
  {
    name: "tp2+",
    key: "tp_two",
    category:2
  },
  {
    name: "tp3+",
    key: "tp_three",
    category:2
  },
  {
    name: "tp4+",
    key: "tp_three",
    category:2
  },
  {
    name: "tp5+",
    key: "tp_six",
    category:2
  },
  {
    name: "ltp",
    key: "low",
    category:2
  },
  {
    name: "htp",
    key: "high",
    category:2
  },



  //passing yards
  {
    name: "TPY",
    key: "total",
    category:3
  },
  {
    name: "APY",
    key: "avg",
    category:3
  },
  {
    name: "150+",
    key: "one_fifty",
    category:3
  },
  {
    name: "175+",
    key: "one_seventy_five",
    category:3
  },
  {
    name: "200+",
    key: "two_hundred",
    category:3
  },
  {
    name: "225+",
    key: "two_hundred_twenty_five",
    category:3
  },
  {
    name: "250+",
    key: "two_hundred_fifty",
    category:3
  },
  {
    name: "275+",
    key: "two_hundred_seventy_five",
    category:3
  },
  {
    name: "300+",
    key: "three_hundred",
    category:3
  },
  {
    name: "325+",
    key: "three_hundred_twenty_five",
    category:3
  },
  {
    name: "350+",
    key: "three_hundred_fifty",
    category:3
  },
  {
    name: "HPY",
    key: "high",
    category:3
  },
  {
    name: "LPY",
    key: "low",
    category:3
  },


  
  // rushing yards
  {
    name: "TRY",
    key: "total",
    category:4
  },
  {
    name: "AVR",
    key: "avg",
    category:4
  },
  {
    name: "30+",
    key: "thirty",
    category:4
  },
  {
    name: "40+",
    key: "thirty",
    category:4
  },
  {
    name: "50+",
    key: "fifty",
    category:4
  },
  {
    name: "60+",
    key: "sixty",
    category:4
  },
  {
    name: "70+",
    key: "seventy",
    category:4
  },
  {
    name: "80+",
    key: "eighty",
    category:4
  },
  {
    name: "90+",
    key: "ninety",
    category:4
  },
  {
    name: "100+",
    key: "hundred",
    category:4
  },
  {
    name: "110+",
    key: "one_hundred_ten",
    category:4
  },
  {
    name: "HRY",
    key: "high",
    category:4
  },
  {
    name: "LRY",
    key: "low",
    category:4
  },


  //receiving yards
  {
    name: "TR",
    key: "total",
    category:5
  },
  {
    name: "AVR",
    key: "avg",
    category:5
  },
  {
    name: "15+",
    key: "fiftheen",
    category:5
  },
  {
    name: "30+",
    key: "thirty",
    category:5
  },
  {
    name: "45+",
    key: "forty_five",
    category:5
  },
  {
    name: "60+",
    key: "sixty",
    category:5
  },
  {
    name: "75+",
    key: "seventy_five",
    category:5
  },
  {
    name: "HRY",
    key: "high",
    category:5
  },
  {
    name: "LRY",
    key: "low",
    category:5
  },
]


export const PLAYER_MATCH_LIST =[
  "player_for",
  "opponent",
  "date",
  "touchdowns",
  "rushing_yards",
  "receiving_yards",
  "passing_yards"
]

export const TEAM_TABS =[
  {id:1,"title":"touchdowns"},
  {id:2,"title":"touchdown passes"},
  {id:3,"title":"passing yards"},
  {id:4,"title":"rushing yards"},
  {id:5,"title":"receiving yards"},
  {id:6,"title":"margins"},
]

export function GetTeamImageByTeamId(teamid){
  return `https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/NFL_team_icons/${teamid}.png`
}