import React from "react";
import "../../utils/styles/privacypolicy/privacypolicy.scss";
import AppBar from "../AppBar/AppBar";
import { withRouter } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import Login from "./Login";
import { ADMIN_CREDENTIALS, decode_admin_credentials, validate_admin_credential } from "../Utils/Utils";
import Dashboard from "./Dashboard";


function AdminDashboard(props) {

  const [decoded_user, setuser] = useState("");
  const [loading, setloader] = useState(false);

  const [addpost, setaddpost] = useState(false);
  const [loggedin, setlogin] = useState(true);

  useEffect(() => {
    let user = new URL(window.location.href).searchParams.get("id");
    // var b = Buffer.from(email, "base64");

    setloader(true);
    let admin_loggedin = window.localStorage.getItem(ADMIN_CREDENTIALS);
    if (JSON.parse(admin_loggedin)) {

      let username = decode_admin_credentials(JSON.parse(admin_loggedin).username);
      let password = decode_admin_credentials(JSON.parse(admin_loggedin).password);

      setlogin(validate_admin_credential(username, password));
    } else setlogin(!loggedin);
    setloader(false);
  }, []);

  const uploadImage = () => null;

  const PostUI = (item) => {
    return (
      <div key={item.id} className="flex flex-1">
        <h3 className="w-64">{item.name}</h3>
        <img src={item.image} className="w-24 h-24 rounded-md" alt="post_img" />
        <h3 className="w-64">{item.content}</h3>
      </div>
    );
  };


  if (loading) 
  return <div style={{ height:'90vh' }} className="flex flex-1 justify-center items-center">
    <p>Checking admin credential..</p>
  </div>;

  else 

  return (
    <div>
      <AppBar his={props.history} />

      {!loggedin ? (
        <Login loggedin={() => setlogin(!loggedin)} />
      ) : (
        <>
          {addpost ? (
            <div className="absolute top-0 bottom-0 right-0 left-0 bg-transparent ">
              <div className="bg-black opacity-40 backdrop-blur-2"></div>
            </div>
          ) : (
            <Dashboard />
          )}
        </>
      )}
    </div>
  );
}
export default withRouter(AdminDashboard);
