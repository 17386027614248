

export const password_validation = (password) => {
  const patteren = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );

  if (password !== "" && password !== null && password !== undefined) {
    if (patteren.test(password)) return false;
    else return true;
  }
  return false;
};

export const email_validation = (email) => {
  const patteren = new RegExp(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  if (email !== "" && email !== null && email !== undefined) {
    if (patteren.test(email)) return false;
    else return true;
  }
  return false;
};

export const username_validation = (username) => {
  if (username !== "" && username !== null && username !== undefined) {
    return username.toString().length < 7;
  }
  return false;
};

export const validate_admin_credential = (admin_username, admin_password) => {

  return (
    admin_username === "Admin@multihub123" && admin_password === "Admin@123"
  );
};


export const encode_admin_credentials = (content) => {

  let date_string = new Date().getTime().toString();

  let encode = btoa(content
    // date_string.slice(0, 5) +
    // content.toString().slice(0, 5) +
    // date_string.slice(5, 10) +
    // content.toString().slice(5, content.length)
  ).toString();

  return encode;
};

export const decode_admin_credentials = (content) => {

  let date_string = new Date().getTime().toString();

  let buffer = Buffer.from(content, "base64");
  let decode = buffer.toString();

  return decode;
};

export function CenterContent(content) {
  return (
    <div
      style={{ height: "65vh" }}
      className="flex flex-1 justify-center items-center"
    >
      <p>{content}</p>
    </div>
  );
}

export function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return date.toLocaleDateString().toString().split('/').join('-') +" | "+ strTime;
}

export function SortListByName(list){
  return list.sort(function (a, b) {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
    }
    return 0;
})
}

const MonthShortForm = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export function FormatDate(strinDate){

  let splittedDate = strinDate.toString().split(".")

  let date = splittedDate[0]

  let month = splittedDate[1].includes('0') ? MonthShortForm[parseInt(splittedDate[1]) - 1 ] : MonthShortForm[parseInt(splittedDate[1]) - 1]

  return date+" "+month
}

export const ADMIN_CREDENTIALS = "ADMIN_CREDENTIALS";

export function ConvertIntoDateObject(date){
  if (date.length < 10) date = "0" + date;

  var year = parseInt(date.slice(6, 10));
  var month = parseInt(date.slice(3, 5)) - 1;
  var c_date = parseInt(date.slice(0, 2));

  return new Date(year, month, c_date, 0, 0, 0, 0);
}