import AppBar from "../AppBar/AppBar";
import React from "react";
import CopyRights from "../CopyRights";
import { SponserListCom } from "../SponerList/SponserList";
import { withRouter } from "react-router-dom";

function Bookiesoffers(props) {
  return (
    <div className="overflow-hidden ">
      <AppBar his={props.history} />

      <div className="w-full md:w-2/3 lg:w-1/2 xl:w-3/12 2xl:w-2/12 h-full p-2 lg:p-0 m-auto">
        <SponserListCom />
      </div>

      <div className="w-1/2 m-auto">
        <CopyRights />
      </div>
    </div>
  );
}

export default withRouter(Bookiesoffers);
