import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import "../../utils/styles/teamdetail/teamdetail.scss";
import AppBar from "../AppBar/AppBar";
import PageBar from "../AppBar/PageBar";
import { SponserListCom } from "../SponerList/SponserList";
import NBAPlayerTable from "./NBAPlayerTable";
import AppLoader from "../Loader/Loader";
import {
  GetNBATeamPlayersList,
  GetNBATeams,
  GetTeamDetails,
} from "../../Busniess_Logic/API/NBAApi";
import {
  NBAAssistsHeader,
  NBALengendList,
  NBAPointsHeader,
  NBAReboundsHeader,
  NBAThreePointsHeader,
} from "./NBA_Constant";
import CopyRights from "../CopyRights";
import { marginTBHeader } from "../AFL/AFL_Constants";
import AdSenseCom from "../AdSense/AdSense";

function NRLTeam(props) {
  const [cat, setCat] = useState(1);
  const [teamid, setTeamid] = useState(null);
  const [playerList, setPlayerlist] = useState(null);

  const [currentTeam, setcurrentTeam] = useState(null);
  const [teamList, setTeamlist] = useState([]);
  const [hthPlayerList, setHTHPlayerlist] = useState(null);
  const [hthcurrentTeam, setHthcurrentTeam] = useState(null);

  const [called, setCalled] = useState(false);
  const [loader, setLoader] = useState(false);
  const [teamloader, setTeamloader] = useState(false);

  const [teamMargin, setMargin] = useState([]);
  const [teamMarginTB, setTBMargin] = useState([]);
  const [teamForagainst, setForagainst] = useState([]);
  const [teamHistory, setTeamhis] = useState({});

  const [currentSeason, setSeason] = useState("");

  var url = new URL(window.location.href);
  var id = url.searchParams.get("id");
  var page = url.searchParams.get("p");
  var season = url.searchParams.get("s");
  var headtohead = url.searchParams.get("hth");

  useEffect(() => {
    // if (!called) {

    if (page) {
      if (page == "ps") setCat(1);
      else if (page == "rb") setCat(2);
      else if (page == "as") setCat(3);
      else if (page == "tp") setCat(4);
      else if (page == "m") setCat(5);
    }

    //head to head
    if (headtohead) GetTeamHeadToHeadUserData(headtohead, season);

    let current_season = season ? season : "2022";
    setSeason(current_season);
    setTeamid(id ? id : "64");
    GetTeamUserData(id ? id : "64", current_season);
    getTeamsData(id ? id : "64", headtohead, current_season);

    window.addEventListener("popstate", (event) => {
      var url = new URL(window.location.href);
      var id = url.searchParams.get("id");
      if (teamid && id)
        if (id !== teamid) {
          //console.log('page reloaded')
          window.location.reload();
        }
    });

    return () => {
      window.removeEventListener("popstate", () => {});
    };
  }, []);

  const GetTeamHeadToHeadUserData = async (id, season) => {
    try {
      const res = await GetNBATeamPlayersList(id, season);
      console.log(res.length)
      if (res) setHTHPlayerlist(res);
    } catch (error) {
      console.log(error);
    }
  };

  const getTeamsData = async (id, headtohead, season) => {
    setTeamloader(true);
    var list = [];
    const res = await GetNBATeams(season);
    if (res && res.response) {
      res.response.forEach((item) => {
        if (item.id === parseInt(id)) {
          setcurrentTeam(item);

          if (item.name === "Destroit Pistons")
            getTeamMarginData("Portland Trail Blazers", season);
          else if (item.name === "Portland Trial Blazers")
            getTeamMarginData("Detroit Pistons", season);
          else getTeamMarginData(item.name, season);
        } else if (headtohead) {
          if (item.id == parseInt(headtohead)) setHthcurrentTeam(item);
        }
        list.push(item);
      });

      list.sort(function (a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      list = list.filter((a) => a.id != parseInt(id));
      if (headtohead) list = list.filter((a) => a.id != parseInt(headtohead));

      setTeamlist(list);
      setTeamloader(false);
    }
    setTeamloader(false);
  };

  const GetTeamUserData = async (id, season) => {
    setLoader(true);
    const res = await GetNBATeamPlayersList(id, season);
    console.log('Response',[res])
    if (res) {
      setLoader(false);
      setPlayerlist(res);
    }
    setLoader(false);
  };

  const redirectToTeam = (id) => {
    // props.history.push('nbateam?id=' + id + '&p=ps')
    if (headtohead)
      props.history.push(`nbateam?id=` + id + `&p=${page}&hth=${headtohead}`);
    else
      props.history.push(
        `nbateam?id=` + id + `&p=${page}&s=${season.slice(0, 4)}`
      );
    window.location.reload();
  };

  const Fullform = (item) => {
    return (
      <div className="flex flex-1 my-1 w-screen lg:w-full md:w-full xl:w-full 2xl:w-full">
        <h1 className="uppercase mx-4 font-bold w-10 xl:w-8 2xl:w-14 text-left text-sm 2xl:text-lg">
          {item.name}
        </h1>
        -
        <p className="capitalize mx-4 text-sm 2xl:text-lg">
          {item.fullform.replaceAll("_", " ")}
        </p>
      </div>
    );
  };

  const onchangecat = (catid) => {
    setCat(catid);
    var href = new URL(window.location.href);

    if (catid === 1) href.searchParams.set("p", "ps");
    else if (catid === 2) href.searchParams.set("p", "rb");
    else if (catid === 3) href.searchParams.set("p", "as");
    else if (catid === 4) href.searchParams.set("p", "tp");
    else if (catid === 5) href.searchParams.set("p", "m");

    //console.log(href.toString());
    window.history.replaceState("", "", href.toString());
  };

  const getTeamMarginData = async (team_name, season) => {
    setTeamloader(true);

    //console.log('find team name => ',team_name)
    try {
      const res = await GetTeamDetails(team_name, season);
      //console.log('margin details', res)
      if (res) {
        setTeamhis(res);
        generateMarginData(res.games_played, res.margin_data);
        generateMarginTBData(res.games_played, res.margin_data);
        // generateForAgainstData(res.games_played, res.score_for, res.score_againsts)
      }
      setTeamloader(false);
    } catch (error) {
      setTeamloader(false);
      setTeamhis(null);
      console.log(error);
    }

    //console.log('team history', res.data)
  };

  const generateMarginData = (gp, list) => {
    //console.log(list)
    var tempList = [];

    tempList.push({
      name: " Win 1-10 ",
      content: ((list[0].content / gp) * 100).toFixed(2),
    });
    tempList.push({
      name: " Win 11+ ",
      content: ((list[1].content / gp) * 100).toFixed(2),
    });

    tempList.push({
      name: " Lose 1-10 ",
      content: ((list[2].content / gp) * 100).toFixed(2),
    });
    tempList.push({
      name: " Lose 11+ ",
      content: ((list[3].content / gp) * 100).toFixed(2),
    });

    //console.log(tempList)
    setMargin(tempList);
  };

  const generateMarginTBData = (gp, list) => {
    //console.log(list)
    var tempList = [];

    tempList.push({ title: " 1-10 Win", content: list[0].content + "/" + gp });
    tempList.push({ title: " 11+ Win", content: list[1].content + "/" + gp });

    tempList.push({ title: " 1-10 Loss", content: list[2].content + "/" + gp });
    tempList.push({ title: " 11+ Loss", content: list[3].content + "/" + gp });

    //console.log(tempList)
    setTBMargin(tempList);
  };

  const generateForAgainstData = (gp, forlist, againstlist) => {
    var tempList = [];

    tempList.push({
      name: " Average Score For",
      content: ((forlist[0].content / gp) * 100).toFixed(2),
    });
    tempList.push({
      name: " Highest Score For ",
      content: ((forlist[1].content / gp) * 100).toFixed(2),
    });
    tempList.push({
      name: " Lowest Score For ",
      content: ((forlist[2].content / gp) * 100).toFixed(2),
    });
    tempList.push({
      name: " Average Total Game Score ",
      content: ((forlist[3].content / gp) * 100).toFixed(2),
    });
    tempList.push({
      name: " Average Winning Margin  ",
      content: ((forlist[4].content / gp) * 100).toFixed(2),
    });

    tempList.push({
      name: " Average Score Against ",
      content: ((againstlist[0].content / gp) * 100).toFixed(2),
    });
    tempList.push({
      name: " Highest Score Against ",
      content: ((againstlist[1].content / gp) * 100).toFixed(2),
    });
    tempList.push({
      name: " Lowest Score Against ",
      content: ((againstlist[2].content / gp) * 100).toFixed(2),
    });
    tempList.push({
      name: " Average Losing Margin ",
      content: ((againstlist[3].content / gp) * 100).toFixed(2),
    });

    //console.log(tempList)
    setForagainst(tempList);
  };

  const teamHistoryData = (tableData, name) => {
    //console.log(tableData)
    var thlist = tableData;
    return (
      <table className="rounded-lg tb-shadow h-auto w-full bg-white p-2">
        <tr style={{ backgroundColor: currentTeam && currentTeam.bgcolor }}>
          <th
            className="rounded-tl-md uppercase w-full text-white text-left p-3"
            style={{ backgroundColor: currentTeam && currentTeam.bgcolor }}
          >
            {name}
          </th>
          <th className="rounded-tr-md w-1/2">{""}</th>
        </tr>

        {thlist.map((item) => {
          return (
            <tr className="">
              <td className="capitalize text-left text-black pl-5 w-1/2 py-3">
                {item.title.replaceAll("_", " ")}
              </td>
              {name === "score against" ? (
                <td className="w-1/2 text-black pr-5">
                  {Math.abs(parseFloat(item.content).toFixed(0).toString())}
                </td>
              ) : (
                <td className="w-1/2 text-black pr-5">{item.content}</td>
              )}
            </tr>
          );
        })}
      </table>
    );
  };

  const EachStatus = (index, item, divide_index) => {
    //console.log('EachStatus', item)
    return (
      <div className="my-0 lg:my-2 xl:my-2 2xl:my-2 mx-2 py-2">
        <div className="flex flex-1 justify-between px-2  my-1 m-auto w-full">
          <h1 className="text-md m-0 p-0 text-black">{item.name}</h1>
          <h1
            className={
              index <= divide_index
                ? "text-md m-0 p-0 text-green-600 font-bold"
                : "text-md m-0 p-0 text-red-600 font-bold"
            }
          >
            {parseFloat(item.content).toFixed(0) + "%"}
          </h1>
        </div>
        <div className="flex flex-1 justify-center  my-2">
          {/* <progress id="green" value={item.content} max="100" className='w-1/2 mx-1 rtl' /> */}
          <progress
            id={index > divide_index ? "red" : "green"}
            value={parseFloat(item.content).toFixed(0)}
            max="100"
            className="w-full mx-1 rounded-xl"
          />
        </div>
      </div>
    );
  };

  const PlayedMatchesGraph = (
    list,
    divide_index,
    title,
    left_title,
    right_title
  ) => {
    return (
      <div className="my-5 rounded-lg">
        <div className="bg-white p-5 rounded-lg">
          <div>
            <h1
              className="text-lg text-bold text-black 
                        text-left py-0 my-2 lg:py-5 xl:py-0 2xl:py-0 text-2xl capitalize"
            >
              {title}
            </h1>
          </div>

          <div className="py-2">
            <div className="flex-none flex-0 xl:flex xl:flex-1 xl:flex-row 2xl:flex-row 2xl:flex 2xl:flex-1 justify-between ">
              <div className="w-full">
                <h1 className="text-black font-bold text-left text-xl py-2 m-0 px-4 capitalize">
                  {left_title}
                </h1>
                {teamHistory &&
                  list.map((item, index) => {
                    if (index <= divide_index)
                      return EachStatus(index, item, divide_index);
                  })}
              </div>

              <div className="w-full">
                <h1 className="text-black font-bold text-left text-xl py-2 px-4 capitalize">
                  {right_title}
                </h1>
                {teamHistory &&
                  list.map((item, index) => {
                    if (index > divide_index)
                      return EachStatus(index, item, divide_index);
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="overflow-hidden">
      <AppBar his={props.history} />

      <div className="team-detail-com w-full sm:w-full md:w-full h-full lg:w-4/5 m-auto xl:w-3/4 2xl:w-3/4 p-3 xl:p-0 2xl:p-0 2xl:my-5 ">
        <div className="xl:flex 2x:flex xl:flex-1 2xl:flex-1">
          <div className="mx-1 w-full">
            <AdSenseCom />

            <PageBar his={props.history} />

            {currentTeam && (
              <div
                style={{ backgroundColor: currentTeam.bgcolor }}
                className="p-3 border border-gray-700 rounded-lg shadow-xl flex flex-1 flex-row items-center justify-start"
              >
                <div className="team-name w-full">
                  <h1 className="text-2xl text-left mx-4 font-bold uppercase text-white">
                    {hthcurrentTeam
                      ? currentTeam.name + " vs\t" + hthcurrentTeam.name
                      : currentTeam.name}
                  </h1>
                </div>
              </div>
            )}

            <div
              className="team-container rounded-lg mt-4 mb-0 border border-gray-700 flex flex-1 flex-row items-center 
                            w-full justify-between lg:justify-start xl:justify-start 2xl:justify-start "
            >
              <a
                className={
                  cat === 1
                    ? "text-md xl:text-lg 2xl:text-lg mx-0 my-0 px-1 text-white float-left text-center xl:px-6 2xl:px-6 py-3 no-underline text-black cursor-pointer capitalize font-bold rounded-l-md"
                    : "text-md xl:text-lg 2xl:text-lg float-left text-center text-white px-1 lg:px-6 xl:px-6 2xl:px-6 py-3 no-underline text-black cursor-pointer capitalize font-bold"
                }
                onClick={() => onchangecat(1)}
                style={{
                  borderBottom: cat === 1 ? "4px solid #ff0058" : "transparent",
                }}
              >
                points
              </a>

              <a
                className={
                  cat === 2
                    ? "text-md xl:text-lg 2xl:text-lg mx-0 lg:mx-2 xl:mx-2 2xl:mx-2 text-white float-left text-center xl:px-6 2xl:px-6 px-2 py-3 no-underline text-black cursor-pointer capitalize font-bold"
                    : "text-md xl:text-lg 2xl:text-lg float-left text-center text-white lg:px-6 xl:px-6 2xl:px-6 mx-2 py-3 no-underline text-black cursor-pointer capitalize font-bold"
                }
                onClick={() => onchangecat(2)}
                style={{
                  borderBottom: cat === 2 ? "4px solid #ff0058" : "transparent",
                }}
              >
                rebounds
              </a>

              <a
                className={
                  cat === 3
                    ? "text-md w-max xl:text-lg 2xl:text-lg mx-0 lg:mx-2 xl:mx-2 2xl:mx-2 px-1 text-white xl:px-6 2xl:px-6 float-left text-center px-0 py-3  no-underline text-black cursor-pointer capitalize font-bold"
                    : "text-md w-max xl:text-lg 2xl:text-lg float-left text-center text-white px-0 lg:px-6 xl:px-6 2xl:px-6 mx-2 py-3 no-underline text-black cursor-pointer capitalize font-bold"
                }
                onClick={() => onchangecat(3)}
                style={{
                  borderBottom: cat === 3 ? "4px solid #ff0058" : "transparent",
                }}
              >
                assists
              </a>
              <a
                className={
                  cat === 4
                    ? "text-md w-max xl:text-lg 2xl:text-lg mx-0 lg:mx-2 xl:mx-2 2xl:mx-2 px-1 text-white xl:px-6 2xl:px-6 float-left text-center px-0 py-3  no-underline text-black cursor-pointer capitalize font-bold"
                    : "text-md w-max xl:text-lg 2xl:text-lg float-left text-center text-white px-0 lg:px-6 xl:px-6 2xl:px-6 mx-2 py-3 no-underline text-black cursor-pointer capitalize font-bold"
                }
                onClick={() => onchangecat(4)}
                style={{
                  borderBottom: cat === 4 ? "4px solid #ff0058" : "transparent",
                }}
              >
                three points
              </a>

              {hthcurrentTeam ? (
                <></>
              ) : (
                <a
                  className={
                    cat === 5
                      ? "text-md w-max xl:text-lg 2xl:text-lg mx-0 lg:mx-2 xl:mx-2 2xl:mx-2 px-1  text-white xl:px-6 2xl:px-6 float-left text-center px-0 py-3  no-underline text-black cursor-pointer capitalize font-bold"
                      : "text-md w-max xl:text-lg 2xl:text-lg float-left text-center text-white px-0 lg:px-6 xl:px-6 2xl:px-6 mx-2 py-3 no-underline text-black cursor-pointer capitalize font-bold"
                  }
                  onClick={() => onchangecat(5)}
                  style={{
                    borderBottom:
                      cat === 5 ? "4px solid #ff0058" : "transparent",
                  }}
                >
                  margin
                </a>
              )}
            </div>

            <div
              className="w-full flex flex-1 flex-row"
              style={{ position: "relative" }}
            >
              <div className="w-full lg:w-4/5 xl:w-4/5 2xl:w-4/5 rounded-xl ">
                {cat === 5 && teamHistory && (
                  <div className="rounded-lg overflow-hidden pt-3">
                    {PlayedMatchesGraph(
                      teamMargin,
                      1,
                      "big win little win",
                      "win",
                      "loss"
                    )}
                  </div>
                )}

                {cat === 5 && teamHistory && (
                  <div className="grid grid-cols-1 gap-4 xl:grid-cols-2 2xl:grid-cols-2 w-full xl:mt-4 2xl:mt-4 overflow-hidden">
                    <div>
                      {teamMarginTB && teamHistoryData(teamMarginTB, "Margin")}
                    </div>
                    <div>
                      {teamHistory &&
                        teamHistory.score_for &&
                        teamHistoryData(teamHistory.score_for, " Score For ")}
                    </div>
                    <div>
                      {teamHistory &&
                        teamHistory.score_against &&
                        teamHistoryData(
                          teamHistory.score_against,
                          " score against "
                        )}
                    </div>
                  </div>
                )}

                {playerList && currentTeam && (
                  <div className="overflow-x-auto">
                    <NBAPlayerTable
                      bgcolor={currentTeam.bgcolor}
                      hthteamid={hthcurrentTeam && hthcurrentTeam.id}
                      curretteamid={currentTeam && currentTeam.id}
                      headerData={
                        cat == 1
                          ? headtohead
                            ? ["team"].concat(NBAPointsHeader)
                            : NBAPointsHeader
                          : cat == 2
                          ? headtohead
                            ? ["team"].concat(NBAReboundsHeader)
                            : NBAReboundsHeader
                          : cat == 3
                          ? headtohead
                            ? ["team"].concat(NBAAssistsHeader)
                            : NBAAssistsHeader
                          : cat == 4
                          ? headtohead
                            ? ["team"].concat(NBAThreePointsHeader)
                            : NBAThreePointsHeader
                          : marginTBHeader
                      }
                      cat={cat}
                      player_list={
                        cat === 5 && teamHistory
                          ? teamHistory.data
                          : hthcurrentTeam && hthPlayerList
                          ? playerList.concat(hthPlayerList)
                          : playerList
                      }
                      season={currentSeason.slice(0, 4)}
                      hthPlayerList={hthPlayerList}
                      his={props.history}
                    />
                  </div>
                )}

                {loader && <AppLoader content="Getting team players" active />}

                {cat <= 4 && playerList && (
                  <div className="w-4/5 rounded-lg py-4">
                    <h1 className="text-left px-4 py-4 text-gray-600">
                      Legend
                    </h1>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-2">
                      {NBALengendList.map((item, index) => {
                        if (cat === 1) {
                          if (index >= 0 && index <= 13) return Fullform(item);
                        }
                        if (cat === 2) {
                          if (index >= 14 && index <= 27) return Fullform(item);
                        }
                        if (cat === 3) {
                          if (index >= 28 && index <= 40) return Fullform(item);
                        }
                        if (cat === 4) {
                          if (index >= 41) return Fullform(item);
                        }
                      })}
                    </div>
                  </div>
                )}
              </div>

              {currentTeam && (
                <div className="w-1/5 ml-1 hidden lg:block xl:block 2xl:block rounded-lg">
                  <table className="w-full overflow-hidden mt-8 rounded-md shadow-2xl text-base xl:text-sm 2xl:text-lg">
                    <tr>
                      <th
                        className="text-white p-2 text-left px-3"
                        style={{ backgroundColor: currentTeam.bgcolor }}
                      >
                        NRL Teams
                      </th>
                    </tr>
                    {!teamloader &&
                      teamList &&
                      teamList.map((item) => {
                        return (
                          <tr
                            onClick={() => redirectToTeam(item.id)}
                            className="cursor-pointer"
                          >
                            <td>
                              <div className="flex flex-1 justify-between px-2 py-3 xl:px-2 2xl:px-3 items-center">
                                <p className="uppercase font-bold text-left text-xs xl:text-xs 2xl:text-base">
                                  {/* {item.name.length > 15 ? item.name.substr(0, 15) + '..' : item.name} */}
                                  {item.short_form}
                                </p>
                                <img
                                  src={item.image}
                                  className="w-6 h-6 object-cover"
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </table>
                </div>
              )}
            </div>
          </div>

          <div className="xl:w-1/3 2xl:w-1/3">
            {!loader && <SponserListCom />}
          </div>
        </div>
      </div>

      {!loader && <CopyRights his={props.history} />}
    </div>
  );
}
export default withRouter(NRLTeam);
