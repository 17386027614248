import React,{ useState } from "react";
import AppBar from "../AppBar/AppBar";

export const TipsScreen = (props) => {
  const [show_signup, setsignup] = useState(false);
  return (
    <div>
      <AppBar
        his={props.history}
        show_signup={show_signup}
        closeSignupModal={() => setsignup(!show_signup)}
      />
    </div>
  );
};
