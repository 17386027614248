import React, { useEffect, useState } from "react";
import AppBar from "./AppBar/AppBar";
import "../utils/styles/home/home.scss";
import { withRouter } from "react-router-dom";
import { GetAllTeams } from "../Busniess_Logic/API/AFLApi";
import AppLoader from "./Loader/Loader";
import { GetNRLTeams } from "../Busniess_Logic/API/NRLApi";
import { SponserListCom } from "./SponerList/SponserList";
import { GetNBATeams } from "../Busniess_Logic/API/NBAApi";
import { GetEPLTeams } from "../Busniess_Logic/API/EPLApi";
import PageBar from "./AppBar/PageBar";
import { MenuList } from "../utils/Appconstant";
import CopyRights from "./CopyRights";
import { reportLog } from "../utils/amplitude";
import { getUser } from "../utils/userinfo";
import { getSeasonList } from "./Utils/SeasonList";
import { SortListByName } from "./Utils/Utils";
import { GetNFLTeams } from "../Busniess_Logic/API/NFLApi";
import { GetBBLTeams } from "../Busniess_Logic/API/BBLApi";

function Home(props) {
  const isLogging = true;
  const [teamlist, setTeamlist] = useState([]);
  const [nrlList, setNrllist] = useState([]);
  const [nbaList, setNbalist] = useState([]);
  const [eplList, setEpllist] = useState([]);
  const [nflList, setNfllist] = useState([]);
  const [bblList, setBbllist] = useState([]);

  //
  const [teamList, setTeamList] = useState([]);
  const [headOne, setHeadone] = useState(0);
  const [headTwo, setHeadtwo] = useState(0);

  const [selected, setSelection] = useState(0);
  const [loader, setLoader] = useState(false);
  const [hovereditem, setHoveritem] = useState(null);

  const [selectedSeason, setSeason] = useState(1);
  const [show_signup, setsignup] = useState(false);

  const seasonList = getSeasonList();

  useEffect(() => {
    let ct = new URL(window.location.href).searchParams.get("ct");
    let cs = window.localStorage.getItem("season");

    let team_index = MenuList.indexOf(MenuList.find((x) => x.url === ct));
    setSelection(team_index ? team_index : 0);

    if (
      seasonList &&
      seasonList[team_index] !== undefined &&
      seasonList[team_index] !== null
    ) {
      let current_season = seasonList[team_index].season.find(
        (x) => x.year === cs
      );
      let first_season = seasonList[team_index].season[0].year;

      setSeason(current_season ? current_season.year : first_season);
      setSelection(team_index ? team_index : 0);

      if (ct) {
        if (ct === "afl") {
          if (isLogging) reportLog("AFL");
          getTeams(current_season ? current_season.year : first_season);
        } else if (ct === "nrl") {
          if (isLogging) reportLog("NRL");
          getNRLTeams(current_season ? current_season.year : first_season);
        } else if (ct === "nba") {
          if (isLogging) reportLog("NBA");
          getNBATeams(current_season ? current_season.year : first_season);
        } else if (ct === "epl") {
          if (isLogging) reportLog("EPL");
          getEPLTeams(current_season ? current_season.year : first_season);
        } else if (ct === "nfl") {
          if (isLogging) reportLog("NFL");
          getNFLTeams(current_season ? current_season.year : first_season);
        } else if (ct === "bbl") {
          if (isLogging) reportLog("BBL");
          getBBLTeams(current_season ? current_season.year : first_season);
        }
      }
    }
  }, [new URL(window.location.href).searchParams.get("ct")]);

  const getTeams = async (season) => {
    setLoader(true);
    const res = await GetAllTeams(season);

    if (res) {
      if (res.response && res.response.length != 0) {
        let sorted_list = SortListByName(res.response);
        setTeamlist(sorted_list);
        setTeamList(sorted_list);
      }
    }

    setLoader(false);
  };

  const getNRLTeams = async (season) => {
    setLoader(true);
    const res = await GetNRLTeams(season);

    if (res.response) {
      let sorted_list = SortListByName(res.response);
      setNrllist(sorted_list);
      setTeamList(sorted_list);
      setLoader(false);
    } else {
      setNrllist([]);
      setTeamList([]);
      setLoader(false);
    }
  };

  const getNBATeams = async (season) => {
    //console.log('getNBATeams')
    try {
      setLoader(true);
      const res = await GetNBATeams(season);

      //console.log(res)
      if (res.response) {
        let sorted_list = SortListByName(res.response);
        setNbalist(sorted_list);
        setTeamList(sorted_list);
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
      return [];
    }
  };

  const getEPLTeams = async (season) => {
    setLoader(true);
    const res = await GetEPLTeams(season.toString().slice(0, 4));

    //console.log(res)
    if (res) {
      if (res.success) {
        let sorted_list = SortListByName(res.response);
        setEpllist(sorted_list);
        setTeamList(sorted_list);
      }
    }
    setLoader(false);
  };

  const getNFLTeams = async (season) => {
    setLoader(true);
    const res = await GetNFLTeams(season.toString().slice(0, 4));

    if (res) {
      let sorted_list = SortListByName(res);
      setNfllist(sorted_list);
      setTeamList(sorted_list);
    }
    setLoader(false);
  };

  const getBBLTeams = async (season) => {
    setLoader(true);
    const res = await GetBBLTeams(season.toString().slice(0, 4));

    if (res) {
      let sorted_list = SortListByName(res);
      setBbllist(sorted_list);
      setTeamList(sorted_list);
    }
    setLoader(false);
  };

  //   const addnewTeam = async () => {
  //     teamNameList.forEach(async (item) => {
  //       const res = await AddTeam(item);
  //     });
  //   };

  //   const addNBATeam = async () => {
  //     NBA_Team_List.forEach(async (item) => {
  //       const res = await AddNBATeam(item);
  //       //console.log(res)
  //     });
  //   };

  //   const addEPLTeam = async () => {
  //     EPL_Team_List.forEach(async (item) => {
  //       const res = await AddEPLTeam(item);
  //       //console.log(res)
  //     });
  //   };

  //   const addMLBTeam = async () => {
  //     MLB_Team_List.forEach(async (item) => {
  //       const res = await AddMLBTeam(item);
  //       //console.log(res)
  //     });
  //   };

  const setTeamId = (id) => {
    if (seasonList) {
      let first_season = seasonList[id].season[0].year;

      if (id === 0) {
        setSelection(id);
        window.localStorage.setItem("season", first_season);
        setSeason(first_season);
        // setSeason("2022")
        props.history.push("?ct=afl");
        getTeams(first_season);
      } else if (getUser()) {
        window.localStorage.setItem("season", first_season);
        setSelection(id);
        setSeason(first_season);

        if (id === 1) {
          props.history.push("?ct=nrl");
          getNRLTeams(first_season);
        } else if (id === 2) {
          getNBATeams(first_season);
          props.history.push("?ct=nba");
        } else if (id === 3) {
          getEPLTeams(first_season);
          props.history.push("?ct=epl");
        } else if (id === 4) {
          getNFLTeams(first_season);
          props.history.push("?ct=nfl");
        } else if (id === 5) {
          getBBLTeams(first_season);
          props.history.push("?ct=bbl");
        }
      } else setsignup(!show_signup);
    }
  };

  const GotoTeam = (item, teamname) => {
    if (teamname === "afl")
      props.history.push(
        "/team?id=" + item.id + "&p=d" + "&s=" + selectedSeason
      );
    else if (getUser()) {
      if (teamname === "nrl")
        props.history.push(
          "/nrlteam?id=" + item.id + "&p=ts" + "&s=" + selectedSeason
        );
      else if (teamname === "nba")
        props.history.push(
          "/nbateam?id=" +
            item.id +
            "&p=ps" +
            "&s=" +
            selectedSeason.toString().slice(0, 4)
        );
      else if (teamname === "epl")
        props.history.push(
          "/eplteam?id=" +
            item.id +
            "&p=ts" +
            "&s=" +
            selectedSeason.toString().slice(0, 4)
        );
      else if (teamname === "nfl")
        props.history.push(
          "/nflteam?id=" +
            item.id +
            "&p=td" +
            "&s=" +
            selectedSeason.toString().slice(0, 4)
        );
      else if (teamname === "bbl")
        props.history.push(
          "/bblteam?id=" +
            item.id +
            "&p=r" +
            "&s=" +
            selectedSeason.toString().slice(0, 4)
        );
    } else setsignup(!show_signup);
  };

  const NameConverter = (teamname) => {
    var modified_team_name = "";
    let tn = teamname.split(" ");

    if (tn.length == 0) modified_team_name = teamname + "\n";
    else if (tn.length == 1) modified_team_name = teamname;
    else if (tn.length == 2) modified_team_name = tn[0] + "\n" + tn[1];
    else if (tn.length == 3)
      modified_team_name = tn[0] + " " + tn[1] + "\n" + tn[2];
    else if (tn.length > 3)
      modified_team_name = tn[0] + " " + tn[1] + "\n" + tn[2] + " " + tn[3];

    return modified_team_name;
  };

  const AFLTeam = (item, teamname) => {
    let modified_team_name = NameConverter(item.name);

    return (
      <div
        className="w-full p-4 cursor-pointer rounded-md flex-col
        flex flex-1 content-center items-center justify-between shadow-2xl bg-white 
        transition delay-0 ease-in-out hover:duration-100
        transform scale-100 hover:scale-105"
        onClick={() => GotoTeam(item, teamname)}
        onMouseEnter={() => setHoveritem(item.name)}
        onMouseLeave={() => setHoveritem(null)}
        style={{
          backgroundColor:
            hovereditem && hovereditem === item.name ? item.bgcolor : "white",
        }}
      >
        <div className="w-14 h-14">
          <img src={item.image} className="w-full" />
        </div>
        <div className="pt-6">
          <h1
            style={{
              borderBottom: `2px solid ${item.bgcolor}`,
              color:
                hovereditem && hovereditem === item.name ? "white" : "black",
            }}
            className="pb-1 w-max afl-team-name text-base lg:text-lg text-left font-bold capitalize"
          >
            {modified_team_name}
          </h1>
        </div>
      </div>
    );
  };

  const conditionForSharkAndTigers = (item) => {
    if (
      item.short_form === "Sharks" ||
      item.short_form === "Tigers" ||
      item.short_form === "Bulldogs"
    )
      return "#000000";
    else return item.bgcolor;
  };

  const NRLTeam = (item, teamname) => {
    var modified_team_name = "";
    let tn = item.name.split(" ");

    if (tn.length == 0) modified_team_name = item.name;
    else if (tn.length == 1) modified_team_name = item.name;
    else if (tn.length == 2) modified_team_name = tn[0] + "\n" + tn[1];
    else if (tn.length == 3) {
      if (item.team_id == "1-22")
        modified_team_name = tn[0] + "\n" + tn[1] + " " + tn[2];
      else modified_team_name = tn[0] + " " + tn[1] + "\n" + tn[2];
    } else if (tn.length > 3)
      modified_team_name = tn[0] + " " + tn[1] + "\n" + tn[2] + " " + tn[3];

    return (
      <div
        className="w-full p-4 cursor-pointer rounded-md flex-col
        flex flex-1 content-center items-center justify-between shadow-2xl bg-white 
        transition delay-0 ease-in-out hover:duration-100
        transform scale-100 hover:scale-105"
        onClick={() => GotoTeam(item, teamname)}
        onMouseEnter={() => setHoveritem(item.name)}
        onMouseLeave={() => setHoveritem(null)}
        style={{
          backgroundColor:
            hovereditem && hovereditem === item.name
              ? conditionForSharkAndTigers(item)
              : "whitesmoke",
        }}
        key={item.id}
      >
        <div className="">
          <img src={item.image} className="w-16 h-16 object-cover" />
        </div>
        <div className="pt-6">
          <h1
            style={{
              borderBottom: `2px solid ${item.bgcolor}`,
              color:
                hovereditem && hovereditem === item.name ? "white" : "black",
              overflowWrap: "break-word",
              wordWrap: "break-word",
              wordBreak: "break-word",
            }}
            className="pb-1 leading-6 lg:text-md text-center font-bold capitalize whitespace-pre"
            // className={item.team_id =="1-32" || item.team_id =="1-28" ?
            // 'pb-1 leading-6 sm:text-md xl:text-sm text-center font-bold capitalize':
            // 'pb-1 leading-6 lg:text-md text-center font-bold capitalize' }
          >
            {modified_team_name}
          </h1>
          {/* <div style={{borderBottom: `2px solid ${item.bgcolor}`}}></div> */}
        </div>
      </div>
    );
  };

  const NBATeam = (item, teamname) => {
    var modified_team_name = "";
    let tn = item.name.split(" ");

    if (tn.length == 1) modified_team_name = item.name;
    else if (tn.length == 2) modified_team_name = tn[0] + "\n" + tn[1];
    else if (tn.length == 3)
      modified_team_name = tn[0] + " " + tn[1] + "\n" + tn[2];
    else if (tn.length > 3)
      modified_team_name = tn[0] + " " + tn[1] + "\n" + tn[2] + " " + tn[3];

    return (
      <div
        className="w-full p-4 cursor-pointer rounded-md flex-col
        flex flex-1 content-center items-center justify-between shadow-2xl bg-white 
        transition delay-0 ease-in-out hover:duration-100
        transform scale-100 hover:scale-105"
        onClick={() => GotoTeam(item, teamname)}
        onMouseEnter={() => setHoveritem(item.name)}
        onMouseLeave={() => setHoveritem(null)}
        style={{
          backgroundColor:
            hovereditem && hovereditem === item.name
              ? conditionForSharkAndTigers(item)
              : "whitesmoke",
        }}
      >
        <div className="">
          <img src={item.image} className="w-16 h-16 object-cover rounded-xl" />
        </div>
        <div className="pt-6">
          <h1
            style={{
              borderBottom: `2px solid ${item.bgcolor}`,
              color:
                hovereditem && hovereditem === item.name ? "white" : "black",
            }}
            className="pb-1 text-md text-center font-bold capitalize whitespace-pre"
          >
            {modified_team_name}
          </h1>
        </div>
      </div>
    );
  };

  const EPLTeam = (item, teamname) => {
    return (
      <div
        className="w-full p-4 cursor-pointer rounded-md flex-col
        flex flex-1 content-center items-center justify-between shadow-2xl bg-white 
        transition delay-0 ease-in-out hover:duration-100
        transform scale-100 hover:scale-105 "
        onClick={() => GotoTeam(item, teamname)}
        onMouseEnter={() => setHoveritem(item.name)}
        onMouseLeave={() => setHoveritem(null)}
        style={{
          backgroundColor:
            hovereditem && hovereditem === item.name
              ? conditionForSharkAndTigers(item)
              : "whitesmoke",
        }}
      >
        <div className="">
          <img src={item.image} className="w-16 h-16 object-cover rounded-xl" />
        </div>
        <div className="pt-6">
          <h1
            style={{
              borderBottom: `2px solid ${item.bgcolor}`,
              color:
                hovereditem && hovereditem === item.name ? "white" : "black",
            }}
            className={
              item.team_id == "9-12" ||
              item.team_id == "9-6" ||
              item.team_id == "9-11"
                ? "pb-1 text-sm text-center font-bold capitalize"
                : "pb-1 text-md text-center font-bold capitalize"
            }
          >
            {item.short_form}
          </h1>
          {/* } */}
        </div>
      </div>
    );
  };

  const NFLTeam = (item, teamname) => {
    return (
      <div
        className="w-full p-4 cursor-pointer rounded-md flex-col
        flex flex-1 content-center items-center justify-between shadow-2xl bg-white 
        transition delay-0 ease-in-out hover:duration-100
        transform scale-100 hover:scale-105 "
        onClick={() => GotoTeam(item, teamname)}
        onMouseEnter={() => setHoveritem(item.name)}
        onMouseLeave={() => setHoveritem(null)}
        style={{
          backgroundColor:
            hovereditem && hovereditem === item.name ? item.bgcolor : "#e8e8e8",
        }}
        key={item.id}
      >
        <div className="">
          <img src={item.image} className="w-16 h-16 object-cover rounded-xl" />
        </div>
        <div className="pt-6">
          <h1
            style={{
              borderBottom: `2px solid red`,
              color:
                hovereditem && hovereditem === item.name ? "white" : "black",
            }}
            className={"pb-1 text-sm text-center font-bold capitalize"}
          >
            {item.name}
          </h1>
        </div>
      </div>
    );
  };

  const BBLTeam = (item, teamname) => {
    return (
      <div
        className="w-full p-4 cursor-pointer rounded-md flex-col
        flex flex-1 content-center items-center justify-between shadow-2xl bg-white 
        transition delay-0 ease-in-out hover:duration-100
        transform scale-100 hover:scale-105 "
        onClick={() => GotoTeam(item, teamname)}
        onMouseEnter={() => setHoveritem(item.name)}
        onMouseLeave={() => setHoveritem(null)}
        style={{
          backgroundColor:
            hovereditem && hovereditem === item.name ? item.bgcolor : "#e8e8e8",
        }}
        key={item.id}
      >
        <div className="">
          <img src={item.image} className="w-16 h-16 object-cover rounded-xl" />
        </div>
        <div className="pt-6">
          <h1
            style={{
              borderBottom: `2px solid ${item.bgcolor}`,
              color:
                hovereditem && hovereditem === item.name ? "white" : "black",
            }}
            className={"pb-1 text-sm text-center font-bold capitalize"}
          >
            {item.name}
          </h1>
        </div>
      </div>
    );
  };

  const EachTopMenu = (svg, name, index) => {
    if (selected === index)
      return (
        <a
          className="cursor-pointer w-20 h-auto xl:w-28 2xl:w-28 xl:h-28 2xl:h-28
            py-2 flex flex-1 justify-center content-center rounded-lg 
            transition delay-0 ease-in-out hover:duration-100 
            items-center align-center flex-col"
          style={{
            backgroundColor: "transparent",
            border: "2px solid #ff0058",
          }}
        >
          <img
            src={`https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/game_icon/${svg}.png`}
            className="w-7 h-7 xl:w-8 2xl:h-8 my-2"
          />
          <h1 className="text-md xl:text-lg 2xl:text-lg font-800 uppercase text-white">
            {name}
          </h1>
        </a>
      );
    else
      return (
        <a
          onClick={() => setTeamId(index)}
          className="cursor-pointer w-20 h-auto xl:w-28 2xl:w-28 xl:h-28 2xl:h-28
            transition delay-0 ease-in-out hover:duration-100
            py-2 flex flex-1 menu-border-color justify-center content-center
            rounded-lg
            items-center align-center flex-col"
        >
          <img
            src={`https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/game_icon/${svg}.png`}
            className="w-7 h-7 xl:w-8 2xl:h-8 my-2"
          />
          <h1 className="text-md xl:text-lg 2xl:text-lg font-800 uppercase text-white">
            {name}
          </h1>
        </a>
      );
  };

  const LoadSeesion = async (season) => {
    setSeason(season);

    setTeamlist([]);
    setTeamList([]);

    window.localStorage.setItem("season", season);

    if (selected === 0) {
      await getTeams(season);
    } else if (selected === 1) {
      await getNRLTeams(season);
    } else if (selected === 2) {
      getNBATeams(season);
    } else if (selected === 3) {
      await getEPLTeams(season);
    } else if (selected === 4) {
      await getNFLTeams(season);
    } else if (selected === 5) {
      await getBBLTeams(season);
    }
  };

  const CalcHeadToHead = () => {
    try {
      if (getUser()) {
        let ct = new URL(window.location.href).searchParams.get("ct");
        if (ct && headOne != 0 && headTwo != 0) {
          if (ct === "afl")
            props.history.push(
              "/team?id=" +
                headOne +
                "&p=d" +
                "&s=" +
                selectedSeason +
                "&hth=" +
                headTwo
            );
          else if (getUser()) {
            if (ct === "nrl")
              props.history.push(
                "/nrlteam?id=" +
                  headOne +
                  "&p=ts" +
                  "&s=" +
                  selectedSeason +
                  "&hth=" +
                  headTwo
              );
            else if (ct === "nba")
              props.history.push(
                "/nbateam?id=" + headOne + "&p=ps" + "&hth=" + headTwo
              );
            else if (ct === "epl")
              props.history.push(
                "/eplteam?id=" + headOne + "&p=ts" + "&hth=" + headTwo
              );
            else if (ct === "nfl")
              props.history.push(
                "/nflteam?id=" +
                  headOne +
                  "&p=ts" +
                  "&hth=" +
                  headTwo +
                  "&s=" +
                  selectedSeason.toString().slice(0, 4)
              );
            else if (ct === "bbl")
              props.history.push(
                "/bblteam?id=" +
                  headOne +
                  "&p=r" +
                  "&hth=" +
                  headTwo +
                  "&s=" +
                  selectedSeason.toString().slice(0, 4)
              );
          } else setsignup(!show_signup);
        } else window.alert("Select Team's first");
      } else setsignup(!show_signup);
    } catch (error) {
      console.log("Erorr", error);
    }
  };

  const seasonUI = (season) => {
    return (
      // <div className="w-48 px-5 py-2">
      <a
        className={
          selectedSeason === season
            ? "block px-2 lg:px-5 w-24 lg:w-40 py-1 capitalize text-base text-center cursor-pointer active-season mx-1"
            : "block px-2 lg:px-5 w-24 lg:w-40 py-1 capitalize text-base text-center cursor-pointer season border border-red-500 mx-1"
        }
        onClick={() => LoadSeesion(season)}
      >
        {season}
      </a>
      // </div>
    );
  };

  return (
    <div className="overflow-hidden h-full main">
      <AppBar
        his={props.history}
        show_signup={show_signup}
        closeSignupModal={() => setsignup(!show_signup)}
      />

      <div
        className="w-full sm:w-full md:w-full lg:w-3/5 m-auto xl:w-9/12 2xl:w-9/12 p-3 
            xl:p-0 2xl:p-0 2xl:my-5 lg:h-screen xl:h-auto 2xl:h-auto"
      >
        <div className="xl:flex 2xl:flex xl:flex-1 2xl:flex-1">
          <div className="mx-1 xl:w-9/12">
            <div className="w-full rounded-md overflow-x-auto top-menu">
              <div className="flex flex-1 justify-start w-full">
                {MenuList.map((item, index) => {
                  return (
                    <div className="each-menu-con w-1/5 2xl:w-auto xl:w-auto">
                      {EachTopMenu(item.svg, item.name, index)}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="h-auto">
              <div className="mt-4 py-1 season-list">
                <div className="flex flex-auto justify-start overflow-hidden items-center align-center w-full">
                  <div>
                    <h1 className="text-base lg:text-xl">Seasons</h1>
                  </div>
                  <div className="flex flex-auto justify-start items-center align-center ml-5 overflow-hidden">
                    {seasonList &&
                      seasonList[selected] &&
                      seasonList[selected].season &&
                      seasonList[selected].season.map((item, index) => {
                        return seasonUI(item.year);
                      })}
                  </div>
                </div>
              </div>

              <div className="mt-4 pb-2">
                <div
                  className="flex flex-col md:flex-row 
                                overflow-visible lg:overflow-hidden justify-start 
                                items-center align-center w-full lg:w-max capitalize"
                >
                  <div className="pr-3">
                    <h1
                      className="text-lg lg:text-xl text-left lg:text-center"
                      for="team_one"
                    >
                      Head to Head
                    </h1>
                  </div>

                  <select
                    name="team_one"
                    id="cars"
                    className="text-white p-2 outline-none mr-3 capitalize
                                    rounded-md bg-transparent border border-red-500 w-full lg:w-min"
                    onChange={(val) => setHeadone(val.target.value)}
                  >
                    <option className="text-black capitalize" value={""}>
                      {"Select any team"}
                    </option>

                    {teamList &&
                      teamList.map((item) => {
                        return (
                          <option
                            className="text-black capitalize"
                            value={item.id}
                            key={item.id}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>

                  {headOne != 0 && (
                    <select
                      name="team_one"
                      id="cars"
                      className="text-white p-2 capitalize outline-none mr-3 rounded-md 
                                        bg-transparent border border-red-500 w-full lg:w-min my-4 lg:my-0"
                      onChange={(val) => setHeadtwo(val.target.value)}
                    >
                      <option className="text-black capitalize" value={""}>
                        {"Select any team"}
                      </option>

                      {teamList &&
                        teamList
                          .filter((a) => a.id != headOne)
                          .map((item) => {
                            return (
                              <option
                                className="text-black capitalize"
                                value={item.id}
                                key={item.id}
                              >
                                {item.name}
                              </option>
                            );
                          })}
                    </select>
                  )}

                  <button
                    className="rounded-sm px-5 py-2 active-season my-3 lg:my-0"
                    type="button"
                    onClick={() => CalcHeadToHead()}
                  >
                    Compare
                  </button>
                </div>
              </div>

              <div>
                {selected === 0 && (
                  <div>
                    <div className="flex flex-auto items-center">
                      <PageBar his={props.history} isSportPage={true} />
                      <p className="text-left my-4 mx-3 text-2xl font-bold capitalize text-white">
                        AFL Teams
                      </p>
                    </div>

                    <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-5 gap-2">
                      {!loader && teamlist ? (
                        teamlist.map((item, index) => (
                          <div className="w-full">{AFLTeam(item, "afl")}</div>
                        ))
                      ) : (
                        <AppLoader
                          msg={"Getting " + selectedSeason + " AFL teams.."}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div>
                {selected === 1 && (
                  <div>
                    <div className="flex flex-auto items-center">
                      <PageBar his={props.history} isSportPage={true} />
                      <p className="text-left my-4 mx-1 text-2xl font-bold capitalize text-white">
                        NRL Teams
                      </p>
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-5 gap-2">
                      {!loader ? (
                        nrlList &&
                        nrlList.map((item, index) => (
                          <div className="w-full" key={item.id}>
                            {NRLTeam(item, "nrl")}
                          </div>
                        ))
                      ) : (
                        <AppLoader
                          msg={"Getting " + selectedSeason + " NRL teams.."}
                        />
                      )}
                    </div>
                    {!loader && nrlList.length == 0 && (
                      <p className="text-center my-4 mx-1 text-lg capitalize text-white">
                        {"No teams played in " + selectedSeason}
                      </p>
                    )}
                  </div>
                )}
              </div>

              <div>
                {selected === 2 && selectedSeason != "2022" && (
                  <div>
                    <div className="flex flex-auto items-center">
                      <PageBar his={props.history} isSportPage={true} />
                      <p className="text-left my-4 mx-1 text-2xl font-bold capitalize text-white">
                        NBA Teams
                      </p>
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-5 gap-2">
                      {!loader && nbaList ? (
                        nbaList.map((item, index) => (
                          <div className="w-full">{NBATeam(item, "nba")}</div>
                        ))
                      ) : (
                        <AppLoader msg={"Getting NBA teams.."} />
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div>
                {selected === 3 && selectedSeason != "2022" && (
                  <div>
                    <div className="flex flex-auto items-center">
                      <PageBar his={props.history} isSportPage={true} />
                      <p className="text-left my-4 mx-1 text-2xl font-bold capitalize text-white">
                        EPL Teams
                      </p>
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-5 gap-2">
                      {!loader && eplList ? (
                        eplList.map((item, index) => (
                          <div className="w-full">{EPLTeam(item, "epl")}</div>
                        ))
                      ) : (
                        <AppLoader
                          msg={"Getting " + selectedSeason + " EPL teams.."}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div>
                {selected === 4 && (
                  <div>
                    <div className="flex flex-auto items-center">
                      <PageBar his={props.history} isSportPage={true} />
                      <p className="text-left my-4 mx-1 text-2xl font-bold capitalize text-white">
                        NFL Teams
                      </p>
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-5 gap-2">
                      {!loader && nflList ? (
                        nflList.map((item, index) => (
                          <div className="w-full">{NFLTeam(item, "nfl")}</div>
                        ))
                      ) : (
                        <AppLoader
                          msg={"Getting " + selectedSeason + " NFL teams.."}
                        />
                      )}
                    </div>
                  </div>
                )}

                {selected === 5 && (
                  <div>
                    <div className="flex flex-auto items-center">
                      <PageBar his={props.history} isSportPage={true} />
                      <p className="text-left my-4 mx-1 text-2xl font-bold capitalize text-white">
                        BBL Teams
                      </p>
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-5 gap-2">
                      {!loader && nflList ? (
                        bblList.map((item, index) => (
                          <div className="w-full">{BBLTeam(item, "bbl")}</div>
                        ))
                      ) : (
                        <AppLoader
                          msg={"Getting " + selectedSeason + " BBL teams.."}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div>
                {selected > 6 && (
                  <div>
                    <PageBar his={props.history} isSportPage={true} />
                    <div
                      className="flex flex-1 justify-center items-center 
                      content-center align-center h-80"
                    >
                      {" "}
                      <p>Coming soon..</p>{" "}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="xl:w-3/12 w-full">
            {!loader && selected < 6 && <SponserListCom />}
          </div>
        </div>
      </div>

      <div className="my-4">
        {!loader && <CopyRights his={props.history} />}
      </div>
    </div>
  );
}

export default withRouter(Home);
