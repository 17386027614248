export const SponserList = [

    {
      id: 1,
      image:
        "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/sponser_data/bethere/neds.png",
      bgcolor: "#ff7800",
      content:
        "We are a passionate team of computer nerds, sports enthusiasts, statisticians, bookies and digital wizards",
      url: "https://www.neds.com.au/mobile-download",
      // url:'https://record.nedsaffiliates.com.au/_rNbqy-8ivW8Lak7-PYDSdWNd7ZgqdRLk/1/?b=WIZARDS',
      // url:"https://record.nedsaffiliates.com.au/_rNbqy-8ivW_BkgpoV0de5mNd7ZgqdRLk/1/"
    },
    {
      id: 2,
      image:
        "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/sponser_data/bethere/ladbrokes.png",
      bgcolor: "#ed1b24",
      content:
        "sign in and score $100. double your winnings in bonus bets on R5.*",
      url: "https://www.ladbrokes.com.au/mobile-download",
      // url: "https://record.wageringaffiliates.com.au/_I7NX2_GSSjkB0WjZNehKqWNd7ZgqdRLk/1/?b=WIZARDS",
      // url:"https://record.wageringaffiliates.com.au/_I7NX2_GSSjmitX36G815rmNd7ZgqdRLk/1/"
    },
    {
      id: 3,
      image:
        "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/sponser_data/bethere/betr.jpg",
      bgcolor: "#172FD4",
      content: "your best bet is to join playup today-austraila's best bookie",
      // url: "https://www.multihub.com.au/playup100",
      // url: "http://record.affiliates.playup.com/_0ACOkAcIIE0a0J_CtyaYcGNd7ZgqdRLk/1/",
      url:"https://aus01.safelinks.protection.outlook.com/?url=https%3A%2F%2Frecord.betraffiliates.com.au%2F_OYeaPWW-7QEOMRDMpvVHzmNd7ZgqdRLk%2F1%2F&data=05%7C01%7Cmichael.liston%40betr.com.au%7C08c0bda19f8f4072a2c608dac2bb048c%7Cdda3f2f6de5d4b688a9754b9fc911ef4%7C0%7C0%7C638036407159279140%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C1000%7C%7C%7C&sdata=U2Nu0ukCdX%2BhgGuQPIO%2BWwzexTvCI7sykyXFIQnyOc0%3D&reserved=0"
    },
    {
      id: 4,
      image:
        "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/sponser_data/bethere/playeup.png",
      bgcolor: "#00f181",
      content: "your best bet is to join playup today-austraila's best bookie",
      // url: "https://www.multihub.com.au/playup100",
      // url: "http://record.affiliates.playup.com/_0ACOkAcIIE0a0J_CtyaYcGNd7ZgqdRLk/1/",
      url:"https://record.affiliates.playup.com/_0ACOkAcIIE3CP9GC20JVYmNd7ZgqdRLk/1/"
    },
    {
      id: 5,
      image: "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/sponser_data/bethere/draftstart.png",
      bgcolor: "#ffffff",
      content:
        "Unibet is part of the Kindred Group, one of the largest online gambling companies in the world.",
      // url: "https://www.multihub.com.au/draftstars",
      // url: "https://record.affiliates.playup.com/_0ACOkAcIIE36PBA04iUMN2Nd7ZgqdRLk/1/",
      url:"https://record.affiliates.playup.com/_0ACOkAcIIE12qOYNvGXWVGNd7ZgqdRLk/1/"
    },
    {
      id: 6,
      image:
        "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/sponser_data/boombet.jpeg",
      bgcolor: "#890dbc",
      content:
        "Unibet is part of the Kindred Group, one of the largest online gambling companies in the world.",
      // url: "https://www.multihub.com.au/boombet100",
      url: "https://www.boombet.com.au/login/?Referrer=PuntingWizards",
    },
    {
      id: 7,
      image:
        "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/sponser_data/bethere/unibet.jpg",
      bgcolor: "#ffffff",
      content:
        "Unibet is part of the Kindred Group, one of the largest online gambling companies in the world.",
      // url: "https://www.multihub.com.au/unibet100",
      url: "https://b1.trickyrock.com/redirect.aspx?pid=32519285&bid=21418",
    },
   
  ];