import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { GetPlayerFullDetail } from "../../Busniess_Logic/API/AFLApi";
import AppBar from "../AppBar/AppBar";
import PageBar from "../AppBar/PageBar";
import AppLoader from "../Loader/Loader";
import '../../utils/styles/teamdetail/teamdetail.scss';
import { SponserListCom } from "../SponerList/SponserList";
import CopyRights from "../CopyRights";
import AdSenseCom from "../AdSense/AdSense";

function PlayerDetail(props) {

    const [called, setCalled] = useState(false)
    const [detail, setDetail] = useState(null)
    const [loader, setLoader] = useState(false)
    const [tc, setTc] = useState(null)

    const [hoverHeader, setHoverheader] = useState(null)

    useEffect(() => {
        if (!called) getplayerdetails()
    })

    const getplayerdetails = async () => {

        setLoader(true)
        var surl = new URLSearchParams(window.location.href)
        var url = new URL(window.location.href);
        var c = url.searchParams.get("id");
        var season = surl.get('s')

        const res = await GetPlayerFullDetail( c ? c : "8-162",season ? season : '2021' )
        if (res) 
        setDetail(res)
        

        setCalled(true)
        setLoader(false)
        setTc(surl.get('tc') ? surl.get('tc') :"#004b8d")

    }

    const CalculateDisposal = () => {
        var disposalList = []
        detail && detail.games && detail.games.forEach(item => {
            var eachDisposal = 0
            item.stats.forEach(stat => {
                if (stat.id === '8-1' || stat.id === '8-2' || stat.id === '8-3' ||
                    stat.id === '8-4' || stat.id === '8-5' || stat.id === '8-6')
                    eachDisposal = eachDisposal + stat.value
            })
            disposalList.push(eachDisposal)
        })
        return disposalList
    }

    const CalculateGoal = () => {
        var goalList = []
        detail && detail.games && detail.games.forEach(item => {
            var eachGoal = 0
            item.stats.forEach(stat => {
                if (stat.id === '8-22')
                    eachGoal = eachGoal + stat.value
            })
            goalList.push(eachGoal)
        })
        return goalList
    }

    const CalculateFantasyPoints = () => {
        var scoreList = []
        detail && detail.games && detail.games.forEach(item => {
            scoreList.push(item.score)
        })
        return scoreList
    }

    const getDisposals = (lw_num) => {
        var count = 0
        CalculateDisposal().forEach(item => {
            if (item >= lw_num)
                count++;
        })
        return count
    }

    const getGoals = (lw_num) => {
        var count = 0
        try {
            CalculateGoal().forEach(item => {
                if (item >= lw_num)
                    count++;
            })
        } catch (e) {
            console.log(e)
        }

        return count
    }

    const getAnyTimeGoals = () => {
        var count = 0
        try {
            CalculateGoal().forEach(item => {
                if (item != 0)
                    count++;
            })
        } catch (e) {
            console.log(e)
        }

        return count
    }

    const getFantasyPoints = (lw_num, hw_num) => {
        var count = 0
        try {
            CalculateFantasyPoints().forEach(item => {
                if (item >= lw_num)
                    count++;
            })
        } catch (e) {
            console.log(e)
        }

        return count
    }

    const playerDataTable = (tableData) => {
        return (
            <table className='rounded-lg tb-shadow h-auto w-full text-black'>
                {
                    tableData.map((item, index) => {
                        if (index === 0) return <tr style={{ backgroundColor: tc }}>
                            <th className='rounded-tl-md uppercase w-full text-white text-left' style={{ backgroundColor: tc }}>{item.title}</th>
                            <th className='rounded-tr-md w-1/2'>{item.content}</th>
                        </tr>
                        else return <tr className=''>
                            <td className='capitalize text-center pl-4 w-1/2 py-1'>{item.title}</td>
                            <td className='w-1/2'>{item.content}</td>
                        </tr>
                    })
                }
            </table>
        )
    }

    const getTotalGames = () => {
        if(detail && detail.games) return (detail.games.length).toString()
        else return "0"
    }

    const GetUserDiposalTB = () => {
        return [
            { title: 'disposals', content: '' },
            { title: '15+ Disposals', content: (getDisposals(15, 20).toString() + '/' + getTotalGames()) },
            { title: '20+ Disposals', content: getDisposals(20, 25) + '/' + getTotalGames() },
            { title: '25+ Disposals', content: getDisposals(25, 30) + '/' + getTotalGames() },
            { title: '30+ Disposals', content: getDisposals(30, 35) + '/' + getTotalGames() },
            { title: '35+ Disposals', content: getDisposals(35, 100) + '/' + getTotalGames() },
            { title: 'hightest disposals', content: Math.max(...CalculateDisposal()) },
            { title: 'lowest disposals', content: Math.min(...CalculateDisposal()) },
            { title: 'average disposals', content: ((CalculateDisposal().reduce((a, b) => a + b, 0)) / CalculateDisposal().length).toFixed(1) }
        ]
    }

    const GetUserGoalsTB = () => {
        return [
            { title: 'goals', content: '' }, { title: 'Total goals', content: CalculateGoal().reduce((a, b) => a + b, 0) },
            { title: 'highest goals', content: Math.max(...CalculateGoal()) },
            { title: 'average goals', content: ((CalculateGoal().reduce((a, b) => a + b, 0)) / CalculateGoal().length).toFixed(1) },
            { title: 'any time goal', content: getAnyTimeGoals() + '/' + getTotalGames() },
            { title: '2+ goals', content: getGoals(2, 3) + '/' + getTotalGames() },
            { title: '3+ goals', content: getGoals(3, 4) + '/' + getTotalGames() },
            { title: '4+ goals', content: getGoals(4, 5) + '/' + getTotalGames() },
            { title: '5+ goals', content: getGoals(5, 100) + '/' + getTotalGames() }
        ]
    }


    const GetUserFantasyPointsTB = () => {
        return [
            { title: 'Fantasy points', content: '' },
            { title: 'Average points', content: (CalculateFantasyPoints().reduce((a, b) => a + b, 0) / CalculateFantasyPoints().length).toFixed(1) },
            { title: '60+ points', content: getFantasyPoints(60, 70) + '/' + getTotalGames() },
            { title: '70+ points', content: getFantasyPoints(70, 80) + '/' + getTotalGames() },
            { title: '80+ points', content: getFantasyPoints(80, 90) + '/' + getTotalGames() },
            { title: '90+ points', content: getFantasyPoints(90, 100) + '/' + getTotalGames() },
            { title: '100+ points', content: getFantasyPoints(100, 110) + '/' + getTotalGames() },
            { title: '110+ points', content: getFantasyPoints(110, 120) + '/' + getTotalGames() },
            { title: '120+ points', content: getFantasyPoints(120, 1000) + '/' + getTotalGames() },
            { title: 'lowest points', content: Math.min(...CalculateFantasyPoints()) },
            { title: 'highest points', content: Math.max(...CalculateFantasyPoints()) }
        ]
    }



    return (
        <div className=''>
            <AppBar his={props.history} />

            

            {
                loader && <div style={{ position: 'relative' }}><AppLoader msg={'Getting player details'} /></div>
            }

            {!loader &&

                <div className='player-detail-com w-full sm:w-full md:w-full lg:w-3/5 
                m-auto xl:w-3/4 2xl:w-3/4 p-3 xl:p-0 2xl:p-0 2xl:py-5 pb-8'>



                    <div className='xl:flex 2x:flex xl:flex-1 2xl:flex-1'>

                        {
                            detail !=null && detail!=undefined ?
                            <div className='mx-1 w-full xl:w-3/4 2xl:w-4/5'
                        >

                            <AdSenseCom/>

                            <PageBar his={props.history} />

                            <div className='team-container p-4 rounded-lg border border-grey-100 shadow-md flex flex-1 flex-row items-center' style={{ backgroundColor: tc }}>
                                <div className='team-name w-full'><h1 className='text-3xl font-bold text-white'>{detail.player_name ? detail.player_name : '' }</h1></div>
                            </div>

                            <div className='my-2 p-3 rounded-lg shadow-md border border-grey-100 flex flex-1 justify-around'>
                                <p className='text-left px-2'>Total games played</p>
                                <p className='text-left px-2'><strong>{getTotalGames()}</strong></p>
                            </div>

                            {
                                detail.games && detail.games.length ?
                                <div className='w-full'>
                                    <div className='rounded-lg shadow-xl items-center my-4 top-tb-container' style={{ position: 'relative' }}>
                                        <table className='p-2 top-table text-base xl:text-sm 2xl:text-lg bg-white text-black'>
                                            <tr>
                                                <th className='rounded-tl-md text-white p-2 capitalize' style={{ backgroundColor: tc }}>Opposition name</th>
                                                {
                                                    detail.games.map((item, index) => {
                                                        if (item.away_team.abbreviation === detail.player_team.abbreviation)
                                                            return <td className='text-md top-tb-data' onMouseEnter={() => setHoverheader(item.home_team.abbreviation)} onMouseLeave={() => setHoverheader(null)}>
                                                                <div className='tool-tip-con'>
                                                                    <strong>{item.home_team.abbreviation}</strong>
                                                                    <p className='tool-tip-data'>{item.home_team.name}</p>
                                                                </div>
                                                            </td>
                                                        else return <td className='text-md top-tb-data' onMouseEnter={() => setHoverheader(item.away_team.abbreviation)} onMouseLeave={() => setHoverheader(null)}>
                                                            <div className='tool-tip-con'>
                                                                <strong>{item.away_team.abbreviation}</strong>
                                                                <p className='tool-tip-data'>{item.away_team.name}</p>
                                                            </div>
                                                        </td>
                                                    })
                                                }
                                            </tr>

                                            <tr >
                                                <th className='text-white p-2 capitalize' style={{ backgroundColor: tc }}>date</th>
                                                {
                                                    detail.games && detail.games.map((item, index) => {
                                                        return <td className='text-sm top-tb-data'>
                                                            <strong>{new Date(item.start_time).getDate() + ' ' + new Date(item.start_time).toLocaleDateString('default', { month: 'long' }).substr(0, 3)}</strong>
                                                        </td>
                                                    })
                                                }
                                            </tr>

                                            <tr>
                                                <th className='text-white p-2 capitalize' style={{ backgroundColor: tc }}>Disposals</th>
                                                {
                                                    CalculateDisposal().map((item) => {
                                                        return <td className='top-tb-data'><strong>{item === 0 || item === -Infinity || item === NaN ? '-' : item}</strong></td>
                                                    })
                                                }
                                            </tr>

                                            <tr>
                                                <th className='text-white p-2 capitalize' style={{ backgroundColor: tc }}>Goals</th>
                                                {
                                                    CalculateGoal().map((item) => {
                                                        return <td className='top-tb-data'><strong>{item === 0 || item === -Infinity || item === NaN ? '-' : item}</strong></td>
                                                    })
                                                }
                                            </tr>

                                            <tr>
                                                <th className='text-white rounded-bl-md p-2 capitalize' style={{ backgroundColor: tc }}>fantasy points</th>
                                                {
                                                    CalculateFantasyPoints().map((item) => {
                                                        return <td className='top-tb-data'><strong>{item === 0 || item === -Infinity || item === NaN ? '-' : item}</strong></td>
                                                    })
                                                }
                                            </tr>
                                        </table>
                                    </div>

                                    <div className='grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 
                                        gap-2 items-start justify-between w-full data-table-con'>

                                        {
                                            playerDataTable(GetUserDiposalTB())
                                        }

                                        {
                                            playerDataTable(GetUserGoalsTB())
                                        }

                                        {
                                            playerDataTable(GetUserFantasyPointsTB())
                                        }

                                    </div>

                                </div>
                                :
                                <div>
                                    <p className="text-white py-3 text-center">No games played</p>
                                </div>
                            }

                        </div>
                        :
                        <div className='mx-1 w-full xl:w-3/4 2xl:w-4/5 m-auto'>
                            <p className="text-white py-5 text-center">Data not found</p>
                        </div>
                        }
                        
                        <div className='mt-14 xl:w-1/4 2xl:w-1/5'>
                            <SponserListCom />
                        </div>
                    </div> 
                    
                </div>
            }

            {
                !loader && <CopyRights his={props.history}/>
            }

        </div>
    )
}

export default withRouter(PlayerDetail);