import { useState } from "react";
import OfferList from "./OfferForm/Offerlist";

const MenuList = [
  {
    id: 1,
    name: "offer's",
  },
];

function Dashboard(props) {
  const [currentMenu, setmenu] = useState(1);

  return (
    <div className="flex flex-row" style={{ height: "91vh" }}>
      <div className="w-full px-8 py-2 overflow-y-auto">
      <OfferList />
      </div>
      {/* <div className="w-64 h-full py-5 ">
        <ul>
          {MenuList.map((x) => (
            <li
              key={x.id}
              className={
                currentMenu === x.id
                  ? "bg-gray-600 cursor-pointer"
                  : "bg-transparent cursor-pointer"
              }
              onClick={() => setmenu(x.id)}
            >
              <a className={"text-left capitalize font-medium p-3 pl-14 block"}>
                {x.name}
              </a>
            </li>
          ))}
        </ul>
      </div>

      <div className="w-full h-full p-5 overflow-y-auto">
        {currentMenu === 1 ? <OfferList /> : <></>}
      </div> */}
    </div>
  );
}

export default Dashboard;
