import React, { useEffect, useState } from "react";
import { NRLTbHoverList } from "./NRL_Constant";
import '../../utils/styles/table/table.scss'


const greeen = '#26e169';
const mediumGreeen = '#73ec9e';
const lightGreeen = '#c1f6d4';


function NRLPlayerTable(props) {

    const [headerList, setHeader] = useState([])

    const [cat, setCat] = useState(null)
    const [sortedth, setSortedth] = useState(null)
    const [playerList, setPlayerlist] = useState([])

    const [onHover, setHover] = useState(false)
    const [hoverkey, setHoverkey] = useState(null)

    useEffect(() => {

        setCat(props.cat)

        if (props.cat === 1) {
            sortByList('total_tries', props.player_list)
        } else if (props.cat === 2) {
            setPlayerlist(props.player_list)
        } else {
            setHeader(null)
            setPlayerlist(null)
        }

    }, [props.cat])

    const ViewPlayer = (item) => {
        props.his.push('/nrlplayer?id=' + item + '&tc=' + props.bgcolor+'&s='+props.season)
    }

    // ascending and descending
    const compareByAsc = (category, key) => {
        return function (a, b) {
            if (a[category] !== null && a[category][key] !== null && b[category] !== null && b[category][key] !== null) {
                if (a[category][key] < b[category][key]) return -1;
            } else return 0

            if (a[category] !== null && a[category][key] !== null && b[category] !== null && b[category][key] !== null) {
                if (a[category][key] > b[category][key]) return 1
            } else return 0;
            return 0;
        };
    }

    const compareByDesc = (category, key) => {
        return function (a, b) {
            if (a[category] !== null && a[category][key] !== null && b[category] !== null && b[category][key] !== null) {
                if (a[category][key] < b[category][key]) return 1;
            } else return 0

            if (a[category] !== null && a[category][key] !== null && b[category] !== null && b[category][key] !== null) {
                if (a[category][key] > b[category][key]) return -1;
            } else return 0
            return 0;
        };
    }

    const checkKey = (key) => {
        if (key === 'avt') return 'average_try_score'
        else if (key === 'tt') return 'total_tries'
        else if (key === '1t') return 'one_try'
        else if (key === '2t') return 'two_try'
        else if (key === '3t') return 'three_try'

        else if (key === 'ht') return 'highest_tries_scored'
        else if (key === 'gp') return 'games_played'

        else if (key === 'player') return 'name'
        else if (key === '#') return 'player_number'

        else return key;
    }

    const sortBy = (key) => {

        setSortedth(key)

        key = checkKey(key)
        var nullRemoveList = []
        var nullList = []

        playerList.forEach(item => {
            if (item.tries) nullRemoveList.push(item)
            else nullList.push(item)
        })

        let arrayCopy = [...nullRemoveList];
        const arrInStr = JSON.stringify(arrayCopy);

        //console.log(arrInStr)

        if (key === 'name' || key === 'player_number') arrayCopy.sort(compareByAscNameIndex(key))
        else arrayCopy.sort(compareByDesc('tries', key));
        const arrInStr1 = JSON.stringify(arrayCopy);

        if (arrInStr === arrInStr1) {
            if (key === 'name' || key === 'player_number') arrayCopy.sort(compareByDescNameIndex(key))
            else arrayCopy.sort(compareByAsc('tries', key));
        }

        var alllist = arrayCopy.concat(nullList)
        //console.log(arrayCopy.length, nullList.length)
        setPlayerlist(alllist)

    }


    const sortByList = (key, list) => {

        var nullRemoveList = []
        var nullList = []

        if(list !=null && list!=undefined){
            list.forEach(item => {
                if (item.tries) nullRemoveList.push(item)
                else nullList.push(item)
            })
    
            // var categoryName = (props.cat === 1 ? 'disposal' : (props.cat === 2 ? 'goals' : 'fantasy_points'))
            let arrayCopy = [...nullRemoveList];
            arrayCopy.sort(compareByDesc('tries', key));
    
            var alllist = arrayCopy.concat(nullList)
            //console.log(arrayCopy.length, nullList.length)
            setPlayerlist(alllist)
        }
        
    }



    const compareByAscNameIndex = (key) => {
        if (key === 'player_id')
            return function (a, b) {
                if (parseInt(a[key]) > parseInt(b[key])) return 1;
                if (parseInt(a[key]) < parseInt(b[key])) return -1;
                return 0;
            };
        else return function (a, b) {
            if (a[key] > b[key]) return 1;
            if (a[key] < b[key]) return -1;
            return 0;
        };
    }

    const compareByDescNameIndex = (key) => {
        if (key === 'player_id')
            return function (a, b) {
                if (parseInt(a[key]) < parseInt(b[key])) return 1;
                if (parseInt(a[key]) > parseInt(b[key])) return -1;
                return 0;
            };
        else
            return function (a, b) {
                if (a[key] < b[key]) return 1;
                if (a[key] > b[key]) return -1;
                return 0;
            };
    }

    const colorBy = (key, value) => {

        if (value === 0 || value === null) return ' '
        else if (value !== null && key !== 'margin') {

            let arrayCopy = [...playerList];
            const arrInStr = JSON.stringify(arrayCopy);

            arrayCopy = arrayCopy.sort((a, b) => b['tries'][key] - a['tries'][key])

            var max = arrayCopy.reduce((prev, current) => prev['tries'][key] > current['tries'][key] ? prev : current)
            if (max['tries'][key] === value) return greeen
            //console.log("max1", max['tries'][key])

            var maxIndex = arrayCopy.indexOf(max)

            if (maxIndex > -1) {
                arrayCopy.splice(maxIndex, 1);
            }

            var max2 = arrayCopy.reduce((prev, current) => (prev['tries'][key] > current['tries'][key] && prev['tries'][key] !== max['tries'][key] && current['tries'][key] !== max['tries'][key] ? prev : current))
            if (max2['tries'][key] === value) return mediumGreeen
            //console.log("max2", max2['tries'][key])

            var maxIndex2 = arrayCopy.indexOf(max2)

            if (maxIndex2 > -1) {
                arrayCopy.splice(maxIndex2, 1);
            }

            var max3 = arrayCopy.reduce((prev, current) => (prev['tries'][key] > current['tries'][key] && prev['tries'][key] !== max2['tries'][key] && current['tries'][key] !== max2['tries'][key] ? prev : current))
            if (max3['tries'][key] === value) return lightGreeen
            //console.log("max3", max3['tries'][key])

        }

    }

    const colorByValues = (key, value) => {
        if (value === 0 || value === null) return ''
        else if (value !== null && key != 'margin') {

            // key=checkKey(key)
            if (key === 'avt') {
                value = parseFloat(value).toFixed(1)
                if (value >= 0.8) return greeen;
                else if (value >= 0.6 && value <= 0.79) return mediumGreeen;
                else if (value >= 0.4 && value <= 0.59) return lightGreeen;
            }

            if (key === 'tt' || key === 'ht') {
                if (value >= 25) return greeen;
                else if (value >= 20 && value <= 24) return mediumGreeen;
                else if (value >= 15 && value <= 19) return lightGreeen;
            }

            if (key === '1t' || key === '2t' || key === '3t') {
                if (value >= 80 && value <= 100) return greeen;
                else if (value >= 60 && value <= 79) return mediumGreeen;
                else if (value >= 40 && value <= 59) return lightGreeen;
            }

        }
        else if (key === 'margin') {

            var templist = []
            playerList.forEach(item => {
                templist.push(item.margin)
            })

            var sortedMarginList = templist.sort((a, b) => b - a).slice(0, 5)
            if (sortedMarginList.includes(value)) return greeen
            else return 'transparent'

        }
    }

    const onMouseEnter = (key) => {
        setHover(true)
        setHoverkey(key)
    }

    const GetKeyHighestValue = (key) => {
        var result = ''
        if(key == 'team') {}
        else {
            NRLTbHoverList.forEach(item => {
                if (key.toString().toUpperCase().trim(' ') === item.name.toUpperCase().trim(' '))
                    result = item.fullform.toString()
            })
        }
        return result
    }

    const onMouseLeave = (key) => {
        setHoverkey(null)
        setHover(false)
    }

    const maniPulateName = (name) => {
        var splitter = name.split(' ')
        if (splitter.length > 1) {

            var initial = splitter[0].substr(0, 1)

            var fullname = ''
            if (splitter.length > 2) fullname = splitter[1] + ' ' + splitter[2]
            else fullname = splitter[1]

            var result = initial + ". " + fullname

            return result
            // .length > 15 ? result.slice(0, 15) + "." : result

        } else return name

    }

    return (
        <div className='nrl-player-table-com rounded-md w-full'>

            {
                props.player_list && props.player_list.length === 0 ?
                    <div>
                        {/* <div className='loader-com'> */}
                        <p>There's no players in nrl team</p>
                        {/* </div> */}
                    </div>
                    :
                    <div >


                        <table className='w-full mt-8 rounded-md tb-shadow text-sm xl:text-xs 2xl:text-lg text-black overflow-hidden' >

                            <tr className=''>
                                {
                                    props.headerData &&
                                    props.headerData.map((item, index) => {
                                        if (item === 'player' || item == '#' || item == 'team') {
                                            return <th onClick={() => sortBy(item)} style={{ backgroundColor: props.bgcolor }}
                                                className={item === sortedth ? 'text-left cursor-pointer uppercase font-bold text-md px-2 py-2 text-white tb-th-color'
                                                    : 'cursor-pointer uppercase font-bold text-md px-2 py-2 text-left text-white'}>
                                                {item}</th>
                                        }
                                        else if (props.cat === 1) {
                                            // if(item === 'avd' || item === 'tg' || item === 'afp') sortBy(item)
                                            return <th onMouseEnter={() => {
                                                if (item !== '') onMouseEnter(item)
                                            }}
                                                onMouseLeave={() => {
                                                    onMouseLeave()
                                                }}
                                                onClick={() => sortBy(item)} style={{ backgroundColor: props.bgcolor }}
                                                className={item === sortedth ? 'cursor-pointer uppercase font-bold text-md px-2 py-2 text-white tb-th-color'
                                                    : 'cursor-pointer uppercase font-bold text-md px-2 py-2 text-white'}>
                                                <div className={hoverkey === item ? 'block tb-header-hover-content z-50 w-max' : 'hidden tb-header-hover-content z-50 w-max'}>
                                                    <p className='text-white'>{hoverkey === item ? GetKeyHighestValue(item) : ''}</p>
                                                </div>
                                                {item}</th>
                                        }
                                        else {
                                            return <th
                                                style={{ backgroundColor: props.bgcolor }}
                                                className={'cursor-pointer uppercase font-bold px-2 py-2 text-white'}>
                                                {item}</th>
                                        }
                                    })
                                }
                            </tr>

                            {
                                playerList &&
                                playerList.map((item, index) => {

                                    var triesData = item.tries

                                    let average_try_score = triesData && triesData.average_try_score !== null ? triesData.average_try_score.toFixed(1) : 0
                                    let total_tries = triesData && triesData.total_tries !== null ? triesData.total_tries : 0
                                    let one_try = triesData && triesData.one_try !== null ? triesData.one_try.toFixed(0) : 0

                                    let two_try = triesData && triesData.two_try !== null ? triesData.two_try.toFixed(0) : 0
                                    let three_try = triesData && triesData.three_try !== null ? triesData.three_try.toFixed(0) : 0
                                    let highest_tries_scored = triesData && triesData.highest_tries_scored !== null ? triesData.highest_tries_scored : 0

                                    let games_played = triesData && triesData.games_played !== null ? triesData.games_played : 0
                                    
                                    let player_number = item.player_number ? item.player_number : "#0" 

                                    if (cat === 1 && total_tries !== 0)
                                        return <tr onClick={() => ViewPlayer(item.player_id)} 
                                        className={ props.hthteamid ? 
                                            item.teams && item.teams.id == props.curretteamid  ? 
                                            "cursor-pointer bg-white" :"cursor-pointer tb-hth-data"
                                            :'cursor-pointer tb-data' }
                                        >
                                            {
                                                props.hthteamid &&
                                                <td className='py-2 my-2 lg:py-3 xl:py-3 2xl:py-3 m-auto' width='0%'>
                                                    <img src={item.teams && item.teams.image} className='m-auto w-7 h-7'/>
                                                </td>
                                            }
                                            <td className='py-2 my-2 lg:py-3 xl:py-3 2xl:py-3' width='6%'>{player_number}</td>
                                            <td className='text-left pl-2 w-1/4' width='100px'>{maniPulateName(item.name)}</td>

                                            <td style={{ backgroundColor: colorBy('total_tries', total_tries) }} width='6%'>{total_tries}</td>
                                            <td style={{ backgroundColor: colorByValues('avt', average_try_score) }} width='6%'>{average_try_score}</td>
                                            <td style={{ backgroundColor: colorByValues('1t', one_try) }} width='6%'>{one_try + '%'}</td>
                                            <td style={{ backgroundColor: colorByValues('2t', two_try) }} width='6%'>{two_try + '%'}</td>
                                            <td style={{ backgroundColor: colorByValues('3t', three_try) }} width='6%'>{three_try + '%'}</td>
                                            <td style={{ backgroundColor: colorBy('highest_tries_scored', highest_tries_scored) }} width='6%'>{highest_tries_scored}</td>

                                            <td style={{ backgroundColor: colorByValues('gp', games_played) }} width='6%'>{games_played}</td>
                                        </tr>

                                    else if (cat === 2)
                                        return <tr className='cursor-pointer tb-data w-full'>

                                            <td className='w-1/5 text-base py-2 lg:py-3 xl:py-3 2xl:py-3'>{new Date(item.date).getDate() + ' ' + new Date(item.date).toLocaleDateString('default', { month: 'long' }).substr(0, 3)}</td>
                                            <td className='text-center text-black w-1/5 text-base lg:text-base xl:text-base 2xl:text-base '>{item.opponent}</td>

                                            <td className='w-1/5 text-base'>{item.for_score}</td>
                                            <td className='w-1/5 text-base'>{item.against_score}</td>

                                            <td className='w-1/5 text-base' style={{ color: item.result === 'W' ? 'green' : item.result === 'D' ? 'grey' : 'red', fontWeight: 'bolder' }}>{item.result}</td>
                                            <td className='w-1/5 text-base'>{item.margin}</td>

                                        </tr>
                                })
                            }
                        </table>
                    </div>
            }
        </div>
    )
}

export default NRLPlayerTable;