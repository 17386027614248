import { PuntingList, SponserList } from "../../utils/Appconstant";
import fb from "../../assets/Facebook.png";

export const SponserListCom = () => {
  const SponerUI = (item) => {
    return (
      <a
        // className={item.bgcolor}
        className={`flex flex-1 justify-between px-3 lg:px-1 xl:px-1 
          2xl:px-1 
          lg:my-3 xl:my-3 2xl:my-3 
          py-2 
          my-5 rounded-lg items-center ${item.bgcolor}`}
        href={item.url}
        target="_blank"
        key={item.id}
      >
        <h1 className="text-xs mx-2 uppercase">Claim Here</h1>
        <img
          src={item.image}
          className="w-2/5 h-10 capitalize object-contain"
        />
        <h1 className="text-xs mx-2 uppercase">Claim Here</h1>
      </a>
    );
  };

  const puntingGroupRedirect = () => {
    if (window.innerWidth <= 749)
      window.open(
        "https://m.facebook.com/groups/604338443451503/?ref=bookmarks"
      );
    else window.open("https://www.facebook.com/groups/604338443451503");
  };

  const cryptoRedirect = () => {
    if (window.innerWidth <= 749)
      window.open(
        "https://m.facebook.com/groups/1823740884451504/?ref=bookmarks"
      );
    else window.open("https://www.facebook.com/groups/1823740884451504");
  };

  const swyftxRedirect = () => {
    if (window.innerWidth <= 749)
      window.open("https://trade.swyftx.com.au/register/?ref=CryptoYoutubers");
    else
      window.open("https://trade.swyftx.com.au/register/?ref=CryptoYoutubers");
  };

  const PutingUI=(item)=>{
    return (
      <a
        href={window.innerWidth <= 749 ? item.mbl_url : item.pc_url}
        target="_blank" key={item.id}
        className="flex flex-1 items-center align-middle px-3 py-2 my-5 rounded-lg justify-start cursor-pointer"
        style={{ backgroundColor:item.bgcolor,border:item.border!='transparent' ? `2px solid ${item.border}`:'' }}
        >

        <img src={item.id == 3 ? item.image : fb} alt="img" className={item.id!=3 ? `w-12 lg:w-8 h-auto`:`w-20 lg:w-14 h-auto`}/>
        <div>
        <p className={"text-left p-0 mx-2 my-0 text-base lg:text-sm xl:text-xs 2xl:text-sm w-max"}>
          <strong className="text-left">{item.header}</strong>
        </p>
        <p className="text-left mx-2 text-sm lg:text-xs xl:text-xs 2xl:text-xs">
          {item.content}
        </p>
        </div>

      </a>
    )
  }

  return (
    <div className="w-full h-full lg:w-full 2xl:w-full mx-0 xl:mx-3">
      <div className="py-5 lg:py-3 xl:py-3 2xl:py-3">
        <h1
          className="text-3xl border-2 p-3 lg:text-2xl xl:text-2xl 2xl:text-2xl
            capitalize font-bold border-red-500 rounded-lg 
            leading-10 lg:leading-9 xl:leading-9 2xl:leading-9"
        >
          Help keep us running and open an account today! <br />
          Thank us later!{" "}
        </h1>
      </div>

      {SponserList.map((item) => {
        return SponerUI(item);
      })}

      {
        PuntingList.map((item)=>{
          return PutingUI(item)
        })
      }

      <div>
        {/* <button
          className="flex flex-1 rounded-lg px-4 w-full py-2 my-2 fb-container items-center"
          onClick={puntingGroupRedirect}
        >
          <img src={fb} className="w-10 h-10 cursor-pointer" />
          <div className="flex flex-1 flex-col px-2 lg:px-0 xl:px-0 2xl:px-0">
            <p className="text-left p-0 mx-2 my-0 text-base lg:text-sm xl:text-xs 2xl:text-sm w-max">
              <strong className="text-left">Multi Hub Punting Group</strong>
            </p>
            <p className="text-left mx-2 text-sm lg:text-xs xl:text-xs 2xl:text-xs">
              Free To Join Click Here
            </p>
          </div>
        </button>

        <button
          className="flex flex-1 rounded-lg px-4 w-full py-2 fb-container items-center"
          onClick={cryptoRedirect}
        >
          <img src={fb} className="w-10 h-10 cursor-pointer" />
          <div className="flex flex-1 flex-col px-2 lg:px-0 xl:px-0 2xl:px-0">
            <p className="text-left p-0 mx-2 my-0 text-base lg:text-sm xl:text-xs 2xl:text-sm w-max">
              <strong className="text-left">Crypto Facebook Group</strong>
            </p>
            <p className="text-left mx-2 text-sm lg:text-xs xl:text-xs 2xl:text-xs">
              Free To Join Click Here
            </p>
          </div>
        </button>

        <button
          className="flex flex-1 rounded-lg px-4 w-full py-2 my-2 border-2 border-blue-400 items-center"
          onClick={swyftxRedirect}
        >
          <img
            src={
              "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/sponser_data/logo_white.png"
            }
            className="w-20 lg:w-24 h-auto xl:h-6 2xl:h-6 cursor-pointer"
          />
          <div className="flex flex-1 flex-col px-2 lg:px-0 xl:px-0 2xl:px-0">
            <p className="text-left p-0 mx-2 my-0 text-base lg:text-sm xl:text-xs 2xl:text-sm w-max">
              <strong className="text-left">Join & Get $10 Bitcoin</strong>
            </p>
            <p className="text-left mx-2 text-sm lg:text-xs xl:text-xs 2xl:text-xs">
              Free To Join Click Here
            </p>
          </div>
        </button> */}
      </div>
    </div>
  );
};
