import React from "react";
import AdSense from 'react-adsense';
import MediaNetAd from "./MediaNetAd";

function AdSenseCom() {

  return (
    <div>
          <MediaNetAd id="547813683" size="728x90" />
    </div>

    // <div className="flex w-full m-auto justify-center">
        // <img src="https://imgur.com/8BDGPXR.png" 
        // className="flex m-auto justify-center"></img>
        // <AdSense.Google
        //   client='ca-pub-4523481526942856'
        //   slot='5751332583'
        //   format='auto'
        // responsive='true'
        // style={{ display: 'block',width:'728px',height:'90px' }}
        // />
    // </div>
  );
}

export default AdSenseCom;
