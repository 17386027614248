//bbl runs header
export const BBLRunsHeader = [
  // "#",
  "player",
  "TR",
  "AVR",
  "10+",
  "15+",
  "20+",
  "25+",
  "30+",
  "40+",
  "50+",
  "LR",
  "HR",
  "GP"
];
//bbl runs header
export const BBLFoursHeader = [
  // "#",
  "player",
  "TF",
  "AVF",
  "1+",
  "2+",
  "3+",
  "4+",
  "GP"
];

//nba rebound header
export const BBLSixesHeader = [
  // "#",
  "player",
  "TS",
  "AVS",
  "1+",
  "2+",
  "3+",
  "GP"
];

//nba assists header
export const BBLWicketsHeader = [
  // "#",
  "player",
  "TW",
  "AVW",
  "1+",
  "2+",
  "3+",
  "LW",
  "HW",
  "GP"
];

export const BBLTeamTBHeader = [
  "date",
  "opponent",
  "team score",
  "opp score",
  "result",
];

export const BBLLengendList = [

  // runs
  {
    name: "TR",
    fullform: "total_runs",
    category: 1,
  },
  {
    name: "AVR",
    fullform: "average_runs",
    category: 1,
  },
  {
    name: "10+",
    fullform: "10+_runs",
    category: 1,
  },
  {
    name: "15+",
    fullform: "15+_runs",
    category: 1,
  },
  {
    name: "20+",
    fullform: "20+_runs",
    category: 1,
  },
  {
    name: "25+",
    fullform: "25+_runs",
    category: 1,
  },
  {
    name: "30+",
    fullform: "30+_runs",
    category: 1,
  },
  {
    name: "40+",
    fullform: "40+_runs",
    category: 1,
  },
  {
    name: "50+",
    fullform: "50+_runs",
    category: 1,
  },
  {
    name: "lr",
    fullform: "lowest_runs",
    category: 1,
  },
  {
    name: "hr",
    fullform: "highest_runs",
    category: 1,
  },
  {
    name: "gp",
    fullform: "games_played",
    category: 1,
  },



  //fours
  {
    name: "tf",
    fullform: "total_fours",
    category: 2,
  },
  {
    name: "avf",
    fullform: "average_fours",
    category: 2,
  },
  {
    name: "1+",
    fullform: "1+_fours",
    category: 2,
  },
  {
    name: "2+",
    fullform: "2+_fours",
    category: 2,
  },
  {
    name: "3+",
    fullform: "3+_fours",
    category: 2,
  },
  {
    name: "4+",
    fullform: "4+_fours",
    category: 2,
  },
  {
    name: "gp",
    fullform: "games_played",
    category: 2,
  },



  //sixes
  {
    name: "ts",
    fullform: "total_sixes",
    category: 3,
  },
  {
    name: "avs",
    fullform: "average_sixes",
    category: 3,
  },
  {
    name: "1+",
    fullform: "1+_sixes",
    category: 3,
  },
  {
    name: "2+",
    fullform: "2+_sixes",
    category: 3,
  },
  {
    name: "3+",
    fullform: "3+_sixes",
    category: 3,
  },
  {
    name: "gp",
    fullform: "games_played",
    category: 3,
  },



  //wickets
  {
    name: "tw",
    fullform: "total_wickets",
    category: 4,
  },
  {
    name: "avw",
    fullform: "average_wickets",
    category: 4,
  },
  {
    name: "1+",
    fullform: "1+_wickets",
    category: 4,
  },
  {
    name: "2+",
    fullform: "2+_wickets",
    category: 4,
  },
  {
    name: "3+",
    fullform: "3+_wickets",
    category: 4,
  },
  {
    name: "lw",
    fullform: "lowest_wickets",
    category: 4,
  },
  {
    name: "hw",
    fullform: "highest_wickets",
    category: 4,
  },
  {
    name: "gp",
    fullform: "games_played",
    category: 4,
  },
  
];


export const extractKeyList = [

  // runs
  {
    name: "TR",
    key: "total",
    category: 1,
  },
  {
    name: "AVR",
    key: "avg",
    category: 1,
  },
  {
    name: "10+",
    key: "ten_plus",
    category: 1,
  },
  {
    name: "15+",
    key: "fiftheen_plus",
    category: 1,
  },
  {
    name: "20+",
    key: "twenty_plus",
    category: 1,
  },
  {
    name: "25+",
    key: "twentyfive_plus",
    category: 1,
  },
  {
    name: "30+",
    key: "thirty_plus",
    category: 1,
  },
  {
    name: "40+",
    key: "forty_plus",
    category: 1,
  },
  {
    name: "50+",
    key: "fifty_plus",
    category: 1,
  },
  {
    name: "lr",
    key: "low",
    category: 1,
  },
  {
    name: "hr",
    key: "high",
    category: 1,
  },
  {
    name: "gp",
    key: "gp",
    category: 1,
  },



  //fours
  {
    name: "tf",
    key: "total",
    category: 2,
  },
  {
    name: "avf",
    key: "avg",
    category: 2,
  },
  {
    name: "1+",
    key: "one_plus",
    category: 2,
  },
  {
    name: "2+",
    key: "two_plus",
    category: 2,
  },
  {
    name: "3+",
    key: "three_plus",
    category: 2,
  },
  {
    name: "4+",
    key: "four_plus",
    category: 2,
  },
  {
    name: "gp",
    key: "gp",
    category: 2,
  },



  //sixes
  {
    name: "ts",
    key: "total",
    category: 3,
  },
  {
    name: "avs",
    key: "avg",
    category: 3,
  },
  {
    name: "1+",
    key: "one_plus",
    category: 3,
  },
  {
    name: "2+",
    key: "two_plus",
    category: 3,
  },
  {
    name: "3+",
    key: "three_plus",
    category: 3,
  },
  {
    name: "gp",
    key: "gp",
    category: 3,
  },



  //wickets
  {
    name: "tw",
    key: "total",
    category: 4,
  },
  {
    name: "avw",
    key: "avg",
    category: 4,
  },
  {
    name: "1+",
    key: "one_plus",
    category: 4,
  },
  {
    name: "2+",
    key: "two_plus",
    category: 4,
  },
  {
    name: "3+",
    key: "three_plus",
    category: 4,
  },
  {
    name: "lw",
    key: "low",
    category: 4,
  },
  {
    name: "hw",
    key: "high",
    category: 4,
  },
  {
    name: "gp",
    key: "gp",
    category: 4,
  },
]


export const PLAYER_MATCH_LIST =[
  "opponent",
  "date",
  "runs",
  "fours",
  "sixes",
  "wickets"
]

export const TEAM_TABS =[
  {id:1,"title":"runs"},
  {id:2,"title":"fours"},
  {id:3,"title":"sixes"},
  {id:4,"title":"wickets"},
  {id:5,"title":"team scores"},
]

export function GetTeamImageByTeamId(teamid){
  return `https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/BBL_team_icons/${teamid}.png`
}