const AWS_Team =
  "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/nrl_teams/";
const AWSLINK = "/home/gearup2/Documents/DraftStartsScrap/AFL.json";

const BASE_URL = "https://apiv3.multihub.com.au/";
// const BASE_URL = "https://devapi.multihub.com.au/";
// const BASE_URL='http://192.168.0.105:8000/'

export async function GetTeamDetails(id,season) {
  const res = await fetch(`https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/${season}/nrl_teams/` + id + ".json", {
    method: "GET",
  });
  //console.log(res)
  var jsonData = await res.json();
  //console.log(jsonData)
  return jsonData;
}

export async function GetNRLPlayerDetail(id,season) {
  const res = await fetch(
    `https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/${season}/nrl_player_data/${id}.json`,
    {
      method: "GET",
    }
  );
  //console.log(res)
  var jsonData = await res.json();
  //console.log(jsonData)
  return jsonData;
}

export async function GetNRLTeams(season) {
  const res = await fetch(BASE_URL + "nrl/filter/team/?season="+season, {
    method: "GET",
  });
  //console.log(res)
  var jsonData = await res.json();
  //console.log(jsonData)
  return jsonData;
}

export async function GetNRLTeamPlayersList(teamid,season) {
  const res = await fetch(BASE_URL + "nrl/playerslist/?team=" + teamid+"&season="+season, {
    method: "GET",
  });
  //console.log(res)
  var jsonData = await res.json();
  //console.log(jsonData)
  return jsonData;
}

export async function AddNRLTeam(team_data) {
  const res = await fetch(BASE_URL + "nrl/team/", {
    method: "POST",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
    body: JSON.stringify(team_data),
  });
  //console.log(res)
  var jsonData = await res.json();
  //console.log(jsonData)
  return jsonData;
}

export async function GetNRLPlayer(player_id) {
  const res = await fetch(BASE_URL + "nrl/player/" + player_id, {
    method: "GET",
  });
  //console.log(res)
  var jsonData = await res.json();
  //console.log(jsonData)
  return jsonData;
}
