import React, { useEffect, useState } from "react";
import "../../utils/styles/appbar/appbar.scss";
import logo from "../../assets/logo.png";
import home from "../../assets/home.png";
import back_outline from "../../assets/back_outline.png";
import back from "../../assets/back.png";

function PageBar(props) {
  const [hoveredHome, setHoveredhome] = useState(false);
  const [hoveredback, setHoveredback] = useState(false);

  return (
    <div className="mb-2 mt-0 lg:my-4 xl:my-4 2xl:my-4">
      <nav className="">
        <div className="flex flex-1 align-center items-center">
          {/* {props.his.location.pathname.includes("sport") ? ( */}
            <img
              src={hoveredback ? back : back_outline}
              onMouseEnter={() => setHoveredback(true)}
              onMouseLeave={() => setHoveredback(false)}
              className="cursor-pointer text-4xl text-white font-bold text-center mx-0
                        md:mx-2 lg:mx-2 xl:mx-2 2xl:mx-2 uppercase h-12"
              onClick={() =>{
                if(props.isSportPage) props.his.push('/')
                else props.his.goBack()
                
              }}
            />
          {/* ) : (
            <img
              src={hoveredback ? back : back_outline}
              onMouseEnter={() => setHoveredback(true)}
              onMouseLeave={() => setHoveredback(false)}
              className="cursor-pointer text-4xl text-white font-bold text-center mx-0
                        md:mx-2 lg:mx-2 xl:mx-2 2xl:mx-2 uppercase h-8"
              onClick={() => props.his.goBack()}
            />
          )} */}
        </div>
      </nav>
    </div>
  );
}
export default PageBar;
