import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import AppBar from "../AppBar/AppBar";
import PageBar from "../AppBar/PageBar";
import AppLoader from "../Loader/Loader";
import "../../utils/styles/teamdetail/teamdetail.scss";
import { SponserListCom } from "../SponerList/SponserList";
import { GetNFLPlayerDetail } from "../../Busniess_Logic/API/NFLApi";
import CopyRights from "../CopyRights";
import AdSenseCom from "../AdSense/AdSense";
import { FormatDate } from "../Utils/Utils";

function NFLPlayerDetail(props) {
  const [called, setCalled] = useState(false);
  const [detail, setDetail] = useState(null);
  const [loader, setLoader] = useState(false);

  const [tc, setTc] = useState(null);
  const [hoverHeader, setHoverheader] = useState(null);

  const [touchdown, setTouchdown] = useState([]);
  const [touchdownPasses, setTouchdownpasses] = useState([]);
  const [rushingYards, setRushing] = useState([]);

  const [receivingYards, setReceiving] = useState([]);
  const [passingYards, setPassing] = useState([]);

  useEffect(() => {
    getplayerdetails();
  }, []);

  const getplayerdetails = async () => {

    setLoader(true);
    
    var surl = new URLSearchParams(window.location.href);
    var url = new URL(window.location.href);
    var c = url.searchParams.get("id");
    var season = surl.get('s')

    const res = await GetNFLPlayerDetail(c ? c:"3045147",season ? season : '2022');
    if (res) {
      setDetail(res);

      try {
        let touchdownList = res.matches.map((x) => parseInt(x.touchdown))
        if (touchdownList && !touchdownList.includes(NaN) && !touchdownList.every((x)=> x === 0))
        setTouchdown(calcPlayerData(touchdownList,"Touchdown",1,3))
      } catch (error) {
        console.log(error);
      }

      try {
        let touchdownPassesList = res.matches.map((x) => parseInt(x.passing_td))
        if (touchdownPassesList && !touchdownPassesList.includes(NaN) && !touchdownPassesList.every((x)=> x === 0))
        setTouchdownpasses(calcPlayerData(touchdownPassesList,"Touchdown Passes",1,5))
      } catch (error) {
        console.log(error);
      }

      try {
        let rushinglist = res.matches.map((x) => parseInt(x.rushing_yards))
        if (rushinglist && !rushinglist.includes(NaN) && !rushinglist.every((x)=> x === 0))
        setRushing(calcPlayerData(rushinglist,"Rushing Yards",25,120))
      } catch (error) {
        console.log(error);
      }

      try {
        let receivinglist = res.matches.map((x) => parseInt(x.receiving_yards)) 
        if (receivinglist && !receivinglist.includes(NaN) && !receivinglist.every((x)=> x === 0))
        setReceiving(calcPlayerData(receivinglist,"Receiving Yards",15,75))
      } catch (error) {
        console.log(error);
      }

      try {
        let passinglist = res.matches.map((x) => parseInt(x.passing_yards)) 
        if (passinglist && !passinglist.includes(NaN) && !passinglist.every((x)=> x === 0))
        setPassing(calcPlayerData(passinglist,"Passing Yards",150,350))
      } catch (error) {
        console.log(error);
      }

    }

    setCalled(true);
    setLoader(false);
    setTc(surl.get("tc") ? surl.get("tc") :"#cf0a2c");
  };

  const calcPlayerData=(matchlist,title,from,to)=>{
    try {

      let sorted = matchlist.sort((a,b) => a - b)
      if(sorted && title){

        let total = matchlist.map(x => x).reduce((a, b) => a + b, 0)
        let avg = (total / matchlist.length) .toFixed(2)
        let high = sorted[ sorted.length - 1 ]
        let low = sorted[ 0 ]

        let result = [ {"title":title,content:""},
          {"title":`Total ${title}`,"content":total},
          {"title":`Average ${title}`,"content": parseFloat(avg).toFixed(0) },
          {"title":`Highest ${title}`,"content":high},
          {"title":`Lowest ${title}`,"content":low},
        ]

        for (let index = from; index <= to; index++){
          result.push({ "title" : index+`+ ${title}`,"content":sorted.filter(x => x >= index).length+'/'+sorted.length }) 
        }

        return result

      } else return null
  
    } catch (error) {
      console.log(error);
      return null
    }
  }

  const playerDataTable = (tableData) => {
    return (
      <div className="rounded-md py-2 my-2">
        {tableData.map((item, index) => {
          if (index === 0)
            return (
              <div style={{ backgroundColor: tc, padding: "3%" }}>
                <div
                  className="rounded-tl-md uppercase w-full text-white text-left font-bold"
                  style={{ backgroundColor: tc }}
                >
                  {item.title}
                </div>
                <div className="rounded-tr-md w-1/2">{item.content}</div>
              </div>
            );
        })}

        <div className="overflow-y-auto" style={{ height: "40vh" }}>
          <table className="rounded-lg tb-shadow h-auto w-full text-black">
            {tableData.map((item, index) => {
              if (index > 0)
                return (
                  <tr className="">
                    <td className="capitalize text-center pl-4 w-1/2 py-1">
                      {item.title}
                    </td>
                    <td className="w-1/2">{item.content }</td>
                  </tr>
                );
            })}
          </table>
        </div>
      </div>
    );
  };

  const getTotalGames = () => {
    if(detail && detail.matches) return (detail.matches.length).toString()
    else return "0"
  }

  return (
    <div className="w-full">
      <AppBar his={props.history} />

      {loader && (
        <div style={{ position: "relative" }}>
          <AppLoader msg={"Getting player details"} />
        </div>
      )}

      {!loader && (
        <div
          className="player-detail-com w-full sm:w-full md:w-full lg:w-3/4 
                m-auto xl:w-3/4 2xl:w-3/4 p-3 xl:p-0 2xl:p-0 2xl:py-5 pb-8"
        >
          <div className="xl:flex 2x:flex xl:flex-1 2xl:flex-1">

            {
              detail!=null && detail!=undefined ? 
              <div className="mx-1 w-full xl:w-3/4 2xl:w-4/5"
              // className='player-detail-com w-full sm:w-full md:w-full lg:w-3/5 m-auto xl:w-3/5 2xl:w-3/5 p-3 xl:p-0 2xl:p-0 2xl:py-5 pb-8'
            >

              <AdSenseCom />
              <PageBar his={props.history} />

              <div
                className="team-container p-4 rounded-lg border border-grey-100 shadow-md flex flex-1 flex-row items-center"
                style={{ backgroundColor: tc }}
              >
                <div className="team-name w-full">
                  <h1 className="text-3xl font-bold text-white">
                    {detail.name ? detail.name : ''}
                  </h1>
                </div>
              </div>

              <div className="my-2 p-3 rounded-lg shadow-md border border-grey-100 flex flex-1 justify-around">
                <p className="text-left px-2">Total games played</p>
                <p className="text-left px-2">
                  <strong>{getTotalGames()}</strong>
                </p>
              </div>
              
              {detail.matches && detail.matches.length != 0 ? (
                <div className="w-full">
                  <div
                    className="rounded-lg shadow-xl items-center my-4 top-tb-container"
                    style={{ position: "relative" }}
                  >
                    <table className="p-2 top-table text-base xl:text-sm 2xl:text-lg bg-white text-black">
                     
                      <tr>
                        <th
                          className="rounded-tl-md text-white p-2 capitalize"
                          style={{ backgroundColor: tc }}
                        >
                          played for
                        </th>

                        {detail.matches.map((item, index) => {
                          return (
                            <td
                              className="text-md top-tb-data"
                              // onMouseEnter={() =>
                              //   setHoverheader(item.home_team.abbreviation)
                              // }
                              // onMouseLeave={() => setHoverheader(null)}
                            >
                              <div className="tool-tip-con">
                                <strong>{item.player_abv}</strong>
                                <p className="tool-tip-data">
                                  {item.player_for}
                                </p>
                              </div>
                            </td>
                          );
                        })}
                      </tr>

                      <tr>
                        <th
                          className="text-white p-2 capitalize"
                          style={{ backgroundColor: tc }}
                        >
                          Opponent
                        </th>
                        {detail.matches.map((item, index) => {
                          return (
                            <td
                              className="text-md top-tb-data"
                              // onMouseEnter={() =>
                              //   // setHoverheader(item.home_team.abbreviation)
                              // }
                              // onMouseLeave={() => setHoverheader(null)}
                            >
                              <div className="tool-tip-con">
                                <strong>{item.opp_abv}</strong>
                                <p className="tool-tip-data">
                                  {item.opp_name}
                                </p>
                              </div>
                            </td>
                          );
                        })}
                      </tr>

                      <tr>
                        <th
                          className="text-white p-2 capitalize"
                          style={{ backgroundColor: tc }}
                        >
                          date
                        </th>
                        {detail.matches.map((item, index) => {

                          return (
                            <td className="text-md top-tb-data">
                              <div className="tool-tip-con">
                                <strong>{FormatDate(item.date)}</strong>
                              </div>
                            </td>
                          );
                        })}
                      </tr>

                      <tr>
                        <th
                          className="text-white p-2 capitalize"
                          style={{ backgroundColor: tc }}
                        >
                          touchdown
                        </th>
                        {detail.matches.map((item, index) => {
                          
                          return (
                            <td className="text-md top-tb-data">
                              <div className="tool-tip-con">
                                <strong>{item.touchdown ? item.touchdown : 0}</strong>
                              </div>
                            </td>
                          );
                        })}
                      </tr>

                      <tr>
                        <th
                          className="text-white p-2 capitalize"
                          style={{ backgroundColor: tc }}
                        >
                          touchdowns passes
                        </th>
                        {detail.matches.map((item, index) => {
                          
                          return (
                            <td className="text-md top-tb-data">
                              <div className="tool-tip-con">
                                <strong>{item.passing_td ? item.passing_td : 0}</strong>
                              </div>
                            </td>
                          );
                        })}
                      </tr>

                      <tr>
                        <th
                          className="text-white p-2 capitalize"
                          style={{ backgroundColor: tc }}
                        >
                          rushing yards
                        </th>
                        {detail.matches.map((item, index) => {
                          
                          return (
                            <td className="text-md top-tb-data">
                              <div className="tool-tip-con">
                                <strong>{item.rushing_yards ? item.rushing_yards : 0}</strong>
                              </div>
                            </td>
                          );
                        })}
                      </tr>

                      <tr>
                        <th
                          className="text-white p-2 capitalize"
                          style={{ backgroundColor: tc }}
                        >
                          receiving yards
                        </th>
                        {detail.matches.map((item, index) => {
                          
                          return (
                            <td className="text-md top-tb-data">
                              <div className="tool-tip-con">
                                <strong>{item.receiving_yards ? item.receiving_yards : 0}</strong>
                              </div>
                            </td>
                          );
                        })}
                      </tr>

                      <tr>
                        <th
                          className="text-white p-2 capitalize"
                          style={{ backgroundColor: tc }}
                        >
                          passing yards
                        </th>
                        {detail.matches.map((item, index) => {
                          
                          return (
                            <td className="text-md top-tb-data">
                              <div className="tool-tip-con">
                                <strong>{item.passing_yards ? item.passing_yards : 0}</strong>
                              </div>
                            </td>
                          );
                        })}
                      </tr>

                    </table>
                  </div>

                  <div
                    className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3 
                    gap-2 items-start justify-between w-full data-table-con nba-player-data-table"
                  >
                    {touchdown && touchdown.length!== 0 && playerDataTable(touchdown)}
                    {touchdownPasses && touchdownPasses.length !==0 && playerDataTable(touchdownPasses)}
                    {rushingYards && rushingYards.length!== 0 && playerDataTable(rushingYards)}
                    {receivingYards && receivingYards.length !==0 && playerDataTable(receivingYards)}
                    {passingYards && passingYards.length !== 0 && playerDataTable(passingYards)}
                  </div>
                </div>
              ):
              (
                <div>
                    <p className="text-white py-3 text-center">No games played</p>
                </div>
              )
              }
            </div>
            :
            <div className='mx-1 w-full xl:w-3/4 2xl:w-4/5 m-auto'>
                <p className="text-white py-5 text-center">Data not found</p>
            </div>
            }
            
            <div className="mt-14 xl:w-1/4 2xl:w-1/5">
              <SponserListCom />
            </div>
          </div>
        </div>
      )}

      { !loader && <CopyRights his={props.history}/> }

    </div>
  );
}

export default withRouter(NFLPlayerDetail);
