const BucketName = "fantasy-sports-data"

const AWS_Team = `https://${BucketName}.s3.ap-southeast-2.amazonaws.com/nba_team_data/`;
const AWSLINK = "/home/gearup2/Documents/DraftStartsScrap/AFL.json";

const BASE_URL = "https://apiv3.multihub.com.au/";
// const BASE_URL = "https://devapi.multihub.com.au/";

export async function GetTeamDetails(id, season) {
  const res = await fetch(
    `https://${BucketName}.s3.ap-southeast-2.amazonaws.com/${season}/nba_team_data/${id}.json`,
    {
      method: "GET",
    }
  );
  //console.log(res);
  var jsonData = await res.json();
  //console.log(jsonData);
  return jsonData;
}

export async function GetNBAPlayerDetail(id, season) {
  const res = await fetch(
    `https://${BucketName}.s3.ap-southeast-2.amazonaws.com/${season}/nba_player_data/${id}.json`,
    {
      method: "GET",
    }
  );
  //console.log(res);
  var jsonData = await res.json();
  //console.log(jsonData);
  return jsonData;
}

export async function GetNBATeams(season) {
  console.log(BASE_URL + "nba/filter/team/?season=" + season.slice(0, 4));
  const res = await fetch(
    BASE_URL + "nba/filter/team/?season=" + season.slice(0, 4),
    {
      method: "GET",
    }
  );
  //console.log(res);
  var jsonData = await res.json();
  //console.log(jsonData);
  return jsonData;
}

export async function GetNBATeamPlayersList(teamid, season) {
  console.log(
    BASE_URL +
      "nba/player_list?team_id=" +
      teamid +
      "&season=" +
      season.slice(0, 4)
  );
  const res = await fetch(
    BASE_URL +
      "nba/player_list?team_id=" +
      teamid +
      "&season=" +
      season.slice(0, 4),
    {
      method: "GET",
    }
  );
  //console.log(res);
  var jsonData = await res.json();
  //console.log(jsonData);
  return jsonData;
}

export async function AddNBATeam(team_data) {
  const res = await fetch(BASE_URL + "nba/team/", {
    method: "POST",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
    body: JSON.stringify(team_data),
  });
  //console.log(res);
  var jsonData = await res.json();
  //console.log(jsonData);
  return jsonData;
}

export async function GetNBAPlayer(player_id) {
  const res = await fetch(BASE_URL + "nba/playerlist/?player_id=" + player_id, {
    method: "GET",
  });
  //console.log(res);
  var jsonData = await res.json();
  //console.log(jsonData);
  return jsonData;
}

// NEW APIS

// NBA Teams
// export async function GetNBATeams(season){
//   try{
//     const res = await fetch(`${BASE_URL}new/team/?season=${season.slice(0,4)}`)
//     return await res.json()
//   }catch(error){
//     console.log(error)
//     return []
//   }
// }

// NBA Players by selected Team ID
export async function GetNBAPlayers(teamid, season) {
  try {
    const res = await fetch(
      `${BASE_URL}filter/newplayers/?id=${teamid}&season=${season.slice(0, 4)}`
    );
    return await res.json();
  } catch (error) {
    console.log(error);
    return [];
  }
}
