export const matchList = [
  {
    round1: "4th",
    id: 1,
    round2: "3th",
    date: "Friday, Auguest 20,2021",
    location: "MRVL",
    img1: "https://firebasestorage.googleapis.com/v0/b/check--the--punt--dev.appspot.com/o/afl%2FWestern Bulldogs.png?alt=media",
    img2: "https://firebasestorage.googleapis.com/v0/b/check--the--punt--dev.appspot.com/o/afl%2FPort Adelaide.png?alt=media",
    time: "7:50 PM",
  },
  {
    round1: "11th",
    round2: "14th",
    id: 2,
    date: "Saturday, Auguest 21,2021",
    location: "MCG",
    img1: "https://firebasestorage.googleapis.com/v0/b/check--the--punt--dev.appspot.com/o/afl%2FRichmond.png?alt=media",
    img2: "https://firebasestorage.googleapis.com/v0/b/check--the--punt--dev.appspot.com/o/afl%2FHawthorn.png?alt=media",
    time: "1:35 PM",
  },
  {
    round1: "17th",
    round2: "15th",
    id: 3,
    date: "Saturday, Auguest 21,2021",
    location: "MRVL",
    img1: "https://firebasestorage.googleapis.com/v0/b/check--the--punt--dev.appspot.com/o/afl%2FAdelaide.png?alt=media",
    img2: "https://firebasestorage.googleapis.com/v0/b/check--the--punt--dev.appspot.com/o/afl%2FGold Coast.png?alt=media",
    time: "1:45 PM",
  },
];

export const teamList = [
  {
    name: "Adelaide",
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/check--the--punt--dev.appspot.com/o/afl%2FAdelaide.png?alt=media",
  },
  {
    name: "Carlton",
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/check--the--punt--dev.appspot.com/o/afl%2FWestern Bulldogs.png?alt=media",
  },
  {
    name: "Brisbane",
    id: 3,
    img: "https://firebasestorage.googleapis.com/v0/b/check--the--punt--dev.appspot.com/o/afl%2FPort Adelaide.png?alt=media",
  },
];

export const ColorList = [
  "#004b8d",
  "#001e2f",
  "#8b0042",
  "#331c54",
  "#d72e24",
  "#002244",
  "#061a33",
];

export const MenuList = [
  {
    id: 1,
    svg: "afl",
    name: "AFL",
    url:'afl'
  },
  {
    id: 2,
    svg: "nrl",
    name: "NRL",
    url:'nrl'
  },
  {
    id: 3,
    svg: "basketball",
    name: "NBA",
    url:'nba'
  },
  {
    id: 4,
    svg: "football",
    name: "EPL",
    url:'epl'
  },
  {
    id: 5,
    svg: "us_football",
    name: "NFL",
    url:'nfl'
  },
  {
    id: 6,
    svg: "cricket",
    name: "BBL",
    url:'bbl'
  },
  {
    id: 7,
    svg: "mbl",
    name: "MLB",
  },
  
];

export const SponserList = [

  {
    id: 1,
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/sponser_data/neds.png",
    bgcolor: " neds",
    content:
      "We are a passionate team of computer nerds, sports enthusiasts, statisticians, bookies and digital wizards",
    url: "https://multihub.com.au/neds250",
  },
  {
    id: 2,
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/sponser_data/labbrokes.png",
    bgcolor: " ladbrokes",
    content:
      "sign in and score $100. double your winnings in bonus bets on R5.*",
    url: "https://multihub.com.au/ladbrokes250",
  },
  {
    id: 3,
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/sponser_data/bethere/betr.jpg",
    bgcolor: "betr",
    content: "your best bet is to join playup today-austraila's best bookie",
    url: "https://aus01.safelinks.protection.outlook.com/?url=https%3A%2F%2Frecord.betraffiliates.com.au%2F_OYeaPWW-7QEOMRDMpvVHzmNd7ZgqdRLk%2F1%2F&data=05%7C01%7Cmichael.liston%40betr.com.au%7C08c0bda19f8f4072a2c608dac2bb048c%7Cdda3f2f6de5d4b688a9754b9fc911ef4%7C0%7C0%7C638036407159279140%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C1000%7C%7C%7C&sdata=U2Nu0ukCdX%2BhgGuQPIO%2BWwzexTvCI7sykyXFIQnyOc0%3D&reserved=0",
  },
  {
    id: 4,
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/sponser_data/playup.png",
    bgcolor: " playup",
    content: "your best bet is to join playup today-austraila's best bookie",
    url: "https://multihub.com.au/playup100",
  },
  {
    id: 5,
    image: "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/sponser_data/logo-draftstars.svg",
    bgcolor: " draftstarin",
    content:
      "Unibet is part of the Kindred Group, one of the largest online gambling companies in the world.",
    url: "https://play.draftstars.com.au/lobby/american-football?modal=signup&Referrer=0IG28RBRC4Z7g7kjLwWOl2Nd7ZgqdRLk&",
  },
  {
    id: 6,
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/sponser_data/boombet.jpeg",
    bgcolor: " boombet",
    content:
      "Unibet is part of the Kindred Group, one of the largest online gambling companies in the world.",
    url: "https://multihub.com.au/boombet100",
  },
  {
    id: 7,
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/sponser_data/Unibet+new+logo+large1.svg",
    bgcolor: " unibet",
    content:
      "Unibet is part of the Kindred Group, one of the largest online gambling companies in the world.",
    url: "https://multihub.com.au/unibet100",
  },
  
];


export const PuntingList = [
  {
    id: 1,
    image:
      "../../assets/Facebook.png",
    bgcolor: "#2a63ad",
    border: "transparent",
    header:"Multi Hub Punting Group",
    content: "Free To Join Click Here",
    mbl_url:'https://m.facebook.com/groups/604338443451503/?ref=bookmarks',
    pc_url: "https://www.facebook.com/groups/604338443451503",
  },
  {
    id: 2,
    image:
      "../../assets/Facebook.png",
    bgcolor: "#2a63ad",
    border: "transparent",
    header:"Crypto Facebook Group",
    content: "Free To Join Click Here",
    mbl_url:'https://m.facebook.com/groups/1823740884451504/?ref=bookmarks',
    pc_url: "https://www.facebook.com/groups/1823740884451504",
  },
  {
    id: 3,
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/sponser_data/logo_white.png",
    bgcolor: "transparent",
    border: "#60a5fa",
    header:"Join & Get $10 Bitcoin",
    content: "Free To Join Click Here",
    mbl_url:'https://trade.swyftx.com.au/register/?ref=CryptoYoutubers',
    pc_url: "https://trade.swyftx.com.au/register/?ref=CryptoYoutubers",
  }
]

