function CopyRights(props) {
  return (
    <footer
      className="w-full sm:w-full md:w-full m-auto xl:w-9/12 p-3 
          xl:p-0 2xl:p-0 2xl:py-5 h-auto hidden xl:block"
    >
      <p className="w-full text-sm text-left opacity-40 leading-loose">
        Multi Hub is not affiliated with AFL, NRL, NBA, EPL, NFL, BBL and MLB.
        <br />
        All company, products, logos and service names used in this website
        belong to their respective owners and are used for identification
        purposes only.
        <br />
        Copyright &#9400;2022 Multi Hub - All Rights Reserved.{" "}
        <a onClick={()=> props.his.push("/privacy-policy")} className='capitalize underline cursor-pointer'>privacy & policy</a>
      </p>

      

      <div className="flex felx-auto items-center justify-start align-middle">
        <p className="w-auto text-sm text-left opacity-40 leading-loose">
        {/* This page is licensed under Creative Commons by  */}
        Designed & Developed by Gearup Studios
        </p>  
        <a href="https://www.gearupstudios.com/" target={"_blank"}
        className="underline opacity-60 w-auto mx-1 text-sm text-left leading-loose">Gearup Studios</a> 
      </div>
      
    </footer>
  );
};
export default CopyRights