import { useEffect } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "./App.css";
import { GetSeasonList_Api } from "./Busniess_Logic/API/AccountApi";
import NewHome from "./NewHome";
import {
  Betr,
  Boombet,
  Drafstars,
  Ladbrokes,
  Neds,
  Playup,
  Unibet,
} from "./Redirects/Redirects";
import PlayerDetail from "./UI/AFL/PlayerDetail";
import TeamDetail from "./UI/AFL/TeamDetail";
import AdminDashboard from "./UI/AdminDashboard";
import BBLPlayerDetail from "./UI/BBL/BBLPlayerDetail";
import BBLTeam from "./UI/BBL/BBLTeam";
import { BetHere } from "./UI/BetHere";
import Bookiesoffers from "./UI/BookieList/BookieList";
import EPLPlayerDetail from "./UI/EPL/EPLPlayerDetail";
import EPLTeam from "./UI/EPL/EPLTeam";
import Home from "./UI/Home";
import NBAPlayerDetail from "./UI/NBA/NBAPlayerDetail";
import NBATeam from "./UI/NBA/NBATeam";
import NFLPlayerDetail from "./UI/NFL/NFLPlayerDetail";
import NFLTeam from "./UI/NFL/NFLTeam";
import NRLPlayerDetail from "./UI/NRL/NRLPlayerDetail";
import NRLTeam from "./UI/NRL/NRLTeam";
import PrivacyPolicy from "./UI/PrivacyPolicy/PrivacyPolicy";
import Profile from "./UI/Profile";
import RegisterPrivacy from "./UI/RegisterPrivacy";
import RegisterTerms from "./UI/RegisterTerms";
import ResetPassword from "./UI/ResetPassword";
import { TermsOfUse } from "./UI/TermsOfUse/TermsOfUse";
import { TipsScreen } from "./UI/TipsScreen/TipsScreen";
import AppRedirects from "./AppRedirects/AppRedirects";
import {
  SEASON_LIST,
  clearSeasonList,
  getSeasonList,
  setSeasonList,
} from "./UI/Utils/SeasonList";
import VerifyAccount from "./UI/VerifyAccount";
import history from "./history";

function App() {
  window.addEventListener("beforeunload", () => clearSeasonList());

  useEffect(() => {
    async function getSeasons() {
      let local_season_list = getSeasonList();
      if (!local_season_list) {
        try {
          const res = await GetSeasonList_Api();
          if (res) setSeasonList(res);
        } catch (error) {
          setSeasonList(SEASON_LIST);
          console.log(error);
        }
      }
    }

    getSeasons();
  }, []);

  return (
    <div className="App">
      <Router history={history}>
        <Switch>
          <Route exact path="/">
            <NewHome props={history} />
          </Route>

          <Route exact path="/sport">
            <Home props={history} />
          </Route>

          {/* afl teams */}
          <Route exact path="/team">
            <TeamDetail props={history} />
          </Route>

          <Route exact path="/redirectapp">
            <AppRedirects />
          </Route>


          <Route exact path="/player">
            <PlayerDetail props={history} />
          </Route>

          {/* nrl teams */}
          <Route exact path="/nrlteam">
            <NRLTeam props={history} />
          </Route>

          <Route exact path="/nrlplayer">
            <NRLPlayerDetail props={history} />
          </Route>

          {/* nba teams */}
          <Route exact path="/nbateam">
            <NBATeam props={history} />
          </Route>

          <Route exact path="/nbaplayer">
            <NBAPlayerDetail props={history} />
          </Route>

          {/* epl teams */}
          <Route exact path="/eplteam">
            <EPLTeam props={history} />
          </Route>

          <Route exact path="/eplplayer">
            <EPLPlayerDetail props={history} />
          </Route>

          {/* nfl teams */}
          <Route exact path="/nflteam">
            <NFLTeam props={history} />
          </Route>

          <Route exact path="/nflplayer">
            <NFLPlayerDetail props={history} />
          </Route>

          {/* bbl teams */}
          <Route exact path="/bblteam">
            <BBLTeam props={history} />
          </Route>

          <Route exact path="/bblplayer">
            <BBLPlayerDetail props={history} />
          </Route>

          <Route exact path="/privacy-policy">
            <PrivacyPolicy props={history} />
          </Route>

          <Route exact path="/draftstars">
            <Drafstars />
          </Route>

          <Route exact path="/boombet100">
            <Boombet />
          </Route>

         

          <Route exact path="/ladbrokes250">
            <Ladbrokes />
          </Route>

          <Route exact path="/betr">
            <Betr />
          </Route>

          <Route exact path="/neds250">
            <Neds />
          </Route>

          <Route exact path="/playup100">
            <Playup />
          </Route>

          <Route exact path="/unibet100">
            <Unibet />
          </Route>

          <Route exact path="/bookiesoffers">
            <Bookiesoffers />
          </Route>

          <Route exact path="/reset-password">
            <ResetPassword />
          </Route>

          <Route exact path="/dashboard">
            <AdminDashboard />
          </Route>

          <Route exact path="/verify-account">
            <VerifyAccount />
          </Route>

          <Route exact path="/register-terms">
            <RegisterTerms />
          </Route>

          <Route exact path="/register-privacy">
            <RegisterPrivacy />
          </Route>

          <Route exact path="/profile">
            <Profile />
          </Route>

          <Route exact path="/bethere">
            <BetHere />
          </Route>

          <Route exact path="/tips">
            <TipsScreen />
          </Route>

          <Route exact path="/termsofuse">
            <TermsOfUse />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
