import { Formik } from "formik";
import React, { useState } from "react";
import {
  AiOutlineMail,
  AiOutlineKey,
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineUser,
  AiOutlinePhone
} from "react-icons/ai";
import * as Yup from "yup";
import Loader from "react-loader-spinner";

function SignUpForm(props) {

  const [show_password, setpassword] = useState(false);
  const [show_confirmpassword,setconfirmpassword] = useState(false)

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const passwordRegExp =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

  const SignupSchema = Yup.object().shape({
    fullname: Yup.string()
      .min(5, "Too Short!")
      .max(70, "Too Long!")
      .required("Required"),

    email: Yup.string().email("Invalid email").required("Required"),

    phonenumber: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Required"),

    password: Yup.string()
      .min(8, "Too Short!")
      .matches(
        passwordRegExp,
        "Password should be contain uppercase, lowercase, alphanumeric and special characters"
      )
      .required("Required"),

    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords does not match")
      .required("Required"),

    agreeTc: Yup.bool().oneOf(
      [true],
      "You must accept the terms and conditions"
    ),
  });

  const ErrorComponent = (error) => {
    return <p className="text-red-400 text-left text-xs my-2">{error}</p>;
  };

  return (
    <div>
      <Formik
        initialValues={{
            fullname: "",
            email: "",
            phonenumber: "",
            password: "",
            confirm_password: "",
            agreeTC: false,
          }}
          onSubmit={(values, { resetForm }) => {
            console.log(values);
            props.onSignupSubmit(values);
          }}
          validationSchema={SignupSchema}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          errors,
          touched,
        }) => (
          <div>

            {/* fullname */}
            <div className="my-5">
              <div className="flex flex-row justify-between items-center border border-gray-500 rounded-md px-2">
                <AiOutlineUser color="white" size={"20"} />
                <input
                  type="text"
                  className="bg-transparent p-2 text-md focus:outline-none ml-1 w-full"
                  placeholder="Full Name"
                  onChange={handleChange("fullname")}
                  onBlur={handleBlur("fullname")}
                />
              </div>
              {errors.fullname &&
                touched.fullname &&
                ErrorComponent(errors.fullname)}
            </div>


            {/* email */}
            <div className="my-5">
              <div className="flex flex-row justify-between items-center border border-gray-500 rounded-md px-2">
                <AiOutlineMail color="white" size={"20"} />
                <input
                  type="email"
                  className="bg-transparent p-2 text-md focus:outline-none ml-1 w-full"
                  placeholder="Email"
                  onChange={handleChange("email")}
                  onBlur={handleBlur("email")}
                />
              </div>
              {errors.email &&
                touched.email &&
                ErrorComponent(errors.email)}
            </div>


            {/* phone */}
            <div className="my-5">
              <div className="flex flex-row justify-between items-center border border-gray-500 rounded-md px-2">
                <AiOutlinePhone color="white" size={"20"} />
                <input
                  type="tel"
                  className="bg-transparent p-2 text-md focus:outline-none ml-1 w-full"
                  placeholder="Mobile Number"
                  onChange={handleChange("phonenumber")}
                  onBlur={handleBlur("phonenumber")}
                />
              </div>
              {errors.phonenumber &&
                touched.phonenumber &&
                ErrorComponent(errors.phonenumber)}
            </div>

            {/* password */}
            <div className="my-5">
              <div className="flex flex-row justify-between items-center border border-gray-500 rounded-md px-2 ">
                <AiOutlineKey color="white" size={"20"} />
                <input
                  type={!show_password ? "password" : "text"}
                  className="bg-transparent p-2 text-md focus:outline-none ml-1 w-full"
                  placeholder="Password"
                  onChange={handleChange("password")}
                  onBlur={handleBlur("password")}
                />

                {values.password !== "" && (
                  <div className="cursor-pointer">
                    {show_password ? (
                      <AiOutlineEye
                        color="white"
                        size={"15"}
                        onClick={() => setpassword(!show_password)}
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        color="white"
                        size={"15"}
                        onClick={() => setpassword(!show_password)}
                      />
                    )}
                  </div>
                )}
              </div>
              {errors.password &&
                touched.password &&
                ErrorComponent(errors.password)}
            </div>


            {/* confirm password */}
            <div className="my-5">
              <div className="flex flex-row justify-between items-center border border-gray-500 rounded-md px-2 ">
                <AiOutlineKey color="white" size={"20"} />
                <input
                  type={!show_confirmpassword ? "password" : "text"}
                  className="bg-transparent p-2 text-md focus:outline-none ml-1 w-full"
                  placeholder="Confirm Password"
                  onChange={handleChange("confirm_password")}
                  onBlur={handleBlur("confirm_password")}
                />

                {values.confirm_password !== "" && (
                  <div className="cursor-pointer">
                    {show_confirmpassword ? (
                      <AiOutlineEye
                        color="white"
                        size={"15"}
                        onClick={() => setconfirmpassword(!show_confirmpassword)}
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        color="white"
                        size={"15"}
                        onClick={() => setconfirmpassword(!show_confirmpassword)}
                      />
                    )}
                  </div>
                )}
              </div>
              {errors.confirm_password &&
                touched.confirm_password &&
                ErrorComponent(errors.confirm_password)}
            </div>

            {/* signup button */}
            <button
              type="submit"
              className="flex primary-bg-color w-full py-2 mt-5 rounded-md flex-1 justify-center items-center"
              onClick={handleSubmit}
              disabled={props.isLoading}
            >
              {
                props.isLoading && <Loader
                  type="Oval"
                  color="white"
                  height={20}
                  width={20}
                />
              }
              <p className="ml-1">{props.isLoading ? ` Creating account..` : `Create FREE Account`}</p>
            </button>

            {/* login button */}
            <button
              type="submit"
              className="block bg-white my-3 w-full py-2 rounded-md"
              onClick={props.onChangeForm}
              disabled={props.isLoading}
            >
              <p className="text-black">Joined? Log In </p>
            </button>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default SignUpForm;
