import React, { useEffect, useState } from "react";
import '../../utils/styles/table/table.scss'
import { EPLLengendList } from "./EPL_Constant";

const greeen = '#26e169';
const mediumGreeen = '#73ec9e';
const lightGreeen = '#c1f6d4';

function EPLPlayerTable(props) {

    let url = new URL(window.location.href);
    let headtohead = url.searchParams.get("hth");
    let season = url.searchParams.get("s")? url.searchParams.get("s") : '2021';

    const [cat, setCat] = useState(null)
    const [sortedth, setSortedth] = useState(null)
    const [playerList, setPlayerlist] = useState([])

    const [onHover, setHover] = useState(false)
    const [hoverkey, setHoverkey] = useState(null)

    useEffect(() => {

        setCat(props.cat)

        if (props.cat == 1) {
            sortByList('total_goals', props.player_list)
        } else if (props.cat == 2) {
            sortByList('total_shots_on_target', props.player_list)
        } 
        else if(props.cat === 3) {
            setPlayerlist(props.player_list)
        } 

    }, [props.cat])

    const ViewPlayer = (item) => {
        props.his.push('/eplplayer?id=' + item + '&tc=' + props.bgcolor+'&s='+season)
    }

    // ascending and descending
    const compareByAsc = (category, key) => {
        return function (a, b) {
            if (a[category] !== null && a[category][key] !== null && b[category] !== null && b[category][key] !== null) {
                if (a[category][key] < b[category][key]) return -1;
            } else return 0

            if (a[category] !== null && a[category][key] !== null && b[category] !== null && b[category][key] !== null) {
                if (a[category][key] > b[category][key]) return 1
            } else return 0;
            return 0;
        };
    }

    const compareByDesc = (category, key) => {
        return function (a, b) {
            if (a[category] !== null && a[category][key] !== null && b[category] !== null && b[category][key] !== null) {
                if (a[category][key] < b[category][key]) return 1;
            } else return 0

            if (a[category] !== null && a[category][key] !== null && b[category] !== null && b[category][key] !== null) {
                if (a[category][key] > b[category][key]) return -1;
            } else return 0
            return 0;
        };
    }

    const checkKey = (key) => {

        if (key === 'player') return 'name'
        else if (key === '#') return 'player_number'
        else if (key === 'gp') return 'games_played'

        if (cat === 1) 
            key = EPLLengendList.slice(0,10).find(x => x.name === key).fullform
        else if (cat === 2) 
            key = EPLLengendList.slice(11,19).find(x => x.name === key).fullform

        return key
    }

    const sortBy = (key) => {

        setSortedth(key)

        key = checkKey(key)
        //console.log('keys',key)
        var categoryName = (props.cat === 1 ? 'goal' :'target_shots')

        var nullRemoveList = []
        var nullList = []
        playerList.forEach(item => {
            if (item.goal && item.target_shots) nullRemoveList.push(item)
            else nullList.push(item)
        })


        let arrayCopy = [...nullRemoveList];
        const arrInStr = JSON.stringify(arrayCopy);
        //console.log(arrInStr.length)
        if (key === 'name' || key === 'player_number') arrayCopy.sort(compareByAscNameIndex(key))
        else arrayCopy.sort(compareByDesc(categoryName, key));
        const arrInStr1 = JSON.stringify(arrayCopy);

        if (arrInStr === arrInStr1) {
            if (key === 'name' || key === 'player_number') arrayCopy.sort(compareByDescNameIndex(key))
            else arrayCopy.sort(compareByAsc(categoryName, key));
        }

        var alllist = arrayCopy.concat(nullList)
        //console.log(arrayCopy.length, nullList.length)
        setPlayerlist(alllist)
    }

    const sortByList = (key, list) => {
        var nullRemoveList = []
        var nullList = []
        // key = checkKey(key)

        if(list !=null && list!=undefined){
            list.forEach(item => {
                if (item.goal && item.target_shots) nullRemoveList.push(item)
                else nullList.push(item)
            })
    
            var categoryName = (props.cat === 1 ? 'goal' :'target_shots')
            let arrayCopy = [...nullRemoveList];
    
            arrayCopy.sort(compareByDesc(categoryName, key));
    
            var alllist = arrayCopy.concat(nullList)
            //console.log(arrayCopy)
            setPlayerlist(alllist)
        }
        
    }



    const compareByAscNameIndex = (key) => {
        if (key === 'player_id')
            return function (a, b) {
                if (parseInt(a[key]) > parseInt(b[key])) return 1;
                if (parseInt(a[key]) < parseInt(b[key])) return -1;
                return 0;
            };
        else return function (a, b) {
            if (a[key] > b[key]) return 1;
            if (a[key] < b[key]) return -1;
            return 0;
        };
    }

    const compareByDescNameIndex = (key) => {
        if (key === 'player_id')
            return function (a, b) {
                if (parseInt(a[key]) < parseInt(b[key])) return 1;
                if (parseInt(a[key]) > parseInt(b[key])) return -1;
                return 0;
            };
        else
            return function (a, b) {
                if (a[key] < b[key]) return 1;
                if (a[key] > b[key]) return -1;
                return 0;
            };
    }

    const colorBy = (key, value) => {

        if (value === 0.0 || value === 0 || value === null) return ' '
        else if (value !== null && key !== 'margin') {

            var nullRemoveList = []
            playerList.forEach(item => {
                if (item.goal && item.target_shots) nullRemoveList.push(item)
            })

            let arrayCopy = [...nullRemoveList];
            const arrInStr = JSON.stringify(arrayCopy);

            var categoryName = (props.cat === 1 ? 'goal' :'target_shots')
            var max = arrayCopy.reduce((prev, current) => prev[categoryName][key] > current[categoryName][key] ? prev : current)

            if(key.includes('average')) { if ( parseFloat(max[categoryName][key])  === parseFloat(value) ) return greeen }
            else { if (max[categoryName][key] === parseInt(value)) return greeen }

            var maxIndex = arrayCopy.indexOf(max)

            if (maxIndex > -1) {
                arrayCopy.splice(maxIndex, 1);
            }

            var max2 = arrayCopy.reduce((prev, current) => (prev[categoryName][key] > current[categoryName][key] ? prev : current))

            if(key.includes('average')) { if ( parseFloat(max2[categoryName][key])  === parseFloat(value) ) return mediumGreeen }
            else { if (max2[categoryName][key] === parseInt(value)) return mediumGreeen }
            

            var maxIndex2 = arrayCopy.indexOf(max2)

            if (maxIndex2 > -1) {
                arrayCopy.splice(maxIndex2, 1);
            }

            var max3 = arrayCopy.reduce((prev, current) => (prev[categoryName][key] > current[categoryName][key] ? prev : current))
            // //console.log('total assists',max3[categoryName][key] === value)    
            if(key.includes('average')) { if ( parseFloat(max3[categoryName][key])  === parseFloat(value) ) return lightGreeen }
            else { if (max3[categoryName][key] === parseInt(value)) return lightGreeen }        
        }
    }

       
    const colorByValues = (key, value) => {
        if (value === 0 || value === null) return ''
        else if (value !== null && key != 'margin') {
            // key=checkKey(key)
            if (key === 'counts') {
                if ( value >= 35) return greeen;
                else if (value >= 25 && value <= 34.99) return mediumGreeen;
                else if (value >= 20 && value <= 24.99) return lightGreeen;
            }
            if (key === 'hg' || key==='hst') {
                if ( value >= 3) return greeen;
                else if (value === 2) return mediumGreeen;
                else if (value === 1) return lightGreeen;
            }

        }
        
    }

    const onMouseEnter = (key) => {
        setHover(true)
        setHoverkey(key)
    }

    const GetKeyHighestValue = (key) => {
        var result = ''
        if(key=='team') {}
        else {
            EPLLengendList.forEach((item, index) => {

                if (cat === 1) {
                    if (index >= 0 && index <= 11) {
                        if (key.toString().toUpperCase().trim(' ') === item.name.toUpperCase().trim(' '))
                            result = item.fullform.toString().replaceAll('_',' ')
                    }
                }
                else if (cat === 2) {
                    if (index >= 11 && index <= 21) {
                        if (key.toString().toUpperCase().trim(' ') === item.name.toUpperCase().trim(' '))
                            result = item.fullform.toString().replaceAll('_',' ')
                    }
                } else {
                    if (key.toString().toUpperCase().trim(' ') === item.name.toUpperCase().trim(' '))
                        result = item.fullform.toString().replaceAll('_',' ')
                }
    
            })
        }
        return result
    }

    const onMouseLeave = (key) => {
        setHoverkey(null)
        setHover(false)
    }

    const maniPulateName = (name) => {
        var splitter = name.split(' ')
        if (splitter.length > 1) {

            var initial = splitter[0].substr(0, 1)

            var fullname = ''
            if (splitter.length > 2) fullname = splitter[1] + ' ' + splitter[2]
            else fullname = splitter[1]

            var result = initial + ". " + fullname

            return result
            // .length > 15 ? result.slice(0, 15) + "." : result

        } else return name

    }

    return (
        <div className='epl-player-table-com rounded-md w-full'>

            {
                props.player_list && props.player_list.length === 0 ?
                    <div>
                        <p className="my-10 text-xl">No players in this team</p>
                    </div>
                    :
                    <div style={{ position: '' }}>

                        <table className='w-full mt-8 rounded-md tb-shadow text-sm xl:text-xs 2xl:text-lg text-black overflow-hidden' >

                            <tr className=''>
                                {
                                    props.headerData &&
                                    props.headerData.map((item, index) => {
                                        if (item === 'player' || item == '#' || item == 'team') {
                                            return <th onClick={() => sortBy(item)} style={{ backgroundColor: props.bgcolor }}
                                                className={item === sortedth ? 'text-left cursor-pointer uppercase font-bold text-md px-2 py-2 text-white tb-th-color'
                                                    : 'cursor-pointer uppercase font-bold text-md px-2 py-2 text-left text-white'}>
                                                {item}</th>
                                        }
                                        else if (props.cat <= 4) {
                                            return <th onMouseEnter={() => {
                                                if (item !== '') onMouseEnter(item)
                                            }}
                                                onMouseLeave={() => {
                                                    onMouseLeave()
                                                }}
                                                onClick={() => sortBy(item)} style={{ backgroundColor: props.bgcolor }}
                                                className={item === sortedth ? 'cursor-pointer uppercase font-bold text-md px-2 py-2 text-white tb-th-color'
                                                    : 'cursor-pointer uppercase font-bold text-md px-2 py-2 text-white'}>
                                                <div className={hoverkey === item ? 'block epl-tb-header-hover-content z-50 w-max' : 'hidden epl-tb-header-hover-content z-50 w-max'}>
                                                    <p className='text-white'>{hoverkey === item ? GetKeyHighestValue(item) : ''}</p>
                                                </div>
                                                {item}</th>
                                        }
                                        else {
                                            return <th
                                                style={{ backgroundColor: props.bgcolor }}
                                                className={'cursor-pointer uppercase font-bold px-2 py-2 text-white'}>
                                                {item}</th>
                                        }
                                    })
                                }
                            </tr>

                            {
                                playerList &&
                                playerList.map((item, index) => {

                                    // goals
                                    var goalsData = item.goal

                                    let total_goals = goalsData && goalsData.total_goals !== null ? goalsData.total_goals : 0;
                                    let average_goals = goalsData && goalsData.average_goals !== null ? goalsData.average_goals : 0;

                                    let one_plus_goals = goalsData && goalsData.one_plus_goals !== null ? goalsData.one_plus_goals.toFixed(0) : 0;
                                    let two_plus_goals = goalsData && goalsData.two_plus_goals !== null ? goalsData.two_plus_goals.toFixed(0) : 0;
                                    let three_plus_goals = goalsData && goalsData.three_plus_goals !== null ? goalsData.three_plus_goals.toFixed(0) : 0;

                                    let total_assists = goalsData && goalsData.total_assists !== null ? goalsData.total_assists : 0;
                                    let average_assists = goalsData && goalsData.average_assists !== null ? goalsData.average_assists : 0;
                                    let anytime_assist = goalsData && goalsData.anytime_assist !== null ? goalsData.anytime_assist : 0;

                                    let lowest_goals = goalsData && goalsData.lowest_goals !== null ? goalsData.lowest_goals : 0;
                                    let highest_goals = goalsData && goalsData.highest_goals !== null ? goalsData.highest_goals : 0;
                                    let games_played = goalsData && goalsData.games_played !== null ? goalsData.games_played : 0;


                                    // shotontarget
                                    var shortOnTargetData = item.target_shots
                                    let total_shots_on_target = shortOnTargetData && shortOnTargetData.total_shots_on_target !== null ? shortOnTargetData.total_shots_on_target : 0;
                                    let average_shots_on_target = shortOnTargetData && shortOnTargetData.average_shots_on_target !== null ? shortOnTargetData.average_shots_on_target.toFixed(1) : 0;
                                    let one_plus_shots_on_target = shortOnTargetData && shortOnTargetData.one_plus_shots_on_target !== null ? shortOnTargetData.one_plus_shots_on_target.toFixed(0) : 0;
                                    let two_plus_shots_on_target = shortOnTargetData && shortOnTargetData.two_plus_shots_on_target !== null ? shortOnTargetData.two_plus_shots_on_target.toFixed(0) : 0;

                                    let three_plus_shots_on_target = shortOnTargetData && shortOnTargetData.three_plus_shots_on_target !== null ? shortOnTargetData.three_plus_shots_on_target.toFixed(0) : 0;
                                    let four_plus_shots_on_target = shortOnTargetData && shortOnTargetData.four_plus_shots_on_target !== null ? shortOnTargetData.four_plus_shots_on_target.toFixed(0) : 0;
                                    
                                    let lowest_shots_on_target = shortOnTargetData && shortOnTargetData.lowest_shots_on_target !== null ? shortOnTargetData.lowest_shots_on_target : 0;
                                    let highest_shots_on_target = shortOnTargetData && shortOnTargetData.highest_shots_on_target !== null ? shortOnTargetData.highest_shots_on_target : 0;
                                    let shots_games_played = shortOnTargetData && shortOnTargetData.games_played !== null ? shortOnTargetData.games_played : 0;


                                    if (cat === 1)
                                        return <tr onClick={() => ViewPlayer(item.player_id)} 
                                        className={ headtohead ? 
                                        item.teams && item.teams.id == props.curretteamid  ? 
                                        "cursor-pointer bg-white" :"cursor-pointer tb-hth-data"
                                        :'cursor-pointer tb-data' }
                                        >
                                            {
                                                headtohead &&
                                                <td className='py-2 my-2 lg:py-3 xl:py-3 2xl:py-3 m-auto' width='0%'>
                                                    <img src={item.teams && item.teams.image} className='m-auto w-7 h-7'/>
                                                </td>
                                            }

                                            <td className='py-2 my-2 lg:py-3 xl:py-3 2xl:py-3' width='6%'>{'#' + item.player_number}</td>
                                            <td className='text-left pl-2 w-full'>{maniPulateName(item.name)}</td>

                                            <td style={{ backgroundColor:colorBy('total_goals',total_goals)}} width='6%'>{total_goals}</td>
                                            <td style={{ backgroundColor:colorBy('average_goals',average_goals) }} width='6%'>{average_goals}</td>

                                            <td style={{ backgroundColor: colorByValues('counts', one_plus_goals) }} width='6%'>{one_plus_goals + '%'}</td>
                                            <td style={{ backgroundColor: colorByValues('counts', two_plus_goals) }} width='6%'>{two_plus_goals + '%'}</td>
                                            <td style={{ backgroundColor: colorByValues('counts', three_plus_goals) }} width='6%'>{three_plus_goals + '%'}</td>

                                            <td width='6%' style={{ backgroundColor: colorBy('total_assists', total_assists) }}>{total_assists}</td>
                                            <td width='6%' style={{ backgroundColor: colorBy('average_assists', average_assists) }}>{average_assists+'%'}</td>
                                            <td style={{ backgroundColor: colorByValues('counts', anytime_assist) }} width='6%'>{anytime_assist+'%'}</td>
                                            <td width='6%'>{lowest_goals}</td>
                                            <td style={{ backgroundColor:colorByValues('hg',highest_goals)}} width='6%'>{highest_goals}</td>
                                            <td width='6%'>{games_played}</td>

                                        </tr>

                                    else if (cat === 2)
                                        return <tr onClick={() => ViewPlayer(item.player_id)} 
                                        className={ headtohead ? 
                                        item.teams && item.teams.id == props.curretteamid  ? 
                                        "cursor-pointer bg-white" :"cursor-pointer tb-hth-data"
                                        :'cursor-pointer tb-data' }
                                        >
                                            {
                                                headtohead &&
                                                <td className='py-2 my-2 lg:py-3 xl:py-3 2xl:py-3 m-auto' width='0%'>
                                                    <img src={item.teams && item.teams.image} className='m-auto w-7 h-7'/>
                                                </td>
                                            }

                                            <td className='py-2 my-2 lg:py-3 xl:py-3 2xl:py-3' width='6%'>{'#' + item.player_number}</td>
                                            <td className='text-left pl-2 w-full' width='100px'>{maniPulateName(item.name)}</td>

                                            <td style={{ backgroundColor:colorBy('total_shots_on_target',total_shots_on_target)}} width='6%'>{total_shots_on_target}</td>
                                            <td style={{ backgroundColor: colorBy('average_shots_on_target',average_shots_on_target) }} width='6%'>{average_shots_on_target}</td>

                                            <td style={{ backgroundColor: colorByValues('counts', one_plus_shots_on_target) }} width='6%'>{one_plus_shots_on_target + '%'}</td>
                                            <td style={{ backgroundColor: colorByValues('counts', two_plus_shots_on_target) }} width='6%'>{two_plus_shots_on_target + '%'}</td>
                                            <td style={{ backgroundColor: colorByValues('counts', three_plus_shots_on_target) }} width='6%'>{three_plus_shots_on_target + '%'}</td>
                                            <td style={{ backgroundColor: colorByValues('counts', four_plus_shots_on_target) }} width='6%'>{four_plus_shots_on_target + '%'}</td>

                                            <td width='6%'>{lowest_shots_on_target}</td>
                                            <td style={{ backgroundColor:colorByValues('hst',highest_shots_on_target)}} width='6%'>{highest_shots_on_target}</td>
                                            <td width='6%'>{shots_games_played}</td>

                                            <td width='6%'></td><td width='6%'></td>
                                            <td width='6%'></td><td width='6%'></td>

                                        </tr>
                                    
                                    else if (cat === 3){

                                        var result = item.team_score - item.opponent_score > 0 ? 'W' : item.team_score == item.opponent_score ? 'D':'L'
                                        var margin = item.team_score - item.opponent_score

                                        return <tr className='cursor-pointer tb-data w-full'>

                                        <td className='w-1/5 text-base py-2 lg:py-3 xl:py-3 2xl:py-3'>{new Date(item.date).getDate() + ' ' + new Date(item.date).toLocaleDateString('default', { month: 'long' }).substr(0, 3)}</td>
                                        <td className='text-center text-black w-1/5 text-base lg:text-base xl:text-base 2xl:text-base '>{item.opponent}</td>

                                        <td className='w-1/5 text-base'>{item.team_score}</td>
                                        <td className='w-1/5 text-base'>{item.opponent_score}</td>

                                        <td className='w-1/5 text-base' style={{ color: result === 'W' ? 'green' : result === 'D' ? 'grey' : 'red', fontWeight: 'bolder' }}>{result}</td>
                                        <td className='w-1/5 text-base'>{margin}</td>

                                    </tr>

                                    }
                                    
                                })
                            }
                        </table>

                        
                    </div>
            }
        </div>
    )
}

export default EPLPlayerTable;