import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const AppRedirects = () => {
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const customUrlScheme = searchParams.get('path');
    const fallbackUrl = 'https://www.multihub.com.au/';

    if (customUrlScheme) {
      // Check if the app is installed by attempting to open the custom URL scheme
      window.location.href = customUrlScheme;

      // Fallback to the web URL after a short delay
      const fallbackTimer = setTimeout(() => {
        window.location.href = fallbackUrl;
      }, 500);

      // Clean up the timer if the component unmounts before the fallback triggers
      return () => {
        clearTimeout(fallbackTimer);
      };
    } else {
      // Redirect to the fallback URL if no custom URL scheme is provided
      window.location.href = fallbackUrl;
    }
  }, [location.search]);

  return (
    <div>
      <p>Attempting to open the app...</p>
    </div>
  );
};

export default AppRedirects;