import { Formik } from "formik";
import React, { useState } from "react";
import {
  AiOutlineMail,
  AiOutlineKey,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
import * as Yup from "yup";
import Loader from "react-loader-spinner";

function LoginForm(props) {

  const [show_password, setpassword] = useState(false);
  const passwordRegExp =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

  const LoginSchema = Yup.object().shape({
    login_email: Yup.string().email("Invalid email").required("Required"),

    login_password: Yup.string()
      .min(8, "Too Short")
      .matches(passwordRegExp, "Password should contains Upper case, lowercase , numbers and special characters ")
      .required("Required"),
  });

  const ErrorComponent = (error) => {
    return <p className="text-red-400 text-left text-xs my-2">{error}</p>;
  };

  return (
    <div>
      <Formik
        initialValues={{
          login_email: "",
          login_password: "",
        }}
        onSubmit={(values) => props.onLoginSubmit(values)}
        validationSchema={LoginSchema}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          errors,
          touched,
        }) => (
          <div>
            {/* username */}
            <div className="my-5">
              <div className="flex flex-row justify-between items-center border border-gray-500 rounded-md px-2">
                <AiOutlineMail color="white" size={"20"} />
                <input
                  type="email"
                  className="bg-transparent p-2 text-md focus:outline-none ml-1 w-full"
                  placeholder="Email"
                  onChange={handleChange("login_email")}
                  onBlur={handleBlur("login_email")}
                />
              </div>
              {errors.login_email &&
                touched.login_email &&
                ErrorComponent(errors.login_email)}
            </div>

            {/* password */}
            <div className="my-5">
              <div className="flex flex-row justify-between items-center border border-gray-500 rounded-md px-2 ">
                <AiOutlineKey color="white" size={"20"} />
                <input
                  type={!show_password ? "password" : "text"}
                  className="bg-transparent p-2 text-md focus:outline-none ml-1 w-full"
                  placeholder="Password"
                  onChange={handleChange("login_password")}
                  onBlur={handleBlur("login_password")}
                />

                {values.login_password !== "" && (
                  <div className="cursor-pointer">
                    {show_password ? (
                      <AiOutlineEye
                        color="white"
                        size={"15"}
                        onClick={() => setpassword(!show_password)}
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        color="white"
                        size={"15"}
                        onClick={() => setpassword(!show_password)}
                      />
                    )}
                  </div>
                )}
              </div>
              {errors.login_password &&
                touched.login_password &&
                ErrorComponent(errors.login_password)}
            </div>

            <div>
              <button className="text-gray-500 text-sm" 
                onClick={props.onForgotPassword}>
                  Forgot password?
              </button>
            </div>

            {/* login button */}
            {
              <button
                type="submit"
                className="flex primary-bg-color w-full py-2 mt-5 rounded-md flex-1 justify-center items-center"
                onClick={handleSubmit}
                disabled={props.isLoading}
              >
                {
                  props.isLoading && <Loader
                    type="Oval"
                    color="white"
                    height={20}
                    width={20}
                  />
                }
                
                <p className="ml-1">{props.isLoading ? ` Logging...` : `Log In`}</p>
              </button>
            }
            

            {/* signup button */}
            <button
              type="submit"
              disabled={props.isLoading}
              className="block bg-white my-3 w-full py-2 rounded-md"
              onClick={props.onChangeForm}
            >
              <p className="text-black">New here ? Sign Up </p>
            </button>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default LoginForm;
