export const disposalTBHeader = [
  "#",
  "player",
  "avd",
  "15+",
  "20+",
  "25+",
  "30+",
  "35+",
  "ld",
  "sld",
  "tld",
  "hd",
  "gp",
];

export const goalTBHeader = [
  "#",
  "player",
  "tg",
  "avg",
  "1g",
  "2g+",
  "3g+",
  "lg",
  "hg",
  "gp",
  "",
  "",
  "",
  "",
  "",
];

export const formTBHeader = [
  "#",
  "player",
  "afp",
  "60+",
  "70+",
  "80+",
  "90+",
  "100+",
  "110+",
  "120+",
  "lp",
  "hp",
  "gp",
];

export const marginTBHeader = [
  "date",
  "opponent",
  "team score",
  "opp score",
  "result",
  "margin"
];

export const AFLTBHeader = ["afl ladder", "p", "pts", "%"];

export const teamNameList = [
  {
    name: "adelaide",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/AFL_team_icons/Adelaide.png",
    bgcolor: "#004b8d",
    team_id: "8-8",
  },
  {
    name: "carlton",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/AFL_team_icons/Carlton.png",
    bgcolor: "#001e2f",
    team_id: "8-2",
  },

  {
    name: "collingwood",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/AFL_team_icons/Collingwood.png",
    bgcolor: "#000000",
    team_id: "8-10",
  },

  {
    name: "fremantle",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/AFL_team_icons/Fremantle.png",
    bgcolor: "#331c54",
    team_id: "8-12",
  },

  {
    name: "gold coast",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/AFL_team_icons/Gold+Coast.png",
    bgcolor: "#d72e24",
    team_id: "8-5",
  },

  {
    name: "hawthron",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/AFL_team_icons/Hawthorn.png",
    bgcolor: "#512b1b",
    team_id: "8-18",
  },

  {
    name: "north melbourne",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/AFL_team_icons/North+Melbourne.png",
    bgcolor: "#003ca0",
    team_id: "8-7",
  },

  {
    name: "richmond",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/AFL_team_icons/Richmond.png",
    bgcolor: "#000000",
    team_id: "8-1",
  },
  {
    name: "st kilda",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/AFL_team_icons/St+Kilda.png",
    bgcolor: "#ed1b2e",
    team_id: "8-14",
  },

  {
    name: "west coast",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/AFL_team_icons/West+Coast.png",
    bgcolor: "#003087",
    team_id: "8-15",
  },
  {
    name: "brisbane",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/AFL_team_icons/Brisbane.png",
    bgcolor: "#8b0042",
    team_id: "8-16",
  },

  {
    name: "essendon",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/AFL_team_icons/Essendon.png",
    bgcolor: "#000000",
    team_id: "8-6",
  },

  {
    name: "geelong",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/AFL_team_icons/Geelong.png",
    bgcolor: "#002244",
    team_id: "8-17",
  },

  {
    name: "gws giants",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/AFL_team_icons/GWS+Giants.png",
    bgcolor: "#ff7900",
    team_id: "8-4",
  },

  {
    name: "melbourne",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/AFL_team_icons/Melbourne.png",
    bgcolor: "#061a33",
    team_id: "8-3",
  },

  {
    name: "port adelaide",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/AFL_team_icons/Port+Adelaide.png",
    bgcolor: "#008aab",
    team_id: "8-13",
  },
  {
    name: "sydney",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/AFL_team_icons/Sydney.png",
    bgcolor: "#e41e31",
    team_id: "8-9",
  },

  {
    name: "western bulldogs",
    image:
      "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/AFL_team_icons/Western+Bulldogs.png",
    bgcolor: "#0039a6",
    team_id: "8-11",
  },
];

export const tableHoverformList = [
  {
    id: 1,
    name: "avd",
    fullform: "Average Disposals",
  },

  {
    id: 2,
    name: "15+",
    fullform: "15 or more disposals",
  },
  {
    id: 3,
    name: "20+",
    fullform: "20 or more disposals",
  },
  {
    id: 4,
    name: "25+",
    fullform: "25 or more disposals",
  },

  {
    id: 5,
    name: "30+",
    fullform: "30 or more disposals",
  },
  {
    id: 6,
    name: "35+",
    fullform: "35 or more disposals",
  },
  {
    id: 7,
    name: "ld",
    fullform: "lowest Disposals",
  },

  {
    id: 8,
    name: "sld",
    fullform: "Second Lowest Disposals",
  },

  {
    id: 9,
    name: "tld",
    fullform: "Third Lowest Disposals",
  },

  {
    id: 10,
    name: "hd",
    fullform: "Highest Disposals",
  },

  {
    id: 11,
    name: "gp",
    fullform: "Games Played",
  },

  //goals
  {
    id: 12,
    name: "tg",
    fullform: "Total Goals",
  },

  {
    id: 13,
    name: "avg",
    fullform: "Average Goals",
  },

  {
    id: 14,
    name: "1g",
    fullform: "Any Time Goal Scorer",
  },

  {
    id: 15,
    name: "2g+",
    fullform: "2 or more goals",
  },
  {
    id: 16,
    name: "3g+",
    fullform: "3 or more goals",
  },

  {
    id: 17,
    name: "lg",
    fullform: "lowest goals",
  },

  {
    id: 18,
    name: "hg",
    fullform: "highest goals",
  },
  {
    id: 19,
    name: "gp",
    fullform: "games played",
  },

  //fatasy points
  {
    id: 20,
    name: "afp",
    fullform: "Average Fantasy Points",
  },

  {
    id: 21,
    name: "60+",
    fullform: "60 or more points",
  },

  {
    id: 22,
    name: "70+",
    fullform: "70 or more points",
  },
  {
    id: 23,
    name: "80+",
    fullform: "80 or more points",
  },
  {
    id: 24,
    name: "90+",
    fullform: "90 or more points",
  },
  {
    id: 25,
    name: "100+",
    fullform: "100 or more points",
  },
  {
    id: 26,
    name: "110+",
    fullform: "110 or more points",
  },
  {
    id: 27,
    name: "120+",
    fullform: "120 or more points",
  },
  {
    id: 28,
    name: "lp",
    fullform: "lowest points",
  },
  {
    id: 29,
    name: "hp",
    fullform: "heighest points",
  },
  {
    id: 30,
    name: "gp",
    fullform: "games played",
  },
];
