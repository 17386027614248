import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import AppBar from "../AppBar/AppBar";
import PageBar from "../AppBar/PageBar";
import AppLoader from "../Loader/Loader";
import "../../utils/styles/teamdetail/teamdetail.scss";
import { SponserListCom } from "../SponerList/SponserList";
import {
  GetNBAPlayerDetail,
} from "../../Busniess_Logic/API/NBAApi";
import CopyRights from "../CopyRights";
import AdSenseCom from "../AdSense/AdSense";

function NBAPlayerDetail(props) {
  const [called, setCalled] = useState(false);
  const [detail, setDetail] = useState(null);
  const [userDetail, setUserdetail] = useState(null);
  const [loader, setLoader] = useState(false);

  const [tc, setTc] = useState(null);
  const [hoverHeader, setHoverheader] = useState(null);

  const [pointsData, setPoints] = useState([]);
  const [reboundsData, setRebounds] = useState([]);
  const [assistsData, setAssists] = useState([]);
  const [threepointsData, setThreePoints] = useState([]);

  useEffect(() => {
    getplayerdetails();
  }, []);

  const getplayerdetails = async () => {
    setLoader(true);
    var surl = new URLSearchParams(window.location.href);
    var url = new URL(window.location.href);
    var c = url.searchParams.get("id");
    var season = surl.get('s')

    const res = await GetNBAPlayerDetail(c ? c:"7-22334",season ? season : '2022');
    if (res) {
      setDetail(res);
      CalculatePointsData(res);
      CalculateReboundsData(res);
      CalculateAssistsData(res);
      CalculateThreePointsData(res);
    }

    setCalled(true);
    setLoader(false);
    setTc(surl.get("tc") ? surl.get("tc") :"#cf0a2c");
  };

  const CalculatePointsData = (data) => {
    if (data) {
      var triesList = [];
      data.games &&
        data.games.forEach((item) => {
          var eachTries = 0;
          item.stats.map((state) => {
            if (state.id === "7-2") {
              eachTries = eachTries + parseInt(state.value);
            }
          });
          triesList.push(eachTries);
        });

      var contentList = [];
      if (triesList.length !== 0) {
        var sortedList = [];
        sortedList = triesList.sort((a, b) => a - b);

        contentList.push({ title: "Points", content: "" });
        contentList.push({
          title: "total points",
          content: sortedList.reduce((a, b) => a + b),
        });
        contentList.push({
          title: "average points",
          content: (
            sortedList.reduce((a, b) => a + b, 0) / sortedList.length
          ).toFixed(1),
        });
        contentList.push({
          title: "highest points",
          content: sortedList[sortedList.length - 1],
        });
        contentList.push({ title: "lowest points", content: sortedList[0] });

        for (var i = 0; i <= 50; i++) {
          if (i === 0)
            contentList.push({
              title: `${i} points`,
              content:
                sortedList.filter(function (ele) {
                  return ele === i;
                }).length +
                "/" +
                triesList.length,
            });
          else
            contentList.push({
              title: `${i}+ points`,
              content:
                sortedList.filter(function (ele) {
                  return ele >= i;
                }).length +
                "/" +
                triesList.length,
            });
        }

        setPoints(contentList);
      }
    }
  };

  const CalculateReboundsData = (data) => {
    if (data) {
      var triesList = [];
      data.games &&
        data.games.forEach((item) => {
          var eachTries = 0;
          item.stats.map((state) => {
            if (state.id === "7-3") {
              eachTries = eachTries + state.value;
            }
          });
          triesList.push(eachTries);
        });

      var contentList = [];
      if (triesList.length !== 0) {
        var sortedList = [];
        sortedList = triesList.sort((a, b) => a - b);

        contentList.push({ title: "Rebounds", content: "" });

        contentList.push({
          title: "total rebounds",
          content: sortedList.reduce((a, b) => a + b),
        });
        contentList.push({
          title: "average rebounds",
          content: (
            sortedList.reduce((a, b) => a + b, 0) / sortedList.length
          ).toFixed(1),
        });
        contentList.push({
          title: "highest rebounds",
          content: sortedList[sortedList.length - 1],
        });
        contentList.push({ title: "lowest rebounds", content: sortedList[0] });

        for (var i = 0; i <= 25; i++) {
          if (i === 0)
            contentList.push({
              title: `${i} rebounds`,
              content:
                sortedList.filter(function (ele) {
                  return ele === i;
                }).length +
                "/" +
                triesList.length,
            });
          else
            contentList.push({
              title: `${i}+ rebounds`,
              content:
                sortedList.filter(function (ele) {
                  return ele >= i;
                }).length +
                "/" +
                triesList.length,
            });
        }

        setRebounds(contentList);
      }
    }
  };

  const CalculateAssistsData = (data) => {
    if (data) {
      var triesList = [];
      data.games &&
        data.games.forEach((item) => {
          var eachTries = 0;
          item.stats.map((state) => {
            if (state.id === "7-4") {
              eachTries = eachTries + state.value;
            }
          });
          triesList.push(eachTries);
        });

      var contentList = [];
      if (triesList.length !== 0) {
        var sortedList = [];
        sortedList = triesList.sort((a, b) => a - b);

        contentList.push({ title: "Assists", content: "" });

        contentList.push({
          title: "total assists",
          content: sortedList.reduce((a, b) => a + b),
        });
        contentList.push({
          title: "average assists",
          content: (
            sortedList.reduce((a, b) => a + b, 0) / sortedList.length
          ).toFixed(1),
        });
        contentList.push({
          title: "highest assists",
          content: sortedList[sortedList.length - 1],
        });
        contentList.push({ title: "lowest assists", content: sortedList[0] });

        for (var i = 0; i <= 20; i++) {
          if (i == 0)
            contentList.push({
              title: `${i} assists`,
              content:
                sortedList.filter(function (ele) {
                  return ele === i;
                }).length +
                "/" +
                triesList.length,
            });
          else
            contentList.push({
              title: `${i}+ assists`,
              content:
                sortedList.filter(function (ele) {
                  return ele >= i;
                }).length +
                "/" +
                triesList.length,
            });
        }

        setAssists(contentList);
      }
    }
  };

  const CalculateThreePointsData = (data) => {
    if (data) {
      var triesList = [];
      data.games &&
        data.games.forEach((item) => {
          var eachTries = 0;
          item.stats.map((state) => {
            if (state.id === "7-8") {
              eachTries = eachTries + state.value;
            }
          });
          triesList.push(eachTries);
        });

      var contentList = [];
      if (triesList.length !== 0) {
        var sortedList = [];
        sortedList = triesList.sort((a, b) => a - b);

        contentList.push({ title: "Three Points", content: "" });

        contentList.push({
          title: "total three points",
          content: sortedList.reduce((a, b) => a + b),
        });
        contentList.push({
          title: "average three points",
          content: (
            sortedList.reduce((a, b) => a + b, 0) / sortedList.length
          ).toFixed(1),
        });
        contentList.push({
          title: "highest three points",
          content: sortedList[sortedList.length - 1],
        });
        contentList.push({
          title: "lowest three points",
          content: sortedList[0],
        });

        for (var i = 0; i <= 10; i++) {
          if (i == 0)
            contentList.push({
              title: `${i} three points`,
              content:
                sortedList.filter(function (ele) {
                  return ele === i;
                }).length +
                "/" +
                triesList.length,
            });
          else
            contentList.push({
              title: `${i}+ three points`,
              content:
                sortedList.filter(function (ele) {
                  return ele >= i;
                }).length +
                "/" +
                triesList.length,
            });
        }

        setThreePoints(contentList);
      }
    }
  };

  const playerDataTable = (tableData) => {
    return (
      <div className="rounded-md py-2 my-2">
        {tableData.map((item, index) => {
          if (index === 0)
            return (
              <div style={{ backgroundColor: tc, padding: "3%" }}>
                <div
                  className="rounded-tl-md uppercase w-full text-white text-left font-bold"
                  style={{ backgroundColor: tc }}
                >
                  {item.title}
                </div>
                <div className="rounded-tr-md w-1/2">{item.content}</div>
              </div>
            );
        })}

        <div className="overflow-y-auto" style={{ height: "40vh" }}>
          <table className="rounded-lg tb-shadow h-auto w-full text-black">
            {tableData.map((item, index) => {
              if (index > 0)
                return (
                  <tr className="">
                    <td className="capitalize text-center pl-4 w-1/2 py-1">
                      {item.title}
                    </td>
                    <td className="w-1/2">{item.content}</td>
                  </tr>
                );
            })}
          </table>
        </div>
      </div>
    );
  };

  const CalculatePoints = () => {
    var triesList = [];
    detail.games.forEach((item) => {
      var tries = 0;

      item.stats.forEach((stat) => {
        if (stat.id === "7-2") tries = tries + stat.value;
      });

      triesList.push(tries);
    });
    return triesList;
  };

  const CalculateRebounds = () => {
    var triesList = [];
    detail.games.forEach((item) => {
      var tries = 0;

      item.stats.forEach((stat) => {
        if (stat.id === "7-3") tries = tries + stat.value;
      });

      triesList.push(tries);
    });
    return triesList;
  };

  const CalculateAssists = () => {
    var triesList = [];
    detail.games.forEach((item) => {
      var tries = 0;

      item.stats.forEach((stat) => {
        if (stat.id === "7-4") tries = tries + stat.value;
      });

      triesList.push(tries);
    });
    return triesList;
  };

  const CalculateThreePoints = () => {
    var triesList = [];
    detail.games.forEach((item) => {
      var tries = 0;

      item.stats.forEach((stat) => {
        if (stat.id === "7-8") tries = tries + stat.value;
      });

      triesList.push(tries);
    });
    return triesList;
  };

  const CalculateMinutes = () => {
    var triesList = [];
    detail.games.forEach((item) => {
      var tries = 0;

      item.stats.forEach((stat) => {
        if (stat.id === "7-1") tries = tries + stat.value;
      });

      triesList.push(tries);
    });
    return triesList;
  };

  const getTotalGames = () => {
    if(detail && detail.games) return (detail.games.length).toString()
    else return "0"
  }

  return (
    <div className="w-full">
      <AppBar his={props.history} />

      {loader && (
        <div style={{ position: "relative" }}>
          <AppLoader msg={"Getting player details"} />
        </div>
      )}

      {!loader && (
        <div
          className="player-detail-com w-full sm:w-full md:w-full lg:w-3/5 
                m-auto xl:w-3/4 2xl:w-3/4 p-3 xl:p-0 2xl:p-0 2xl:py-5 pb-8"
        >
          <div className="xl:flex 2x:flex xl:flex-1 2xl:flex-1">

            {
              detail!=null && detail!=undefined ? 
              <div className="mx-1 w-full xl:w-3/4 2xl:w-4/5"
              // className='player-detail-com w-full sm:w-full md:w-full lg:w-3/5 m-auto xl:w-3/5 2xl:w-3/5 p-3 xl:p-0 2xl:p-0 2xl:py-5 pb-8'
            >

              <AdSenseCom />

              <PageBar his={props.history} />

              <div
                className="team-container p-4 rounded-lg border border-grey-100 shadow-md flex flex-1 flex-row items-center"
                style={{ backgroundColor: tc }}
              >
                <div className="team-name w-full">
                  <h1 className="text-3xl font-bold text-white">
                    {detail.player_name ? detail.player_name : ''}
                  </h1>
                </div>
              </div>

              <div className="my-2 p-3 rounded-lg shadow-md border border-grey-100 flex flex-1 justify-around">
                <p className="text-left px-2">Total games played</p>
                <p className="text-left px-2">
                  <strong>{getTotalGames()}</strong>
                </p>
              </div>

              {detail.games && detail.games.length != 0 ? (
                <div className="w-full">
                  <div
                    className="rounded-lg shadow-xl items-center my-4 top-tb-container"
                    style={{ position: "relative" }}
                  >
                    <table className="p-2 top-table text-base xl:text-sm 2xl:text-lg bg-white text-black">
                     
                      <tr>
                        <th
                          className="rounded-tl-md text-white p-2 capitalize"
                          style={{ backgroundColor: tc }}
                        >
                          played for
                        </th>
                        {detail.games.map((item, index) => {
                          if (
                            item.home_team.id ===
                            item.player_team_id
                          )
                            return (
                              <td
                                className="text-md top-tb-data"
                                onMouseEnter={() =>
                                  setHoverheader(item.home_team.abbreviation)
                                }
                                onMouseLeave={() => setHoverheader(null)}
                              >
                                <div className="tool-tip-con">
                                  <strong>{item.home_team.abbreviation}</strong>
                                  <p className="tool-tip-data">
                                    {item.home_team.name}
                                  </p>
                                </div>
                              </td>
                            );
                          else if(item.away_team.id ===
                            item.player_team_id)
                            return (
                              <td
                                className="text-md top-tb-data"
                                onMouseEnter={() =>
                                  setHoverheader(item.away_team.abbreviation)
                                }
                                onMouseLeave={() => setHoverheader(null)}
                              >
                                <div className="tool-tip-con">
                                  <strong>{item.away_team.abbreviation}</strong>
                                  <p className="tool-tip-data">
                                    {item.away_team.name}
                                  </p>
                                </div>
                              </td>
                            );
                        })}
                      </tr>

                      <tr>
                        <th
                          className="text-white p-2 capitalize"
                          style={{ backgroundColor: tc }}
                        >
                          Opponent
                        </th>
                        {detail.games.map((item, index) => {
                          if (
                            item.home_team.id !==
                            item.player_team_id
                          )
                            return (
                              <td
                                className="text-md top-tb-data"
                                onMouseEnter={() =>
                                  setHoverheader(item.home_team.abbreviation)
                                }
                                onMouseLeave={() => setHoverheader(null)}
                              >
                                <div className="tool-tip-con">
                                  <strong>{item.home_team.abbreviation}</strong>
                                  <p className="tool-tip-data">
                                    {item.home_team.name}
                                  </p>
                                </div>
                              </td>
                            );
                          else if(item.away_team.id !==
                            item.player_team_id)
                            return (
                              <td
                                className="text-md top-tb-data"
                                onMouseEnter={() =>
                                  setHoverheader(item.away_team.abbreviation)
                                }
                                onMouseLeave={() => setHoverheader(null)}
                              >
                                <div className="tool-tip-con">
                                  <strong>{item.away_team.abbreviation}</strong>
                                  <p className="tool-tip-data">
                                    {item.away_team.name}
                                  </p>
                                </div>
                              </td>
                            );
                        })}
                      </tr>

                      <tr>
                        <th
                          className="text-white p-2 capitalize"
                          style={{ backgroundColor: tc }}
                        >
                          date
                        </th>
                        {detail.games.map((item, index) => {
                          return (
                            <td className="text-sm top-tb-data">
                              <strong>
                                {new Date(item.start_time).getDate() +
                                  " " +
                                  new Date(item.start_time)
                                    .toLocaleDateString("default", {
                                      month: "long",
                                    })
                                    .substr(0, 3)}
                              </strong>
                            </td>
                          );
                        })}
                      </tr>

                      <tr>
                        <th
                          className="text-white p-2 capitalize"
                          style={{ backgroundColor: tc }}
                        >
                          points
                        </th>
                        {CalculatePoints().map((item) => {
                          return (
                            <td className="top-tb-data">
                              <strong>
                                {item === 0 ||
                                item === -Infinity ||
                                item === NaN
                                  ? "-"
                                  : item}
                              </strong>
                            </td>
                          );
                        })}
                      </tr>

                      <tr>
                        <th
                          className="text-white p-2 capitalize"
                          style={{ backgroundColor: tc }}
                        >
                          rebounds
                        </th>
                        {CalculateRebounds().map((item) => {
                          return (
                            <td className="top-tb-data">
                              <strong>
                                {item === 0 ||
                                item === -Infinity ||
                                item === NaN
                                  ? "-"
                                  : item}
                              </strong>
                            </td>
                          );
                        })}
                      </tr>

                      <tr>
                        <th
                          className="text-white p-2 capitalize"
                          style={{ backgroundColor: tc }}
                        >
                          assists
                        </th>
                        {CalculateAssists().map((item) => {
                          return (
                            <td className="top-tb-data">
                              <strong>
                                {item === 0 ||
                                item === -Infinity ||
                                item === NaN
                                  ? "-"
                                  : item}
                              </strong>
                            </td>
                          );
                        })}
                      </tr>

                      <tr>
                        <th
                          className="text-white p-2 capitalize"
                          style={{ backgroundColor: tc }}
                        >
                          three points
                        </th>
                        {CalculateThreePoints().map((item) => {
                          return (
                            <td className="top-tb-data">
                              <strong>
                                {item === 0 ||
                                item === -Infinity ||
                                item === NaN
                                  ? "-"
                                  : item}
                              </strong>
                            </td>
                          );
                        })}
                      </tr>

                      <tr>
                        <th
                          className="text-white p-2 capitalize"
                          style={{ backgroundColor: tc }}
                        >
                          minutes
                        </th>
                        {CalculateMinutes().map((item) => {
                          return (
                            <td className="top-tb-data">
                              <strong>
                                {item === 0 ||
                                item === -Infinity ||
                                item === NaN
                                  ? "-"
                                  : item}
                              </strong>
                            </td>
                          );
                        })}
                      </tr>

                    </table>
                  </div>

                  <div
                    className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 
                    gap-2 items-start justify-between w-full data-table-con nba-player-data-table"
                  >
                    {pointsData && playerDataTable(pointsData)}

                    {reboundsData && playerDataTable(reboundsData)}

                    {assistsData && playerDataTable(assistsData)}

                    {threepointsData && playerDataTable(threepointsData)}
                  </div>
                </div>
              ):
              (
                <div>
                    <p className="text-white py-3 text-center">No games played</p>
                </div>
              )
              }
            </div>
            :
            <div className='mx-1 w-full xl:w-3/4 2xl:w-4/5 m-auto'>
                <p className="text-white py-5 text-center">Data not found</p>
            </div>
            }
            
            <div className="mt-14 xl:w-1/4 2xl:w-1/5">
              <SponserListCom />
            </div>
          </div>
        </div>
      )}

      { !loader && <CopyRights his={props.history}/> }

    </div>
  );
}

export default withRouter(NBAPlayerDetail);
