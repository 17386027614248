import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import '../../utils/styles/teamdetail/teamdetail.scss'
import AppBar from "../AppBar/AppBar";
import PageBar from "../AppBar/PageBar";
import { SponserListCom } from "../SponerList/SponserList";
import EPLPlayerTable from "./EPLPlayerTable";
import AppLoader from "../Loader/Loader";
import { GetEPLTeamPlayersList, GetEPLTeams,GetTeamDetails } from "../../Busniess_Logic/API/EPLApi";
import { EPLLengendList, EPLGoalsHeader, EPLShotsOnTargetHeader, resultTBHeader,} from '../EPL/EPL_Constant';
import { Chart as ChartJS, ArcElement, Tooltip, Legend,CategoryScale,
    LinearScale,
    BarElement,
    Title } from 'chart.js';
import CopyRights from "../CopyRights";
import AdSenseCom from "../AdSense/AdSense";

ChartJS.register(ArcElement, Tooltip, Legend,CategoryScale,
    LinearScale,
    BarElement,
    Title,);

function EPLTeam(props) {

    const [cat, setCat] = useState(1)
    const [teamstats, setTeamstats] = useState(1)
    const [playerstats, setPlayerstats] = useState(1)

    const [hthcurrentTeam, setHthcurrentTeam] = useState(null)
    const [hthPlayerList, setHTHPlayerlist] = useState(null)

    const [teamid, setTeamid] = useState(null)
    const [playerList, setPlayerlist] = useState(null)

    const [currentTeam, setcurrentTeam] = useState(null)
    const [teamList, setTeamlist] = useState([])

    const [loader, setLoader] = useState(false)
    const [teamloader, setTeamloader] = useState(false)
    const [teamData, setTeamData] = useState({})

    var url = new URL(window.location.href);
    var id = url.searchParams.get("id");
    var page = url.searchParams.get("p");
    var season = url.searchParams.get("s") ? url.searchParams.get("s") : '2021';
    var headtohead = url.searchParams.get("hth");

    useEffect(() => {

        async function apiCall(){
            await GetTeamUserData(id?id:"40",season)
            await getTeamsData(id?id:"40",headtohead,season)
        }

        //head to head
        if(headtohead) GetTeamHeadToHeadUserData(headtohead,season)

        apiCall()

        if(page){
            if (page == 'ts') setCat(1)
            else if (page == 'ps') setCat(2)
        }
        
        setTeamid(id?id:"40")

        window.addEventListener('popstate', (event) => {

            var url = new URL(window.location.href);
            var id = url.searchParams.get("id");
            if (teamid && id) if (id !== teamid) {
                window.location.reload()
            }

        });

        return () => {
            window.removeEventListener('popstate', () => { })
        }

    }, [])

    const getTeamsData = async (id,hthteamid,season) => {

        setTeamloader(true)
        var list = []
        const res = await GetEPLTeams(season)
        if (res) {
            if(res.success){
                res.response.forEach((item) => {
                    if (item.id === parseInt(id)) {
                        setcurrentTeam(item)
                        //console.log('current item',item)
                        getTeamFromS3Data(item.name,season)
                    }
                    else if(hthteamid){
                        if(item.id == parseInt(hthteamid)) setHthcurrentTeam(item)
                    } 
                    list.push(item)
                })
    
                list.sort(function (a, b) {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1;
                    }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                })
    
                list = list.filter((a)=> a.id!= parseInt(id))
                if(hthteamid)
                list = list.filter((a)=> a.id!= parseInt(hthteamid))
    
                setTeamlist(list)
                setTeamloader(false)
            }
        }
        setTeamloader(false)

    }

    const GetTeamHeadToHeadUserData = async (id,season) => {
        setLoader(true)
        const res = await GetEPLTeamPlayersList(id,season)
        if (res) setHTHPlayerlist(res)
        setLoader(false)
    }

    const GetTeamUserData = async (id,season) => {

        setLoader(true)
        const res = await GetEPLTeamPlayersList(id,season)
        //console.log('response',res)
        if (res) {
            setLoader(false)
            setPlayerlist(res)
        }
        setLoader(false)
    }

    const redirectToTeam = (id) => {
        if (headtohead) props.history.push(`eplteam?id=` + id+`&p=${page}&hth=${headtohead}`)
        else props.history.push(`eplteam?id=` + id+`&p=${page}`)
        window.location.reload()
    }

    const Fullform = (item) => {
        return <div className='flex flex-1 my-1 w-screen lg:w-full md:w-full xl:w-full 2xl:w-full'>
            <h1 className='uppercase mx-4 font-bold w-10 xl:w-8 2xl:w-14 text-left text-sm 2xl:text-lg'>{item.name}</h1>
            -
            <p className='capitalize mx-4 text-sm 2xl:text-lg'>{item.fullform.replaceAll('_',' ')}</p>
        </div>
    }

    const onchangecat = (catid) => {
        
        
        var href = new URL(window.location.href);

        if (catid === 1) {
            href.searchParams.set('p', 'ts');
            setCat(catid)
        } 
        else if (catid === 2) {
            href.searchParams.set('p', 'ps');
            if(teamData) setCat(catid)
        } 

        window.history.replaceState('', '', href.toString())
    }

    const onChangeTeamStats=(id)=>{
        setTeamstats(id)
    }

    const onChangePlayerStats=(id)=>{
        setPlayerstats(id)
    }

    const getTeamFromS3Data = async (team_name,season) => {

        setTeamloader(true)
        const res = await GetTeamDetails(team_name,season)
        if (res) setTeamData(res)
        setTeamloader(false)
        
    }


    const EachStatus = (index, item, divide_index,bgcolor) => {
        return <div className='my-0 lg:my-2 xl:my-2 2xl:my-2 mx-2 py-2 w-full'>

            <div className='flex flex-1 justify-between px-2  my-1 m-auto w-full'>
                <h1 className={'text-md m-0 p-0 text-green-600 font-bold'} 
                style={{color:bgcolor}}>{parseFloat(item.percentage).toFixed(0) + "%"}</h1>
            </div>
            <div className='flex flex-1 justify-center my-2'>
                <progress id={bgcolor} value={parseFloat(item.percentage).toFixed(0)} 
                max="100" className='w-full mx-1 rounded-xl ' />
            </div>

        </div>
    }


    const CustomEachStatus = (item,bgcolor) => {
        return <div className='my-0 lg:my-2 xl:my-2 2xl:my-2 mx-2 py-2'>

            <div className='flex flex-1 justify-between px-2  my-1 m-auto w-full'>
                <h1 className={`text-md m-0 p-0 font-bold`}
                style={{color:bgcolor}}>{parseFloat(item.content).toFixed(0) + "%"}</h1>
            </div>
            <div className='flex flex-1 justify-center my-2'>
                <progress id={bgcolor} value={parseFloat(item.content).toFixed(0)} max="100" className='w-full mx-1 rounded-xl ' />
            </div>

        </div>
    }

    const WinOrLossGraph = (list,title) => {

        return (<div className='my-5 rounded-lg'>
            <div className='bg-white p-5 rounded-lg'>

                <div>
                    <h1 className='text-lg text-bold text-black 
                        text-left py-0 my-2 lg:py-5 xl:py-0 2xl:py-0 text-2xl capitalize'>{title}</h1>
                </div>

                <div className='py-2'>

                    <div className='flex-none flex-0 xl:flex xl:flex-1 xl:flex-row 2xl:flex-row 2xl:flex 2xl:flex-1 justify-between '>

                        <div className='w-full'>
                            <h1 className='text-black font-bold text-left text-xl py-2 m-0 px-4 capitalize'>Win</h1>
                            {EachStatus(0, list[0] ? list[0] : 0, 0,"green")}
                        </div>

                        <div className='w-full'>
                            <h1 className='text-black font-bold text-left text-xl py-2 m-0 px-4 capitalize'>Draw</h1>
                            {EachStatus(1, list[1] ? list[1] : 0, 1,"orange")}
                        </div>

                        <div className='w-full'>
                            <h1 className='text-black font-bold text-left text-xl py-2 px-4 capitalize'>Loss</h1>
                            {EachStatus(2, list[2] ? list[2] : 0, 2,"red")}
                        </div>

                    </div>

                </div>

            </div>
        </div>
        )
    }

    const LineGraphYesNo=(list,title)=>{
        return (
            <div className="my-1">
                <div className='bg-white p-5 rounded-lg'>

                <div>
                    <h1 className='text-lg text-bold text-black 
                        text-left py-0 my-2 lg:py-5 xl:py-0 2xl:py-0 text-2xl font-bold capitalize'>{title}</h1>
                </div>

                <div className='py-2'>
                    <div className='flex-none flex-0 xl:flex xl:flex-1 xl:flex-row 2xl:flex-row 2xl:flex 2xl:flex-1 justify-between '>
                        {
                            list.map((item,index) =>{
                                return <div className='w-full py-3'>
                                    <h1 className='text-black font-bold text-left text-xl py-0 m-0 px-4 capitalize'>
                                        {item.name.replaceAll('_',' ')}</h1>
                                    {CustomEachStatus(item,index == 0 ? "green":"red")}
                                </div>
                            })
                        }
                    </div>
                </div>

                </div>
            </div>
        )
    }

    const LineGraph=(list,title,name=true)=>{
        return (
            <div className="my-1">
                <div className='bg-white p-5 rounded-lg'>

                <div>
                    <h1 className='text-lg text-bold text-black 
                        text-left py-0 my-2 lg:py-5 xl:py-0 2xl:py-0 text-2xl font-bold capitalize'>{title}</h1>
                </div>

                <div className='py-2'>
                    <div className='flex-none flex-0 xl:flex xl:flex-1 xl:flex-row 2xl:flex-row 2xl:flex 2xl:flex-1 justify-between '>
                        {
                            list.map((item,index) =>{
                                //console.log("$$$",item)
                                return <div className='w-full py-3'>
                                    <h1 className='text-black font-bold text-left text-xl py-0 m-0 px-4 h-14 capitalize'>
                                    {index == 1 ?  item.name.replaceAll('_',' ') :
                                    currentTeam.name +' '+ item.name.replaceAll('_',' ')}</h1>
                                    {CustomEachStatus(item,"green")}
                                </div>
                            })
                        }
                    </div>
                </div>

                </div>
            </div>
        )
    }

    const DoubleChanceGraph = (list,title) => {

        return (<div className='my-5 rounded-lg w-full'>
            <div className='bg-white p-5 rounded-lg w-full'>
                
                <div>
                    <h1 className='text-lg text-bold text-black 
                        text-left py-0 my-2 lg:py-5 xl:py-0 2xl:py-0 text-2xl capitalize'>{title}</h1>
                </div>

                <div className='py-2'>

                    <div className='flex-none flex-0 xl:flex xl:flex-1 xl:flex-row 2xl:flex-row 2xl:flex 2xl:flex-1 justify-between'>

                        <div>
                            <h1 className='text-black font-bold text-left text-xl py-2 m-0 px-4 capitalize w-full'>{`${currentTeam && currentTeam.name} Win or Draw`} </h1>
                            {EachStatus(0, list[0] ? list[0] : 0, 0,"green")}
                        </div>

                        <div>
                            <h1 className='text-black font-bold text-left text-xl py-2 m-0 px-4 capitalize w-full'>{`${currentTeam && currentTeam.name} Win or Opponent Win`} </h1>
                            {EachStatus(1, list[1] ? list[1] : 0, 1,"green")}
                        </div>

                        <div>
                            <h1 className='text-black font-bold text-left text-xl py-2 px-4 capitalize w-full'>Opponent Win or Draw</h1>
                            {EachStatus(2, list[2] ? list[2] : 0, 2,"green")}
                        </div>

                    </div>

                </div>

            </div>
        </div>
        )
    }

    const generateTableData = (name,tableDataList) => {

        //console.log(tableDataList)
        var thlist = tableDataList
        return (
            <table className='rounded-lg tb-shadow h-auto w-full bg-white p-2'>
                <tr style={{ backgroundColor: currentTeam && currentTeam.bgcolor }}>
                    <th className='rounded-tl-md uppercase w-full text-white text-left p-3'
                        style={{ backgroundColor: currentTeam && currentTeam.bgcolor }}>{name}</th>
                    <th className='rounded-tr-md w-1/2'>{""}</th>
                </tr>

                {
                    thlist.map((item) => {
                        return <tr className=''>
                            <td className='capitalize text-left text-black pl-5 w-1/2 py-3'>{item.name.replaceAll("_", " ")}</td>
                            <td className='w-1/2 text-black pr-5'>{item.content}</td>
                        </tr>
                    })
                }
            </table>
        )
    }

    const eachCorrectScoreData =(item)=>{
        return <div className='border border-gray-200 w-full flex flex-auto rounded-xl my-2'>
            <h5 className="capitalize text-left text-black p-4 w-3/4">{item.name.replaceAll("_", " ")}</h5>
            <h2 className='text-black bg-gray-100 text-lg font-bold w-1/4 lg:p-4 xl:py-4 xl:px-0 2xl:p-4 m-0'>{item.content}</h2>
        </div>
    }

    const correctScoreTableData = (name,correctscoreList) => {

        var winList = correctscoreList.win
        var drawList = correctscoreList.draw
        var lossList = correctscoreList.loss

        return (
            <div className="my-4 rounded-lg">

            <div style={{ backgroundColor: currentTeam && currentTeam.bgcolor }} className="w-full">
                <h1 className="rounded-tl-md uppercase w-full text-white text-left p-3 font-bold">{name}</h1>
            </div>

            <div className="flex flex-auto justify-between">
            
            <div className="grid grid-cols-1 gap-2 xl:grid-cols-3 w-full px-2">
                <div className="">
                {
                    winList.map(item =>{
                        return eachCorrectScoreData(item)
                    })
                }
                </div>

                <div className="">
                {
                    drawList.map(item =>{
                        return eachCorrectScoreData(item)
                    })
                }
                </div>

                <div className="">
                {
                    lossList.map(item =>{
                        return eachCorrectScoreData(item)
                    })
                }
                </div>
            </div>
            </div>
            </div>
        )
    }

    const OverUnderCustomGraph=(overunderList)=>{
        return overunderList.map((item,index) =>{
            return <div className={index == 0 ? 'border-b-1 border-gray-100 w-full m-auto':'w-full m-auto '}>
                <div className="flex flex-auto flex-cols m-auto">
                    <div className="flex flex-auto align-center items-center">

                        <div className="w-2/5">
                            <h1 className="text-md text-black text-left">{item.name}</h1>
                        </div>

                        <div className="w-2/5">
                            <h1 className="text-lg font-bold" style={{color:'#339410'}}>{item.percentage+'%'}</h1>
                        </div>

                        <div className="w-1/5 border">
                            <div style={{height:Math.round(item.percentage*2)+'px',background:'#339410'}} 
                            className="w-full"></div>
                        </div>

                    </div>
                </div>
            </div>
        })
    }


    return (
        <div className='overflow-hidden'>
            <AppBar his={props.history} />

            <div className='team-detail-com w-full sm:w-full md:w-full h-full lg:w-4/5 
                m-auto xl:w-3/4 2xl:w-3/4 p-3 xl:p-0 2xl:p-0 2xl:my-5'>
                <div className='xl:flex 2x:flex xl:flex-1 2xl:flex-1'>

                    <div className='mx-1 w-full'>

                        <AdSenseCom />

                        <PageBar his={props.history} />

                        {
                            currentTeam &&
                            <div style={{ backgroundColor: currentTeam.bgcolor }} className='p-3 border border-gray-700 rounded-lg shadow-xl flex flex-1 flex-row items-center justify-start'>
                                <div className='team-name w-full'>
                                    <h1 className='text-2xl text-left mx-4 font-bold uppercase text-white'>
                                        { hthcurrentTeam ? currentTeam.name+' vs\t'+ hthcurrentTeam.name : currentTeam.name}
                                    </h1>
                                </div>
                            </div>
                        }

                        <div className='team-container rounded-lg mt-4 mb-0 border border-gray-700 flex flex-1 flex-row items-center 
                            w-full justify-around lg:justify-start xl:justify-start 2xl:justify-start'>

                            <a className={cat === 1 ?
                                'text-md xl:text-lg 2xl:text-lg mx-0 my-0 px-1 text-white float-left text-center xl:px-6 2xl:px-6 py-3 no-underline text-black cursor-pointer capitalize font-bold rounded-l-md'
                                :
                                'text-md xl:text-lg 2xl:text-lg float-left text-center text-white px-1 lg:px-6 xl:px-6 2xl:px-6 py-3 no-underline text-black cursor-pointer capitalize font-bold'}
                                onClick={() => onchangecat(1)}
                                style={{ borderBottom: cat === 1 ? '4px solid #ff0058' : 'transparent' }}>player stats</a>

                            {
                                hthcurrentTeam ? <></>
                                :
                                <a className={cat === 2 ?
                                'text-md xl:text-lg 2xl:text-lg mx-0 lg:mx-2 xl:mx-2 2xl:mx-2 text-white float-left text-center xl:px-6 2xl:px-6 px-2 py-3 no-underline text-black cursor-pointer capitalize font-bold'
                                :
                                'text-md xl:text-lg 2xl:text-lg float-left text-center text-white lg:px-6 xl:px-6 2xl:px-6 mx-2 py-3 no-underline text-black cursor-pointer capitalize font-bold'}
                                onClick={() => onchangecat(2)}
                                style={{ borderBottom: cat === 2 ? '4px solid #ff0058' : 'transparent' }}>team stats</a>
                            }
                  
                        </div>

                        
                        <div className='w-full flex flex-1 flex-row' style={{position:'relative'}}>
                            {
                                teamData && <div className='w-full lg:w-4/5 xl:w-4/5 2xl:w-4/5 rounded-xl'>

                                {/* team state 1*/}
                                {
                                    teamData && cat === 2 && <div className='grid grid-cols-3 gap-0 lg:gap-4 justify-center align-center items-center w-full xl:mt-4 2xl:mt-4 overflow-hidden'>
                                    
                                    <a className={teamstats === 1 ?
                                        'text-sm w-auto xl:text-lg 2xl:text-lg mx-0 lg:mx-2 xl:mx-2 2xl:mx-2 px-1 text-white xl:px-6 2xl:px-6 text-center px-0 py-3  no-underline text-black cursor-pointer capitalize font-bold'
                                        :
                                        'text-sm w-auto xl:text-lg 2xl:text-lg text-center text-white px-0 lg:px-6 xl:px-6 2xl:px-6 py-3 no-underline text-black cursor-pointer capitalize font-bold '
                                    }
                                    onClick={() => onChangeTeamStats(1)}
                                    style={{ borderBottom: teamstats === 1 ? '4px solid #ff0058' : 'transparent' }}>team Results</a>

                                    <a className={teamstats === 2 ?
                                    'text-sm w-auto xl:text-lg 2xl:text-lg mx-0 lg:mx-2 xl:mx-2 2xl:mx-2 px-1 text-white xl:px-6 2xl:px-6 text-center px-0 py-3  no-underline capitalize  text-black cursor-pointer font-bold'
                                    :
                                    'text-sm w-auto xl:text-lg 2xl:text-lg  text-center text-white px-0 lg:px-6 xl:px-6 2xl:px-6  py-3 no-underline text-black capitalize cursor-pointer font-bold '
                                    }
                                    onClick={() => onChangeTeamStats(2)}
                                    style={{ borderBottom: teamstats === 2 ? '4px solid #ff0058' : 'transparent' }}>team overs/Unders</a>

                                    <a className={teamstats === 3 ?
                                        'text-sm w-auto xl:text-lg 2xl:text-lg mx-0 lg:mx-2 xl:mx-2 2xl:mx-2 px-1 text-white xl:px-6 2xl:px-6 text-center px-0 py-3  no-underline text-black capitalize cursor-pointer font-bold break-words'
                                        :
                                        'text-sm w-auto xl:text-lg 2xl:text-lg text-center text-white px-0 lg:px-6 xl:px-6 2xl:px-6  py-3 no-underline text-black capitalize cursor-pointer font-bold break-words '
                                    }
                                    onClick={() => onChangeTeamStats(3)}
                                    style={{ borderBottom: teamstats === 3 ? '4px solid #ff0058' : 'transparent' }}>team Correct/Exact scores</a>

                                    </div>
                                }

                                {/* team state 2*/}

                                {
                                    teamData && cat === 1 && <div className='grid grid-cols-2 gap-4 w-full xl:mt-4 2xl:mt-4 overflow-hidden'>

                                    <a className={playerstats === 1 ?
                                    'text-md w-max xl:text-lg 2xl:text-lg mx-0 lg:mx-2 xl:mx-2 2xl:mx-2 px-1 text-white xl:px-6 2xl:px-6 text-center px-0 py-3  no-underline text-black cursor-pointer capitalize font-bold'
                                    :
                                    'text-md w-max xl:text-lg 2xl:text-lg  text-center text-white px-0 lg:px-6 xl:px-6 2xl:px-6 mx-2 py-3 no-underline text-black cursor-pointer capitalize font-bold'
                                    }
                                    onClick={() => onChangePlayerStats(1)}
                                    style={{ borderBottom: playerstats === 1 ? '4px solid #ff0058' : 'transparent' }}>Goals/Assists</a>

                                    <a className={playerstats === 2 ?
                                        'text-md w-max xl:text-lg 2xl:text-lg mx-0 lg:mx-2 xl:mx-2 2xl:mx-2 px-1 text-white xl:px-6 2xl:px-6 text-center px-0 py-3  no-underline text-black cursor-pointer capitalize font-bold'
                                        :
                                        'text-md w-max xl:text-lg 2xl:text-lg  text-center text-white px-0 lg:px-6 xl:px-6 2xl:px-6 mx-2 py-3 no-underline text-black cursor-pointer capitalize font-bold'
                                    }
                                    onClick={() => onChangePlayerStats(2)}
                                    style={{ borderBottom: playerstats === 2 ? '4px solid #ff0058' : 'transparent' }}>Shots on Target</a>

                                    </div>
                                }

                                {
                                    teamData && cat === 2 && teamstats === 1 && <div>
                                        <div className='rounded-lg overflow-hidden pt-3'>
                                        {WinOrLossGraph(teamData.win_draw_loss ? teamData.win_draw_loss : [],"Win/ Draw/ Loss")}</div>

                                        {/* <div className='rounded-lg overflow-hidden pt-3'>
                                        {DoubleChanceGraph(teamData.double_chance ? teamData.double_chance : [],"Double Chance")}</div> */}

                                        <div className="w-auto h-auto mx-1 bg-white p-5 rounded-lg my-5">
                                        {LineGraphYesNo(teamData.both_team_yes_no ? teamData.both_team_yes_no : [],"Both Teams to Score")}
                                        </div>

                                        <div className="w-auto h-auto mx-1 bg-white p-5 rounded-lg my-5">
                                        {LineGraph(teamData.both_team_wdl ? teamData.both_team_wdl : [],"Result & Both to Score")}
                                        </div>

                                        <div className='rounded-lg overflow-hidden pt-3 grid grid-cols-1 gap-5 xl:grid-cols-3 p-0'>
                                            <div>
                                            {generateTableData('Team Averages',teamData.team_averages ? teamData.team_averages : [])}
                                            </div>
                                        </div>
                                    </div>  
                                }

                                {
                                    currentTeam && teamData && cat === 2 && teamstats === 2 && <div>
                                        
                                        <div className='rounded-lg overflow-hidden pt-3 grid grid-cols-1 gap-5 xl:grid-cols-3 p-0'>

                                            <div className="w-full h-auto mx-1 bg-white p-5 rounded-lg">
                                                <div>
                                                    <h1 className="text-xl font-bold text-black">Over / Under 2.5 Goals</h1>
                                                </div>
                                                <div className="w-full mt-14">
                                                    {OverUnderCustomGraph(teamData && teamData.over2_5goals)}
                                                </div>
                                            </div>

                                            <div>
                                                {generateTableData(currentTeam.name+ ' goals over',teamData && teamData.total_goals_over_under.over4_5List)}
                                            </div>

                                            <div>
                                                {generateTableData(currentTeam.name+ ' goals under',teamData && teamData.total_goals_over_under.under4_5List)}
                                            </div>

                                            <div>
                                                {generateTableData("Total Match"+ ' goals over',teamData && teamData.total_goals_over_under.over5_5List)}
                                            </div>

                                            <div>
                                                {generateTableData("Total Match"+ ' goals under',teamData && teamData.total_goals_over_under.under5_5List)}
                                            </div>

                                            <div>
                                                {generateTableData(currentTeam.name+' Goals',teamData && teamData.team_score)}
                                            </div>

                                            <div>
                                                {generateTableData('Goals Against '+currentTeam.name,teamData && teamData.opposition_team_score)}
                                            </div>

                                        </div>
                                    </div>
                                }

                                {
                                    currentTeam && teamData && cat === 2 && teamstats === 3 && <div>
                                        <div className='rounded-lg overflow-hidden pt-3 grid grid-cols-1 gap-5 xl:grid-cols-3 p-0'>
                                            
                                            <div>
                                                {generateTableData('exact total match goals',teamData && teamData.total_match_goals)}
                                            </div>

                                            <div>
                                                {generateTableData('Exact '+currentTeam.name+' Goals',teamData && teamData.exact_team_score)}
                                            </div>

                                            <div>
                                                {generateTableData('Exact Goals Against '+currentTeam.name,teamData && teamData.exact_opposition_team_score)}
                                            </div>

                                            <div>
                                                {generateTableData('clean sheet',teamData && teamData.clean_sheet)}
                                            </div>

                                        </div>
                                        <div>
                                            <div className="bg-white">
                                                {correctScoreTableData('Correct Scores',teamData && teamData.correct_scores)}
                                            </div>
                                        </div>
                                    </div>
                                }

                                {
                                    cat==1 && playerList && currentTeam && <div className='overflow-x-auto'> 
                                        <EPLPlayerTable bgcolor={currentTeam.bgcolor}
                                        hthteamid = {hthcurrentTeam && hthcurrentTeam.id}
                                        curretteamid = {currentTeam && currentTeam.id}
                                        headerData = {
                                            playerstats == 1 ?
                                            headtohead ? ["team"].concat(EPLGoalsHeader) : EPLGoalsHeader
                                            : headtohead ? ["team"].concat(EPLShotsOnTargetHeader) : EPLShotsOnTargetHeader
                                        }
                                        cat={playerstats} 
                                        player_list={hthPlayerList ? playerList.concat(hthPlayerList) : playerList} 
                                        his={props.history} />
                                    </div>
                                }

                                {
                                    teamData && cat ===2 && teamstats == 1 && currentTeam && <div className='overflow-x-auto'> 
                                        <EPLPlayerTable bgcolor={currentTeam.bgcolor}
                                        headerData = { resultTBHeader }
                                        cat={3} player_list={teamData.result_table} his={props.history} />
                                    </div>
                                }

                                {
                                    loader && <AppLoader content='Getting team players' active />
                                }

                                {
                                    cat == 1 &&  playerList &&
                                    <div className='w-4/5 rounded-lg py-4'>
                                        <h1 className='text-left px-4 py-4 text-gray-600'>Legend</h1>
                                        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-2'>
                                            {
                                                EPLLengendList.map((item, index) => {
                                                    if (playerstats === 1) { if (index >= 0 && index <= 10) return Fullform(item) }
                                                    if (playerstats === 2) { if (index >= 11 && index <= 19) return Fullform(item) }
                                                })
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                                 
                            }
                            


                            {
                                currentTeam &&
                                <div className='w-1/5 ml-1 hidden lg:block xl:block 2xl:block rounded-lg'>
                                    <table className='w-full overflow-hidden mt-8 rounded-md shadow-2xl text-base xl:text-sm 2xl:text-lg'>
                                        <tr>
                                            <th className='text-white p-2 text-left px-3' style={{ backgroundColor: currentTeam.bgcolor }}>EPL Teams</th>
                                        </tr>
                                        {
                                            !teamloader && teamList && teamList.map(item => {
                                                return <tr onClick={() => redirectToTeam(item.id)} className='cursor-pointer'>
                                                    <td ><div className='flex flex-1 justify-between px-2 py-3 xl:px-2 2xl:px-3 items-center'>
                                                        <p className='uppercase font-bold text-left text-xs xl:text-xs 2xl:text-base'>
                                                            {/* {item.name.length > 15 ? item.name.substr(0, 15) + '..' : item.name} */}
                                                            {item.short_form}
                                                        </p>
                                                        <img src={item.image} className='w-6 h-6 object-cover' />
                                                    </div></td>
                                                </tr>
                                            })
                                        }
                                    </table>

                                </div>
                            }
                        </div>
                    </div>

                    <div className="xl:w-1/3 2xl:w-1/3">
                        {!loader && <SponserListCom />}
                    </div>

                </div>
            </div>
            
            {!loader &&  <CopyRights his={props.history}/>}
            
        </div>
    )
}
export default withRouter(EPLTeam);