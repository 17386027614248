import React from "react";
import logo from "../../assets/Logo/logo.png";

export const TermsOfUse = (props) => {
  return (
    <div className="p-5">
      <img
        src={logo}
        className="cursor-pointer w-full h-auto px-12"
        onClick={() => null}
      />

      <div className="my-5">
        <p className="text-left font-bold text-lg">{`Payment Terms & Conditions`}</p>
        <p className="text-left my-4 leading-8">{`Multi Hub provides three packages to choose from for each AFL and Horse Racing Tips. AFL Tips includes one round access which is a one-off payment and gives you access for the upcoming round as AFL, Full/Remaining AFL season including finals is a one-off payment valued at $8/week per round and each week of finals, weekly subscription which is billed as a weekly prepayment of $9.99. At the end of the season your weekly subscription will pause following Grand Final Day and automatically renew the week of round 1 or the first game of the official AFL season. Horse Racing Tips subscription packages, weekly which is billed as a weekly prepayment of $9.99, 6 months which is billed as a 6 month prepayment of $229.99, yearly which is billed as a yearly prepayment of $399.99. Customers can cancel at anytime.`}</p>
        <p className="text-left my-6 leading-8">{`Subscription renewals occur unless you or we cancel the subscription. Weekly subscription may be cancelled at any time in the app. Go to More > Payments > Cancel Subscription to do so. Multi Hub reserves the right to cancel a subscription at any time.`}</p>
        <p className="text-left my-6 leading-8">{`This is not financial advice and Multi Hub does not offer refunds.`}</p>
        <p className="text-left my-6 leading-8">{`This is not financial advice and Multi Hub does not offer refunds. The information provided on the app is of general nature and is for informational purposes only. It does not take into account the financial situation, objectives or needs of any person. You should not make any investment decisions based solely on the information presented herein. Before making any investment decision, you must consider whether it is appropriate for you in light of your personal circumstances and seek professional investment, tax, and/or personal financial advice. To the maximum extent permitted by law, Multi Hub, its related bodies corporate, officers, directors and employees do not accept liability for any loss or damage arising out of or in connection with any reliance on the information given in this app. Any opinions expressed on the app may be subject to change without notice. Multi Hub does not guarantee the performance, or rate of return, or repayment of capital, or payment of income from any investment referred to on this app. Gambling involves risks and investments. Past performance is not an indicator of future results.`}</p>
        <p className="text-left my-6 leading-8">{`WHAT ARE YOU PREPARED TO LOSE? SET A DEPOSIT LIMIT.`}</p>
        <p className="text-left my-6 leading-8">{`For free and confidential support call 1800 858 858 or visit`} <a target="_blank" className="underline cursor-pointer" href="http://gamblinghelponline.org.au">{`Gambling Help Online`}</a></p>
         
      </div>
    </div>
  );
};
