import React from "react";
import "../../utils/styles/privacypolicy/privacypolicy.scss";
import AppBar from "../AppBar/AppBar";
import { withRouter } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { ResetPassword_Api } from "../../Busniess_Logic/API/ResetPasswordApi";
import { password_validation } from "../Utils/Utils";

function ResetPassword(props) {
  const [decoded_user, setuser] = useState("");

  const [password, setpassword] = useState("");
  const [confirm_password, setconfirm_password] = useState("");

  const [password_err, setpassword_err] = useState(false);
  const [confirm_password_err, set_confirm_password_err] = useState(false);

  const [show_password, setshowpassword] = useState(false);
  const [show_confirm_password, setshow_confirm_password] = useState(false);

  const [loading, setloader] = useState(false);
  const [isResetted, setresetted] = useState(false);

  useEffect(() => {
    let user = new URL(window.location.href).searchParams.get("id");
    // var b = Buffer.from(email, "base64");

    setuser(user);
  }, []);

  const validatePassword = (password_value) => {
    const patteren = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );

    if (
      password_value !== "" &&
      password_value !== null &&
      password_value !== undefined
    ) {
      if (patteren.test(password_value)) return false;
      else return true;
    }
    return false;
  };

  const validate_password = (txt) => setpassword_err(password_validation(txt));
  const validate_confirm_password = (txt) =>
    set_confirm_password_err(password !== txt);

  const isSubmitBtnEnabled = () =>
    password !== "" &&
    confirm_password !== "" &&
    !password_err &&
    !confirm_password_err;

  const Resetpassword = async () => {
    if (decoded_user) {
      setloader(true);

      try {
        let reset_data = {
          id: decoded_user,
          password: password,
        };

        const res = await ResetPassword_Api(reset_data);
        console.log(res);

        if (res)
          if (res.success) {
            setloader(false);
            setresetted(true);
          } else window.alert(res.response);
      } catch (error) {
        setloader(false);
      } finally {
        setloader(false);
      }
    } else window.alert("User not found..");
  };

  if (!isResetted)
    return (
      <div>
        <AppBar his={props.history} />
        <div
          className="flex flex-auto justify-center items-center "
          style={{ height: "85vh" }}
        >
          <div className="border border-gray-500 p-3 w-10/12 md:w-1/3 xl:w-1/4 2xl:w-1/5 rounded-md">
            <h3 className="text-xl font-medium">Reset Password</h3>

            <div className="my-5 p-2">
              <div className="xl:my-3 2xl:my-5">
                <p className="text-left text-xs">Enter new password *</p>
                <div className="flex flex-row items-center justify-between">
                  <input
                    type={show_password ? "text" : "password"}
                    onChange={(event) => setpassword(event.target.value)}
                    value={password}
                    placeholder="Eg: John@1234"
                    className="w-full my-2 rounded-md p-1.5 focus:outline-none bg-transparent border border-gray-500"
                    onBlur={(event) => validate_password(event.target.value)}
                  />
                  <a
                    className="cursor-pointer text-xs ml-1"
                    onClick={() => setshowpassword(!show_password)}
                  >
                    {show_password ? `hide` : `show`}
                  </a>
                </div>

                {password_err && (
                  <p className="text-red-500 text-sm text-left mb-4">{`Invalid password / Password should be contain uppercase, lowercase, alphanumeric and special characters`}</p>
                )}
              </div>

              <div className="xl:my-3 2xl:my-5">
                <p className="text-left text-xs">Confirm new password *</p>
                <div className="flex flex-row items-center justify-between">
                  <input
                    type={show_confirm_password ? "text" : "password"}
                    onChange={(event) =>
                      setconfirm_password(event.target.value)
                    }
                    value={confirm_password}
                    placeholder="Eg: John@1234"
                    className="w-full my-2 rounded-md p-1.5 focus:outline-none bg-transparent border border-gray-500"
                    onBlur={(event) =>
                      validate_confirm_password(event.target.value)
                    }
                  />
                  <a
                    className="cursor-pointer text-xs ml-1"
                    onClick={() =>
                      setshow_confirm_password(!show_confirm_password)
                    }
                  >
                    {show_confirm_password ? `hide` : `show`}
                  </a>
                </div>

                {confirm_password_err && (
                  <p className="text-red-500 text-sm text-left mb-4">{`Password not match`}</p>
                )}
              </div>

              <div className="mt-2">
                <button
                  type="submit"
                  disabled={!isSubmitBtnEnabled() || loading}
                  onClick={Resetpassword}
                  style={{ backgroundColor: "#fd0356" }}
                  className=" w-full p-2 rounded-lg font-semibold bg-[#fd0356]"
                >
                  {loading ? `Resetting Password...` : `ResetPassword`}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  else
    return (
      <div>
        <AppBar his={props.history} />

        <div
          className="flex flex-auto justify-center items-center "
          style={{ height: "70vh" }}
        >
          <div className="border border-gray-500 p-3 w-10/12 md:w-1/3 xl:w-1/4 2xl:w-1/5 rounded-md">
            <h3 className="text-xl font-medium">
              Password Resetted successfully
            </h3>

            <div className="mt-5">
              <button
                type="submit"
                onClick={() => props.history.push("/")}
                style={{ backgroundColor: "#fd0356" }}
                className=" w-full p-2 rounded-lg font-semibold bg-[#fd0356]"
              >
                Visit home
              </button>
            </div>
          </div>
        </div>
      </div>
    );
}
export default withRouter(ResetPassword);
