import { SponserList } from "./utils";

// import { SponserList } from "../../utils/Appconstant";
export const BetHere = (props) => {
  const SponerUI = (item) => {
    return (
      <a
        // className={item.bgcolor}
        className={`m-2 rounded-lg items-center ${item.bgcolor}`}
        href={item.url}
        key={item.id}
        onClick={()=> window.ReactNativeWebView.postMessage('bet_click')}
        style={{ background:item.bgcolor,width:'43%' }}
      >
        <div>
          <img
            src={item.image}
            className="w-full h-24 capitalize object-contain rounded-lg"
          />
        </div>
      </a>
    );
  };

  return (
    <div>
    <div className="h-full p-8 w-full m-auto flex flex-row justify-center">
    <div className="w-full md:w-1/3 flex flex-wrap h-full mx-0 xl:mx-3">
      {SponserList.map((item) => {
        return SponerUI(item);
      })}
    </div>
    </div>
    </div>
  );
};


