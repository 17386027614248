import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import '../../utils/styles/teamdetail/teamdetail.scss'
import AppBar from "../AppBar/AppBar";
import { GetTeamDetails, GetNRLTeamPlayersList, GetNRLTeams } from '../../Busniess_Logic/API/NRLApi'
import { NRLTbHoverList,NRLTriresHeader } from "./NRL_Constant";
import PageBar from "../AppBar/PageBar";
import { SponserListCom } from "../SponerList/SponserList";
import NRLPlayerTable from "./NRLPlayerTable";
import AppLoader from "../Loader/Loader";
import CopyRights from "../CopyRights";
import { marginTBHeader } from "../AFL/AFL_Constants";
import AdSenseCom from "../AdSense/AdSense";


function NRLTeam(props) {

    const [cat, setCat] = useState(1)
    const [teamid, setTeamid] = useState(null)
    const [playerList, setPlayerlist] = useState(null)

    const [currentTeam, setcurrentTeam] = useState(null)
    const [teamList, setTeamlist] = useState([])

    const [hthPlayerList, setHTHPlayerlist] = useState(null)
    const [hthcurrentTeam, setHthcurrentTeam] = useState(null)
    const [loader, setLoader] = useState(false)
    const [teamloader, setTeamloader] = useState(false)

    const [teamMargin, setMargin] = useState([])
    const [teamForagainst, setForagainst] = useState([])
    const [teamHistory, setTeamhis] = useState(null)
    const [currentSeason, setSeason] = useState("")

    var url = new URL(window.location.href);
    var id = url.searchParams.get("id");
    var page = url.searchParams.get("p");
    var season = url.searchParams.get("s");
    var headtohead = url.searchParams.get("hth");

    useEffect(() => {

        if(page){
            if (page == 'ts') setCat(1)
            else if (page == 'm') setCat(2)
            else if (page == 'pf') setCat(3)
            else if (page == 'pa') setCat(3)
        }

        if(season) setSeason(season)
        
        setTeamid(id ? id :"386")
        GetTeamUserData(id ? id :"386",season ? season : "" )
        getTeamsData(id ? id :"386",season ? season : "",headtohead)

        //head to head
        if(headtohead) 
        GetTeamHeadToHeadUserData(headtohead, season ? season : '2022' )

        window.addEventListener('popstate', (event) => {

            var url = new URL(window.location.href);
            var id = url.searchParams.get("id");
            if (teamid && id) if (id !== teamid) {
                //console.log('page reloaded')
                window.location.reload()
            }

        });

        return () => {
            window.removeEventListener('popstate', () => { })
        }

    }, [])

    const GetTeamHeadToHeadUserData = async (id,season) => {
        setLoader(true)
        const res = await GetNRLTeamPlayersList(id,season)
        if (res) setHTHPlayerlist(res)
        setLoader(false)
    }

    const getTeamsData = async (id,season,hthteamid) => {

        setTeamloader(true)
        var list = []
        const res = await GetNRLTeams( season == "2021" ? "" : season )
        if (res) {
            res.response.forEach(item => {
                if (item.id === parseInt(id)) {
                    setcurrentTeam(item)
                    getteamS3Data(item.team_id,season)
                }else if(hthteamid){
                    if(item.id == parseInt(hthteamid)) setHthcurrentTeam(item)
                }
                list.push(item)
            })

            list.sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                }
                return 0;
            })

            list = list.filter((a)=> a.id!= parseInt(id))
            if(hthteamid)
            list = list.filter((a)=> a.id!= parseInt(hthteamid))

            setTeamlist(list)
            setTeamloader(false)
        }
    }

    const GetTeamUserData = async (id,season) => {

        setLoader(true)
        const res = await GetNRLTeamPlayersList(id,season)
        if (res) setPlayerlist(res)
        setLoader(false)

    }

    const redirectToTeam = (id) => {
        // props.history.push('nrlteam?id=' + id)
        if (headtohead) props.history.push(`nrlteam?id=` + id+`&p=${page}&s=${season}&hth=${headtohead}`)
        else props.history.push(`nrlteam?id=` + id+`&p=${page}&s=${season}`)
        window.location.reload()
    }

    const Fullform = (item) => {
        return <div className='flex flex-1 my-1 w-full'>
            <h1 className='uppercase mx-4 font-bold w-10 xl:w-8 2xl:w-14 text-left text-sm 2xl:text-lg'>{item.name}</h1>
            -
            <p className='capitalize mx-4 text-sm 2xl:text-lg'>{item.fullform}</p>
        </div>
    }

    const onchangecat = (catid) => {
        setCat(catid)
        var href = new URL(window.location.href);

        if (catid === 1) href.searchParams.set('p', 'ts');
        else if (catid === 2) href.searchParams.set('p', 'm');
        else if (catid === 3) href.searchParams.set('p', 'pf');
        else if (catid === 4) href.searchParams.set('p', 'pa');
        
        window.history.replaceState('', '', href.toString())
    }

    const getteamS3Data = async (team_id,season) => {

        // setCalled(true)
        console.log('season',season,team_id)
        setTeamloader(true)
        const res = await GetTeamDetails(team_id,season==""?"2021":season)
        if (res) {
            setTeamhis(res)
            generateMarginData(res.data, res.margin)
            generateForAgainstData(res.data, res.points_for, res.points_against)
        }
        setTeamloader(false)

    }

    const generateMarginData = (datalen, list) => {
        var tempList = []

        tempList.push({ 'name': " Win 1-12 ", "content": ((list[0].content / datalen.length) * 100).toFixed(2) })
        tempList.push({ 'name': " Win 13+ ", "content": ((list[1].content / datalen.length) * 100).toFixed(2) })

        tempList.push({ 'name': " Lose 1-12 ", "content": ((list[2].content / datalen.length) * 100).toFixed(2) })
        tempList.push({ 'name': " Lose 13+ ", "content": ((list[3].content / datalen.length) * 100).toFixed(2) })

        setMargin(tempList)
    }

    const generateForAgainstData = (datalen, forlist, againstlist) => {
        var tempList = []

        tempList.push({ 'name': " 0-9 ", "content": ((forlist[0].content / datalen.length) * 100).toFixed(2) })
        tempList.push({ 'name': " 10-19 ", "content": ((forlist[1].content / datalen.length) * 100).toFixed(2) })
        tempList.push({ 'name': " 20-29 ", "content": ((forlist[2].content / datalen.length) * 100).toFixed(2) })
        tempList.push({ 'name': " 30-39 ", "content": ((forlist[3].content / datalen.length) * 100).toFixed(2) })
        tempList.push({ 'name': " 40-49 ", "content": ((forlist[4].content / datalen.length) * 100).toFixed(2) })
        tempList.push({ 'name': " 50+ ", "content": ((forlist[5].content / datalen.length) * 100).toFixed(2) })

        tempList.push({ 'name': " 0-9 ", "content": ((againstlist[0].content / datalen.length) * 100).toFixed(2) })
        tempList.push({ 'name': " 10-19 ", "content": ((againstlist[1].content / datalen.length) * 100).toFixed(2) })
        tempList.push({ 'name': " 20-29 ", "content": ((againstlist[2].content / datalen.length) * 100).toFixed(2) })
        tempList.push({ 'name': " 30-39 ", "content": ((againstlist[3].content / datalen.length) * 100).toFixed(2) })
        tempList.push({ 'name': " 40-49 ", "content": ((againstlist[4].content / datalen.length) * 100).toFixed(2) })
        tempList.push({ 'name': " 50+ ", "content": ((againstlist[5].content / datalen.length) * 100).toFixed(2) })

        //console.log(tempList)
        setForagainst(tempList)
    }

    const teamHistoryData = (tableData, is_between) => {

        //console.log(tableData)
        var thlist = tableData
        return (
            <table className='rounded-lg tb-shadow h-auto w-full bg-white p-2'>
                <tr style={{ backgroundColor: currentTeam.bgcolor }}>
                    <th className='rounded-tl-md uppercase w-full text-white text-left p-3'
                        style={{ backgroundColor: currentTeam.bgcolor }}>{is_between ? 'Points For' : 'Points Against'}</th>
                    <th className='rounded-tr-md w-1/2'>{""}</th>
                </tr>

                {
                    thlist.map((item) => {
                        return <tr className=''>
                            <td className='capitalize text-left text-black pl-5 w-1/2 py-3'>{item.title.replaceAll("_", " ")}</td>
                            {
                                is_between ? <td className='w-1/2 text-black pr-5'>{ Math.abs((parseFloat(item.content).toFixed(0).toString())) }</td>
                                    :
                                    <td className='w-1/2 text-black pr-5'>{ Math.abs(item.content) }</td>
                            }

                        </tr>
                    })
                }
            </table>
        )
    }

    const teamMarginTB = (title,tableData,total_game) => {

        var thlist = tableData
        return (
            <table className='rounded-lg tb-shadow h-auto w-full bg-white p-2'>
                <tr style={{ backgroundColor: currentTeam.bgcolor }}>
                    <th className='rounded-tl-md uppercase w-full text-white text-left p-3'
                        style={{ backgroundColor: currentTeam.bgcolor }}>{title}</th>
                    <th className='rounded-tr-md w-1/2'>{""}</th>
                </tr>

                {
                    thlist.map((item) => {
                        let title = item.title.split(' ')
                        return <tr className=''>
                            <td className='capitalize text-left text-black pl-5 w-1/2 py-3'>{title[1]+' '+title[0]}</td>
                            <td className='w-1/2 text-black pr-5'>{ Math.abs((parseFloat(item.content).toFixed(0).toString())) + '/'+total_game }</td>
                        </tr>
                    })
                }
            </table>
        )
    }

    const EachStatus = (index, item, divide_index) => {
        //console.log('EachStatus', item)
        return <div className='my-0 lg:my-2 xl:my-2 2xl:my-2 mx-2 py-2'>

            <div className='flex flex-1 justify-between px-2  my-1 m-auto w-full'>
                <h1 className='text-md m-0 p-0 text-black'>{item.name}</h1>
                <h1 className={index <= divide_index ? 'text-md m-0 p-0 text-green-600 font-bold' : "text-md m-0 p-0 text-red-600 font-bold"}>{parseFloat(item.content).toFixed(0) + "%"}</h1>
            </div>
            <div className='flex flex-1 justify-center  my-2'>
                {/* <progress id="green" value={item.content} max="100" className='w-1/2 mx-1 rtl' /> */}
                <progress id={index > divide_index ? "red" : "green"} value={parseFloat(item.content).toFixed(0)} max="100" className='w-full mx-1 rounded-xl ' />
            </div>

        </div>
    }

    const PlayedMatchesGraph = (list, divide_index, title, left_title, right_title) => {

        return (<div className='my-5 rounded-lg'>
            <div className='bg-white p-5 rounded-lg'>

                <div>
                    <h1 className='text-lg text-bold text-black 
                        text-left py-0 my-2 lg:py-5 xl:py-5 2xl:py-5 capitalize'>{title}</h1>
                </div>

                <div className='py-2'>

                    <div className='flex-none flex-0 xl:flex xl:flex-1 xl:flex-row 2xl:flex-row 2xl:flex 2xl:flex-1 justify-between '>

                        <div className='w-full'>
                            <h1 className='text-black font-bold text-left text-xl py-2 m-0 px-4 capitalize'>{left_title}</h1>
                            {
                                teamHistory && list.map((item, index) => {
                                    if (index <= divide_index)
                                        return EachStatus(index, item, divide_index)
                                })
                            }
                        </div>

                        <div className='w-full'>
                            <h1 className='text-black font-bold text-left text-xl py-2 px-4 capitalize'>{right_title}</h1>
                            {
                                teamHistory && list.map((item, index) => {
                                    if (index > divide_index)
                                        return EachStatus(index, item, divide_index)
                                })
                            }
                        </div>

                    </div>

                </div>

            </div>
        </div>
        )
    }




    return (
        <div className='overflow-hidden'>
            <AppBar his={props.history} />

            <div className='team-detail-com w-full sm:w-full md:w-full h-full lg:w-4/5 m-auto xl:w-3/4 2xl:w-3/4 p-3 xl:p-0 2xl:p-0 2xl:my-5 '>
                <div className='xl:flex 2x:flex xl:flex-1 2xl:flex-1'>

                    <div className='mx-1 w-full'>

                        <AdSenseCom />

                        <PageBar his={props.history} />

                        {
                            currentTeam &&
                            <div style={{ backgroundColor: currentTeam.bgcolor }} className='p-3 border border-gray-700 rounded-lg shadow-xl flex flex-1 flex-row items-center justify-start'>
                                <div className='team-name w-full'>
                                <h1 className='text-2xl text-left mx-4 font-bold uppercase text-white'>
                                        { hthcurrentTeam ? currentTeam.name+' vs\t'+ hthcurrentTeam.name : currentTeam.name}
                                    </h1>
                                </div>
                            </div>
                        }


                        <div className='team-container rounded-lg mt-4 mb-0 border border-gray-700 flex flex-1 flex-row items-center 
                            w-full justify-between lg:justify-start xl:justify-start 2xl:justify-start'>

                            <a className={cat === 1 ?
                                'text-md xl:text-lg 2xl:text-lg mx-0 my-0 px-2 text-white float-left text-center xl:px-6 2xl:px-6 py-3 no-underline text-black cursor-pointer capitalize font-bold rounded-l-md'
                                :
                                'text-md xl:text-lg 2xl:text-lg float-left text-center text-white px-2 lg:px-6 xl:px-6 2xl:px-6 py-3 no-underline text-black cursor-pointer capitalize font-bold'}
                                onClick={() => onchangecat(1)}
                                style={{ borderBottom: cat === 1 ? '4px solid #ff0058' : 'transparent' }}>try scorers</a>

                            {
                                hthcurrentTeam ?<></>:
                                <a className={cat === 2 ?
                                'text-md xl:text-lg 2xl:text-lg mx-0 lg:mx-2 xl:mx-2 2xl:mx-2 px-2 text-white float-left text-center xl:px-6 2xl:px-6 px-6 py-3 no-underline text-black cursor-pointer capitalize font-bold'
                                :
                                'text-md xl:text-lg 2xl:text-lg float-left text-center text-white px-2 lg:px-6 xl:px-6 2xl:px-6 mx-2 py-3 no-underline text-black cursor-pointer capitalize font-bold'}
                                onClick={() => onchangecat(2)}
                                style={{ borderBottom: cat === 2 ? '4px solid #ff0058' : 'transparent' }}>margins</a>
                            }

                            {
                                hthcurrentTeam ? <></>
                                :
                                <a className={cat === 3 ?
                                'text-md w-max xl:text-lg 2xl:text-lg mx-0 lg:mx-2 xl:mx-2 2xl:mx-2 px-1 text-white xl:px-6 2xl:px-6 float-left text-center px-0 py-3  no-underline text-black cursor-pointer capitalize font-bold'
                                :
                                'text-md w-max xl:text-lg 2xl:text-lg float-left text-center text-white px-0 lg:px-6 xl:px-6 2xl:px-6 mx-2 py-3 no-underline text-black cursor-pointer capitalize font-bold'
                                }
                                onClick={() => onchangecat(3)}
                                style={{ borderBottom: cat === 3 ? '4px solid #ff0058' : 'transparent' }}>for / against</a>
                            }
                            
                        </div>


                        <div className='w-full flex flex-1 flex-row' style={{ position: 'relative' }}>


                            <div className='w-full lg:w-4/5 xl:w-4/5 2xl:w-4/5 rounded-xl'>

                                {
                                    teamHistory && cat === 2 && <div className='rounded-lg overflow-hidden pt-3'>
                                        {PlayedMatchesGraph(teamMargin, 1, "big win little win", "win", "loss")}
                                    </div>
                                }

                                {
                                    teamHistory && cat === 2 && <div className='rounded-lg overflow-hidden pt-3 w-full lg:w-1/3'>
                                        {teamMarginTB("Margin",teamHistory.margin,teamHistory.data.length)}
                                    </div>
                                }

                                {
                                    teamHistory && cat === 3 && <div className='rounded-lg overflow-hidden pt-3'>
                                        {PlayedMatchesGraph(teamForagainst, 5, "points for/against", "points for", "points against")}
                                    </div>
                                }

                                {
                                    teamHistory && cat === 3 &&
                                    <div className='grid grid-cols-1 gap-4 xl:grid-cols-2 2xl:grid-cols-2 w-full xl:mt-4 2xl:mt-4 overflow-hidden'>
                                        <div >
                                            {
                                                teamHistory && teamHistory.points_for && teamHistoryData(teamHistory.points_for.slice(6, 11), true)
                                            }
                                        </div>
                                        <div>
                                            {
                                                teamHistory && teamHistory.points_against && teamHistoryData(teamHistory.points_against.slice(6, 10), false)
                                            }
                                        </div>
                                    </div>
                                }

                                {
                                    playerList && currentTeam && cat!=2 && cat!=3 && <div className='overflow-x-auto'> 
                                        <NRLPlayerTable bgcolor={currentTeam.bgcolor}
                                        hthteamid = {hthcurrentTeam && hthcurrentTeam.id}
                                        curretteamid = {currentTeam && currentTeam.id}
                                        headerData = {
                                            headtohead ? ["team"].concat(NRLTriresHeader) : NRLTriresHeader
                                        }
                                        cat={cat} player_list={ hthPlayerList ? playerList.concat(hthPlayerList) : playerList } 
                                        his={props.history} season={currentSeason}/>
                                    </div>
                                }

                                {
                                    playerList && currentTeam && cat == 2 && teamHistory && <div className='overflow-x-auto'> 
                                        <NRLPlayerTable bgcolor={currentTeam.bgcolor}
                                        headerData = {
                                            marginTBHeader
                                        }
                                        cat={cat} player_list={teamHistory.data} his={props.history} season={currentSeason}/>
                                    </div>
                                }

                                {
                                    cat == 2 && teamHistory==null &&
                                    <div className="py-10">
                                        <p>Team details not found..</p>
                                    </div>
                                }

                                {
                                    loader && <AppLoader content='Getting team players' active />
                                }

                            </div>


                            {
                                currentTeam &&
                                <div className='w-1/5 ml-1 hidden lg:block xl:block 2xl:block rounded-lg'>
                                    <table className='w-full overflow-hidden mt-8 rounded-md shadow-2xl text-base xl:text-sm 2xl:text-lg'>
                                        <tr>
                                            <th className='text-white p-2 text-left px-3' style={{ backgroundColor: currentTeam.bgcolor }}>NRL Teams</th>
                                        </tr>
                                        {
                                            !teamloader && teamList && teamList.map(item => {
                                                return <tr onClick={() => redirectToTeam(item.id)} className='cursor-pointer'>
                                                    <td ><div className='flex flex-1 justify-between px-2 py-3 xl:px-2 2xl:px-3 items-center'>
                                                        <p className='uppercase font-bold text-left text-xs xl:text-xs 2xl:text-base'>
                                                            {/* {item.name.length > 15 ? item.name.substr(0, 15) + '..' : item.name} */}
                                                            {item.short_form}
                                                        </p>
                                                        <img src={item.image} className='w-6 h-6 object-cover' />
                                                    </div></td>
                                                </tr>
                                            })
                                        }
                                    </table>

                                </div>
                            }
                        </div>

                        {
                            cat !== 0 && cat !== 4 && playerList &&
                            <div className='w-4/5 rounded-lg py-4'>
                                <h1 className='text-left px-4 py-4 text-gray-600'>Legend</h1>
                                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-2'>
                                    {
                                        NRLTbHoverList.map((item, index) => {
                                            return Fullform(item)
                                        })
                                    }
                                </div>
                            </div>
                        }

                    </div>

                    <div className="xl:w-1/3 2xl:w-1/3">
                        {
                            !loader && <SponserListCom />
                        }
                    </div>

                </div>
            </div>

            { !loader && <CopyRights his={props.history}/> }

        </div>
    )
}
export default withRouter(NRLTeam);