import { useState } from "react";
import {
  GetExpoTokenList_Api,
  PostOfferList_Api,
  SendNotification_Api,
} from "../../../../Busniess_Logic/API/AdminDashboard";
import { useEffect } from "react";

function AddOffer(props) {
  const [title, settitle] = useState("");
  const [content, setcontent] = useState("");

  const [isSchedule, setis_schedule] = useState(false);
  const [schedule, setschedule] = useState("");
  const [tokenlist, settokenlist] = useState([]);

  const [progressList, setprogressList] = useState([]);
  const [isLoading, setloading] = useState(false);
  const [isSubmit, setsubmit] = useState(false);

  const [token_array, settokenarraylist] = useState([]);
  const [update, setupdate] = useState(false);
  const [show_result, setshowresult] = useState(false);

  useEffect(() => {
    async function gettokenlist() {
      try {
        const res = await GetExpoTokenList_Api();
        if (res) {
          settokenlist(res);

          let size = 99;
          let arrayOfArrays = [];

          let token_list_array = res.map((x) => x.token);

          for (let i = 0; i < token_list_array.length; i += size) {
            arrayOfArrays.push(token_list_array.slice(i, i + size));
          }

          setprogressList(new Array(arrayOfArrays.length).fill(false));
          settokenarraylist(arrayOfArrays);
        }
      } catch (error) {
        alert("Cannot get user tokens");
      }
    }

    gettokenlist();
  }, [update]);

  const PostOffer = async () => {
    if (title !== "" && content !== "") {
      if (isSchedule && schedule === "") {
        alert("Please select schedule datetime");
        return;
      }

      let offer_data = {
        title: title,
        content: content,
        created_in: new Date(),
        status: !isSchedule,
        scheduled: isSchedule ? new Date(schedule) : null,
      };

      try {
        setsubmit(true);
        setloading(true);

        const res = await PostOfferList_Api(offer_data);

        if (res) {
          //
          if (!schedule) {
            divideTokenListSendNotificaiton(offer_data);
            setupdate(!update);
            // props.updateList();
          }
        }

        setloading(false);
      } catch (error) {
        setloading(false);
      }
    } else alert("Enter all required fields");
  };

  const divideTokenListSendNotificaiton = (offer_data) => {

    try {

      if (tokenlist) {
        
        let progress = [];

        token_array.map(async (x, i) => {

          await sendNotification(x, offer_data)
          .then((res)=>{
            progress.push({ status:res.status,msg:res.msg });
          })
          .catch((err)=> progress.push(false) );
          
        });

        setTimeout(()=>{

            console.log(progress)
            setprogressList(progress);
            setupdate(!update);
            setshowresult(true);

        },1500)

      }

    } catch (error) {
      setloading(false);
    }
  };

  const sendNotification = async (tokenList, offer_data) => {

    try {
      const result = await SendNotification_Api(tokenList, offer_data);

      if (result.data) {
        let res = result.data[0];

        if (res.status === "ok") {

          return { status:true,msg:'' }

        } else if (res.status === "error") {

          return { status:false,msg:res.details.error }

        }
      } else return { status:false,msg:`Something went wrong` };
    } catch (error) {
      console.log(" Erorr => ", error);
      // alert(error)
      return { status:false,msg:`Something went wrong` }
    }
  };

  return (
    <div className="w-full h-full flex items-center justify-center">
      {!isSubmit ? (
        <form
          className="w-2/5 border border-gray-900 p-8 rounded-lg z-50 drop-shadow-md"
          style={{ backgroundColor: "#121a26" }}
        >
          <div>
            <input
              type="text"
              placeholder="Title"
              value={title}
              onChange={(e) => settitle(e.target.value)}
              className="p-2 px-5 w-full my-2 bg-transparent rounded-lg border border-gray-500 outline-none focus:outline-none"
            />
          </div>

          <div>
            <textarea
              name="content"
              id="content"
              cols="10"
              rows="4"
              value={content}
              onChange={(e) => setcontent(e.target.value)}
              placeholder="Description"
              className="p-2 px-5 w-full my-2 bg-transparent rounded-lg border border-gray-500 outline-none focus:outline-none"
            />
          </div>

          <div class="py-4 flex flex-row justify-start">
            <input
              class="form-check-input  h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              type="checkbox"
              id="flexCheckDefault"
              value={isSchedule}
              onChange={(val) => setis_schedule(!isSchedule)}
            />
            <label
              class="form-check-label inline-block text-white"
              for="flexCheckDefault"
            >
              {`Schedule your post`}
            </label>
          </div>

          {isSchedule && (
            <div>
              <input
                type="datetime-local"
                className="p-2 px-5 w-full my-2 bg-transparent rounded-lg border border-gray-500 outline-none focus:outline-none"
                onChange={(e) => setschedule(e.target.value)}
              />
            </div>
          )}

          <div className="flex items-center justify-between align-middle">
            <button
              type="button"
              style={{ border: "2px solid #fd0356", color: "#fff" }}
              className="w-4/5 p-2 rounded-lg font-semibold bg-[#fd0356] my-5 mx-1"
              onClick={props.closeModal}
            >
              {`Cancel`}
            </button>

            <button
              type="button"
              style={{ backgroundColor: "#fd0356" }}
              onClick={PostOffer}
              // onClick={divideTokenListSendNotificaiton}
              className=" w-4/5 p-2.5 rounded-lg font-semibold bg-[#fd0356] mx-1"
            >
              {schedule ? `Schedule now` : `Send now`}
            </button>
          </div>
        </form>
      ) : (
        <div
          className="w-3/5 border border-gray-900 p-8 rounded-lg z-50 drop-shadow-md"
          style={{ backgroundColor: "#121a26" }}
        >
          {show_result ? (
            <div>
              {progressList && progressList.length !== 0 && token_array ? (
                <div>
                  <p>Notification status</p>
                  {progressList.map((x, i) => {
                    return (
                      <div
                        className="flex flex-1 justify-between items-center my-5"
                        key={i}
                      >
                        <p>{`Status (${token_array[i].length} users)`}</p>

                        <div className="flex flex-col">
                        <p
                          className={
                            !isLoading
                              ? x.status
                                ? "text-green-500"
                                : "text-red-500"
                              : ""
                          }
                        >
                          {isLoading
                            ? `In Progress...`
                            : x.status
                            ? `Sent Sucesssfully `
                            : `Failed to sent`}
                        </p>

                        {
                          x.msg && x.msg!=="" && <p className="text-gray-400 text-xs">{x.msg}</p>
                        }
                        </div>
                        
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div>
                  <p>
                    {isLoading
                      ? `Sending notifications..`
                      : `Notification send successfully`}
                  </p>
                </div>
              )}
            </div>
          ) : (
            <div>
              <p>Sending...</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default AddOffer;
