const BASE_URL = "https://apiv3.multihub.com.au/";

// const BASE_URL = "http://192.168.1.107:8000/";

export const config = {
  bucket: "fantasy-admin-data",
  keyPrefix: "offers/",
  region: "ap-southeast-2",
  accessKey: "AKIAXLVZBLWSZ7TEGT7W",
  secretKey: "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",
};

export async function GetOfferList_Api() {
  const res = await fetch(BASE_URL + "admincontent/offer/", {
    method: "GET",
  });
  console.log(res);
  var jsonData = await res.json();
  console.log(jsonData);
  return jsonData;
}

export async function PostOfferList_Api(offer_data) {
  const res = await fetch(BASE_URL + "admincontent/offer/", {
    method: "POST",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
    body: JSON.stringify(offer_data),
  });
  console.log(res);
  var jsonData = await res.json();
  console.log(jsonData);
  return jsonData;
}

export async function UpdateOffer_Api(offer_data) {
  offer_data.status = true;

  const res = await fetch(
    BASE_URL + "admincontent/offer/" + offer_data.id + "/",
    {
      method: "PUT",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify(offer_data),
    }
  );
  console.log(res);
  var jsonData = await res.json();
  console.log(jsonData);
  return jsonData;
}

export async function GetExpoTokenList_Api() {
  const res = await fetch(BASE_URL + "expo/token/", {
    method: "GET",
  });
  console.log(res);
  var jsonData = await res.json();
  console.log(jsonData);
  return jsonData;
}

export async function SendNotification_Api(token_list, offer_data) {
  // token_list
  // ["ExponentPushToken[bBbcJLIZC89aFrus-WUQwM]"]
  const message = {
    to: token_list,
    sound: "default",
    title: offer_data.title,
    body: offer_data.content,
    data: { data: "goes here" },
  };

  const res = await fetch(BASE_URL+"send/push/notification/", {
    method: "POST",
    body: JSON.stringify(message),
  });

  var jsonData = await res.json();
  console.log(jsonData);
  console.log("Response = >", jsonData);

  return jsonData;
}
