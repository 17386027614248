import check from "./assets/checked_circle.png";
import React, { useEffect } from "react";
import AppBar from "./UI/AppBar/AppBar";
import { withRouter } from "react-router-dom";
import { SponserListCom } from "./UI/SponerList/SponserList";
import { MenuList } from "./utils/Appconstant";
import CopyRights from "./UI/CopyRights";
import AdSenseCom from "./UI/AdSense/AdSense";
import AppleStore from "../src/assets/appstore.svg";
import PlayStore from "../src/assets/google-play-badge.png"; 
import { getUser } from "./utils/userinfo";
import { useState } from "react";

const checkBoxList = [check, check, check, check];

function NewHome(props) {

  const [show_signup,setsignup] = useState(false)

  const getSport = (sport) => {
    // window.localStorage.setItem('season','2022')
    props.history.push("/sport/?ct=" + sport);
  };

  // className="w-full p-4 cursor-pointer rounded-md flex-col xl:flex-row
  //       flex flex-1 content-center items-center align-middle justify-center shadow-2xl  
  //       transition delay-0 ease-in-out hover:duration-100
  //       transform scale-100 hover:scale-110 border border-gray-600 
  //       hover:border-2 hover:border-gray-500 border-opacity-50"

  const Sports = (item, index) => {
    
    return (
      <a
        className="w-full p-4 cursor-pointer rounded-md flex-col xl:flex-row
        flex flex-1 content-center items-center align-middle justify-center shadow-2xl  
        transition delay-0 ease-in-out hover:duration-100
        transform scale-100 hover:scale-110 border border-gray-600 
        hover:border-2 hover:border-gray-500 border-opacity-50 "
        onClick={() => {

          if(index === 0)
          getSport(item.name.toString().toLowerCase());

          else {

            if(getUser()) getSport(item.name.toString().toLowerCase());
            else setsignup(!show_signup)

          } 

        }}
        key={index}
        style={{ background:'#EEEEEE' }}
      >
        <img
          src={`https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/game_icon/${item.svg}_app.png`}
          className="w-24 h-24 object-contain "
        />
        <h1 className="w-max mx-5 my-3 lg:my-0 afl-team-name text-3xl text-black text-left font-bold capitalize">
          {item.name}
        </h1>
      </a>
    );
  };

  return (
    <div
      className="overflow-y-scroll md:overflow-y-scroll 
    lg:overflow-y-scroll xl:overflow-y-scroll 2xl:overflow-hidden h-full main"
    >
      <AppBar 
        his={props.history} 
        show_signup={show_signup} 
        closeSignupModal={()=> setsignup(!show_signup)}
      />

      <div
        className="w-full sm:w-full md:w-full lg:w-3/5 m-auto xl:w-9/12 2xl:w-9/12 p-3 
          xl:p-0 2xl:p-0 2xl:my-5 lg:h-screen xl:h-auto 2xl:h-auto"
      >
        <div className="xl:flex 2xl:flex xl:flex-1 2xl:flex-1">
          <div className="mx-1 w-full xl:w-9/12">
            <div className="w-full rounded-md overflow-x-auto top-menu">
              <div className="flex flex-col lg:flex-row my-5 items-center md:justify-center align-middle">
                <div className="">
                  <h1 className="text-3xl font-bold capitalize w-auto break-words linear-wipe">
                    the ultimate punters cheat sheet!
                  </h1>
                </div>
                <div className="flex flex-row md:w-2/3 md:justify-center md:items-center">
                  {checkBoxList.map((item,index) => {
                    return (
                      <img key={index}
                        src={item}
                        alt="check"
                        className="mx-2 w-10 h-10 my-2 lg:my-0 md:w-12 md:h-12 xl:w-10 xl:h-10 2xl:w-12 2xl:h-12 text-white rounded-full"
                      />
                    );
                  })}
                </div>
                
              </div>
              <p className="block  sm:block lg:hidden md:block justify-center self-center my-4 ">Download the Multi Hub App today!</p>
              <div  className="flex justify-center"
              style={{width:"100%"}}>
              <div className="grid-cols-2  sm:grid-cols-2 justify-center grid lg:grid-cols-3 md:grid-cols-2" style={{width:"fit-content"}}>
                 <p className="hidden sm:hidden md:hidden lg:block justify-center self-center">Download the Multi Hub App today!</p>
                
                <div className="grid place-items-center" >
                <a  href='https://apps.apple.com/in/app/multi-hub/id1615524592'>
                <img width="auto" style={{height:"60px"}} alt='Get it on Google Play' src={AppleStore}></img>
                </a>
                </div>
                <div className="grid place-items-center">
                <a href='https://play.google.com/store/apps/details?id=com.multihub.app&utm_source=website&utm_campaign=website&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                  <img width="auto" style={{height:"60px"}} alt='Get it on Google Play' src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"/>
                </a>
                </div>
              </div>
              </div>

              <AdSenseCom />

              <div className="h-auto my-5">
                <h1 className="text-white font-bold text-2xl text-left">
                  Sports
                </h1>
                <div className="grid grid-cols-2 lg:grid-cols-3 gap-0">
                  {MenuList.map((item, index) => {
                    return (
                      <div
                        className={
                          index === MenuList.length - 1
                            ? "each-menu-con mlb_sport col-span-full lg:col-span-1 lg:m-0 lg:w-full w-full 2xl:w-auto xl:w-auto p-3 2xl:p-5 lg:col-start-2"
                            : "each-menu-con w-full 2xl:w-auto xl:w-auto p-3 2xl:p-5"
                        } key={index}
                      >
                        {Sports(item, index)}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="xl:w-3/12">
            <SponserListCom />
          </div>
        </div>
      </div>

      <div className="py-5">
        <CopyRights his={props.history}/>
      </div>

    </div>
  );
}

export default withRouter(NewHome);
