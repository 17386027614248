export const EPL_Team_List=[
    {
        name: "Arsenal",
        image: "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/epl_team_icon/Arsenal.png",
        short_form: "Arsenal",
        bgcolor: "#bb1c3f",
        team_id: "9-2"
    },
    {
        name: "Aston villa",
        image: "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/epl_team_icon/Aston+villa.png",
        short_form: "Aston",
        bgcolor: "#89bee2",
        team_id: "9-22"
    },
    {
        name: "Brentford",
        image: "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/epl_team_icon/Brentford.png",
        short_form: "Brentford",
        bgcolor: "#cb0018",
        team_id: "9-219"
    },
    {
        name: "Brighton & Hove Albion",
        image: "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/epl_team_icon/Brighton+&+Hove+Albion.png",
        short_form: "Brighton",
        bgcolor: "#004f8a",
        team_id: "9-48"
    },
    {
        name: "Burnley FC",
        image: "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/epl_team_icon/Burnley+FC.png",
        short_form: "Burnley",
        bgcolor: "#7ccde4",
        //#852e44            
        team_id: "9-4"
    },
    {
        name: "Chelsea",
        image: "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/epl_team_icon/Chelsea.png",
        short_form: "Chelsea",
        bgcolor: "#004c90",
        team_id: "9-5"
    },
    {
        name: "Crystal Palace",
        image: "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/epl_team_icon/Crystal+Palace.png",
        short_form: "Crystal",
        bgcolor: "#cb2035",
        team_id: "9-6"
    },
    {
        name: "Everton",
        image: "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/epl_team_icon/Everton.png",
        short_form: "Everton",
        bgcolor: "#164b85",
        team_id: "9-7"
    },
    {
        name: "Leeds United",
        image: "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/epl_team_icon/Leeds+United.png",
        short_form: "Leeds",
        bgcolor: "#bd8f37",
        team_id: "9-218"
    },
    {
        name: "Leicester City",
        image: "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/epl_team_icon/Leicester+City.png",
        short_form: "Leicester",
        bgcolor: "#233b6e",
        team_id: "9-9"
    },
    {
        name: "Liverpool",
        image: "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/epl_team_icon/Liverpool.png",
        short_form: "Liverpool",
        // bgcolor: "#595959",
         //#bd395a
        bgcolor:'#e3152d',
        team_id: "9-10"
    },
    {
        name: "Manchester City",
        image: "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/epl_team_icon/Manchester+City.png",
        short_form: "Manchester",
        bgcolor: "#58aedc",
        team_id: "9-11"
    },
    {
        name: "Manchester United",
        image: "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/epl_team_icon/Manchester+United.png",
        short_form: "Manchester",
        bgcolor: "#e60021",
        team_id: "9-12"
    },
    {
        name: "Newcastle United",
        image: "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/epl_team_icon/Newcastle+United.png",
        short_form: "Newcastle",
        bgcolor: "#00add9",
        team_id: "9-23"
    },
    {
        name: "Norwich City",
        image: "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/epl_team_icon/Norwich+City.png",
        short_form: "Norwich",
        bgcolor: "#00964f",
        team_id: "9-24"
    },
    {
        name: "Southampton",
        image: "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/epl_team_icon/Southampton.png",
        short_form: "Southampton",
        bgcolor: "#ce2332",
        team_id: "9-14"
    },
    {
        name: "Tottenham Hotspur",
        image: "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/epl_team_icon/tottenham-hotspur.png",
        short_form: "Tottenham",
        bgcolor: "#091f52",
        team_id: "9-18"
    },
    {
        name: "Watford",
        image: "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/epl_team_icon/Watford.png",
        short_form: "Watford",
        bgcolor: "#ffec3e",
        team_id: "9-19"
    },
    {
        name: "West Ham United",
        image: "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/epl_team_icon/West+Ham+United.png",
        short_form: "WestHam",
        bgcolor: "#6b2a36",
        team_id: "9-21"
    },
    {
        name: "Wolverhampton Wanderers",
        image: "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/epl_team_icon/Wolverhampton+Wanderers.png",
        short_form: "Wolverhampton",
        bgcolor: "#f68c4a",
        team_id: "9-32"
    }
]


//nba points header
export const EPLGoalsHeader = [
    '#', 'player', 'tg', 'avg', '1+', '2+', '3+', 'ta','ava','ata', 'lg','hg','gp'
]

//nba rebound header
export const EPLShotsOnTargetHeader = [
    '#', 'player', 'tst', 'avst', '1+', '2+', '3+', '4+', 'lst', 'hst','gp',"","","",""
]

//EPL assists header
export const EPLScoresHeader = [
    '#', 'player', 'ta', 'ata', '2+', '4+', '6+', '8+', '10+','12+','la', 'sla','ha','sha','gp'
]

export const resultTBHeader = [
    'date', 'opponent', 'team score', 'opp score', 'result', 'margin'
]


export const EPLLengendList = [

    //goals 
    {
        id: 1,
        name: 'tg',
        fullform: 'total_goals'
    },
    {
        id: 2,
        name: 'avg',
        fullform: 'average_goals'
    },
    {
        id: 3,
        name: '1+',
        fullform: 'one_plus_goals'
    },
    {
        id: 4,
        name: '2+',
        fullform: 'two_plus_goals'
    },
    {
        id: 5,
        name: '3+',
        fullform: 'three_plus_goals'
    },
    {
        id: 6,
        name: 'ta',
        fullform: 'total_assists'
    },
    {
        id: 7,
        name: 'ava',
        fullform: 'average_assists'
    },
    {
        id: 8,
        name: 'ata',
        fullform: 'anytime_assist'
    },
    {
        id: 9,
        name: 'lg',
        fullform: 'lowest_goals'
    },
    {
        id: 10,
        name: 'hg',
        fullform: 'highest_goals'
    },
    {
        id: 11,
        name: 'gp',
        fullform: 'games_played'
    },


    // shots on target
    
    {
        id: 12,
        name: 'tst',
        fullform: 'total_shots_on_target'
    },
    {
        id: 13,
        name: 'avst',
        fullform: 'average_shots_on_target'
    },
    {
        id: 14,
        name: '1+',
        fullform: 'one_plus_shots_on_target'
    },
    {
        id: 15,
        name: '2+',
        fullform: 'two_plus_shots_on_target'
    },
    {
        id: 16,
        name: '3+',
        fullform: 'three_plus_shots_on_target'
    },
    {
        id: 17,
        name: '4+',
        fullform: 'four_plus_shots_on_target'
    },
    {
        id: 18,
        name: 'lst',
        fullform: 'lowest_shots_on_target'

    },
    {
        id: 19,
        name: 'hst',
        fullform: 'highest_shots_on_target'

    },
    {
        id: 20,
        name: 'gp',
        fullform: 'games_played'

    },

    
]

export const GenerateBothTeamScoreChartData=(team_data)=>{

    let labelList = []
    let dataList = []

    if(team_data)
    team_data.forEach((item,index) =>{
        if(index > 1){
            labelList.push(item.name.replaceAll('_',' '))
            dataList.push(item.content)    
        }
        
    })

    

    return {
        labels: labelList,
        datasets: [
            {
            label: 'Both Team Score',
            data: dataList,
            backgroundColor: ["Green", "Red", "LightGreen","Orange"],
            hoverBackgroundColor: ["Green", "Red", "LightGreen","Orange"]
            },
        ],
    }
}

export const generateOptions=(optionText,isStack)=>{
    if(isStack)
    return {
        responsive: true,
       
        plugins: {
        outlabels: true,
          legend: {
            position: 'left',
          },
          labels: {
                fontColor: "#fff",
                boxWidth: 14,
                fontFamily: "proximanova"
            },
          title: {
            display: true,
            text: optionText,
          },
          tooltip: {
            enabled: true
          }
        },
        scales: {
            x: {
                stacked: true,
                display: false,
                ticks: {
                    beginAtZero: true,
                    max: 100,
                    min: 0
                  }
            },
            y: {
                stacked: true,
                display: false,
                ticks: {
                    beginAtZero: true,
                    max: 100,
                    min: 0
                  }
            },
        },
    };
    // return {
    //     maintainAspectRatio: false,
    //     spanGaps: false,
    //     responsive: true,
    //     legend: {
    //       display: true,
    //       position: "left",
    //       labels: {
    //         fontColor: "#fff",
    //         boxWidth: 14,
    //         fontFamily: "proximanova"
    //       }
    //     },
    //     plugins: {
    //         datalabels: {
    //             color: "#white",
    //             align: "center"
    //         },
    //       title: {
    //         display: true,
    //         text: optionText,
    //       },
    //       tooltip: {
    //         callbacks: {
    //           title: function () {
    //             return optionText;
    //           }
    //         }
    //       },
    //     },
    //     responsive: true,
    //     scales: {
            
    //         x: {
    //           stacked: true,
    //         },
    //         y: {
    //           stacked: true,
    //         },
    //       },
          
    //   };
    else 
    return {
        responsive: true,
        
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: optionText,
          },
        },
    };
}

export const GenerateUnderOverChartData=(team_data)=>{

    const labels = ['Over / Under 2.5 Goals'];

    let labelList = []
    let dataList = []

    if(team_data)
    team_data.forEach(item =>{
        labelList.push(item.name)
        dataList.push(item.percentage)
    })

    return  {
        labels,
        datasets: [
            {
                label: 'Over 2.5 Goals',
                data: [dataList[0]],
                backgroundColor: 'rgb(255, 99, 132)',
                
            },
            {
                label: 'Under 2.5 Goals',
                data: [dataList[1]],
                backgroundColor: 'rgb(75, 192, 192)',
                
            }
            
        ],
    }
}
