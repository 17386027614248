import React, { useState } from "react";
import { useEffect } from "react";
import Loader from "react-loader-spinner";
import { withRouter } from "react-router-dom";
import {
  GetUserProfile_Api,
  LogOut_Api,
} from "../../Busniess_Logic/API/AccountApi";
import { getUser, removeUser } from "../../utils/userinfo";
import AppBar from "../AppBar/AppBar";

function Profile(props) {
  const [profile, setprofile] = useState(null);
  const [isLoading, setloading] = useState(false);
  const [profileLoading, setprofileLoading] = useState(false);

  useEffect(() => {
    async function getuserProfile() {
      await GetUserProfile();
    }
    getuserProfile();
  }, []);

  const GetUserProfile = async () => {
    try {
      setprofileLoading(true);
      let user = getUser();

      if (user) {
        const res = await GetUserProfile_Api(user.id);
        if (res) setprofile(res);
      }

      setprofileLoading(false);
    } catch (error) {
      setprofileLoading(false);
    }
  };

  const LogOut = async () => {
    try {
      setloading(true);
      let user = getUser();

      if (user) {
        const res = await LogOut_Api(user.id);
        if (res) {
          if (res.success) {
            removeUser();
            props.history.push("/");
          }
        } else alert(res.response);
      }

      setloading(false);
    } catch (error) {
      setloading(false);
      alert(error);
    }
  };

  return (
    <div>
      <AppBar his={props.history} />
      {!profileLoading ? (
        <div>
          {profile ? (
            <div className="flex flex-1 justify-center items-center">
              <div className="my-5">
                <p className="text-lg font-semibold text-left my-2">
                  {profile.username}
                </p>
                <p className="text-sm font-light text-left my-2">
                  {profile.email}
                </p>
                <p className="text-sm font-light text-left my-2">
                  {profile.phonenumber}
                </p>

                <button
                  type="submit"
                  className="flex primary-bg-color w-full py-2 mt-5 rounded-md flex-1 justify-center items-center"
                  onClick={() => LogOut()}
                  disabled={isLoading}
                >
                  {isLoading && (
                    <Loader type="Oval" color="white" height={20} width={20} />
                  )}

                  <p className="ml-1">
                    {isLoading ? ` Logging Out...` : `LogOut`}
                  </p>
                </button>
              </div>
            </div>
          ) : (
            <p>{`Cannot get user profile details`}</p>
          )}
        </div>
      ) : (
        <div>
          <div className="flex flex-1 justify-center items-center">
            <Loader type="Oval" color="white" height={20} width={20} />
            <p className="ml-2">Getting user profile..</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default withRouter(Profile);
