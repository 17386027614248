const AWS_Team =
  "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/nfl/nba_team_data/";
const AWSLINK = "/home/gearup2/Documents/DraftStartsScrap/AFL.json";

const BASE_URL = "https://apiv3.multihub.com.au/";
// const BASE_URL='http://192.168.1.102:8000/'

export async function GetTeamDetails(id,season) {
  const res = await fetch(`https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/${season}/nfl/nfl_team_data/${id}.json`, {
    method: "GET",
  });
  //console.log(res);
  var jsonData = await res.json();
  //console.log(jsonData);
  return jsonData;
}

export async function GetNFLPlayerDetail(id,season) {
  const res = await fetch(
    `https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/${season.slice(0,4)}/nfl/nfl_player_data/${id}.json`,
    {
      method: "GET",
    }
  );
  //console.log(res);
  var jsonData = await res.json();
  //console.log(jsonData);
  return jsonData;
}

export async function GetNFLTeams(season) {
  const res = await fetch(BASE_URL + "nfl/team?season="+season,{
    method: "GET",
  });
  //console.log(res);
  var jsonData = await res.json();
  //console.log(jsonData);
  return jsonData;
}

export async function GetNFLTeamPlayersList(teamid,season) {
  const res = await fetch(`${BASE_URL}nfl/filter/players/?id=${teamid}&season=${season}`, {
    method: "GET",
  });
  //console.log(res);
  var jsonData = await res.json();
  //console.log(jsonData);
  return jsonData;
}

export async function AddNBATeam(team_data) {
  const res = await fetch(BASE_URL + "nba/team/", {
    method: "POST",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
    body: JSON.stringify(team_data),
  });
  //console.log(res);
  var jsonData = await res.json();
  //console.log(jsonData);
  return jsonData;
}

export async function GetNBAPlayer(player_id) {
  const res = await fetch(BASE_URL + "nba/playerlist/?player_id=" + player_id, {
    method: "GET",
  });
  //console.log(res);
  var jsonData = await res.json();
  //console.log(jsonData);
  return jsonData;
}
