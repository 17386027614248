import { Formik } from "formik";
import React, { useState } from "react";
import {
  AiOutlineMail,
  AiOutlineKey,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
import Loader from "react-loader-spinner";
import * as Yup from "yup";

function ForgotPasswordForm(props) {

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
  });

  const ErrorComponent = (error) => {
    return <p className="text-red-400 text-left text-xs my-2">{error}</p>;
  };

  return (
    <div>
      <Formik
        initialValues={{
          email: "",
        }}
        onSubmit={(values) => props.onSubmit(values)}
        validationSchema={LoginSchema}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          values,
          errors,
          touched,
        }) => (
          <div>
            {/* username */}
            <div className="my-5">
              <div className="flex flex-row justify-between items-center border border-gray-500 rounded-md px-2">
                <AiOutlineMail color="white" size={"20"} />
                <input
                  type="email"
                  className="bg-transparent p-2 text-md focus:outline-none ml-1 w-full"
                  placeholder="Email id.."
                  onChange={handleChange("email")}
                  onBlur={handleBlur("email")}
                />
              </div>
              {errors.email &&
                touched.email &&
                ErrorComponent(errors.email)}
            </div>

            {/* login button */}
            <button
              type="submit"
              className="flex primary-bg-color w-full py-2 mt-5 rounded-md flex-1 justify-center items-center"
              onClick={handleSubmit}
              disabled={props.isLoading}
            >
              {
                props.isLoading && <Loader
                  type="Oval"
                  color="white"
                  height={20}
                  width={20}
                />
              }
              <p className="ml-1">{ props.isLoading ? ` Sending mail..` : `Send email`}</p>
            </button>

            {/* signup button */}
            <button
              type="submit"
              className="block bg-white my-3 w-full py-2 rounded-md"
              onClick={props.onClose}
              disabled={props.isLoading}
            >
              <p className="text-black">Cancel</p>
            </button>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default ForgotPasswordForm;
