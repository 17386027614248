import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import AppBar from "../AppBar/AppBar";
import PageBar from "../AppBar/PageBar";
import AppLoader from "../Loader/Loader";
import "../../utils/styles/teamdetail/teamdetail.scss";
import { SponserListCom } from "../SponerList/SponserList";
import {
  GetEPLPlayer,
  GetEPLPlayerDetail,
} from "../../Busniess_Logic/API/EPLApi";
import CopyRights from "../CopyRights";
import AdSenseCom from "../AdSense/AdSense";

function EPLPlayerDetail(props) {
  const [called, setCalled] = useState(false);
  const [detail, setDetail] = useState(null);
  const [userDetail, setUserdetail] = useState(null);
  const [loader, setLoader] = useState(false);

  const [tc, setTc] = useState(null);
  const [hoverHeader, setHoverheader] = useState(null);

  const [goalsData, setGoals] = useState([]);
  const [assistsData, setAssists] = useState([]);
  const [shotsOnTargetData, setShotsOnTarget] = useState([]);

  useEffect(() => {
    getplayerdetails();
  }, []);

  const getplayerdetails = async () => {

    try {

        setLoader(true);
        var surl = new URLSearchParams(window.location.href);
        var url = new URL(window.location.href);
        var c = url.searchParams.get("id");
        let season = surl.get("s") ? surl.get("s") :'2021';
    
        console.log(season)
    
        const res = await GetEPLPlayerDetail(c?c:"9-6992",season);
        if (res) {
          setDetail(res);
          CalculateGoalsData(res);
          CalculateShotsOnTargetData(res);
          CalculateExactGoalsData(res);
        }
    
        // const player = await GetEPLPlayer(c?c:"9-6992");
        // if (player) {
        //   setUserdetail(player);
        //   // processTBData(player[0])
        // }
    
        setCalled(true);
        setLoader(false);
        setTc(surl.get("tc")?surl.get("tc"):"#bb1c3f");

    } catch (error) {
      setLoader(false);
      setDetail(null);
      console.log(error)  
    }

    
  };

  const CalculateGoalsData = (data) => {
    if (data) {
      var goalList = [];
      var assistsList = [];

      

      data.games &&
        data.games.forEach((item) => {
          var eachTries = 0;
          var eachAssists = 0;
          item.stats.map((state) => {
            if (state.id === "9-9019") {
              eachTries = eachTries + parseInt(state.value);
            }
            if (state.id === "9-9020") {
              eachAssists = eachAssists + parseInt(state.value);
            }
          });
          goalList.push(eachTries);
          assistsList.push(eachAssists);
        });

      var contentList = [];
      if (goalList.length !== 0) {
        var sortedList = [];
        sortedList = goalList.sort((a, b) => a - b);

        contentList.push({ title: "Goals", content: "" });
        contentList.push({
          title: "total goals",
          content: sortedList.reduce((a, b) => a + b,0),
        });
        contentList.push({
          title: "average goals",
          content:(sortedList.reduce((a, b) => a + b,0)/sortedList.length).toFixed(1),
        });
        contentList.push({
          title: "highest goals",
          content: sortedList[sortedList.length - 1],
        });
        contentList.push({ title: "lowest goals", content: sortedList[0] });

        contentList.push({
          title: "anytime goalscorer",
          content: sortedList.reduce((a, b) => a + b)+'/'+goalList.length
        });
        contentList.push({
          title: "2+ goals",
          content:sortedList.filter(function (ele) {
            return ele >= 2;
          }).length +
          "/" +
          goalList.length,
        });
        contentList.push({
          title: "3+ goals",
          content: sortedList.filter(function (ele) {
            return ele >= 3;
          }).length +
          "/" +
          goalList.length,
        });
        contentList.push({
          title: "anytime assists",
          content: assistsList.reduce((a, b) => a + b)+"/"+goalList.length,
        });
       

        setGoals(contentList);
      }
    }
  };

  const CalculateShotsOnTargetData = (data) => {
    if (data) {
      var shotsOnTargetList = [];
      data.games &&
        data.games.forEach((item) => {
          var eachTries = 0;
          item.stats.map((state) => {
            if (state.id === "9-9023") {
              eachTries = eachTries + state.value;
            }
          });
          shotsOnTargetList.push(eachTries);
        });

      var contentList = [];
      if (shotsOnTargetList.length !== 0) {
        var sortedList = [];
        sortedList = shotsOnTargetList.sort((a, b) => a - b);

        contentList.push({ title: "Shots On Target", content: "" });

        contentList.push({
          title: "total shots on target",
          content: sortedList.reduce((a, b) => a + b),
        });
        contentList.push({
          title: "average shots on target",
          content:(sortedList.reduce((a, b) => a + b,0)/sortedList.length).toFixed(1),
        });
        contentList.push({
          title: "highest shots on target",
          content: sortedList[sortedList.length - 1],
        });
        contentList.push({
          title: "1+ shots on target",
          content: sortedList.filter(function (ele) {
            return ele >= 1;
          }).length +
          "/" +
          shotsOnTargetList.length,
        });
        contentList.push({
          title: "2+ shots on target",
          content: sortedList.filter(function (ele) {
            return ele >= 2;
          }).length +
          "/" +
          shotsOnTargetList.length,
        });
        contentList.push({
          title: "3+ shots on target",
          content: sortedList.filter(function (ele) {
            return ele >= 3;
          }).length +
          "/" +
          shotsOnTargetList.length,
        });
        contentList.push({
          title: "4+ shots on target",
          content: sortedList.filter(function (ele) {
            return ele >= 4;
          }).length +
          "/" +
          shotsOnTargetList.length,
        });

        setShotsOnTarget(contentList);
      }
    }
  };

  const CalculateExactGoalsData = (data) => {
    if (data) {
      var exactGoalList = [];
      data.games &&
        data.games.forEach((item) => {
          var eachTries = 0;
          item.stats.map((state) => {
            if (state.id === "9-9019") {
              eachTries = eachTries + state.value;
            }
          });
          exactGoalList.push(eachTries);
        });

      var contentList = [];
      if (exactGoalList.length !== 0) {
        var sortedList = [];
        sortedList = exactGoalList.sort((a, b) => a - b);

        contentList.push({ title: "Exact Goals", content: "" });

        contentList.push({
          title: "exactly 1 goals",
          content: sortedList.filter(function (ele) {
            return ele == 1;
          }).length +
          "/" +
          exactGoalList.length,
        });
        contentList.push({
          title: "exactly 2 goals",
          content:sortedList.filter(function (ele) {
            return ele == 2;
          }).length +
          "/" +
          exactGoalList.length,
        });
        contentList.push({
          title: "exactly 3 goals",
          content: sortedList.filter(function (ele) {
            return ele == 3;
          }).length +
          "/" +
          exactGoalList.length,
        });
        
        setAssists(contentList);
      }
    }
  };

  const playerDataTable = (tableData) => {
    return (
      <div className="rounded-md py-2 my-2">
        {tableData.map((item, index) => {
          if (index === 0)
            return (
              <div style={{ backgroundColor: tc, padding: "3%" }}>
                <div
                  className="rounded-tl-md uppercase w-full text-white text-left font-bold"
                  style={{ backgroundColor: tc }}
                >
                  {item.title}
                </div>
                <div className="rounded-tr-md w-1/2">{item.content}</div>
              </div>
            );
        })}

        <div className="overflow-y-auto">
          <table className="rounded-lg tb-shadow h-auto w-full text-black">
            {tableData.map((item, index) => {
              if (index > 0)
                return (
                  <tr className="">
                    <td className="capitalize text-center pl-4 w-1/2 py-1">
                      {item.title}
                    </td>
                    <td className="w-1/2">{item.content}</td>
                  </tr>
                );
            })}
          </table>
        </div>
      </div>
    );
  };

  const CalculateGoals = () => {
    var triesList = [];
    detail.games.forEach((item) => {
      var tries = 0;

      item.stats.forEach((stat) => {
        if (stat.id === "9-9019") tries = tries + stat.value;
      });

      triesList.push(tries);
    });
    return triesList;
  };

  const CalculateShotsOnTarget = () => {
    var triesList = [];
    detail.games.forEach((item) => {
      var tries = 0;

      item.stats.forEach((stat) => {
        if (stat.id === "9-9023") tries = tries + stat.value;
      });

      triesList.push(tries);
    });
    return triesList;
  };

  const CalculateAssists = () => {
    var triesList = [];
    detail.games.forEach((item) => {
      var tries = 0;

      item.stats.forEach((stat) => {
        if (stat.id === "9-9020") tries = tries + stat.value;
      });

      triesList.push(tries);
    });
    return triesList;
  };

  const getTotalGames = () => {
    if(detail && detail.games) return (detail.games.length).toString()
    else return "0"
  }

  return (
    <div className="w-full">
      <AppBar his={props.history} />

      {loader && (
        <div style={{ position: "relative" }}>
          <AppLoader msg={"Getting player details"} />
        </div>
      )}

      {!loader && (
        <div
          className="player-detail-com w-full sm:w-full md:w-full lg:w-3/5 
                m-auto xl:w-3/4 2xl:w-3/4 p-3 xl:p-0 2xl:p-0 2xl:py-5 pb-8"
        >
          <div className="xl:flex 2x:flex xl:flex-1 2xl:flex-1">
            {
              detail != null && detail!=undefined ?
              <div
              className="mx-1 w-full xl:w-3/4 2xl:w-4/5"
              // className='player-detail-com w-full sm:w-full md:w-full lg:w-3/5 m-auto xl:w-3/5 2xl:w-3/5 p-3 xl:p-0 2xl:p-0 2xl:py-5 pb-8'
            >

              <AdSenseCom/>
              <PageBar his={props.history} />

              <div
                className="team-container p-4 rounded-lg border border-grey-100 shadow-md flex flex-1 flex-row items-center"
                style={{ backgroundColor: tc }}
              >
                <div className="team-name w-full">
                  <h1 className="text-3xl font-bold text-white">
                    {detail.player_name ? detail.player_name : ''}
                  </h1>
                </div>
              </div>

              <div className="my-2 p-3 rounded-lg shadow-md border border-grey-100 flex flex-1 justify-around">
                <p className="text-left px-2">Total games played</p>
                <p className="text-left px-2">
                  <strong>{getTotalGames()}</strong>
                </p>
              </div>

              {detail.games && detail.games.length != 0 ? (
                <div className="w-full">
                  <div
                    className="rounded-lg shadow-xl items-center my-4 top-tb-container"
                    style={{ position: "relative" }}
                  >
                    <table className="p-2 top-table text-base xl:text-sm 2xl:text-lg bg-white text-black">
                     
                      <tr>
                        <th
                          className="rounded-tl-md text-white p-2 capitalize"
                          style={{ backgroundColor: tc }}
                        >
                          Opposition name
                        </th>
                        {detail.games.map((item, index) => {
                          if (
                            item.away_team.abbreviation ===
                            detail.player_team.abbreviation
                          )
                            return (
                              <td
                                className="text-md top-tb-data"
                                onMouseEnter={() =>
                                  setHoverheader(item.home_team.abbreviation)
                                }
                                onMouseLeave={() => setHoverheader(null)}
                              >
                                <div className="tool-tip-con">
                                  <strong>{item.home_team.abbreviation}</strong>
                                  <p className="tool-tip-data">
                                    {item.home_team.name}
                                  </p>
                                </div>
                              </td>
                            );
                          else
                            return (
                              <td
                                className="text-md top-tb-data"
                                onMouseEnter={() =>
                                  setHoverheader(item.away_team.abbreviation)
                                }
                                onMouseLeave={() => setHoverheader(null)}
                              >
                                <div className="tool-tip-con">
                                  <strong>{item.away_team.abbreviation}</strong>
                                  <p className="tool-tip-data">
                                    {item.away_team.name}
                                  </p>
                                </div>
                              </td>
                            );
                        })}
                      </tr>

                      <tr>
                        <th
                          className="text-white p-2 capitalize"
                          style={{ backgroundColor: tc }}
                        >
                          date
                        </th>
                        {detail.games.map((item, index) => {
                          return (
                            <td className="text-sm top-tb-data">
                              <strong>
                                {new Date(item.start_time).getDate() +
                                  " " +
                                  new Date(item.start_time)
                                    .toLocaleDateString("default", {
                                      month: "long",
                                    })
                                    .substr(0, 3)}
                              </strong>
                            </td>
                          );
                        })}
                      </tr>

                      <tr>
                        <th
                          className="text-white p-2 capitalize"
                          style={{ backgroundColor: tc }}
                        >
                          goals
                        </th>
                        {CalculateGoals().map((item) => {
                          return (
                            <td className="top-tb-data">
                              <strong>
                                {item === 0 ||
                                item === -Infinity ||
                                item === NaN
                                  ? "-"
                                  : item}
                              </strong>
                            </td>
                          );
                        })}
                      </tr>

                      <tr>
                        <th
                          className="text-white p-2 capitalize"
                          style={{ backgroundColor: tc }}
                        >
                          assists
                        </th>
                        {CalculateAssists().map((item) => {
                          return (
                            <td className="top-tb-data">
                              <strong>
                                {item === 0 ||
                                item === -Infinity ||
                                item === NaN
                                  ? "-"
                                  : item}
                              </strong>
                            </td>
                          );
                        })}
                      </tr>

                      <tr>
                        <th
                          className="text-white p-2 capitalize"
                          style={{ backgroundColor: tc }}
                        >
                          shots on target
                        </th>
                        {CalculateShotsOnTarget().map((item) => {
                          return (
                            <td className="top-tb-data">
                              <strong>
                                {item === 0 ||
                                item === -Infinity ||
                                item === NaN
                                  ? "-"
                                  : item}
                              </strong>
                            </td>
                          );
                        })}
                      </tr>

                    </table>
                  </div>

                  <div
                    className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 
                                        gap-2 items-start justify-between w-full data-table-con nba-player-data-table"
                  >
                    {goalsData && playerDataTable(goalsData)}

                    {shotsOnTargetData && playerDataTable(shotsOnTargetData)}

                    {assistsData && playerDataTable(assistsData)}

                  </div>
                </div>
              ):(
                <div>
                    <p className="text-white py-3 text-center">No games played</p>
                </div>
              )}
            </div>:
            <div className='mx-1 w-full xl:w-3/4 2xl:w-4/5 m-auto'>
                <p className="text-white py-5 text-center">Data not found</p>
            </div>
            }
            
            <div className="mt-14 xl:w-64">
              <SponserListCom />
            </div>
          </div>
        </div>
      )}

      {
          !loader && <CopyRights his={props.history}/>
      }

    </div>
  );
}

export default withRouter(EPLPlayerDetail);
