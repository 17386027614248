const AWS_Team =
  "https://${BucketName}.s3.ap-southeast-2.amazonaws.com/epl_team_data/";
const AWSLINK = "/home/gearup2/Documents/DraftStartsScrap/AFL.json";

const BucketName = "fantasy-sports-data"

const BASE_URL = "https://apiv3.multihub.com.au/epl/";
// const BASE_URL = "https://devapi.multihub.com.au/epl/";
// const BASE_URL = "http://192.168.0.105:8000/epl/";

export async function GetTeamDetails(id, season) {
  console.log(
    `https://${BucketName}.s3.ap-southeast-2.amazonaws.com/${season}/epl_teams_data/${id}.json`
  );
  const res = await fetch(
    `https://${BucketName}.s3.ap-southeast-2.amazonaws.com/${season}/epl_teams_data/${id}.json`,
    {
      method: "GET",
    }
  );
  //console.log(res);
  var jsonData = await res.json();
  //console.log(jsonData);
  return jsonData;
}

export async function GetEPLPlayerDetail(id, season) {
  console.log(
    `https://${BucketName}.s3.ap-southeast-2.amazonaws.com/${season}/epl_player_data/${id}.json`
  );
  const res = await fetch(
    `https://${BucketName}.s3.ap-southeast-2.amazonaws.com/${season}/epl_player_data/${id}.json`,
    {
      method: "GET",
    }
  );
  //console.log(res);
  var jsonData = await res.json();
  //console.log(jsonData);
  return jsonData;
}

export async function GetEPLTeams(season) {
  console.log("Get EPL Teams", BASE_URL + "filter/team/?season=" + season);

  const res = await fetch(BASE_URL + "filter/team/?season=" + season, {
    method: "GET",
  });
  //console.log(res);
  var jsonData = await res.json();
  //console.log(jsonData);
  return jsonData;
}

export async function GetEPLTeamPlayersList(teamid, season) {
  console.log(BASE_URL + "playerslist/?team=" + teamid + "&season=" + season);
  const res = await fetch(
    BASE_URL + "playerslist/?team=" + teamid + "&season=" + season,
    {
      method: "GET",
    }
  );
  //console.log(res);
  var jsonData = await res.json();
  //console.log(jsonData);
  return jsonData;
}

export async function AddEPLTeam(team_data) {
  const res = await fetch(BASE_URL + "team/", {
    method: "POST",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
    body: JSON.stringify(team_data),
  });
  //console.log(res);
  var jsonData = await res.json();
  //console.log(jsonData);
  return jsonData;
}

export async function GetEPLPlayer(player_id) {
  const res = await fetch(BASE_URL + "playerlist/?player_id=" + player_id, {
    method: "GET",
  });
  //console.log(res);
  var jsonData = await res.json();
  //console.log(jsonData);
  return jsonData;
}
