const AWS = "https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/teams/AFL.json";

const AWSLINK = "/home/gearup2/Documents/DraftStartsScrap/AFL.json";

const AWSS3 = `https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/`

const BASE_URL = "https://apiv3.multihub.com.au/afl/";
// const BASE_URL='http://192.168.0.105:8001/afl/'
// const BASE_URL = "https://devapi.multihub.com.au/afl/";

export async function GetAFLTeams() {
  const res = await fetch(AWSLINK, {
    method: "GET",
  });
  //console.log(res)
  return res;
}

export async function GetAllTeams(season) {
  const res = await fetch(BASE_URL + "filter/team/?season="+season, {
    method: "GET",
  });
  //console.log(res)
  var jsonData = await res.json();
  //console.log(jsonData)
  return jsonData;
}

export async function GetTeamPlayersList(teamid,season) {
  const res = await fetch(BASE_URL + "player/?team=" + teamid+'&season='+season, {
    method: "GET",
  });
  //console.log(res)
  var jsonData = await res.json();
  //console.log(jsonData)
  return jsonData;
}

export async function GetTeamPlayersDisposal(id) {
  const res = await fetch(BASE_URL + "disposal/" + id, {
    method: "GET",
  });
  //console.log(res)
  var jsonData = await res.json();
  //console.log(jsonData)
  return jsonData;
}

export async function GetPlayerFullDetail(id,season) {
  const res = await fetch(
    `${AWSS3}${season}/players/${id}.json`,
    {
      method: "GET",
    }
  );
  //console.log(res)
  var jsonData = await res.json();
  //console.log(jsonData)
  return jsonData;
}

export async function GetTeamHistory(id,season) {
  const res = await fetch(
    `${AWSS3}${season}/teams/${id}.json`,
    {
      method: "GET",
    }
  );
  //console.log(res)
  var jsonData = await res.json();
  //console.log(jsonData)
  return jsonData;
}

export async function GetTeamPlayersGoals(id) {
  const res = await fetch(BASE_URL + "goal/" + id, {
    method: "GET",
  });
  //console.log(res)
  var jsonData = await res.json();
  //console.log(jsonData)
  return jsonData;
}

export async function AddTeam(team_data) {
  const res = await fetch(BASE_URL + "team/", {
    method: "POST",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
    body: JSON.stringify(team_data),
  });
  //console.log(res)
  var jsonData = await res.json();
  //console.log(jsonData)
  return jsonData;
}

export async function GetUser(competition_id, id) {
  const res = await fetch(
    `https://onesport-api.playup.io/sport/8/api/v1/widgets/competition/${competition_id}/player/${id}/summary?platform=2`,
    {
      method: "GET",
    }
  );
  //console.log(res)
  var jsonData = await res.json();
  //console.log(jsonData)
  return jsonData;
}

export async function GetAFLPlayer(player_id) {
  const res = await fetch(BASE_URL + "player/" + player_id + "/", {
    method: "GET",
  });
  //console.log(res)
  var jsonData = await res.json();
  //console.log(jsonData)
  return jsonData;
}

export async function GetAFLPlayerDataBySeason(season,player_id) {
    const res = await fetch(BASE_URL + "player/" + player_id + "/", {
      method: "GET",
    });
    //console.log(res)
    var jsonData = await res.json();
    //console.log(jsonData)
    return jsonData;
  }
