import React, { useEffect, useState } from "react";
import { tableHoverformList } from "./AFL_Constants";
import '../../utils/styles/table/table.scss'
import { getUser } from "../../utils/userinfo";


const greeen = '#26e169';
const mediumGreeen = '#73ec9e';
const lightGreeen = '#c1f6d4';



function PlayersTable(props) {

    const [cat, setCat] = useState(null)
    const [sortedth, setSortedth] = useState(null)
    const [playerList, setPlayerlist] = useState([])

    const [onHover, setHover] = useState(false)
    const [hoverkey, setHoverkey] = useState(null)

    useEffect(() => {

        setCat(props.cat)
        if (props.cat === 1) {
            sortByList('avd', props.player_list)
        }
        else if (props.cat === 2) {
            sortByList('tg', props.player_list)
        }
        else if (props.cat === 3) {
            sortByList('afp', props.player_list)
        }
        else if (props.cat == 4) {
            setPlayerlist(props.player_list)
        }

    }, [props.cat])

    const ViewPlayer = (item) => {

        if(getUser())
        props.his.push('/player?id=' + item + '&tc=' + props.bgcolor+'&s='+props.season)
        else props.onShowSignup()
        // alert('Create FREE Account Enjoy All Features')
        
    }


    // ascending and descending
    const compareByAsc = (category, key) => {
        return function (a, b) {
            if (a[category] !== null && a[category][key] !== null && b[category] !== null && b[category][key] !== null) {
                if (a[category][key] < b[category][key]) return -1;
            } else return 0

            if (a[category] !== null && a[category][key] !== null && b[category] !== null && b[category][key] !== null) {
                if (a[category][key] > b[category][key]) return 1
            } else return 0;
            return 0;
        };
    }

    const compareByDesc = (category, key) => {
        return function (a, b) {
            if (a[category] !== null && a[category][key] !== null && b[category] !== null && b[category][key] !== null) {
                if (a[category][key] < b[category][key]) return 1;
            } else return 0

            if (a[category] !== null && a[category][key] !== null && b[category] !== null && b[category][key] !== null) {
                if (a[category][key] > b[category][key]) return -1;
            } else return 0
            return 0;
        };
    }

    const checkKey = (key) => {
        if (key === '15+') return 'fiftheen_plus'
        else if (key === '20+') return 'twenty_plus'
        else if (key === '25+') return 'twentyfive_plus'
        else if (key === '30+') return 'thirty_plus'
        else if (key === '35+') return 'thirtyfive_plus'

        else if (key === '60+') return 'sixthy_plus'
        else if (key === '70+') return 'seventy_plus'
        else if (key === '80+') return 'eighty_plus'
        else if (key === '90+') return 'ninety_plus'
        else if (key === '100+') return 'hundred_plus'
        else if (key === '110+') return 'onehundredten_plus'
        else if (key === '120+') return 'onehundredtwenty_plus'

        else if (key === '1g') return 'one_G'
        else if (key === '2g+') return 'two_G'
        else if (key === '3g+') return 'three_G'

        else if (key === 'player') return 'name'
        else if (key === '#') return 'player_number'
        else if (key === 'gp') return 'games_played'

        else if (key === 'lg') return 'lowest_goal'
        else if (key === 'hg') return 'highest_goal'

        else if (key === 'lp') return 'lowest_points'
        else if (key === 'hp') return 'highest_points'

        else return key;
    }

    const sortBy = (key) => {

        setSortedth(key)

        key = checkKey(key)
        var categoryName = (cat === 1 ? 'disposal' : (cat === 2 ? 'goals' : 'fantasy_points'))

        var nullRemoveList = []
        var nullList = []
        playerList.forEach(item => {
            if (item.disposal && item.goals && item.fantasy_points) nullRemoveList.push(item)
            else nullList.push(item)
        })

        let arrayCopy = [...nullRemoveList];
        const arrInStr = JSON.stringify(arrayCopy);
        if (key === 'name' || key === 'player_number') arrayCopy.sort(compareByAscNameIndex(key))
        else arrayCopy.sort(compareByDesc(categoryName, key));
        const arrInStr1 = JSON.stringify(arrayCopy);

        if (arrInStr === arrInStr1) {
            if (key === 'name' || key === 'player_number') arrayCopy.sort(compareByDescNameIndex(key))
            else arrayCopy.sort(compareByAsc(categoryName, key));
        }
        

        var alllist = arrayCopy.concat(nullList)
        //console.log(key,arrayCopy.length, nullList.length)
        setPlayerlist(alllist)
    }


    const sortByList = (key, list) => {

        var nullRemoveList = []
        var nullList = []

        if(list !=null && list!=undefined){
            list.forEach(item => {
                if (item.disposal && item.goals && item.fantasy_points) nullRemoveList.push(item)
                else nullList.push(item)
            })
    
            var categoryName = (props.cat === 1 ? 'disposal' : (props.cat === 2 ? 'goals' : 'fantasy_points'))
            let arrayCopy = [...nullRemoveList];
            arrayCopy.sort(compareByDesc(categoryName, key));
    
            var alllist = arrayCopy.concat(nullList)
            //console.log(arrayCopy.length, nullList.length)
            setPlayerlist(alllist)
        }
        
    }



    const compareByAscNameIndex = (key) => {
        if (key === 'player_id')
            return function (a, b) {
                if (parseInt(a[key]) > parseInt(b[key])) return 1;
                if (parseInt(a[key]) < parseInt(b[key])) return -1;
                return 0;
            };
        else return function (a, b) {
            if (a[key] > b[key]) return 1;
            if (a[key] < b[key]) return -1;
            return 0;
        };
    }

    const compareByDescNameIndex = (key) => {
        if (key === 'player_id')
            return function (a, b) {
                if (parseInt(a[key]) < parseInt(b[key])) return 1;
                if (parseInt(a[key]) > parseInt(b[key])) return -1;
                return 0;
            };
        else
            return function (a, b) {
                if (a[key] < b[key]) return 1;
                if (a[key] > b[key]) return -1;
                return 0;
            };
    }

    const colorBy = (key, value) => {
        if (value === 0 || value === null) return ' '
        else if (value !== null && key !== 'margin') {

            var nullRemoveList = []
            playerList.forEach(item => {
                if (item.disposal && item.goals && item.fantasy_points) nullRemoveList.push(item)
            })

            let arrayCopy = [...nullRemoveList];
            const arrInStr = JSON.stringify(arrayCopy);

            var categoryName = (cat === 1 ? 'disposal' : (cat === 2 ? 'goals' : 'fantasy_points'))
            var max = arrayCopy.reduce((prev, current) => prev[categoryName][key] > current[categoryName][key] ? prev : current)


            if (max[categoryName][key] === value) return greeen


            var maxIndex = arrayCopy.indexOf(max)

            if (maxIndex > -1) {
                arrayCopy.splice(maxIndex, 1);
            }

            var max2 = arrayCopy.reduce((prev, current) => (prev[categoryName][key] > current[categoryName][key] ? prev : current))
            if (max2[categoryName][key] === value) return mediumGreeen

            var maxIndex2 = arrayCopy.indexOf(max2)

            if (maxIndex2 > -1) {
                arrayCopy.splice(maxIndex2, 1);
            }

            var max3 = arrayCopy.reduce((prev, current) => (prev[categoryName][key] > current[categoryName][key] ? prev : current))
            if (max3[categoryName][key] === value) return lightGreeen
        }

    }


    const colorByValues = (key, value) => {
        if (value === 0 || value === null) return ''
        else if (value !== null && key != 'margin') {

            if (key === 'avd') {
                value = parseFloat(value).toFixed(1)
                if (value >= 25) return greeen;
                else if (value >= 20 && value <= 24.9) return mediumGreeen;
                else if (value >= 15 && value <= 19.9) return lightGreeen;
            }


            if (key === 'ld' || key === 'sld' || key === 'tld' || key === 'hd') {
                if (value >= 25) return greeen;
                else if (value >= 20 && value <= 24) return mediumGreeen;
                else if (value >= 15 && value <= 19) return lightGreeen;
            }

            if (key === 'fiftheen_plus' || key === 'twenty_plus' || key === 'twentyfive_plus' || key === 'thirty_plus' || key === 'thirtyfive_plus') {
                if (value >= 100) return greeen;
                else if (value >= 90 && value <= 99) return mediumGreeen;
                else if (value >= 80 && value <= 89) return lightGreeen;
            }

            if (key === 'sixthy_plus' || key === 'seventy_plus' || key === 'eighty_plus' || key === 'ninety_plus'
                || key === 'hundred_plus' || key === 'onehundredten_plus' || key === 'onehundredtwenty_plus' ||
                key === 'one_G' || key === 'two_G' || key === 'three_G') {
                if (value >= 100) return greeen;
                else if (value >= 90 && value <= 99) return mediumGreeen;
                else if (value >= 80 && value <= 89) return lightGreeen;
            }

            if (key === 'avg') {
                value = parseFloat(value).toFixed(1)
                if (value >= 2) return greeen;
                else if (value >= 1.5 && value <= 1.9) return mediumGreeen;
                else if (value >= 1.0 && value <= 1.4) return lightGreeen;
            }

        }
        else if (key === 'margin') {

            var templist = []
            playerList.forEach(item => {
                templist.push(item.margin)
            })

            var sortedMarginList = templist.sort((a, b) => b - a).slice(0, 5)
            if (sortedMarginList.includes(value)) return greeen
            else return 'transparent'

        }
    }



    const onMouseEnter = (key) => {
        console.log('key => ',key)
        if (key === '#' || key === 'player_number' || key === 'q1' || key === 'q2' || key === 'q3' || key === 'q4' || key == 'team') return 0;
        else {
            setHover(true)
            setHoverkey(key)
        }
    }

    const GetKeyHighestValue = (key) => {
        return tableHoverformList.find((x)=> x.name===key).fullform
    }

    const onMouseLeave = (key) => {
        setHoverkey(null)
        setHover(false)
    }

    const maniPulateName = (name) => {
        var splitter = name.split(' ')
        if (splitter.length > 1) {
            var initial = splitter[0].substr(0, 1)
            var fullname = ''

            if (splitter.length > 2) fullname = splitter[1] + ' ' + splitter[2]
            else fullname = splitter[1]

            var result = initial + ". " + fullname
            return result
        } else return name

    }

    return (
        <div className='player-table-com rounded-md w-full'>
            {
                props.player_list && props.player_list.length === 0 ?
                    <div>
                        <p className="py-5 capitalize">There's no players in this team</p>
                    </div>
                    :
                    <div >
                        <table className='w-full mt-8 rounded-md tb-shadow text-sm 
                        xl:text-xs 2xl:text-lg text-black overflow-hidden'>
                            <tr className=''>
                                {
                                    props.headerData &&
                                    props.headerData.map((item, index) => {
                                        if (item === 'player' || item == '#' || item == 'team') {
                                            return <th onClick={() => sortBy(item)} style={{ backgroundColor: props.bgcolor }}
                                                className={item === sortedth ? 'text-left cursor-pointer uppercase font-bold text-md px-2 py-2 text-white tb-th-color'
                                                    : 'cursor-pointer uppercase font-bold text-md px-2 py-2 text-left text-white'}>
                                                {item}</th>
                                        }
                                        else if (props.cat !== 4) {
                                            // if(item === 'avd' || item === 'tg' || item === 'afp') sortBy(item)
                                            return <th onMouseEnter={() => {
                                                if (item !== '') onMouseEnter(item)
                                            }}
                                                onMouseLeave={() => {
                                                    onMouseLeave()
                                                }}
                                                onClick={() => sortBy(item)} style={{ backgroundColor: props.bgcolor }}
                                                className={item === sortedth ? 'cursor-pointer uppercase font-bold text-md px-2 py-2 text-white tb-th-color'
                                                    : 'cursor-pointer uppercase font-bold text-md px-2 py-2 text-white'}>
                                                <div className={hoverkey === item ? 'block afl-tb-header-hover-content' : 'hidden tb-header-hover-content'}>
                                                    <p className='text-white'>{hoverkey === item ? GetKeyHighestValue(item) : ''}</p>
                                                </div>
                                                {item}</th>
                                        }
                                        else {
                                            return <th
                                                style={{ backgroundColor: props.bgcolor }}
                                                className={'cursor-pointer uppercase font-bold px-2 py-2 text-white'}>
                                                {item}</th>
                                        }
                                    })
                                }
                            </tr>

                            {
                                playerList &&
                                playerList.map((item, index) => {

                                    var disposaalData = item.disposal
                                    var goalData = item.goals
                                    var fantasyData = item.fantasy_points

                                    let avd = disposaalData && disposaalData.avd !== null ? disposaalData.avd.toFixed(1) : 0
                                    let fiftheen_plus = disposaalData && disposaalData.fiftheen_plus !== null ? disposaalData.fiftheen_plus.toFixed(0) : 0
                                    let twenty_plus = disposaalData && disposaalData.twenty_plus !== null ? disposaalData.twenty_plus.toFixed(0) : 0
                                    
                                    let twentyfive_plus = disposaalData && disposaalData.twentyfive_plus !== null ? disposaalData.twentyfive_plus.toFixed(0) : 0
                                    let thirty_plus = disposaalData && disposaalData.thirty_plus !== null ? disposaalData.thirty_plus.toFixed(0) : 0
                                    let thirtyfive_plus = disposaalData && disposaalData.thirtyfive_plus !== null ? disposaalData.thirtyfive_plus.toFixed(0) : 0

                                    let ld = disposaalData && disposaalData.ld !== null ? disposaalData.ld : 0
                                    let sld = disposaalData && disposaalData.sld !== null ? disposaalData.sld : 0
                                    let tld = disposaalData && disposaalData.tld !== null ? disposaalData.tld : 0
                                    let hd = disposaalData && disposaalData.hd !== null ? disposaalData.hd : 0


                                    if (cat === 1)
                                        return <tr onClick={() => ViewPlayer(item.player_id)} 
                                        className={ props.hthteamid ? 
                                        item.teams && item.teams.id == props.curretteamid  ? 
                                        "cursor-pointer bg-white" :"cursor-pointer tb-hth-data"
                                        :'cursor-pointer tb-data' }
                                        >
                                            {
                                                props.hthteamid &&
                                                <td className='py-2 my-2 lg:py-3 xl:py-3 2xl:py-3 m-auto' width='0%'>
                                                    <img src={item.teams && item.teams.image} className='m-auto w-7 h-7'/>
                                                </td>
                                            }
                                            <td className='py-2 my-2 lg:py-3 xl:py-3 2xl:py-3' width='6%'>{'#' + item.player_number}</td>
                                            <td className='text-left pl-2 w-1/4' width='100px'>{maniPulateName(item.name)}</td>

                                            <td style={{ backgroundColor: colorByValues('avd', avd) }} width='6%'>{avd}</td>
                                            <td style={{ backgroundColor: colorByValues('fiftheen_plus', fiftheen_plus) }} width='6%'>{fiftheen_plus + '%'}</td>
                                            <td style={{ backgroundColor: colorByValues('twenty_plus', twenty_plus) }} width='6%'>{twenty_plus + '%'}</td>
                                            <td style={{ backgroundColor: colorByValues('twentyfive_plus', twentyfive_plus) }} width='6%'>{twentyfive_plus + '%'}</td>
                                            <td style={{ backgroundColor: colorByValues('thirty_plus', thirty_plus) }} width='6%'>{thirty_plus + '%'}</td>
                                            <td style={{ backgroundColor: colorByValues('thirtyfive_plus', thirtyfive_plus) }} width='6%'>{thirtyfive_plus + '%'}</td>

                                            <td style={{ backgroundColor: colorByValues('ld', ld) }} width='6%'>{ld}</td>
                                            <td style={{ backgroundColor: colorByValues('sld', sld) }} width='6%'>{sld}</td>
                                            <td style={{ backgroundColor: colorByValues('tld', tld) }} width='6%'>{tld}</td>
                                            <td style={{ backgroundColor: colorByValues('hd') }} width='6%'>{hd}</td>
                                            <td width='6%'>{disposaalData && disposaalData.games_played !== null ? 
                                            disposaalData.games_played : 0}</td>

                                        </tr>


                                    else if (cat === 2 && goalData != undefined && goalData != null)
                                        return <tr onClick={() => ViewPlayer(item.player_id)} 
                                            className={ props.hthteamid ? 
                                            item.teams && item.teams.id == props.curretteamid  ? 
                                            "cursor-pointer bg-white w-max" :"cursor-pointer tb-hth-data"
                                            :'cursor-pointer tb-data w-max' }
                                            >
                                            
                                            {
                                                props.hthteamid &&
                                                <td className='py-2 my-2 lg:py-3 xl:py-3 2xl:py-3 m-auto' width='0%'>
                                                    <img src={item.teams && item.teams.image} className='m-auto w-7 h-7'/>
                                                </td>
                                            }
                                            <td className='py-2 my-2 lg:py-3 xl:py-3 2xl:py-3'>{'#' + item.player_number}</td>
                                            <td className='text-left pl-2 w-max' >{maniPulateName(item.name)}</td>

                                            <td style={{ backgroundColor: colorBy('tg', goalData.tg) }}>{goalData.tg !== null ? goalData.tg : '-'}</td>
                                            <td style={{ backgroundColor: colorByValues('avg', goalData.avg) }}>{goalData.avg !== null ? goalData.avg.toFixed(1) : '-'}</td>
                                            <td style={{ backgroundColor: colorByValues('one_G', goalData.one_G.toFixed(0)) }}>{goalData.one_G !== null ? goalData.one_G.toFixed(0) + '%' : '-'}</td>
                                            <td style={{ backgroundColor: colorByValues('two_G', goalData.two_G.toFixed(0)) }}>{goalData.two_G !== null ? goalData.two_G.toFixed(0) + '%' : '-'}</td>

                                            <td style={{ backgroundColor: colorByValues('three_G', goalData.three_G.toFixed(0)) }}>{goalData.three_G !== null ? goalData.three_G.toFixed(0) + '%' : '-'}</td>
                                            <td>{goalData.lowest_goal !== null ? goalData.lowest_goal: '-'}</td>
                                            <td>{goalData.highest_goal !== null ? goalData.highest_goal: '-'}</td>
                                            <td>{goalData.games_played !== null ? goalData.games_played: '-'}</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>

                                    else if (cat === 3 && fantasyData != undefined && fantasyData != null)
                                        return <tr onClick={() => ViewPlayer(item.player_id)} 
                                            className={ props.hthteamid ? 
                                            item.teams && item.teams.id == props.curretteamid  ? 
                                            "cursor-pointer bg-white" :"cursor-pointer tb-hth-data"
                                            :'cursor-pointer tb-data' }>

                                            {
                                                props.hthteamid &&
                                                <td className='py-2 my-2 lg:py-3 xl:py-3 2xl:py-3 m-auto' width='0%'>
                                                    <img src={item.teams && item.teams.image} className='m-auto w-7 h-7'/>
                                                </td>
                                            }
                                            <td className='py-2 my-2 lg:py-3 xl:py-3 2xl:py-3'>{'#' + item.player_number}</td>
                                            <td className='text-left pl-2 w-max' >{maniPulateName(item.name)}</td>

                                            <td style={{ backgroundColor: colorBy('afp', fantasyData.afp) }}>{fantasyData.afp.toFixed(1)}</td>
                                            <td style={{ backgroundColor: colorByValues('sixthy_plus', fantasyData.sixthy_plus.toFixed(0)) }}>{fantasyData.sixthy_plus.toFixed(0) + '%'}</td>
                                            <td style={{ backgroundColor: colorByValues('seventy_plus', fantasyData.seventy_plus.toFixed(0)) }}>{fantasyData.seventy_plus.toFixed(0) + '%'}</td>
                                            <td style={{ backgroundColor: colorByValues('eighty_plus', fantasyData.eighty_plus.toFixed(0)) }}>{fantasyData.eighty_plus.toFixed(0) + '%'}</td>

                                            <td style={{ backgroundColor: colorByValues('ninety_plus', fantasyData.ninety_plus.toFixed(0)) }}>{fantasyData.ninety_plus.toFixed(0) + '%'}</td>
                                            <td style={{ backgroundColor: colorByValues('hundred_plus', fantasyData.hundred_plus.toFixed(0)) }}>{fantasyData.hundred_plus.toFixed(0) + '%'}</td>
                                            <td style={{ backgroundColor: colorByValues('onehundredten_plus', fantasyData.onehundredten_plus.toFixed(0)) }}>{fantasyData.onehundredten_plus.toFixed(0) + '%'}</td>
                                            <td style={{ backgroundColor: colorByValues('onehundredtwenty_plus', fantasyData.onehundredtwenty_plus.toFixed(0)) }}>{fantasyData.onehundredtwenty_plus.toFixed(0) + '%'}</td>

                                            <td>{fantasyData.lowest_points !== null ? fantasyData.lowest_points: '-'}</td>
                                            <td>{fantasyData.highest_points !== null ? fantasyData.highest_points: '-'}</td>
                                            <td>{fantasyData.games_played !== null ? fantasyData.games_played: '-'}</td>

                                        </tr>

                                    else if (cat === 4 && item != undefined && item != null)
                                        return <tr className='cursor-pointer tb-data w-full'>

                                            <td className='w-1/5 text-base py-2 lg:py-3 xl:py-3 2xl:py-3'>{new Date(item.dt).getDate() + ' ' + new Date(item.dt).toLocaleDateString('default', { month: 'long' }).substr(0, 3)}</td>
                                            <td className='text-center text-black w-1/5 text-base lg:text-base xl:text-base 2xl:text-base '>{item.opponent}</td>

                                            <td className='w-1/5 text-base'>{item.team_score}</td>
                                            <td className='w-1/5 text-base'>{item.op_score}</td>

                                            <td className='w-1/5 text-base' style={{ color: item.result === 'W' ? 'green' : item.result === 'D' ? 'grey' : 'red', fontWeight: 'bolder' }}>{item.result}</td>
                                            <td className='w-1/5 text-base'>{item.margin}</td>

                                        </tr>
                                })
                            }
                        </table>
                    </div>
            }
        </div>
    )
}

export default PlayersTable;