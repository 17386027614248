import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import "../../utils/styles/teamdetail/teamdetail.scss";
import AppBar from "../AppBar/AppBar";
import PageBar from "../AppBar/PageBar";
import { SponserListCom } from "../SponerList/SponserList";
import BBLPlayerTable from "./BBLPlayerTable";
import AppLoader from "../Loader/Loader";
import {
  GetBBLTeamPlayersList,
  GetBBLTeams,
  GetTeamDetails,
} from "../../Busniess_Logic/API/BBLApi";
import {
  BBLRunsHeader,
  BBLFoursHeader,
  BBLSixesHeader,
  BBLWicketsHeader,
  BBLLengendList,
  TEAM_TABS,
  BBLTeamTBHeader
} from "./BBL_Constant";
import CopyRights from "../CopyRights";
import AdSenseCom from "../AdSense/AdSense";
import { ConvertIntoDateObject } from "../Utils/Utils";

function BBLTeam(props) {

  const [cat, setCat] = useState(1);
  const [teamid, setTeamid] = useState(null);
  const [playerList, setPlayerlist] = useState(null);

  const [currentTeam, setcurrentTeam] = useState(null);
  const [teamList, setTeamlist] = useState([]);
  const [hthPlayerList, setHTHPlayerlist] = useState(null);
  const [hthcurrentTeam, setHthcurrentTeam] = useState(null);

  const [loader, setLoader] = useState(false);
  const [teamloader, setTeamloader] = useState(false);
  const [teamHistory, setTeamhis] = useState({});
  const [currentSeason, setSeason] = useState("");

  var url = new URL(window.location.href);
  var id = url.searchParams.get("id");
  var page = url.searchParams.get("p");
  var season = url.searchParams.get("s");
  var headtohead = url.searchParams.get("hth");

  useEffect(() => {
    // if (!called) {

    if (page) {
      if (page == "r") setCat(1);
      else if (page == "f") setCat(2);
      else if (page == "s") setCat(3);
      else if (page == "w") setCat(4);
      else if (page == "ts") setCat(5);
    }

    let current_season = season ? season : "2022";
    setSeason(current_season);
    setTeamid(id ? id : "1");

    //head to head
    if (headtohead) GetTeamHeadToHeadUserData(headtohead,current_season);

    GetTeamUserData(id ? id : "1", current_season);
    getTeamsData(id ? id : "1", headtohead, current_season);

    window.addEventListener("popstate", (event) => {
      var url = new URL(window.location.href);
      var id = url.searchParams.get("id");
      if (teamid && id)
        if (id !== teamid) {
          //console.log('page reloaded')
          window.location.reload();
        }
    });

    return () => {
      window.removeEventListener("popstate", () => {});
    };
  }, []);

  const GetTeamHeadToHeadUserData = async (id,season) => {
    const res = await GetBBLTeamPlayersList(id,season);
    if (res){
      let manipuldatedData = manipulatePlayerStats(res)
      if(manipuldatedData) setHTHPlayerlist(manipuldatedData);
    } 
  };

  const manipulatePlayerStats=(playerlist)=>{

    try {
      if(playerlist && playerlist.length !== 0){

        playerlist.map((x) => {
  
          let runs = x.run
  
          let fours = x.four
  
          let sixes = x.six
  
          let wickets = x.wicket
  
          if(runs) runs = manipulateObject(runs,Object.keys(runs))
          if(fours) fours = manipulateObject(fours,Object.keys(fours))
          if(sixes) sixes = manipulateObject(sixes,Object.keys(sixes))
          if(wickets) wickets = manipulateObject(wickets,Object.keys(wickets))
  
        })
      }
  
      return playerlist;  
    } catch (error) {
      console.log(error);
      return []
    }
    
  }

  const manipulateObject=(object,keyList)=>{

    let nonPercentageKeys = ['total','low','high','gp','avg','id','season']
    
    if(object && keyList.length!==0){
      keyList.map((key)=>{
        if(!nonPercentageKeys.includes(key)) object[key] = parseInt(parseFloat( (object[key] / object.gp) * 100).toFixed(0)) 
      })
    }

    return object
  }

  const getTeamsData = async (id, headtohead, season) => {
    setTeamloader(true);
    var list = [];
    const res = await GetBBLTeams(season.slice(0,4));
    if (res && res) {
      res.forEach((item) => {
        if (item.id === parseInt(id)) {
          setcurrentTeam(item);
          getTeamMarginData(item.team_id, season);
        } else if (headtohead) {
          if (item.id == parseInt(headtohead)) setHthcurrentTeam(item);
        }
        list.push(item);
      });

      list.sort(function (a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      list = list.filter((a) => a.id != parseInt(id));
      if (headtohead) list = list.filter((a) => a.id != parseInt(headtohead));

      setTeamlist(list);
      setTeamloader(false);
    }
    setTeamloader(false);
  };

  const GetTeamUserData = async (id, season) => {

    setLoader(true);

    const res = await GetBBLTeamPlayersList(id, season);
    if (res) {

      setLoader(false);

      let manipuldatedData = manipulatePlayerStats(res)
      if(manipuldatedData) setPlayerlist(manipuldatedData);

    }

    setLoader(false);
  };

  const redirectToTeam = (id) => {
    if (headtohead)
      props.history.push(`bblteam?id=` + id + `&p=${page}&hth=${headtohead}&s=${season.slice(0, 4)}`);
    else
      props.history.push(
        `bblteam?id=` + id + `&p=${page}&s=${season.slice(0, 4)}`
      );
    window.location.reload();
  };

  const Fullform = (item, category) => {
    if (item.category === category)
      return (
        <div className="flex flex-1 my-1 w-screen md:w-full">
          <h1 className="uppercase mx-4 font-bold w-10 xl:w-auto text-left text-sm 2xl:text-lg">
            {item.name.replaceAll("_", " ")}
          </h1>
          -
          <p className="capitalize mx-4 text-sm 2xl:text-lg text-left">
            {item.fullform.replaceAll("_", " ")}
          </p>
        </div>
      );
  };

  const onchangecat = (catid) => {
    setCat(catid);
    var href = new URL(window.location.href);

    if (catid === 1) href.searchParams.set("p", "r");
    else if (catid === 2) href.searchParams.set("p", "f");
    else if (catid === 3) href.searchParams.set("p", "s");
    else if (catid === 4) href.searchParams.set("p", "w");
    else if (catid === 5) href.searchParams.set("p", "ts");

    window.history.replaceState("", "", href.toString());
  };

  const getTeamMarginData = async (team_name, season) => {
    setTeamloader(true);
    try {
      const res = await GetTeamDetails(team_name, season.slice(0,4));
      if (res) {
        setTeamhis(res);
      }
      setTeamloader(false);
    } catch (error) {
      setTeamloader(false);
      setTeamhis(null);
      console.log(error);
    }
  };

  const teamHistoryData = (tableData, name) => {
    var thlist = tableData;
    return (
      <table className="rounded-lg tb-shadow h-auto w-full bg-white p-2">
        <tr style={{ backgroundColor: currentTeam && currentTeam.bgcolor }}>
          <th
            className="rounded-tl-md uppercase w-full text-white text-left p-3"
            style={{ backgroundColor: currentTeam && currentTeam.bgcolor }}
          >
            {name}
          </th>
          <th className="rounded-tr-md w-1/2">{""}</th>
        </tr>

        {thlist.map((item) => {
          return (
            <tr className="">
              <td className="capitalize text-left text-black pl-5 w-1/2 py-3">
                {item.title.replaceAll("_", " ")}
              </td>
              <td className="w-1/2 text-black pr-5">
                {name === "Runs"
                  ? Math.abs(parseFloat(item.content).toFixed(0).toString()) +
                    "/" +
                    teamHistory.games.length
                  : Math.abs(parseFloat(item.content).toFixed(0).toString())}
              </td>
            </tr>
          );
        })}
      </table>
    );
  };

  const teamTabHeader = (x) => {

    let teamName = x.title.split(" ")

    let isSingleLine = teamName.length <= 1
    return (
      <a
        className={
          cat === x.id
            ? `block text-lg mx-0 my-0 px-1 text-center xl:px-6 2xl:px-6 py-3 no-underline text-white cursor-pointer capitalize font-bold ${
                x.id === 1 ? "rounded-l-md" : "rounded-none"
              } h-16 flex justify-center items-center`
            : `text-lg text-center px-1 lg:px-6 xl:px-6 2xl:px-6 py-3 no-underline text-white cursor-pointer capitalize font-bold h-16 flex justify-center items-center`
        }
        onClick={() => onchangecat(x.id)}
        style={{
          borderBottom: cat === x.id ? "4px solid #ff0058" : "transparent",
        }}
      >
        {x.title}
      </a>
    );
  };

  const getTableHeader = () => {
    if (cat === 1)
      return headtohead
        ? ["team"].concat(BBLRunsHeader)
        : BBLRunsHeader;
    else if (cat === 2)
      return headtohead
        ? ["team"].concat(BBLFoursHeader)
        : BBLFoursHeader;
    else if (cat === 3)
      return headtohead
        ? ["team"].concat(BBLSixesHeader)
        : BBLSixesHeader;
    else if (cat === 4)
      return headtohead
        ? ["team"].concat(BBLWicketsHeader)
        : BBLWicketsHeader;
    else return BBLTeamTBHeader;
  };

  const sortedTeamGamesdata=(teamhistorydata)=>{
    try {
      if(teamhistorydata) {
        if(teamhistorydata.games && teamhistorydata.games.length !== 0)
          return teamhistorydata.games.sort(function (a, b) {
            if (ConvertIntoDateObject(a.date) < ConvertIntoDateObject(b.date)) {
                return -1;
            }
            if (ConvertIntoDateObject(a.date) > ConvertIntoDateObject(b.date)) {
                return 1;
            }
            return 0;
        })
      }else return []  
    } catch (error) {
      console.log(error);
      return []
    }
    
  }

  return (
    <div className="overflow-hidden">
      <AppBar his={props.history} />

      <div className="team-detail-com w-full sm:w-full md:w-full h-full lg:w-4/5 m-auto xl:w-3/4 2xl:w-3/4 p-3 xl:p-0 2xl:p-0 2xl:my-5">
        <div className="xl:flex 2x:flex xl:flex-1 2xl:flex-1">
          <div className="mx-1 w-full xl:w-full 2xl:w-full nfl-header-container">
            <AdSenseCom />
            <PageBar his={props.history} />

            {currentTeam && (
              <div
                style={{ backgroundColor: currentTeam.bgcolor }}
                className="p-3 border border-gray-700 rounded-lg shadow-xl flex flex-1 flex-row items-center justify-start"
              >
                <div className="team-name w-full">
                  <h1 className="text-2xl text-left mx-4 font-bold uppercase text-white">
                    {hthcurrentTeam
                      ? currentTeam.name + " vs\t" + hthcurrentTeam.name
                      : currentTeam.name}
                  </h1>
                </div>
              </div>
            )}

            <div
              className="team-container nfl-team-header
              overflow-y-auto rounded-lg mt-4 mb-0 border border-gray-700 flex flex-1 flex-row items-center 
              w-full justify-between lg:justify-start xl:justify-start 2xl:justify-start "
            >
              {TEAM_TABS.map((x, i) => {
                if (hthcurrentTeam)
                  if (i === 4 ) return <></>;
                  else return teamTabHeader(x);
                else return teamTabHeader(x);
              })}
            </div>

            <div
              className="w-full flex flex-1 flex-row"
              style={{ position: "relative" }}
            >
              <div className="w-full lg:w-4/5 xl:w-4/5 2xl:w-4/5 rounded-xl ">
                {cat === 5 && teamHistory && (
                  <div className="grid grid-cols-1 gap-4 xl:grid-cols-2 2xl:grid-cols-2 w-full xl:mt-4 2xl:mt-4 overflow-hidden">
                    <div>
                      {teamHistory &&
                        teamHistory.runs &&
                        teamHistoryData(teamHistory.runs, "Runs")}
                    </div>
                    <div>
                      {teamHistory &&
                        teamHistory.score_for &&
                        teamHistoryData(teamHistory.score_for, " Score For ")}
                    </div>
                    <div>
                      {teamHistory &&
                        teamHistory.score_against &&
                        teamHistoryData(
                          teamHistory.score_against,
                          " Score Against "
                        )}
                    </div>
                  </div>
                )}

                {playerList && currentTeam && (
                  <div className="overflow-x-auto">
                    <BBLPlayerTable
                      bgcolor={currentTeam.bgcolor}
                      hthteamid={hthcurrentTeam && hthcurrentTeam.team_id}
                      curretteamid={currentTeam && currentTeam.team_id}
                      headerData={getTableHeader()}
                      cat={cat}
                      player_list={
                        cat === 5 && teamHistory
                          ? sortedTeamGamesdata(teamHistory)
                          : hthcurrentTeam && hthPlayerList
                          ? playerList.concat(hthPlayerList)
                          : playerList
                      }
                      season={currentSeason.slice(0, 4)}
                      hthPlayerList={hthPlayerList}
                      his={props.history}
                    />
                  </div>
                )}

                {loader && <AppLoader content="Getting team players" active />}

                {cat < 5 && playerList && (
                  <div className="w-full rounded-lg py-4">
                    <h1 className="text-left px-4 py-4 text-gray-600">
                      Legend
                    </h1>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                      {BBLLengendList.map((item, index) => {
                        return Fullform(item, cat);
                      })}
                    </div>
                  </div>
                )}
              </div>

              {currentTeam && (
                <div className="w-1/5 ml-1 hidden lg:block xl:block 2xl:block rounded-lg">
                  <table className="w-full overflow-hidden mt-8 rounded-md shadow-2xl text-base xl:text-sm 2xl:text-lg">
                    <tr>
                      <th
                        className="text-white p-2 text-left px-3"
                        style={{ backgroundColor: currentTeam.bgcolor }}
                      >
                        BBL Teams
                      </th>
                    </tr>
                    {!teamloader &&
                      teamList &&
                      teamList.map((item) => {
                        return (
                          <tr
                            onClick={() => redirectToTeam(item.id)}
                            className="cursor-pointer"
                          >
                            <td>
                              <div className="flex flex-1 justify-between px-2 py-3 xl:px-2 2xl:px-3 items-center">
                                <p className="uppercase font-bold text-left text-xs xl:text-xs 2xl:text-base">
                                  {item.name}
                                </p>
                                <img
                                  src={item.image}
                                  className="w-6 h-6 object-cover"
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </table>
                </div>
              )}
            </div>
          </div>

          <div className="xl:w-1/3 2xl:w-1/3">
            {!loader && <SponserListCom />}
          </div>
        </div>
      </div>
      {!loader && <CopyRights his={props.history} />}
    </div>
  );
}
export default withRouter(BBLTeam);
