const BASE_URL = "https://apiv3.multihub.com.au/";
// const BASE_URL='http://192.168.1.104:8001/'

export async function GetTeamDetails(id, season) {
  const res = await fetch(
    `https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/${season}/bbl/bbl_team_data/` +
      id +
      ".json",
    {
      method: "GET",
    }
  );
  //console.log(res)
  var jsonData = await res.json();
  //console.log(jsonData)
  return jsonData;
}

export async function GetBBLPlayerDetail(id, season) {
  const res = await fetch(
    `https://fantasy-sports-data.s3.ap-southeast-2.amazonaws.com/${season}/bbl/bbl_player_data/${id}.json`,
    {
      method: "GET",
    }
  );
  //console.log(res)
  var jsonData = await res.json();
  //console.log(jsonData)
  return jsonData;
}

export async function GetBBLTeams(season) {
  // console.log("\n\n Get BBL Teams",BASE_URL + "bbl/team/?season=" + season);
  const res = await fetch(BASE_URL + "bbl/team/?season=" + season, {
    method: "GET",
  });
  //console.log(res)
  var jsonData = await res.json();
  //console.log(jsonData)
  return jsonData;
}

export async function GetBBLTeamPlayersList(teamid, season) {
  const res = await fetch(
    BASE_URL + "bbl/filter/players/?id=" + teamid + "&season=" + season,
    {
      method: "GET",
    }
  );
  //console.log(res)
  var jsonData = await res.json();
  //console.log(jsonData)
  return jsonData;
}

export async function AddBBLTeam(team_data) {
  const res = await fetch(BASE_URL + "bbl/team/", {
    method: "POST",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
    body: JSON.stringify(team_data),
  });
  //console.log(res)
  var jsonData = await res.json();
  //console.log(jsonData)
  return jsonData;
}

export async function GetBBLPlayer(player_id) {
  const res = await fetch(BASE_URL + "bbl/player/" + player_id, {
    method: "GET",
  });
  //console.log(res)
  var jsonData = await res.json();
  //console.log(jsonData)
  return jsonData;
}
