import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import '../../utils/styles/teamdetail/teamdetail.scss'
import AppBar from "../AppBar/AppBar";
import PlayersTable from "./PlayerTable";
import { GetAllTeams, GetTeamHistory, GetTeamPlayersList } from '../../Busniess_Logic/API/AFLApi'
import AppLoader from "../Loader/Loader";
import { tableHoverformList,disposalTBHeader, formTBHeader, goalTBHeader, marginTBHeader, } from "./AFL_Constants";
import PageBar from "../AppBar/PageBar";
import { SponserListCom } from "../SponerList/SponserList";
import CopyRights from "../CopyRights";
import AdSenseCom from "../AdSense/AdSense";
import { getUser } from "../../utils/userinfo";

function TeamDetail(props) {

    const [cat, setCat] = useState(1)
    const [teamid, setTeamid] = useState(null)

    const [playerList, setPlayerlist] = useState(null)
    const [hthPlayerList, setHTHPlayerlist] = useState(null)

    const [currentTeam, setcurrentTeam] = useState(null)
    const [hthcurrentTeam, setHthcurrentTeam] = useState(null)
    const [teamList, setTeamlist] = useState([])

    const [called, setCalled] = useState(false)
    const [loader, setLoader] = useState(false)
    const [teamloader, setTeamloader] = useState(false)

    const [teamHistory, setTeamhis] = useState(null)
    const [teamGraphdata, setTeamgraphdata] = useState([])

    const [score_for, setScoreFor] = useState([])
    const [score_against, setScoreAgainst] = useState([])

    const [currentSeason, setSeason] = useState("")
    const [show_signup,setsignup] = useState(false)

    var url = new URL(window.location.href);
    var id = url.searchParams.get("id");
    var page = url.searchParams.get("p");
    var season = url.searchParams.get("s");
    var headtohead = url.searchParams.get("hth");

    useEffect(() => {
        if (!called) {

            if(page)
                if (page == 'd') setCat(1)
                else if (page == 'g') setCat(2)
                else if (page == 'fp') setCat(3)
            
            setSeason( season ? season : '2022' )
            setTeamid(id ? id :"95")
            GetTeamUserData(id ? id :"95", season ? season : '2022' )

            //head to head
            if(headtohead) 
                GetTeamHeadToHeadUserData(headtohead, season ? season : '2022' )

            getTeamsData(id ? id :"95" , season ? season : '2022',headtohead)
            setCalled(true)
            
        }

        window.addEventListener('popstate', (event) => {

            var url = new URL(window.location.href);
            var id = url.searchParams.get("id");
            if (teamid && id) if (id !== teamid) {
                //console.log('page reloaded')
                window.location.reload()
            }

        });

        return () => {
            window.removeEventListener('popstate', () => { })
        }

    },[])

    const getTeamsData = async (id,season,hthteamid) => {
        setTeamloader(true)
        var list = []
        const res = await GetAllTeams(season)
        if (res.response) {
            if(res.response.length!=0){

                res.response.forEach(item => {
                    if (item.id === parseInt(id)) {
                        setcurrentTeam(item)
                        getteamHistory(item.team_id,season)
                    }
                    else if(hthteamid){
                        if(item.id == parseInt(hthteamid)) setHthcurrentTeam(item)
                    }
                    list.push(item)
                })

                list.sort(function (a, b) {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1;
                    }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                })

                list = list.filter((a)=> a.id!= parseInt(id))
                if(hthteamid)
                list = list.filter((a)=> a.id!= parseInt(hthteamid))
                setTeamlist(list)
                
            }
        }
        setTeamloader(false)

    }

    const GetTeamUserData = async (id,season) => {
        setLoader(true)
        const res = await GetTeamPlayersList(id,season)
        if (res) setPlayerlist(res)
        setLoader(false)
    }

    const GetTeamHeadToHeadUserData = async (id,season) => {
        setLoader(true)
        const res = await GetTeamPlayersList(id,season)
        if (res) setHTHPlayerlist(res)
        setLoader(false)
    }

    const redirectToTeam = (id) => {
        if (headtohead) props.history.push(`team?id=` + id+`&p=${page}&s=${season}&hth=${headtohead}`)
        else props.history.push(`team?id=` + id+`&p=${page}&s=${season}`)
        window.location.reload()
    }

    const Fullform = (item) => {
        return <div className='flex flex-1 my-1 w-full'>
            <h1 className='uppercase mx-4 font-bold w-4 xl:w-4 2xl:w-10 text-left text-sm 2xl:text-lg'>{item.name}</h1>
            -
            <p className='capitalize mx-4 text-sm 2xl:text-lg'>{item.fullform}</p>
        </div>
    }

    const onchangecat = (catid) => {

        try {
            var href = new URL(window.location.href);

            if (catid === 1) {
    
                setCat(catid)
                href.searchParams.set('p', 'd');
                window.history.replaceState('', '', href.toString())
    
            } 
    
            else if(getUser()){
    
                setCat(catid)
    
                if (catid === 2) href.searchParams.set('p', 'g');
                else if (catid === 3) href.searchParams.set('p', 'fp');
                else if (catid === 4) href.searchParams.set('p', 'th');
    
                window.history.replaceState('', '', href.toString())
    
            }else setsignup(!show_signup)    
        } catch (error) {
            
        }

    }

    const getteamHistory = async (team_id,season) => {
        try {
            setTeamloader(true)
            const res = await GetTeamHistory(team_id,season)
            if (res.id) {
                console.log("getteamHistory",res)
                setTeamhis(res)
                generateScoreForData(res.avg_high_low)
                generateScoreAgainstData(res.avg_high_low)
                generateteamData(res.data, res.between_margin)
            }
    
            setTeamloader(false)    
        } catch (error) {
            
        }
        
    }

    const generateScoreForData = (res) => {
        try {
            var list = []
            list = res.slice(0, 3)
            list.push(res[6])
            list.push(res[7])
            setScoreFor(list)    
        } catch (error) {
            
        }
    }

    const generateScoreAgainstData = (res) => {
        try {
            var list = []
            list = res.slice(3, 6)
            list.push(res[8])
            setScoreAgainst(list)    
        } catch (error) {
            
        }
    }

    const generateteamData = (datalen, list) => {
        var tempList = []

        tempList.push({ 'name': " Win 1-24 ", "content": ((list[0].content / datalen.length) * 100).toFixed(2) })
        tempList.push({ 'name': " Win 25+ ", "content": ((list[1].content / datalen.length) * 100).toFixed(2) })

        tempList.push({ 'name': " Win 1-39 ", "content": ((list[2].content / datalen.length) * 100).toFixed(2) })
        tempList.push({ 'name': " Win 40+ ", "content": ((list[3].content / datalen.length) * 100).toFixed(2) })

        tempList.push({ 'name': " Lose 1-24 ", "content": ((list[4].content / datalen.length) * 100).toFixed(2) })
        tempList.push({ 'name': " Lose 25+ ", "content": ((list[5].content / datalen.length) * 100).toFixed(2) })

        tempList.push({ 'name': " Lose 1-39 ", "content": ((list[6].content / datalen.length) * 100).toFixed(2) })
        tempList.push({ 'name': " Lose 40+ ", "content": ((list[7].content / datalen.length) * 100).toFixed(2) })

        //console.log(tempList)
        setTeamgraphdata(tempList)
    }


    const teamHistoryData = (tbtitle, tableData, is_between) => {

        console.log("teamHistoryData",tableData)
        var thlist = tableData

        return (
            <table className='rounded-lg tb-shadow h-auto w-full bg-white p-2'>
                <tr style={{ backgroundColor: currentTeam.bgcolor }}>
                    <th className='rounded-tl-md uppercase w-full text-white text-left p-3'
                        style={{ backgroundColor: currentTeam.bgcolor }}>{tbtitle}</th>
                    <th className='rounded-tr-md w-1/2'>{""}</th>
                </tr>

                {
                    thlist.map((item) => {
                        return <tr className=''>
                            <td className='capitalize text-left text-black pl-5 w-1/2 py-3'>{item.title.replaceAll("_", " ")}</td>
                            {
                                is_between ? <td className='w-1/2 text-black pr-5'>{(item.content.toString() + "/" + teamHistory.data.length)}</td>
                                    :
                                    <td className='w-1/2 text-black pr-5'>{item.content}</td>
                            }

                        </tr>
                    })
                }
            </table>
        )
    }

    const EachStatus = (index, item) => {
        //console.log('EachStatus', item)
        return <div className='my-0 lg:my-2 xl:my-2 2xl:my-2 mx-2 py-2'>

            <div className='flex flex-1 justify-between px-2  my-1 m-auto w-full'>
                <h1 className='text-md m-0 p-0 text-black'>{item.name}</h1>
                <h1 className={index <= 3 ? 'text-md m-0 p-0 text-green-600 font-bold' : "text-md m-0 p-0 text-red-600 font-bold"}>{parseFloat(item.content).toFixed(0) + "%"}</h1>
            </div>
            <div className='flex flex-1 justify-center  my-2'>
                {/* <progress id="green" value={item.content} max="100" className='w-1/2 mx-1 rtl' /> */}
                <progress id={index > 3 ? "red" : "green"} value={parseFloat(item.content).toFixed(0)} max="100" className='w-full mx-1 rounded-xl ' />
            </div>

        </div>
    }

    const PlayedMatchesGraph = () => {

        return (<div className='my-8 rounded-lg'>
            <div className='bg-white p-5 rounded-lg'>

                <div>
                    <h1 className='text-lg text-bold text-black 
                        text-left py-0 my-2 lg:py-5 xl:py-5 2xl:py-5 capitalize'>big win little win</h1>
                    {/* <p className='text-md text-black 
                        text-left py-0 capitalize'>The % of matches each team has won or lost within the <br /> specified points margin </p> */}
                </div>

                <div className='py-2'>

                    <div className='flex-none flex-0 xl:flex xl:flex-1 xl:flex-row 2xl:flex-row 2xl:flex 2xl:flex-1 justify-between '>

                        <div className='w-full'>
                            <h1 className='text-black font-bold text-left text-xl py-2 m-0 px-4'>Win</h1>
                            {
                                teamHistory && teamGraphdata.map((item, index) => {
                                    if (index <= 3)
                                        return EachStatus(index, item)
                                })
                            }
                        </div>

                        <div className='w-full'>
                            <h1 className='text-black font-bold text-left text-xl py-2 px-4'>Loss</h1>
                            {
                                teamHistory && teamGraphdata.map((item, index) => {
                                    if (index > 3)
                                        return EachStatus(index, item)
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }

    return (
        <div className='overflow-hidden'>

            <AppBar 
                his={props.history} 
                show_signup={show_signup} 
                closeSignupModal={()=> setsignup(!show_signup)}
            />

            <div className='team-detail-com w-full sm:w-full md:w-full h-full lg:w-4/5 m-auto xl:w-3/4 2xl:w-3/4 p-3 xl:p-0 2xl:p-0 2xl:my-5 '>
                <div className='xl:flex 2x:flex xl:flex-1 2xl:flex-1'>
                    <div className='mx-1 w-full'>
                    
                        <AdSenseCom />
                        <PageBar his={props.history} />

                        {
                            currentTeam &&
                            <div style={{ backgroundColor: currentTeam.bgcolor }} 
                            className='p-3 border border-gray-700 rounded-lg shadow-xl flex flex-1 flex-row items-center justify-start'>
                                <div className='team-name w-full'>
                                    <h1 className='text-2xl text-left mx-4 font-bold uppercase text-white'>
                                        { hthcurrentTeam ? currentTeam.name+' vs\t'+ hthcurrentTeam.name : currentTeam.name}
                                    </h1>
                                </div>
                            </div>
                        }


                        <div className='team-container rounded-lg mt-4 mb-0 border border-gray-700 flex flex-1 flex-row items-center 
                            w-full justify-between lg:justify-start xl:justify-start 2xl:justify-start'>

                            <a className={cat === 1 ?
                                'text-xs xl:text-lg 2xl:text-lg mx-0 my-0 px-2 text-white float-left text-center xl:px-6 2xl:px-6 py-3 no-underline text-black cursor-pointer capitalize font-bold rounded-l-md'
                                :
                                'text-xs xl:text-lg 2xl:text-lg float-left text-center text-white px-2 lg:px-6 xl:px-6 2xl:px-6 py-3 no-underline text-black cursor-pointer capitalize font-bold'}
                                onClick={() => onchangecat(1)}
                                style={{ borderBottom: cat === 1 ? '4px solid #ff0058' : 'transparent' }}>disposals</a>

                            <a className={cat === 2 ?
                                'text-xs xl:text-lg 2xl:text-lg mx-0 lg:mx-2 xl:mx-2 2xl:mx-2 px-2 text-white float-left text-center xl:px-6 2xl:px-6 px-6 py-3 no-underline text-black cursor-pointer capitalize font-bold'
                                :
                                'text-xs xl:text-lg 2xl:text-lg float-left text-center text-white px-2 lg:px-6 xl:px-6 2xl:px-6 mx-2 py-3 no-underline text-black cursor-pointer capitalize font-bold'}
                                onClick={() => onchangecat(2)}
                                style={{ borderBottom: cat === 2 ? '4px solid #ff0058' : 'transparent' }}>goals</a>

                            <a className={cat === 3 ?
                                'text-xs w-max xl:text-lg 2xl:text-lg mx-0 lg:mx-2 xl:mx-2 2xl:mx-2 px-0 text-white xl:px-6 2xl:px-6 float-left text-center px-0 py-3  no-underline text-black cursor-pointer capitalize font-bold'
                                :
                                'text-xs w-max xl:text-lg 2xl:text-lg float-left text-center text-white px-0 lg:px-6 xl:px-6 2xl:px-6 mx-2 py-3 no-underline text-black cursor-pointer capitalize font-bold'
                            }
                                onClick={() => onchangecat(3)}
                                style={{ borderBottom: cat === 3 ? '4px solid #ff0058' : 'transparent' }}>fantasy points</a>

                            {
                                hthcurrentTeam ? <></> :
                                <a className={cat === 4 ?
                                'text-xs xl:text-lg 2xl:text-lg mx-0 lg:mx-2 xl:mx-2 2xl:mx-2 px-2 text-white float-left xl:px-6 2xl:px-6 text-center px-6 py-3  no-underline text-black cursor-pointer capitalize font-bold rounded-r-md xl:rounded-r-none 2xl:rounded-r-none'
                                :
                                'text-xs xl:text-lg 2xl:text-lg float-left text-center text-white px-2 lg:px-6 xl:px-6 2xl:px-6 mx-2 py-3 no-underline text-black cursor-pointer capitalize font-bold'
                                }
                                onClick={() => onchangecat(4)}
                                style={{ borderBottom: cat === 4 ? '4px solid #ff0058' : 'transparent' }}>margins</a>
                            }
                            

                        </div>


                        <div className='w-full flex flex-1 flex-row' style={{ position: 'relative' }}>


                            <div className='w-full lg:w-4/5 xl:w-4/5 2xl:w-4/5 rounded-xl'>

                                {
                                    teamHistory && 
                                    cat === 4 && 
                                    <div className='rounded-lg overflow-hidden'>{PlayedMatchesGraph()}</div>
                                }

                                {
                                    cat === 4 && 
                                    <div className='flex-none xl:flex 2xl:flex xl:flex-1 2xl:flex-1 w-full xl:mt-4 2xl:mt-0 overflow-hidden'>
                                        <div className='w-full xl:w-1/2 2xl:w-1/2'>
                                            <div className='my-4 xl:my-0 2xl:my-0'>
                                                {
                                                    teamHistory && teamHistory.between_margin && teamHistoryData("margin", teamHistory.between_margin, true)
                                                }
                                            </div>
                                        </div>
                                        <div className='w-full ml-0 xl:w-1/2 2xl:w-1/2 xl:ml-5 2xl:ml-2'>
                                            <div className='my-4 xl:my-0 2xl:my-0'>
                                                {
                                                    teamHistory && score_for && teamHistoryData("score for", score_for, false)
                                                }
                                            </div>
                                        </div>

                                        <div className='w-full ml-0 xl:w-1/2 2xl:w-1/2 xl:ml-5 2xl:ml-2'>
                                            <div className='my-4 xl:my-0 2xl:my-0'>
                                                {
                                                    teamHistory && score_against && teamHistoryData("score against", score_against, false)
                                                }
                                            </div>
                                        </div>

                                    </div>
                                }

                                {
                                    playerList && currentTeam && cat!=4 && 
                                    <div>
                                        <div className='overflow-x-auto'> 
                                            <PlayersTable 
                                            bgcolor={currentTeam.bgcolor} 
                                            hthteamid = {hthcurrentTeam && hthcurrentTeam.id}
                                            curretteamid = {currentTeam && currentTeam.id}
                                            headerData ={
                                                cat == 1 ?   
                                                headtohead ? ["team"].concat(disposalTBHeader) : disposalTBHeader
                                                : cat == 2 ?
                                                headtohead ? ["team"].concat(goalTBHeader) : goalTBHeader
                                                : headtohead ? ["team"].concat(formTBHeader) : formTBHeader
                                            }
                                            cat={cat} 
                                            player_list={ hthPlayerList ? playerList.concat(hthPlayerList) : playerList } 
                                            his={props.history} 
                                            season={currentSeason}
                                            onShowSignup={()=> setsignup(!show_signup)}
                                            />
                                        </div>
                                    </div>
                                    
                                }

                                {
                                    teamHistory && playerList && currentTeam && cat == 4 && <div className='overflow-x-auto'> 
                                        <PlayersTable bgcolor={currentTeam.bgcolor} 
                                        headerData={marginTBHeader}
                                        cat={cat} player_list={teamHistory.data} his={props.history} 
                                        season={currentSeason}/>
                                    </div>
                                }

                                {
                                    cat == 4 && teamHistory==null && 
                                    <div className="py-10">
                                        <p>Team details not found..</p>
                                    </div>
                                }

                                {
                                    loader && 
                                    <div style={{ position: 'relative' }}>
                                        <AppLoader msg={'Getting team player'} />
                                    </div>
                                }

                            </div>
                                
                            {
                                currentTeam &&
                                <div className='w-1/5 ml-1 hidden lg:block xl:block 2xl:block rounded-lg'>
                                    <table className='w-full overflow-hidden mt-8 rounded-md shadow-2xl text-base xl:text-sm 2xl:text-lg'>
                                        <tr>
                                            <th className='text-white p-2 text-left px-3' style={{ backgroundColor: currentTeam.bgcolor }}>AFL Teams</th>
                                        </tr>
                                        {
                                            !teamloader && teamList && teamList.map(item => {
                                                return <tr onClick={() => redirectToTeam(item.id)} className='cursor-pointer'>
                                                    <td><div className='flex flex-1 justify-between px-2 py-3 xl:px-2 2xl:px-3 items-center'>
                                                        <p className='uppercase font-bold text-left text-xs xl:text-xs 2xl:text-base'>{item.name}</p>
                                                        <img src={item.image} className='w-6 h-6' />
                                                    </div></td>
                                                </tr>
                                            })

                                        }
                                    </table>

                                </div>
                            }
                        </div>

                        {
                            cat !== 0 && cat !== 4 && playerList &&
                            <div className='w-4/5 rounded-lg py-4'>
                                <h1 className='text-left px-4 py-4 text-gray-600'>Legend</h1>
                                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-2'>
                                    {
                                        tableHoverformList.map((item, index) => {
                                            if (cat === 1) { if (index >= 0 && index <= 10) return Fullform(item) }
                                            if (cat === 2) { if (index >= 11 && index <= 18) return Fullform(item) }
                                            if (cat === 3) { if (index >= 19) return Fullform(item) }
                                        })
                                    }
                                </div>
                            </div>
                        }

                    </div>

                    <div className="xl:w-1/3 2xl:w-1/3">
                        {
                            !loader && <SponserListCom />
                        }
                    </div>

                </div>
            </div>

            {
                !loader && <CopyRights his={props.history}/>
            }

        </div>
    )
}
export default withRouter(TeamDetail);